<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>角色管理</el-breadcrumb-item>
      <el-breadcrumb-item>角色列表</el-breadcrumb-item>
    </el-breadcrumb>


    <!-- 卡片视图 -->
    <el-card>
      <el-row :gutter="10">
        <el-col :span="5">
          <el-input
            placeholder="请输入角色名称"
            v-model="queryParams.queryStr"
            clearable
            @clear="getRoleList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getRoleList"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="5">
          <el-button 
            type="primary" 
            @click="addRoleDialogVisible = true" 
            v-has="'sys:role:add'"
            icon="el-icon-plus"
            >添加角色</el-button
          >
        </el-col>
      </el-row>


      <!-- 表格数据 -->
      <el-table :data="roleInfoList" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column label="角色名称" prop="roleName"></el-table-column>
        <el-table-column label="角色类型" width="100px">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.roleType === 0">项目角色</el-tag>
            <el-tag v-else-if="scope.row.roleType === 1" type="success"
              >运营角色</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="超级管理员" width="90px">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.isSupadmin === 0" type="info"
              >否</el-tag>
            <el-tag v-else-if="scope.row.isSupadmin === 1" type="success"
              >是</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="是否有效" width="80px">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.availableBl"
              @change="roleStateChanged(scope.row)"
              v-has="'sys:role:edit'"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="角色编码" prop="roleCode"></el-table-column>
        <el-table-column label="备注" prop="remark"></el-table-column>
        <el-table-column label="创建时间" prop="createTime" width="160px"></el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="修改"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="showEditDialog(scope.row.id)"
                v-has="'sys:role:edit'"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="removeById(scope.row.id)"
                v-has="'sys:role:delete'"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="分配权限"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="warning"
                icon="el-icon-lock"
                size="mini"
                @click="showRoleAuthDialog(scope.row.id)"
                v-has="'sys:role:auth'"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>


      <!-- 分页组件 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>


      <!-- 添加角色 -->
      <el-dialog
        title="添加角色"
        :visible.sync="addRoleDialogVisible"
        width="40%"
        @close="addDialogClosed"
        :append-to-body="true"
      >
        <el-form :model="addRoleInfoForm" :rules="addRules" ref="addRoleRef">
          <el-form-item label="角色名称" label-width="120px" prop="roleName">
            <el-input
              autocomplete="off"
              v-model="addRoleInfoForm.roleName"
              clearable
              placeholder="最多输入20个字"
            ></el-input>
          </el-form-item>
          <el-form-item label="角色编码" label-width="120px" prop="roleCode">
            <el-input
              autocomplete="off"
              v-model="addRoleInfoForm.roleCode"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="角色类型" label-width="120px" prop="roleType">
            <el-radio-group v-model="addRoleInfoForm.roleType">
                <el-radio :label="0">项目角色</el-radio>
                <el-radio :label="1">运营角色</el-radio>
            </el-radio-group><br/>
            <span style="color: rgb(175, 175, 175);">
              项目角色：客户方相关人员，数据权限仅限某个项目；<br/>
              运营角色：我方内部员工，不受项目维度影响，有所有数据权限。
            </span>
          </el-form-item>
          <el-form-item label="超级管理员" label-width="120px" prop="isSupadmin">
            <el-radio-group v-model="addRoleInfoForm.isSupadmin">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="备注" label-width="120px" prop="remark">
            <el-input
              autocomplete="off"
              v-model="addRoleInfoForm.remark"
              clearable
              placeholder="最多输入50个字"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addRoleDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addRoleInfoSubmit()">提 交</el-button>
        </span>
      </el-dialog>


      <!-- 修改角色 -->
      <el-dialog
        title="修改角色"
        :visible.sync="editRoleDialogVisible"
        width="40%"
        @close="editDialogClosed"
      >
        <el-form :model="editRoleInfoForm" :rules="addRules" ref="editRoleRef">
          <el-form-item label="角色名称" label-width="120px" prop="roleName">
            <el-input
              autocomplete="off"
              v-model="editRoleInfoForm.roleName"
              clearable
              placeholder="最多输入20个字"
            ></el-input>
          </el-form-item>
          <el-form-item label="角色编码" label-width="120px" prop="roleCode">
            <el-input
              autocomplete="off"
              v-model="editRoleInfoForm.roleCode"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="角色类型" label-width="120px" prop="roleType">
            <el-radio-group v-model="editRoleInfoForm.roleType">
                <el-radio :label="0">项目角色</el-radio>
                <el-radio :label="1">运营角色</el-radio>
            </el-radio-group><br/>
            <span style="color: rgb(175, 175, 175);">
              项目角色：客户方相关人员，数据权限仅限某个项目；<br/>
              运营角色：我方内部员工，不受项目维度影响，有所有数据权限。
            </span>
          </el-form-item>
          <el-form-item label="超级管理员" label-width="120px" prop="isSupadmin">
            <el-radio-group v-model="editRoleInfoForm.isSupadmin">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="备注" label-width="120px" prop="remark">
            <el-input
              autocomplete="off"
              v-model="editRoleInfoForm.remark"
              clearable
              placeholder="最多输入50个字"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editRoleDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="editRoleInfoSubmit()">提 交</el-button>
        </span>
      </el-dialog>


      <!-- 角色授权 -->
      <el-dialog
        title="角色授权"
        :visible.sync="authRoleDialogVisible"
        width="40%"
        @close="authDialogClosed"
      >
        <div style="height:350px; overflow: auto;">
          <el-tree 
            :data="treeData" 
            :props="treeProps" 
            show-checkbox
            :default-expand-all="true"
            :check-strictly="true"
            node-key="id"
            :default-checked-keys="treeDefaultChecked"
            ref="authTreeRef">
          </el-tree>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="authRoleDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="roleAuthSubmit()">提 交</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      addRules: {
        roleName: [
          { required: true, message: "请输入角色名称", trigger: "blur" },
          {
            min: 2,
            max: 20,
            message: "最多只能输入20个字",
            trigger: "blur",
          },
        ],
        roleCode: [{ required: true, message: "请输入角色编码", trigger: "blur" }],
        remark: [
          {
            min: 2,
            max: 50,
            message: "最多只能输入50个字",
            trigger: "blur",
          },
        ]
      },
      queryParams: {
        queryStr: "",
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      addRoleInfoForm: {
        roleName: "",
        roleCode: "",
        roleType: 0,
        remark: "",
        isSupadmin: 0
      },
      editRoleInfoForm: {

      },
      roleInfoList: [],
      addRoleDialogVisible: false,
      editRoleDialogVisible: false,
      authRoleDialogVisible: false,

      // 资源树参数
      treeData: [],
      treeProps: {
        children: 'children',
        label: 'rsName'
      },
      treeDefaultChecked: [],
      currentRoleId: null,
    }
  },
  created() {
    this.getRoleList();
  },
  methods: {
    addDialogClosed() {
      this.$refs.addRoleRef.resetFields();
    },
    editDialogClosed() {
      // this.$refs.editRoleRef.resetFields();
    },
    handleSizeChange(newSize) {
      this.queryParams.pageSize = newSize;
      this.getRoleList();
    },
    handleCurrentChange(newPage) {
      this.queryParams.pageNo = newPage;
      this.getRoleList();
    },
    async getRoleList() {
      const { data: res } = await this.$http.get("role/find/byPage", {
        params: this.queryParams,
      });
      if (res.code == 0) {
        this.roleInfoList = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.error(res.msg);
      }
    },
    async showEditDialog(id) {
      const { data: res } = await this.$http.get("role/find/" + id);
      if (res.code == 0) {
        this.editRoleInfoForm = res.data;
        this.editRoleDialogVisible = true;
      } else {
        this.$message.error(res.msg);
      }
    },
    editRoleInfoSubmit() {
      this.$refs.editRoleRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "role/update",
            this.editRoleInfoForm
          );
          if (res.code == 0) {
            this.$message.success("修改成功");
            this.getRoleList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.editRoleDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    async removeById(id) {
      const confirmResult = await this.$confirm("是否确认删除该角色?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.delete("role/remove/" + id);
      if (res.code == 0) {
        this.$message.success("删除角色成功！");
        this.getRoleList();
      } else {
        return this.$message.error("删除角色失败！");
      }
    },
    async roleStateChanged(roleInfo) {
      const confirmResult = await this.$confirm(
        "此操作将影响角色用户相关功能, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        this.$message.info("已取消操作");
        roleInfo.availableBl = !roleInfo.availableBl;
        return false;
      }

      const { data: res } = await this.$http.get("role/change/status", {
        params: {
          roleId: roleInfo.id,
          available: roleInfo.availableBl
        }
      });

      if (res.code == 0) {
        this.$message.success("修改成功");
      } else {
        this.$message.error(res.msg);
      }
    },
    addRoleInfoSubmit() {
      this.$refs.addRoleRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "role/add",
            this.addRoleInfoForm
          );
          if (res.code == 0) {
            this.$message.success("添加成功");
            this.getRoleList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.addRoleDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    async showRoleAuthDialog(id) {
      const { data: res1 } = await this.$http.get("role/find/rsids/" + id);
      if (res1.code == 0) {
        console.log(res1.data);
        this.treeDefaultChecked = res1.data;
      }

      const { data: res } = await this.$http.get("resource/find/all/tree");
      if (res.code == 0) {
        this.treeData = res.data;
        this.authRoleDialogVisible = true;
        this.currentRoleId = id;
      } else {
        this.$message.error(res.msg);
      }
    },
    authDialogClosed() {

    },
    async roleAuthSubmit() {
      let ids = this.$refs.authTreeRef.getCheckedKeys();
      if (ids.length == 0) {
        this.$message.error('请授权再提交.');
        return false;
      }
      
      const { data: res } = await this.$http.post("role/auth", {
        rsIds: ids,
        roleId: this.currentRoleId
      });
      if (res.code == 0) {
        this.$message.success("授权成功");
      } else {
        this.$message.error(res.msg);
      }
    }
  }
}
</script>

<style lang="less" scoped>

</style>