<template>
  <div>
    <el-row>
      <el-select
        v-model="queryParams.projectCode"
        placeholder="请选择项目"
        clearable
        @clear="getDeviceList"
        @change="getDeviceList"
      >
        <el-option
          v-for="item in projectInfoList"
          :key="item.projectCode"
          :label="item.projectName"
          :value="item.projectCode"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="queryParams.deviceCode"
        placeholder="请选择设备"
        clearable
        @change="getStatisData"
      >
        <el-option
          v-for="item in deviceInfoList"
          :key="item.deviceCode"
          :label="item.name"
          :value="item.deviceCode"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="statisYear"
        placeholder="请选择年份"
        @change="getStatisData"
      >
        <el-option label="2020" :value="2020"></el-option>
        <el-option label="2021" :value="2021"></el-option>
        <el-option label="2022" :value="2022"></el-option>
        <el-option label="2023" :value="2023"></el-option>
        <el-option label="2024" :value="2024"></el-option>
        <el-option label="2025" :value="2025"></el-option>
        <el-option label="2026" :value="2026"></el-option>
        <el-option label="2027" :value="2027"></el-option>
        <el-option label="2028" :value="2028"></el-option>
        <el-option label="2029" :value="2029"></el-option>
        <el-option label="2030" :value="2030"></el-option>
      </el-select>
    </el-row>
    <el-row>
      <div style="height: 340px; margin-top: 30px; overflow: auto">
        <div
          id="diagramChartTS"
          class="chart"
          style="width: 99%; height: 99%"
        ></div>
      </div>
      <!-- 表格数据 -->
      <el-table :data="dataInfoList" border>
        <el-table-column type="index"></el-table-column>
        <el-table-column label="设备名称" prop="deviceName" width="180px"></el-table-column>
        <el-table-column label="所属断面" prop="dmName" width="120px"></el-table-column>
        <el-table-column label="时间" width="120px">
          <template slot-scope="scope">
            {{scope.row.stYear}}年{{scope.row.stMonth}}月
          </template>
        </el-table-column>
        <el-table-column label="最小值（米）" prop="minValue"></el-table-column>
        <el-table-column label="最大值（米）" prop="maxValue"></el-table-column>
        <el-table-column label="平均值（米）" prop="averageValue"></el-table-column>
      </el-table>
    </el-row>
  </div>
</template>

<script>
var icnow = new Date();
export default {
  inheritAttrs: false,
  data() {
    return {
      queryParams: {
        projectCode: "",
        deviceCode: "",
        pageNo: 1,
        pageSize: 10,
      },
      projectInfoList: [],
      deviceInfoList: [],
      dataInfoList: [],
      statisYear: icnow.getFullYear(),
    };
  },
  created() {
    this.getAllProject();
  },
  methods: {
    async getAllProject() {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        this.projectInfoList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getDeviceList() {
      const { data: res } = await this.$http.post("device/shenya/find/page", {
        pageNo: 1,
        pageSize: 900,
        projectCode: this.queryParams.projectCode,
      });
      if (res.code == 0) {
        if (res.data == null) {
          this.deviceInfoList = [];
          return;
        }

        var dArray = [];
        res.data.list.forEach(d => {
          if (d.slyali === '0') {
            dArray.push(d);
          }
        });
        this.deviceInfoList = dArray;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getStatisData() {
      const { data: res } = await this.$http.get("statis/shenya/find/data", {
        params: {
          deviceCode: this.queryParams.deviceCode,
          year: this.statisYear,
        }
      });
      if (res.code == 0 && res.data != null) {
        this.dataInfoList = res.data;
        this.drawDiagram(res.data);
      } else {
        this.dataInfoList = [];
        this.drawDiagram(null);
      }
    },
    drawDiagram(dataList) {
      // 基于准备好的dom，初始化echarts实例
      if (this.diagramChart == null) {
        this.diagramChart = this.$echarts.init(
          document.getElementById("diagramChartTS")
        );
      }

      if (dataList == null || dataList.length == 0) {
        this.diagramChart.clear();
        return;
      }

      let minValueData = [];
      let maxValueData = [];
      let averValueData = [];
      let timeData = [];
      let devName = "";
      for (let i = 0; i < dataList.length; i++) {
        let v1 = this.common.floatFormat(dataList[i].minValue, 4);
        minValueData.push(v1);

        let v2 = this.common.floatFormat(dataList[i].maxValue, 4);
        maxValueData.push(v2);

        let v3 = this.common.floatFormat(dataList[i].averageValue, 4);
        averValueData.push(v3);
        
        timeData.push(dataList[i].stYear + "-" + dataList[i].stMonth);
        devName = dataList[i].deviceName;
      }

      // Y轴类型
      let yAxisType = 'value';
      let option = {
        title: {
          text: this.statisYear + "年浸润线透视分析（单位：m）",
          textStyle: {
            fontSize: 14,
          },
          subtext: "设备：" + devName,
          subtextStyle: {
            fontSize: 12,
          }
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["最小值","最大值","平均值"],
        },
        grid: {
          top: "90",
          bottom: "30",
          left: "60"
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: timeData,
        },
        yAxis: {
          type: yAxisType,
          axisLabel: {
            formatter: '{value} m'
          },
        },
        series: [
          {
            name: "最小值",
            type: "line",
            data: minValueData,
            markPoint: {
              data: [
                { type: "max", name: "最大值" },
                { type: "min", name: "最小值" },
              ],
            },
          },
          {
            name: "最大值",
            type: "line",
            data: maxValueData,
            markPoint: {
              data: [
                { type: "max", name: "最大值" },
                { type: "min", name: "最小值" },
              ],
            },
          },
          {
            name: "平均值",
            type: "line",
            data: averValueData,
            markPoint: {
              data: [
                { type: "max", name: "最大值" },
                { type: "min", name: "最小值" },
              ],
            },
          },
        ],
      };

      // 绘制图表
      this.diagramChart.clear();
      this.diagramChart.setOption(option);
    }
  },
};
</script>