<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>数据中心</el-breadcrumb-item>
      <el-breadcrumb-item>作业日志</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图 -->
    <el-card>
      <el-row :gutter="10">
        <el-col :span="3">
          <el-select
            v-model="queryParams.jobType"
            placeholder="请选择类型"
            clearable
            @clear="getLogList"
            @change="getLogList"
          >
            <el-option
              v-has="'sys:mtr:gantan'"
              label="干滩"
              :value="10"
            ></el-option>
            <el-option
              v-has="'sys:mtr:wuwei'"
              label="库水位"
              :value="20"
            ></el-option>
            <el-option
              v-has="'sys:mtr:quanzhan'"
              label="位移"
              :value="30"
            ></el-option>
            <el-option
              v-has="'sys:mtr:cexie'"
              label="内部测斜"
              :value="40"
            ></el-option>
            <el-option
              v-has="'sys:mtr:shenya'"
              label="浸润线"
              :value="50"
            ></el-option>
            <el-option
              v-has="'sys:mtr:yuliang'"
              label="降雨量"
              :value="60"
            ></el-option>
            <el-option
              v-has="'sys:mtr:zhuodu'"
              label="混浊度"
              :value="91"
            ></el-option>
            <el-option
              v-has="'sys:mtr:mingqu'"
              label="明渠流量"
              :value="22"
            ></el-option>
            <el-option label="MCU" :value="90"></el-option>
          </el-select>
        </el-col>
        <el-col :span="21">
          <el-date-picker
            format="yyyy-MM-dd HH:mm:ss"
            v-model="timeRange"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="getLogList"
          >
          </el-date-picker>
          <span style="color: red; font-size: 14px; float: right"
            >温馨提示：作业日志仅保留30天.</span
          >
        </el-col>
      </el-row>

      <!-- 表格数据 -->
      <el-table :data="logInfoList" border stripe>
        <el-table-column label="执行结果" prop="runResult" width="80px">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.runResult === 1" type="success"
              >成功</el-tag
            >
            <el-tag v-else-if="scope.row.runResult === 0">执行中</el-tag>
            <el-tag v-else-if="scope.row.runResult === 2" type="warning"
              >无数据</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          label="任务内容"
          prop="content"
          width="260px"
        ></el-table-column>
        <el-table-column
          label="响应结果"
          prop="resultContent"
        ></el-table-column>
        <el-table-column
          label="执行时间"
          prop="runTime"
          width="180px"
        ></el-table-column>
      </el-table>

      <!-- 分页组件 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      queryParams: {
        jobType: null,
        pageNo: 1,
        pageSize: 10,
        startTime: null,
        endTime: null,
      },
      total: 0,
      logInfoList: [],
      timeRange: [],
    }
  },
  created () {
    this.getLogList();
  },
  methods: {
    handleSizeChange (newSize) {
      this.queryParams.pageSize = newSize;
      this.getLogList();
    },
    handleCurrentChange (newPage) {
      this.queryParams.pageNo = newPage;
      this.getLogList();
    },
    async getLogList () {
      if (this.timeRange != null && this.timeRange.length > 0) {
        this.queryParams.startTime = this.common.dateFormat('YYYY-MM-dd HH:mm:ss', this.timeRange[0]);
        this.queryParams.endTime = this.common.dateFormat('YYYY-MM-dd HH:mm:ss', this.timeRange[1]);
      } else {
        this.queryParams.startTime = null;
        this.queryParams.endTime = null;
      }
      const { data: res } = await this.$http.post("logs/joblogs/page", this.queryParams);
      if (res.code == 0) {
        this.logInfoList = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.error(res.msg);
      }
    }
  }
}
</script>

<style lang="less" scoped>
</style>