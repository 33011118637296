<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>消息管理</el-breadcrumb-item>
      <el-breadcrumb-item>消息配置</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row>
        <el-col :span="8">
          <label
            >接收站内信：
            <el-switch
              v-model="messageConfig.sendInternalBl"
              @change="sendInternalChanged(messageConfig)"
              v-has="'sys:msg:config:edit'"
            >
            </el-switch>
          </label>
        </el-col>
        <el-col :span="8">
          <label
            >接收短信：
            <el-switch
              v-model="messageConfig.sendSmsBl"
              @change="sendSmsChanged(messageConfig)"
              v-has="'sys:msg:config:edit'"
            >
            </el-switch>
          </label>
        </el-col>
        <el-col :span="8">
          <label
            >接收微信：
            <el-switch
              v-model="messageConfig.sendWxBl"
              @change="sendWxChanged(messageConfig)"
              v-has="'sys:msg:config:edit'"
            >
            </el-switch>
          </label>
        </el-col>
      </el-row>
    </el-card>

    <el-card>
      <label>短信接收人员列表：</label>

      <el-row>
        <el-button
          type="primary"
          @click="showAddReceiverDialog()"
          icon="el-icon-plus"
          class="label-css"
          v-has="'sys:msg:config:receiver:edit'"
          >添加接收人</el-button
        >
      </el-row>

      <!-- 表格数据 -->
      <el-table :data="smsReceiverInfoList" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column
          label="姓名"
          prop="realName"
          width="120px"
        ></el-table-column>
        <el-table-column
          label="职务"
          prop="jobTitle"
          width="140px"
        ></el-table-column>
        <el-table-column
          label="手机号"
          prop="phone"
          width="140px"
        ></el-table-column>
        <el-table-column label="邮箱" prop="email"></el-table-column>
        <el-table-column label="预警等级" width="120px">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.alarmLevel === 0">蓝色预警</el-tag>
            <el-tag v-else-if="scope.row.alarmLevel === 1" class="yellowClass"
              >黄色预警</el-tag
            >
            <el-tag v-else-if="scope.row.alarmLevel === 2" type="warning"
              >橙色预警</el-tag
            >
            <el-tag v-else-if="scope.row.alarmLevel === 3" type="danger"
              >红色预警</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="是否有效" width="90px">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.availableBl"
              @change="receiverStateChanged(scope.row)"
              v-has="'sys:msg:config:receiver:edit'"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          label="创建时间"
          prop="createTime"
          width="160px"
        ></el-table-column>
        <el-table-column label="操作" width="140px">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="修改"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="showEditDialog(scope.row.id)"
                v-has="'sys:msg:config:receiver:edit'"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="removeById(scope.row.id)"
                v-has="'sys:msg:config:receiver:delete'"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页组件 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>

      <!-- 添加接收人 -->
      <el-dialog
        title="添加接收人"
        :visible.sync="addReceiverDialogVisible"
        width="40%"
        @close="addReceiverDialogClosed"
        :append-to-body="true"
      >
        <el-form
          :model="addReceiverInfoForm"
          :rules="addRules"
          ref="addReceiverRef"
        >
          <el-form-item label="姓名" label-width="120px" prop="realName">
            <el-input
              autocomplete="off"
              v-model="addReceiverInfoForm.realName"
              clearable
              placeholder="最多输入20个字"
            ></el-input>
          </el-form-item>
          <el-form-item label="职务" label-width="120px" prop="jobTitle">
            <el-input
              autocomplete="off"
              v-model="addReceiverInfoForm.jobTitle"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号" label-width="120px" prop="phone">
            <el-input
              autocomplete="off"
              v-model="addReceiverInfoForm.phone"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="邮箱" label-width="120px" prop="email">
            <el-input
              autocomplete="off"
              v-model="addReceiverInfoForm.email"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="预警等级" label-width="120px" prop="alarmLevel">
            <el-radio-group v-model="addReceiverInfoForm.alarmLevel">
              <el-radio :label="0">蓝色预警</el-radio>
              <el-radio :label="1">黄色预警</el-radio>
              <el-radio :label="2">橙色预警</el-radio>
              <el-radio :label="3">红色预警</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="备注" label-width="120px" prop="remark">
            <el-input
              autocomplete="off"
              v-model="addReceiverInfoForm.remark"
              clearable
              placeholder="最多输入50个字"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addReceiverDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addReceiverInfoSubmit()"
            >提 交</el-button
          >
        </span>
      </el-dialog>

      <!-- 修改接收人 -->
      <el-dialog
        title="修改接收人"
        :visible.sync="editReceiverDialogVisible"
        width="40%"
        @close="editReceiverDialogClosed"
        :append-to-body="true"
      >
        <el-form
          :model="editReceiverInfoForm"
          :rules="addRules"
          ref="editReceiverRef"
        >
          <el-form-item label="姓名" label-width="120px" prop="realName">
            <el-input
              autocomplete="off"
              v-model="editReceiverInfoForm.realName"
              clearable
              placeholder="最多输入20个字"
            ></el-input>
          </el-form-item>
          <el-form-item label="职务" label-width="120px" prop="jobTitle">
            <el-input
              autocomplete="off"
              v-model="editReceiverInfoForm.jobTitle"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号" label-width="120px" prop="phone">
            <el-input
              autocomplete="off"
              v-model="editReceiverInfoForm.phone"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="邮箱" label-width="120px" prop="email">
            <el-input
              autocomplete="off"
              v-model="editReceiverInfoForm.email"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="预警等级" label-width="120px" prop="alarmLevel">
            <el-radio-group v-model="editReceiverInfoForm.alarmLevel">
              <el-radio :label="0">蓝色预警</el-radio>
              <el-radio :label="1">黄色预警</el-radio>
              <el-radio :label="2">橙色预警</el-radio>
              <el-radio :label="3">红色预警</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="备注" label-width="120px" prop="remark">
            <el-input
              autocomplete="off"
              v-model="editReceiverInfoForm.remark"
              clearable
              placeholder="最多输入50个字"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editReceiverDialogVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="editReceiverInfoSubmit()"
            >提 交</el-button
          >
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    // 自定义手机号规则
    var checkMobile = (rule, value, callback) => {
      const regMobile = /^1[34578]\d{9}$/
      if (regMobile.test(value)) {
        return callback()
      }
      // 返回一个错误提示
      callback(new Error('请输入合法的手机号码'))
    }

    // 自定义邮箱规则
    var checkEmail = (rule, value, callback) => {
      if (value.length == 0) {
        return callback()
      }
      const regEmail = /^\w+@\w+(\.\w+)+$/
      if (regEmail.test(value)) {
        // 合法邮箱
        return callback()
      }
      callback(new Error('请输入合法邮箱'))
    }

    return {
      addRules: {
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" },
        { validator: checkMobile, trigger: 'blur' }],
        email: [{ validator: checkEmail, trigger: 'blur' }],
        realName: [{ required: true, message: "请输入姓名", trigger: "blur" }]
      },
      queryParams: {
        pageNo: 1,
        pageSize: 10
      },
      total: 0,
      messageConfig: {

      },
      addReceiverInfoForm: {
        realName: "",
        jobTitle: "",
        phone: "",
        email: "",
        alarmLevel: 0,
        remark: ""
      },
      editReceiverInfoForm: {

      },
      smsReceiverInfoList: [],
      addReceiverDialogVisible: false,
      editReceiverDialogVisible: false,
      canEdit: true,
    }
  },
  created () {
    this.getMessageConfig();
    this.getReceiverList();
  },
  methods: {
    async getMessageConfig () {
      const { data: res } = await this.$http.get("message/find/config");
      if (res.code == 0) {
        this.messageConfig = res.data;
        this.canEdit = true;
        // console.log(this.messageConfig);
      } else {
        this.canEdit = false;
        this.$message.error(res.msg);
      }
    },
    async sendInternalChanged (config) {
      if (!this.canEdit) {
        return false;
      }
      const confirmResult = await this.$confirm(
        "此操作将使用户无法接收到站内信, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        this.$message.info("已取消操作");
        config.sendInternalBl = !config.sendInternalBl;
        return false;
      }

      const { data: res } = await this.$http.get("message/change/internal/msg", {
        params: {
          configId: config.id,
          on: config.sendInternalBl
        }
      });

      if (res.code == 0) {
        this.$message.success("修改成功");
      } else {
        this.$message.error(res.msg);
      }
    },
    async sendSmsChanged (config) {
      if (!this.canEdit) {
        return false;
      }

      const confirmResult = await this.$confirm(
        "此操作将使用户无法接收到短信, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        this.$message.info("已取消操作");
        config.sendSmsBl = !config.sendSmsBl;
        return false;
      }

      const { data: res } = await this.$http.get("message/change/sms/msg", {
        params: {
          configId: config.id,
          on: config.sendSmsBl
        }
      });

      if (res.code == 0) {
        this.$message.success("修改成功");
      } else {
        this.$message.error(res.msg);
      }
    },
    async sendWxChanged (config) {
      if (!this.canEdit) {
        return false;
      }

      const confirmResult = await this.$confirm(
        "此操作将使用户无法接收到微信消息, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        this.$message.info("已取消操作");
        config.sendWxBl = !config.sendWxBl;
        return false;
      }

      const { data: res } = await this.$http.get("message/change/wx/msg", {
        params: {
          configId: config.id,
          on: config.sendWxBl
        }
      });

      if (res.code == 0) {
        this.$message.success("修改成功");
      } else {
        this.$message.error(res.msg);
      }
    },
    handleSizeChange (newSize) {
      this.queryParams.pageSize = newSize;
      this.getReceiverList();
    },
    handleCurrentChange (newPage) {
      this.queryParams.pageNo = newPage;
      this.getReceiverList();
    },
    addReceiverInfoSubmit () {
      this.$refs.addReceiverRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "message/add/sms/receiver",
            this.addReceiverInfoForm
          );
          if (res.code == 0) {
            this.$message.success("添加成功");
            this.getReceiverList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.addReceiverDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    addReceiverDialogClosed () {
      this.$refs.addReceiverRef.resetFields();
    },
    async getReceiverList () {
      const { data: res } = await this.$http.get("message/sms/receiver/page", {
        params: this.queryParams
      });
      if (res.code == 0) {
        this.smsReceiverInfoList = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.error(res.msg);
      }
    },
    async receiverStateChanged (info) {
      const confirmResult = await this.$confirm(
        "此操作将使用户无法接收到短信, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        this.$message.info("已取消操作");
        info.availableBl = !info.availableBl;
        return false;
      }

      const { data: res } = await this.$http.get("message/change/receiver/status", {
        params: {
          receiverId: info.id,
          status: info.availableBl
        }
      });

      if (res.code == 0) {
        this.$message.success("修改成功");
      } else {
        this.$message.error(res.msg);
      }
    },
    async removeById (id) {
      const confirmResult = await this.$confirm("是否确认删除该人员?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.delete("message/remove/sms/receiver/" + id);
      if (res.code == 0) {
        this.$message.success("删除成功！");
        this.getReceiverList();
      } else {
        return this.$message.error("删除失败！");
      }
    },
    editReceiverDialogClosed () {
      this.$refs.editReceiverRef.resetFields();
    },
    async editReceiverInfoSubmit () {
      this.$refs.editReceiverRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "message/update/sms/receiver",
            this.editReceiverInfoForm
          );
          if (res.code == 0) {
            this.$message.success("修改成功");
            this.getReceiverList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.editReceiverDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    async showEditDialog (id) {
      const { data: res } = await this.$http.get("message/find/one/" + id);
      if (res.code == 0) {
        this.editReceiverInfoForm = res.data;
        this.editReceiverDialogVisible = true;
      } else {
        this.$message.error(res.msg);
      }
    },
    showAddReceiverDialog () {
      if (!this.canEdit) {
        return false;
      }

      this.addReceiverDialogVisible = true;
    }
  }
}
</script>

<style lang="less" scoped>
.label-css {
  margin-top: 15px;
}
.yellowClass {
  background-color: rgb(255, 254, 191);
  border-color: rgb(230, 215, 18);
  color: rgb(177, 165, 0);
}
</style>