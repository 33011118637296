<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>附件管理</el-breadcrumb-item>
      <el-breadcrumb-item>附件列表</el-breadcrumb-item>
    </el-breadcrumb>

    <el-row :gutter="5">
      <el-col :span="6">
        <el-card>
          <div slot="header">
            <span class="span-title">项目列表</span>
          </div>
          <el-tree
            :data="projectList"
            node-key="id"
            default-expand-all
            :expand-on-click-node="false"
            :props="defaultProps"
            @node-click="loadFiles"
            class="tree-sy"
          >
            <span class="custom-tree-node" slot-scope="{ node }">
              <span>{{ node.label }}</span>
            </span>
          </el-tree>
        </el-card>
      </el-col>
      <el-col :span="18">
        <el-card>
          <div slot="header">
            <span class="span-title">附件列表</span>
          </div>
          <el-button 
            type="primary" 
            @click="addFileDialogShow()"
            icon="el-icon-plus"
            v-has="'sys:attachment:mgr'"
            >添加文件</el-button
          >

          <!-- 表格数据 -->
          <el-table :data="fileList" border stripe>
            <el-table-column label="文件名" prop="fileName"></el-table-column>
            <el-table-column label="创建时间" prop="createTime" width="160px"></el-table-column>
            <el-table-column label="操作" width="120px">
              <template slot-scope="scope">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="下载文件"
                  placement="top"
                  :enterable="false"
                >
                  <el-button
                    type="success"
                    icon="el-icon-download"
                    @click="downlaodFile(scope.row.id, scope.row.fileName)"
                  ></el-button>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="删除"
                  placement="top"
                  :enterable="false"
                >
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    @click="removeById(scope.row.id)"
                    v-has="'sys:attachment:mgr'"
                  ></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>

          <!-- 分页组件 -->
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryParams.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="queryParams.pageSize"
            layout="total, sizes, prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </el-card>

        <!-- 添加文件 -->
        <el-dialog
          title="添加文件"
          :visible.sync="addFileDialogVisible"
          width="40%"
          @close="addDialogClosed"
        >
          <el-upload
            ref="upload"
            :action= COMMON_CONFIG.PROJECT_FILE_UPLOAD_URL
            name="file"
            :before-upload="beforeFileUpload"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-success="handleSuccess"
            :file-list="addFileList"
            :headers="fileHeaders"
            :multiple="true"
            :limit="10"
            :data="queryParams"
            :auto-upload="false">
            <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
            <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button>
            <div slot="tip" class="el-upload__tip">上传文件不超过10MB，最多上传10个文件。</div>
          </el-upload>
        </el-dialog>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fileHeaders: {
        'X-Authorization-With': localStorage.getItem('token')
      },
      defaultProps: {
        children: 'children',
        label: 'projectName'
      },
      queryParams: {
        projectCode: "",
        pageNo: 1,
        pageSize: 10
      },
      total: 0,
      projectList: [],
      fileList: [],
      addFileList: [],
      addFileDialogVisible: false,
    }
  },
  created() {
    this.getProjectList();
  },
  methods: {
    handleSizeChange(newSize) {
      this.queryParams.pageSize = newSize;
      this.loadFiles(this.queryParams);
    },
    handleCurrentChange(newPage) {
      this.queryParams.pageNo = newPage;
      this.loadFiles(this.queryParams);
    },
    async getProjectList() {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        if (res.data == null) {
          this.projectList = [];
          return;
        }
        this.projectList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async loadFiles(data) {
      this.queryParams.projectCode = data.projectCode;
      const { data: res } = await this.$http.get("project/att/find/byPage", {
        params: this.queryParams
      });
      if (res.code == 0) {
        if (res.data == null) {
          this.fileList = [];
          this.total = 0;
          return;
        }
        
        this.fileList = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.error(res.msg);
      }
    },
    async removeById(id) {
      const confirmResult = await this.$confirm("是否确认删除该文件?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.delete("project/att/remove/" + id);
      if (res.code == 0) {
        this.$message.success("删除成功！");
        this.loadFiles(this.queryParams);
      } else {
        return this.$message.error("删除失败！");
      }
    },
    addFileDialogShow() {
      this.addFileList = [];
      this.addFileDialogVisible = true;
    },
    addDialogClosed() {
    },
    handleSuccess(res) {
      if (res.code == 0) {
        this.$message.success("上传成功.");
        this.addFileList = [];
        this.addFileDialogVisible = false;
        this.loadFiles(this.queryParams);
      } else {
        this.$message.error(res.msg);
      }
    },
    submitUpload() {
      this.$refs.upload.submit();
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    beforeFileUpload(file) {
      const isLtM = file.size / 1024 / 1024 < 10;
      if (!isLtM) {
          this.$message.error('上传文件不能超过10MB!');
      }
      return isLtM;
    },
    downlaodFile(id, fileName) {
      this.$http(
        {
            method: 'get',
            url: 'project/att/download?id=' + id,
            headers: {
              'Content-Type': 'application/json'
            },
            responseType: 'blob'
        }).then(response => {
            // console.log(response);
            this.download(response, fileName);
        }).catch((error) => {
            this.$message.error(error);
        }
      );
    },
    download (data, fileName) {
        if (data.status == 204) {
          this.$message.error("文件不存在.");
          return;
        }

        let url = window.URL.createObjectURL(new Blob([data.data]));
        let link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    }
  }
}
</script>

<style lang="less">
.el-upload--text {
  width: 80px;
  height: 0px;
  overflow: visible;
  border: 0px;
}
</style>