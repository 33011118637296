<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>设备管理</el-breadcrumb-item>
      <el-breadcrumb-item>渗压计管理</el-breadcrumb-item>
      <el-breadcrumb-item>渗压计列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图 -->
    <el-card>
      <el-row>
        <el-col :span="4">
          <el-select
            v-model="syQueryParams.projectCode"
            placeholder="请选择项目"
            clearable
            @clear="getDeviceByProject"
            @change="getDeviceByProject"
          >
            <el-option
              v-for="item in projectInfoList"
              :key="item.projectCode"
              :label="item.projectName"
              :value="item.projectCode"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-select 
            @change="getShenyaList"
            v-model="syQueryParams.dmCode" 
            placeholder="请选择断面" 
            clearable>
            <el-option
              v-for="item in duanmianList"
              :key="item.dmCode"
              :label="item.name"
              :value="item.dmCode"
              >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <el-button
            @click="exportDevices"
            type="success"
            icon="el-icon-download"
            v-has="'sys:device:shenya:export'"
            >导出</el-button
          >
        </el-col>
      </el-row>



      <!-- 表格数据 -->
      <el-table :data="shenyaInfoList" border stripe>
        <el-table-column type="expand">
          <template slot-scope="props">
            <span v-if="props.row.remark == null || props.row.remark == ''">无备注</span>
            <span v-else>{{ props.row.remark }}</span>
          </template>
        </el-table-column>
        <el-table-column label="图片">
          <template slot-scope="scope">
            <el-popover
              placement="right"
              width="300"
              trigger="hover">
              <img :src="COMMON_CONFIG.LOAD_DEVICE_IMG_URL + scope.row.deviceImg" height="300" width="300"/>
              <img slot="reference" :src="COMMON_CONFIG.LOAD_DEVICE_IMG_URL + scope.row.deviceImg" height="50" width="50"/>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="设备名称" prop="name"></el-table-column>
        <el-table-column
          label="渗流压力"
          prop="slyali"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.slyali === '0'" type="info"
              >否</el-tag
            >
              <el-tag v-else type="success">是</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="所属断面" prop="dmname"></el-table-column>
        <el-table-column label="基准高度（M）" prop="jzgaodu" width="110px"></el-table-column>
        <el-table-column label="基准模数值（F）" prop="jzmoshuzhi" width="120px"></el-table-column>
        <el-table-column label="基准温度（℃）" prop="jzwendu" width="110px"></el-table-column>
        <el-table-column label="计算值K（KPa/F）" prop="k" width="130px"></el-table-column>
        <el-table-column label="计算值B（KPa）" prop="b" width="120px"></el-table-column>
        <el-table-column label="孔顶高层（M）" prop="kdgaoceng" width="120px"></el-table-column>
        <el-table-column label="关联MCU" prop="mcuDeviceCode" width="90px">
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.mcuDeviceCode === null || scope.row.mcuDeviceCode === ''"
              type="info"
              >未关联</el-tag
            >
            <el-popover
                  v-else
                  placement="top-start"
                  width="200"
                  trigger="hover"
                  @show="showMcuInfo(scope.row.mcuDeviceCode)"
                  :content="mcuDeviceInfo">
                  <el-tag slot="reference" type="success">已关联</el-tag>
                </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="关联串口" prop="comCode" width="90px">
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.comCode === null || scope.row.comCode === ''"
              type="info"
              >未关联</el-tag
            >
            <el-popover
                  v-else
                  placement="top-start"
                  width="200"
                  trigger="hover"
                  @show="showComInfo(scope.row.comCode)"
                  :content="comDeviceInfo">
                  <el-tag slot="reference" type="success">已关联</el-tag>
                </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="是否有效" width="80px">
          <template slot-scope="scope">
            <el-switch
              disabled
              v-model="scope.row.availableBl"
              @change="deviceStateChanged(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="最新采集时间" prop="collectTime" width="150px"></el-table-column>
        <el-table-column label="创建日期" prop="createTime" width="100px">
          <template slot-scope="scope">
            {{common.dateFormat('YYYY-MM-dd', new Date(Date.parse(scope.row.createTime)))}}
          </template>
        </el-table-column>
        <!-- <el-table-column label="操作" width="260px" fixed="right">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="关联MCU"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="warning"
                @click="mcuSettingDialog(scope.row)"
                v-has="'sys:device:shenya:bind'"
              >MCU</el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="关联串口"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="success"
                icon="el-icon-connection"
                @click="comSettingDialog(scope.row.deviceCode)"
                v-has="'sys:device:shenya:bind'"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="修改"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                @click="showEditDialog(scope.row.deviceCode)"
                v-has="'sys:device:shenya:edit'"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                v-has="'sys:device:shenya:delete'"
                @click="removeByDeviceCode(scope.row.deviceCode)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column> -->
      </el-table>

      <!-- 分页组件 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="syQueryParams.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="syQueryParams.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>




      <!-- 添加渗压计 -->
      <el-dialog
        title="添加渗压计"
        :visible.sync="addDialogVisible"
        width="50%"
        v-if="addDialogVisible"
        @close="addDialogClosed"
      >
        <div style="height:350px; overflow: auto;">
        <el-form :model="shenyaInfoForm" :rules="shenyaRules" ref="addShenyaInfoRef">
          <el-form-item label="设备图片" label-width="120px">
            <!-- <v-device-img-upload
              @imgOnChange="addDeviceImgChange"
              @imgOnRemove="addDeviceImgRemove"
              >
            </v-device-img-upload> -->
          </el-form-item>
          <el-form-item label="所属项目" label-width="120px" prop="projectCode">
            <el-select
              @change="getDuanmian(shenyaInfoForm.projectCode)"
              v-model="shenyaInfoForm.projectCode"
              placeholder="请选择项目"
              clearable
            >
              <el-option
                v-for="item in projectInfoList"
                :key="item.projectCode"
                :label="item.projectName"
                :value="item.projectCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备型号" label-width="120px" prop="deviceModel">
            <el-select v-model="shenyaInfoForm.deviceModel" placeholder="请选择型号" clearable>
              <el-option
                v-for="item in deviceModelList"
                :key="item.modelCode"
                :label="item.modelName"
                :value="item.modelCode"
                >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备名称" label-width="120px" prop="name">
            <el-input
              autocomplete="off"
              v-model="shenyaInfoForm.name"
              clearable
              placeholder="最多输入25个字"
            ></el-input>
          </el-form-item>
          <el-form-item label="设备行政编号" label-width="120px" prop="deviceGovCode">
            <el-input
              autocomplete="off"
              v-model="shenyaInfoForm.deviceGovCode"
              clearable
              placeholder="格式：AABBCCDDDDEEFF，用于上报应急管理系统"
            ></el-input><br/>
            <span style="color: rgb(175, 175, 175);">
              省[AA]市[BB]县/区[CC]尾矿库[DDDD]监测设备类型[EE]设备位号[FF]
            </span>
          </el-form-item>
          <el-form-item label="渗流压力" label-width="120px" prop="slyali">
            <el-radio-group v-model="shenyaInfoForm.slyali">
              <el-radio :label="'1'">是</el-radio>
              <el-radio :label="'0'">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="基准高度" label-width="120px" prop="jzgaodu">
            <el-input
              autocomplete="off"
              v-model="shenyaInfoForm.jzgaodu"
              clearable
            >
            <template slot="append">M</template>
            </el-input>
          </el-form-item>
          <el-form-item label="基准模数值" label-width="120px" prop="jzmoshuzhi">
            <el-input
              autocomplete="off"
              v-model="shenyaInfoForm.jzmoshuzhi"
              clearable
            >
            <template slot="append">F</template>
            </el-input>
          </el-form-item>
          <el-form-item label="基准温度" label-width="120px" prop="jzwendu">
            <el-input
              autocomplete="off"
              v-model="shenyaInfoForm.jzwendu"
              clearable
            >
            <template slot="append">℃</template>
            </el-input>
          </el-form-item>
          <el-form-item label="计算值K" label-width="120px" prop="k">
            <el-input
              autocomplete="off"
              v-model="shenyaInfoForm.k"
              clearable
            >
            <template slot="append">KPa/F</template>
            </el-input>
          </el-form-item>
          <el-form-item label="计算值B" label-width="120px" prop="b">
            <el-input
              autocomplete="off"
              v-model="shenyaInfoForm.b"
              clearable
            >
            <template slot="append">KPa</template>
            </el-input>
          </el-form-item>
          <el-form-item label="孔顶高层" label-width="120px" prop="kdgaoceng">
            <el-input
              autocomplete="off"
              v-model="shenyaInfoForm.kdgaoceng"
              clearable
            >
            <template slot="append">M</template>
            </el-input>
          </el-form-item>
          <el-form-item label="所属断面" label-width="120px" prop="duanmianCode">
            <el-row>
              <el-col :span="12">
                <el-select v-model="shenyaInfoForm.duanmianCode" placeholder="请选择断面" clearable>
                  <el-option
                    v-for="item in duanmianList"
                    :key="item.dmCode"
                    :label="item.name"
                    :value="item.dmCode"
                  >
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="12">
                <el-row>
                  <el-col :span="4">排序</el-col>
                  <el-col :span="8">
                    <el-input
                      autocomplete="off"
                      v-model="duanmianOrder"
                      disabled
                    ></el-input>
                  </el-col>
                  <el-col :span="12">
                    <el-button-group>
                      <el-button icon="el-icon-minus" @click="decrease"></el-button>
                      <el-button icon="el-icon-plus" @click="increase"></el-button>
                    </el-button-group>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="所属坝级" label-width="120px" prop="barCode">
            <el-select
              v-model="shenyaInfoForm.barCode"
              placeholder="请选择坝级"
              clearable
            >
              <el-option
                v-for="item in barLevelData"
                :key="item.barCode"
                :label="item.name"
                :value="item.barCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="埋深" label-width="120px" prop="maishen">
            <el-input
              autocomplete="off"
              v-model="shenyaInfoForm.maishen"
              clearable
            >
            <template slot="append">M</template>
            </el-input>
          </el-form-item>
          <el-form-item label="备注" label-width="120px" prop="remark">
            <el-input
              type="textarea"
              v-model="shenyaInfoForm.remark"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addInfoSubmit()">提 交</el-button>
        </span>
      </el-dialog>





      <!-- 编辑渗压计 -->
      <el-dialog
        title="编辑渗压计"
        :visible.sync="editDialogVisible"
        width="50%"
        v-if="editDialogVisible"
      >
        <div style="height:350px; overflow: auto;">
        <el-form :model="shenyaInfoForm" :rules="shenyaRules" ref="editShenyaInfoRef">
          <el-form-item label="设备图片" label-width="120px">
            <!-- <v-device-img-upload
              :defaultImgUrl="shenyaInfoForm.deviceImg"
              @imgOnChange="addDeviceImgChange"
              @imgOnRemove="addDeviceImgRemove"
              >
            </v-device-img-upload> -->
          </el-form-item>
          <el-form-item label="所属项目" label-width="120px" prop="projectCode">
            <el-select
              v-model="shenyaInfoForm.projectCode"
              placeholder="请选择项目"
              clearable
            >
              <el-option
                v-for="item in projectInfoList"
                :key="item.projectCode"
                :label="item.projectName"
                :value="item.projectCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备型号" label-width="120px" prop="deviceModel">
            <el-select v-model="shenyaInfoForm.deviceModel" placeholder="请选择型号" clearable>
              <el-option
                v-for="item in deviceModelList"
                :key="item.modelCode"
                :label="item.modelName"
                :value="item.modelCode"
                >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备名称" label-width="120px" prop="name">
            <el-input
              autocomplete="off"
              v-model="shenyaInfoForm.name"
              clearable
              placeholder="最多输入25个字"
            ></el-input>
          </el-form-item>
          <el-form-item label="设备行政编号" label-width="120px" prop="deviceGovCode">
            <el-input
              autocomplete="off"
              v-model="shenyaInfoForm.deviceGovCode"
              clearable
              placeholder="格式：AABBCCDDDDEEFF，用于上报应急管理系统"
            ></el-input><br/>
            <span style="color: rgb(175, 175, 175);">
              省[AA]市[BB]县/区[CC]尾矿库[DDDD]监测设备类型[EE]设备位号[FF]
            </span>
          </el-form-item>
          <el-form-item label="渗流压力" label-width="120px" prop="slyali">
            <el-radio-group v-model="shenyaInfoForm.slyali">
              <el-radio :label="'1'">是</el-radio>
              <el-radio :label="'0'">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="基准高度" label-width="120px" prop="jzgaodu">
            <el-input
              autocomplete="off"
              v-model="shenyaInfoForm.jzgaodu"
              clearable
            >
            <template slot="append">M</template>
            </el-input>
          </el-form-item>
          <el-form-item label="基准模数值" label-width="120px" prop="jzmoshuzhi">
            <el-input
              autocomplete="off"
              v-model="shenyaInfoForm.jzmoshuzhi"
              clearable
            >
            <template slot="append">F</template>
            </el-input>
          </el-form-item>
          <el-form-item label="基准温度" label-width="120px" prop="jzwendu">
            <el-input
              autocomplete="off"
              v-model="shenyaInfoForm.jzwendu"
              clearable
            >
            <template slot="append">℃</template>
            </el-input>
          </el-form-item>
          <el-form-item label="计算值K" label-width="120px" prop="k">
            <el-input
              autocomplete="off"
              v-model="shenyaInfoForm.k"
              clearable
            >
            <template slot="append">KPa/F</template>
            </el-input>
          </el-form-item>
          <el-form-item label="计算值B" label-width="120px" prop="b">
            <el-input
              autocomplete="off"
              v-model="shenyaInfoForm.b"
              clearable
            >
            <template slot="append">KPa</template>
            </el-input>
          </el-form-item>
          <el-form-item label="孔顶高层" label-width="120px" prop="kdgaoceng">
            <el-input
              autocomplete="off"
              v-model="shenyaInfoForm.kdgaoceng"
              clearable
            >
            <template slot="append">M</template>
            </el-input>
          </el-form-item>
          <el-form-item label="所属断面" label-width="120px" prop="duanmianCode">
            <el-row>
              <el-col :span="12">
                <el-select v-model="shenyaInfoForm.duanmianCode" placeholder="请选择断面" clearable>
                  <el-option
                    v-for="item in duanmianList"
                    :key="item.dmCode"
                    :label="item.name"
                    :value="item.dmCode"
                  >
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="12">
                <el-row>
                  <el-col :span="4">排序</el-col>
                  <el-col :span="8">
                    <el-input
                      autocomplete="off"
                      v-model="duanmianOrder"
                      disabled
                    ></el-input>
                  </el-col>
                  <el-col :span="12">
                    <el-button-group>
                      <el-button icon="el-icon-minus" @click="decrease"></el-button>
                      <el-button icon="el-icon-plus" @click="increase"></el-button>
                    </el-button-group>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="所属坝级" label-width="120px" prop="barCode">
            <el-select
              v-model="shenyaInfoForm.barCode"
              placeholder="请选择坝级"
              clearable
            >
              <el-option
                v-for="item in barLevelData"
                :key="item.barCode"
                :label="item.name"
                :value="item.barCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="埋深" label-width="120px" prop="maishen">
            <el-input
              autocomplete="off"
              v-model="shenyaInfoForm.maishen"
              clearable
            >
            <template slot="append">M</template>
            </el-input>
          </el-form-item>
          <el-form-item label="备注" label-width="120px" prop="remark">
            <el-input
              type="textarea"
              v-model="shenyaInfoForm.remark"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="editInfoSubmit()">保 存</el-button>
        </span>
      </el-dialog>


      <el-dialog
        title="导出提示"
        :visible.sync="showDownloadVisible"
        width="30%">
        <span>数据导出已执行，完成后文件将在【下载中心】展示。</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showDownloadVisible = false">取 消</el-button>
          <el-button type="primary" @click="showDownloadVisible = false">确 定</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showUploadVisible: false,
      showDownloadVisible: false,
      addDialogVisible: false,
      editDialogVisible: false,
      showSelectComDialogVisible: false,
      showBindMcuDialogVisible: false,
      syQueryParams: {
        projectCode: "",
        dmCode: "",
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      shenyaRules: {
        projectCode: [{ required: true, message: "请选择项目", trigger: "blur" }],
        deviceModel: [{ required: true, message: "请选择型号", trigger: "blur" }],
        barCode: [{ required: true, message: "请选择坝级", trigger: "blur" }],
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        jzgaodu: [{ required: true, message: "请输入基准高度", trigger: "blur" }],
        jzmoshuzhi: [{ required: true, message: "请输入基准模数", trigger: "blur" }],
        jzwendu: [{ required: true, message: "请输入基准温度", trigger: "blur" }],
        k: [{ required: true, message: "请输入计算值K", trigger: "blur" }],
        b: [{ required: true, message: "请输入计算值B", trigger: "blur" }],
        kdgaoceng: [{ required: true, message: "请输入孔顶高层", trigger: "blur" }],
        duanmianCode: [{ required: true, message: "请输入断面编号", trigger: "blur" }],
        maishen: [{ required: true, message: "请输入埋深", trigger: "blur" }],
      },
      projectInfoList: [],
      shenyaInfoList: [],
      shenyaInfoForm: {
        slyali: '0',
      },
      currentDeviceCode: "",
      currentProjectCode: "",
      comDeviceInfo: "",
      mcuDeviceInfo: "",
      duanmianList: [],
      duanmianOrder: 1,
      deviceModelList: [],
      barLevelData: [],
    }
  },
  created() {
    this.getAllProject();
    this.getDeviceModel();
  },
  methods: {
    decrease() {
      if (this.duanmianOrder > 1) {
        this.duanmianOrder--;
      }
    },
    increase() {
      this.duanmianOrder++;
    },
    handleSizeChange(newSize) {
      this.syQueryParams.pageSize = newSize;
      this.getShenyaList();
    },
    handleCurrentChange(newPage) {
      this.syQueryParams.pageNo = newPage;
      this.getShenyaList();
    },
    async getDeviceModel() {
      const { data: res } = await this.$http.get("device/find/models", {
        params: {
          deviceType: 50
        }
      });
      if (res.code == 0) {
        this.deviceModelList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getAllProject() {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        this.projectInfoList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getBarLevelList(projectCode) {
      const { data: res } = await this.$http.get(
        "barlevel/find/byPage",
        {
          params: {
            pageNo: 1,
            pageSize: 100,
            projectCode: projectCode
          },
        }
      );
      if (res.code == 0) {
        if (res.data == null) {
          this.barLevelData = [];
          return;
        }
        this.barLevelData = res.data.list;
        console.log(this.barLevelData);
      } else {
        this.$message.error(res.msg);
      }
    },
    getDeviceByProject(){
      this.getShenyaList();
      this.getDuanmian(this.syQueryParams.projectCode);
    },
    async getDuanmian(projectCode) {
      this.getBarLevelList(projectCode);
      const { data: res } = await this.$http.get("dm/find/byPage", {
        params: {
          pageNo: 1,
          pageSize: 900,
          projectCode: projectCode,
          dmType: 1,
        }
      });
      if (res.code == 0) {
        if (res.data == null) {
          this.duanmianList = [];
          return;
        }

        this.duanmianList = res.data.list;
      }
    },
    async getShenyaList() {
      const { data: res } = await this.$http.post("device/shenya/find/page", this.syQueryParams);
      if (res.code == 0) {
        if (res.data == null) {
          this.shenyaInfoList = [];
          this.total = 0;
          return;
        }

        this.shenyaInfoList = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.error(res.msg);
      }
    },
    showAddDialog() {
      this.shenyaInfoForm = {};
      this.addDialogVisible = true;
    },
    addDialogClosed() {
      this.$refs.addShenyaInfoRef.resetFields();
    },
    async addInfoSubmit() {
      this.shenyaInfoForm.sortNum = this.duanmianOrder;
      this.$refs.addShenyaInfoRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "device/shenya/add",
            this.shenyaInfoForm
          );
          if (res.code == 0) {
            this.$message.success("添加成功");
            this.getShenyaList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.addDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    comSettingDialog(deviceCode) {
      this.currentDeviceCode = deviceCode;
      this.showSelectComDialogVisible = true;
    },
    async showEditDialog(deviceCode) {
      const { data: res } = await this.$http.get("device/shenya/find/bycode/" + deviceCode);
      if (res.code == 0) {
        this.shenyaInfoForm = res.data;
        this.duanmianOrder = res.data.sortNum;
        this.getDuanmian(res.data.projectCode);
        this.editDialogVisible = true;
      } else {
        this.$message.error(res.msg);
      }
    },
    async editInfoSubmit() {
      this.shenyaInfoForm.sortNum = this.duanmianOrder;
      this.$refs.editShenyaInfoRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "device/shenya/update",
            this.shenyaInfoForm
          );
          if (res.code == 0) {
            this.$message.success("修改成功");
            this.getShenyaList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.editDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    async removeByDeviceCode(deviceCode) {
      const confirmResult = await this.$confirm("是否确认删除该设备?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.delete("device/shenya/remove/" + deviceCode);
      if (res.code == 0) {
        this.$message.success("删除成功！");
        this.getShenyaList();
      } else {
        return this.$message.error(res.msg);
      }
    },
    afterBindCom() {
      this.getShenyaList();
    },
    async deviceStateChanged(devInfo) {
      const confirmResult = await this.$confirm(
        "此操作将影响平台收集设备数据, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        this.$message.info("已取消操作");
        devInfo.availableBl = !devInfo.availableBl;
        return false;
      }

      const { data: res } = await this.$http.get("device/change/status", {
        params: {
          deviceCode: devInfo.deviceCode,
          available: devInfo.availableBl
        }
      });

      if (res.code == 0) {
        this.$message.success("修改成功");
      } else {
        this.$message.error(res.msg);
      }
    },
    async showComInfo(comCode) {
      const { data: res } = await this.$http.get("virtual/com/find/bycode", {
        params: {
          comCode: comCode
        },
        showLoading: false
      });
      if (res.code == 0) {
        this.comDeviceInfo = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    mcuSettingDialog(deviceInfo) {
      this.currentProjectCode = deviceInfo.projectCode;
      this.currentDeviceCode = deviceInfo.deviceCode;
      this.showBindMcuDialogVisible = true;
    },
    cancelBindMcu() {
      this.showBindMcuDialogVisible = false;
    },
    bindMcuSubmit() {
      this.showBindMcuDialogVisible = false;
      this.getShenyaList();
    },
    async showMcuInfo(mcuDeviceCode) {
      const { data: res } = await this.$http.get("device/find/bycode", {
        params: {
          deviceCode: mcuDeviceCode
        },
        showLoading: false
      });
      if (res.code == 0) {
        this.mcuDeviceInfo = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    addDeviceImgChange(url) {
      this.shenyaInfoForm.deviceImg = url;
      // console.log(url);
    },
    addDeviceImgRemove() {
      this.shenyaInfoForm.deviceImg = "";
      // console.log('删除了图片');
    },
    async exportDevices(){
      if (this.syQueryParams.projectCode == null || this.syQueryParams.projectCode == "") {
        this.$message.error("请选择项目.");
        return;
      }
      const { data: res } = await this.$http.post("file/shenya/export", this.syQueryParams);
      if (res.code == 0) {
        this.showDownloadVisible = true;
      } else {
        this.$message.error(res.msg);
      }
    },
    showUploadView() {
      this.showUploadVisible = true;
    },
    batchAddDevices() {
      this.$refs.deviceInfoUploadRef.submitUpload();
    },
    batchAddDevicesSuccess() {
      this.getShenyaList();
    }
  }
}
</script>

<style lang="less" scoped>

</style>