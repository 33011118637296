<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>项目模块</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图 -->
    <el-card>
      <el-row>
        <el-col :span="4">
          <el-select
            v-model="queryParams.projectCode"
            placeholder="请选择项目"
            @clear="getModuleList"
            @change="getModuleList"
          >
            <el-option
              v-for="item in projectInfoList"
              :key="item.projectCode"
              :label="item.projectName"
              :value="item.projectCode"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>

      <!-- 表格数据 -->
      <el-table :data="moduleInfoList" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column label="模块名称" prop="mdName"></el-table-column>
        <el-table-column label="模块标识" prop="mdCode"></el-table-column>
        <el-table-column label="是否开启" width="120px">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.on"
              @change="moduleChanged(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      queryParams: {
        projectCode: "",
        pageNo: 1,
        pageSize: 10,
      },
      projectInfoList: [],
      moduleInfoList: [],
    }
  },
  created () {
    this.getAllProject();
  },
  methods: {
    async getAllProject () {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        this.projectInfoList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getModuleList () {
      const { data: res } = await this.$http.get("pmd/find/all/module", {
        params: {
          projectCode: this.queryParams.projectCode
        }
      });
      if (res.code == 0) {
        this.moduleInfoList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async moduleChanged (moduleInfo) {
      const confirmResult = await this.$confirm(
        "此操作将影响平台项目展示, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        this.$message.info("已取消操作");
        moduleInfo.on = !moduleInfo.on;
        return false;
      }

      const { data: res } = await this.$http.get("pmd/change/status", {
        params: {
          projectCode: this.queryParams.projectCode,
          mdCode: moduleInfo.mdCode,
          on: moduleInfo.on
        }
      });

      if (res.code == 0) {
        this.$message.success("修改成功");
      } else {
        this.$message.error(res.msg);
      }
    }
  }
}
</script>