<template>
  <!-- 全圆测量限差管理 -->
  <div>
    <!-- 表格数据 -->
      <el-table :data="tableInfoList" border stripe>
        <el-table-column type="expand">
          <template slot-scope="props">
            <span v-if="props.row.remark == null || props.row.remark == ''">无备注</span>
            <span v-else>{{ props.row.remark }}</span>
          </template>
        </el-table-column>
        <el-table-column label="限差名" prop="name"></el-table-column>
        <el-table-column label="水平角归零差" prop="glcSpj"></el-table-column>
        <el-table-column label="竖直角归零差" prop="glcSzj"></el-table-column>
        <el-table-column label="2C互差" prop="hc2c"></el-table-column>
        <el-table-column label="指标差互差" prop="zbcHc"></el-table-column>
        <el-table-column label="测回水平角互差" prop="hcSpj"></el-table-column>
        <el-table-column label="测回竖直角互差" prop="hcSzj"></el-table-column>
        <el-table-column label="测回斜距互差" prop="hcXj"></el-table-column>
        <el-table-column label="创建时间" prop="createTime" width="150px"></el-table-column>
        <!-- <el-table-column label="操作" width="120px">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="修改"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                @click="showEditDialog(scope.row.id)"
                v-has="'sys:device:qz:qy:edit'"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                v-has="'sys:device:qz:qy:delete'"
                @click="removeById(scope.row.id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column> -->
      </el-table>

      <!-- 分页组件 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>



      <!-- 添加限差 -->
      <el-dialog
        title="添加限差"
        :visible.sync="addDialogVisible"
        width="50%"
        v-if="addDialogVisible"
        @close="addDialogClosed"
      >
        <div style="height:350px; overflow: auto;">
        <el-form :model="dataFormObj" :rules="formRules" ref="addInfoRef" label-width="130px">
          <el-form-item label="所属项目" prop="projectCode">
            <el-select
              v-model="dataFormObj.projectCode"
              placeholder="请选择项目"
              clearable
            >
              <el-option
                v-for="item in projectInfoList"
                :key="item.projectCode"
                :label="item.projectName"
                :value="item.projectCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="名称" prop="name">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.name"
              clearable
              placeholder="最多输入15个字"
            ></el-input>
          </el-form-item>
          <el-form-item label="水平角归零差" prop="glcSpj">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.glcSpj"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="竖直角归零差" prop="glcSzj">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.glcSzj"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="2C互差" prop="hc2c">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.hc2c"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="指标差互差" prop="zbcHc">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.zbcHc"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="测回水平角互差" prop="hcSpj">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.hcSpj"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="测回竖直角互差" prop="hcSzj">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.hcSzj"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="测回斜距互差" prop="hcXj">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.hcXj"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input
              type="textarea"
              v-model="dataFormObj.remark"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addInfoSubmit()">提 交</el-button>
        </span>
      </el-dialog>



      <!-- 编辑限差 -->
      <el-dialog
        title="编辑限差"
        :visible.sync="editDialogVisible"
        width="50%"
        v-if="editDialogVisible"
      >
        <div style="height:350px; overflow: auto;">
        <el-form :model="dataFormObj" :rules="formRules" ref="editInfoRef" label-width="130px">
          <el-form-item label="所属项目" prop="projectCode">
            <el-select
              v-model="dataFormObj.projectCode"
              placeholder="请选择项目"
              clearable
            >
              <el-option
                v-for="item in projectInfoList"
                :key="item.projectCode"
                :label="item.projectName"
                :value="item.projectCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="名称" prop="name">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.name"
              clearable
              placeholder="最多输入15个字"
            ></el-input>
          </el-form-item>
          <el-form-item label="水平角归零差" prop="glcSpj">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.glcSpj"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="竖直角归零差" prop="glcSzj">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.glcSzj"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="2C互差" prop="hc2c">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.hc2c"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="指标差互差" prop="zbcHc">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.zbcHc"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="测回水平角互差" prop="hcSpj">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.hcSpj"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="测回竖直角互差" prop="hcSzj">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.hcSzj"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="测回斜距互差" prop="hcXj">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.hcXj"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input
              type="textarea"
              v-model="dataFormObj.remark"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="editInfoSubmit()">提 交</el-button>
        </span>
      </el-dialog>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  data() {
    return {
      addDialogVisible: false,
      editDialogVisible: false,
      tableInfoList: [],
      queryParams: {
        projectCode: "",
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      formRules: {
        projectCode: [{ required: true, message: "请选择项目", trigger: "blur" }],
        name: [{ required: true, message: "请输入名字", trigger: "blur" }],
        glcSpj: [{ required: true, message: "请输入水平角归零差", trigger: "blur" }],
        glcSzj: [{ required: true, message: "请输入竖直角归零差", trigger: "blur" }],
        hc2c: [{ required: true, message: "请输入2C互差", trigger: "blur" }],
        zbcHc: [{ required: true, message: "请输入指标差互差", trigger: "blur" }],
        hcSpj: [{ required: true, message: "请输入测回水平角互差", trigger: "blur" }],
        hcSzj: [{ required: true, message: "请输入测回竖直角互差", trigger: "blur" }],
        hcXj: [{ required: true, message: "请输入测回斜距互差", trigger: "blur" }],
      },
      dataFormObj: {},
      projectInfoList: [],
    };
  },
  created() {
    this.getAllProject();
    this.getTableDataList();
  },
  methods: {
    async getAllProject() {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        this.projectInfoList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    handleSizeChange(newSize) {
      this.queryParams.pageSize = newSize;
      this.getTableDataList();
    },
    handleCurrentChange(newPage) {
      this.queryParams.pageNo = newPage;
      this.getTableDataList();
    },
    projectCodeChange(projectCode) {
      this.queryParams.projectCode = projectCode;
      this.getTableDataList();
    },
    async getTableDataList() {
      const { data: res } = await this.$http.get("device/qz/qyxc/find/page", {
        params: this.queryParams
      });
      if (res.code == 0) {
        if (res.data == null) {
          this.tableInfoList = [];
          this.total = 0;
          return;
        }
        this.tableInfoList = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.error(res.msg);
      }
    },
    showAddDialog() {
      this.dataFormObj = {};
      this.addDialogVisible = true;
    },
    addDialogClosed() {
      this.$refs.addInfoRef.resetFields();
    },
    async addInfoSubmit() {
      this.$refs.addInfoRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "device/qz/qyxc/add",
            this.dataFormObj
          );
          if (res.code == 0) {
            this.$message.success("添加成功");
            this.getTableDataList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.addDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    async showEditDialog(id) {
      this.dataFormObj = {};
      const { data: res } = await this.$http.get("device/qz/qyxc/find/byid/" + id);
      if (res.code == 0) {
        this.dataFormObj = res.data;
        this.editDialogVisible = true;
      } else {
        this.$message.error(res.msg);
      }
    },
    async editInfoSubmit() {
      this.$refs.editInfoRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "device/qz/qyxc/update",
            this.dataFormObj
          );
          if (res.code == 0) {
            this.$message.success("修改成功");
            this.getTableDataList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.editDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    async removeById(id) {
      const confirmResult = await this.$confirm("是否确认删除该设备?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.delete("device/qz/qyxc/remove/" + id);
      if (res.code == 0) {
        this.$message.success("删除成功！");
        this.getTableDataList();
      } else {
        return this.$message.error(res.msg);
      }
    },
  },
};
</script>