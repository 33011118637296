<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>项目详情</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图 -->
    <el-card>
      <el-row :gutter="5">
        <el-col :span="16">
          <el-card>
          <div slot="header">
            <span class="span-title">项目信息</span>
          </div>
          <el-form :model="editProjectInfoForm" :rules="addRules" ref="editProjectRef">
            <el-form-item label="所属组织" label-width="120px" prop="orgId">
              <el-select v-model="editProjectInfoForm.orgId" placeholder="请选择组织" clearable disabled>
                  <el-option
                    v-for="item in orgInfoList"
                    :key="item.id"
                    :label="item.orgName"
                    :value="item.id">
                  </el-option>
              </el-select>
            </el-form-item>
            <el-row :gutter="5">
              <el-col :span="12">
                <el-form-item label="项目名称" label-width="120px" prop="projectName">
                  <el-input
                    autocomplete="off"
                    v-model="editProjectInfoForm.projectName"
                    clearable
                    placeholder="最多输入50个字"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-button
                  type="success"
                  icon="el-icon-collection"
                  @click="editCertDialogShow(editProjectInfoForm.projectCode, editProjectInfoForm.projectName, editProjectInfoForm.isCert)"
                >备案详情</el-button>
              </el-col>
            </el-row>
            <el-row :gutter="5">
              <el-col :span="11">
                <el-form-item label="地址" label-width="120px" >
                  <el-input
                    autocomplete="off"
                    v-model="selectedProvinceName"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label-width="0px" >
                  <el-input
                    autocomplete="off"
                    v-model="selectedCityName"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-input
                    autocomplete="off"
                    v-model="selectedAreaName"
                    disabled
                  ></el-input>
              </el-col>
            </el-row>
            <el-row :gutter="5">
              <el-col :span="11">
                <el-form-item label="经度" label-width="120px" prop="lng">
                  <el-input
                    autocomplete="off"
                    v-model="editProjectInfoForm.lng"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="纬度" label-width="60px" prop="lat">
                  <el-input
                    autocomplete="off"
                    v-model="editProjectInfoForm.lat"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-button @click="showLocationDialog()" type="primary" class="el-icon-location-information">显示地图</el-button>
              </el-col>
            </el-row>
            <el-form-item label="海拔" label-width="120px" prop="elevation">
              <el-input
                autocomplete="off"
                v-model="editProjectInfoForm.elevation"
                disabled
                placeholder="单位：米"
              ></el-input>
            </el-form-item>
            <el-form-item label="滩顶高程" label-width="120px" prop="gaocheng">
              <el-input
                autocomplete="off"
                v-model="editProjectInfoForm.gaocheng"
                disabled
                placeholder="单位：米"
              ></el-input>
            </el-form-item>
            <el-form-item label="项目地址" label-width="120px" prop="address">
              <el-input
                autocomplete="off"
                v-model="editProjectInfoForm.address"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="备注" label-width="120px" prop="remark">
              <el-input
                autocomplete="off"
                v-model="editProjectInfoForm.remark"
                disabled
              ></el-input>
            </el-form-item>
          </el-form>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card>
            <div slot="header">
              <span class="span-title">附件列表</span>
            </div>
            <el-button 
              type="primary" 
              @click="addFileDialogShow()"
              icon="el-icon-plus"
              v-has="'sys:attachment:mgr'"
              >添加文件</el-button
            >
            <el-table :data="projectFiles"
              border stripe>
              <el-table-column label="名称" prop="fileName"></el-table-column>
              <el-table-column label="操作" width="120px">
                <template slot-scope="scope">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="下载文件"
                    placement="top"
                    :enterable="false"
                  >
                    <el-button
                      type="success"
                      icon="el-icon-download"
                      @click="downlaodFile(scope.row.id, scope.row.fileName)"
                    ></el-button>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="删除"
                    placement="top"
                    :enterable="false"
                  >
                    <el-button
                      type="danger"
                      icon="el-icon-delete"
                      v-has="'sys:attachment:mgr'"
                      @click="removeById(scope.row.id)"
                    ></el-button>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
          </el-card>
        </el-col>
      </el-row>

      <el-dialog
        title="项目坐标"
        :visible.sync="showMapDialogVisible"
        v-if="showMapDialogVisible"
        width="60%">
        <v-coordinator :defaultPoit="markerPoit" :defaultAddress="mapAddress" :canEdit="mapCanEdit" @onLocation="mapLocation"></v-coordinator>
      </el-dialog>

      <!-- 修改备案 -->
      <el-dialog
        title="备案详情"
        :visible.sync="showEditCertDialogVisible"
        v-if="showEditCertDialogVisible"
        width="70%"
        @close="editCertDialogClose">
        <div style="height:400px; overflow: auto;">
          <v-edit-project-cert :projectInfo="selectedProjectRow" @dataSubmit="editCertSubmit" @dataCancel="editCertCancel"></v-edit-project-cert>
        </div>
      </el-dialog>


      <!-- 添加文件 -->
        <el-dialog
          title="添加文件"
          :visible.sync="addFileDialogVisible"
          width="40%"
          @close="addDialogClosed"
        >
          <el-upload
            ref="upload"
            :action= COMMON_CONFIG.PROJECT_FILE_UPLOAD_URL
            name="file"
            :before-upload="beforeFileUpload"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-success="handleSuccess"
            :file-list="addFileList"
            :headers="fileHeaders"
            :multiple="true"
            :limit="10"
            :data="editProjectInfoForm"
            :auto-upload="false">
            <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
            <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button>
            <div slot="tip" class="el-upload__tip">上传文件不超过10MB，最多上传10个文件。</div>
          </el-upload>
        </el-dialog>
    </el-card>
  </div>
</template>

<script>
import vAddressSelector from '../../addressSelector/AddressSelector.vue';
import vCoordinator from "@/components/coordinatSelector/Coordinator.vue";
import vAddProjectCert from "./AddProjectCert.vue";
import vEditProjectCert from "./EditProjectCert.vue";
export default {
  components: {
    vAddressSelector,
    vCoordinator,
    vAddProjectCert,
    vEditProjectCert,
  },
  data() {
    return {
      selectedProvinceCode: '',
      selectedProvinceName: '',
      selectedCityCode: '',
      selectedCityName: '',
      selectedAreaCode: '',
      selectedAreaName: '',
      queryParams: {
        queryStr: "",
        pageNo: 1,
        pageSize: 10,
        orgId: null,
      },
      addProjectInfoForm: {
        orgId: null,
        projectName: "",
        projectScale: null,
        provinceCode: null,
        provinceName: null,
        cityCode: null,
        cityName: null,
        areaCode: null,
        areaName: null,
        address: "",
        introduction: "",
        remark: "",
        lng: "",
        lat: "",
        elevation: "",
      },
      editProjectInfoForm: {},
      addRules: {},
      addProjectDialogVisible: false,
      editProjectDialogVisible: false,
      projectInfoList: [],
      total: 0,
      orgInfoList: [],
      showMapDialogVisible: false,
      mapCanEdit: true,
      markerPoit: {
        lng: "116.404",
        lat: "39.915",
      },
      mapAddress: "",
      // 是否新增方式打开地图
      showMapIsAdd: true,


      // 备案相关
      selectedProjectRow: {},
      showAddCertDialogVisible: false,
      showEditCertDialogVisible: false,

      projectFiles: [],
      addFileList: [],
      addFileDialogVisible: false,
      fileHeaders: {
        'X-Authorization-With': localStorage.getItem('token')
      },
    }
  },
  created() {
    this.getProjectList();
    this.getAllOrgList();
  },
  methods: {
    async getProjectFiles() {
      const { data: res } = await this.$http.get("project/att/find/byPage", {
        params: {
          pageNo: 1,
          pageSize: 20,
          projectCode: this.editProjectInfoForm.projectCode
        },
      });
      if (res.code == 0 && res.data != null) {
        this.projectFiles = res.data.list;
      }
    },
    async getProjectList() {
      const { data: res } = await this.$http.get("project/find/by/code");
      if (res.code == 0 && res.data != null) {
        this.editProjectInfoForm = res.data[0];
        this.showEditDialog(this.editProjectInfoForm.id);
        this.getProjectFiles();
      } else {
        this.$message.error(res.msg);
      }
    },
    async showEditDialog(id) {
      const { data: res } = await this.$http.get("project/find/" + id);
      if (res.code == 0) {
        this.editProjectInfoForm = res.data;
        this.selectedProvinceCode = res.data.provinceCode;
        this.selectedProvinceName = res.data.provinceName;
        this.selectedCityCode = res.data.cityCode;
        this.selectedCityName = res.data.cityName;
        this.selectedAreaCode = res.data.areaCode;
        this.selectedAreaName = res.data.areaName;
        this.editProjectDialogVisible = true;
      } else {
        this.$message.error(res.msg);
      }
    },
    async removeProjectById(id) {
      const confirmResult = await this.$confirm("是否确认删除该项目?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.delete("project/remove/" + id);
      if (res.code == 0) {
        this.$message.success("删除项目成功！");
        this.getProjectList();
      } else {
        return this.$message.error("删除项目失败！");
      }
    },
    handleSizeChange(newSize) {
      this.queryParams.pageSize = newSize;
      this.getProjectList();
    },
    handleCurrentChange(newPage) {
      this.queryParams.pageNo = newPage;
      this.getProjectList();
    },
    async getAllOrgList() {
      const { data: res } = await this.$http.get("org/find/all");
      if (res.code == 0) {
        this.orgInfoList = res.data;
      }
    },
    addDialogClosed() {
      this.$refs.addAddressRef.clearData();
      this.$refs.addProjectRef.resetFields();
    },
    editDialogClosed() {
      // this.$refs.editProjectRef.resetFields();
    },
    addProjectDialogShow() {
      this.addProjectDialogVisible = true;
    },
    addProjectInfoSubmit() {
      this.$refs.addProjectRef.validate(async (valid) => {
        if (valid) {
          this.addProjectInfoForm.provinceCode = this.selectedProvinceCode;
          this.addProjectInfoForm.provinceName = this.selectedProvinceName;
          this.addProjectInfoForm.cityCode = this.selectedCityCode;
          this.addProjectInfoForm.cityName = this.selectedCityName;
          this.addProjectInfoForm.areaCode = this.selectedAreaCode;
          this.addProjectInfoForm.areaName = this.selectedAreaName;
          const { data: res } = await this.$http.post(
            "project/add",
            this.addProjectInfoForm
          );
          if (res.code == 0) {
            this.$message.success("添加成功");
            this.getProjectList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.addProjectDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    editProjectInfoSubmit() {
      this.$refs.editProjectRef.validate(async (valid) => {
        if (valid) {
          this.editProjectInfoForm.provinceCode = this.selectedProvinceCode;
          this.editProjectInfoForm.provinceName = this.selectedProvinceName;
          this.editProjectInfoForm.cityCode = this.selectedCityCode;
          this.editProjectInfoForm.cityName = this.selectedCityName;
          this.editProjectInfoForm.areaCode = this.selectedAreaCode;
          this.editProjectInfoForm.areaName = this.selectedAreaName;
          const { data: res } = await this.$http.post(
            "project/update",
            this.editProjectInfoForm
          );
          if (res.code == 0) {
            this.$message.success("修改成功");
            this.getProjectList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.editProjectDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    chengeAddress(data) {
      this.selectedProvinceCode = data.provinceCode;
      this.selectedProvinceName = data.provinceName;
      this.selectedCityCode = data.cityCode;
      this.selectedCityName = data.cityName;
      this.selectedAreaCode = data.areaCode;
      this.selectedAreaName = data.areaName;
      console.log(data);
    },
    addProjectLngLatShowDialog() {
      this.mapCanEdit = true;
      this.showMapIsAdd = true;
      this.markerPoit = {lng:"116.404", lat:"39.915"};
      this.showMapDialogVisible = true;
    },
    editProjectLngLatShowDialog() {
      this.mapCanEdit = true;
      this.showMapIsAdd = false;
      this.markerPoit = {lng:this.editProjectInfoForm.lng, lat:this.editProjectInfoForm.lat};
      this.mapAddress = this.editProjectInfoForm.address;
      this.showMapDialogVisible = true;
    },
    mapLocation(data) {
      console.log(data);
      if (this.showMapIsAdd) {
        this.addProjectInfoForm.lng = '' + data.point.lng;
        this.addProjectInfoForm.lat = '' + data.point.lat;
        this.addProjectInfoForm.address = data.address;
      } else {
        this.editProjectInfoForm.lng = '' + data.point.lng;
        this.editProjectInfoForm.lat = '' + data.point.lat;
        this.editProjectInfoForm.address = data.address;
      }
    },
    showLocationDialog() {
      this.mapCanEdit = false;
      this.markerPoit = {lng:this.editProjectInfoForm.lng, lat:this.editProjectInfoForm.lat};
      this.mapAddress = this.editProjectInfoForm.address;
      this.showMapDialogVisible = true;
    },

    // 复选框
    // 当用户手动勾选数据行的 Checkbox 时触发的事件,确保只能选中一项数据
		selected(selection, row){
			this.$refs.multipleTable.clearSelection();
			if(selection.length == 0) return ;
			this.$refs.multipleTable.toggleRowSelection(row, true);
      console.log(row);
      this.selectedProjectRow = row;
		},

    addCertDialogShow() {
      if (this.selectedProjectRow.id == null) {
        this.$message.error("请选择一个项目再操作.");
        return false;
      }

      if (this.selectedProjectRow.isCert == 1) {
        this.$message.error("该项目已经备案.");
        return false;
      }

      this.showAddCertDialogVisible = true;
    },
    addCertSubmit() {
      this.showAddCertDialogVisible = false;
      this.getProjectList();
    },
    addCertCancel() {
      this.showAddCertDialogVisible = false;
    },
    async editCertDialogShow(code, name, isCert) {
      if (isCert === 0) {
        this.$message.error("该项目尚未备案.");
        return false;
      }
      const { data: res } = await this.$http.get("project/find/cert/bycode", {
        params: {
          projectCode: code
        },
      });
      if (res.code == 0) {
        this.selectedProjectRow = res.data;
        this.selectedProjectRow.projectName = name;
        this.showEditCertDialogVisible = true;
      } else {
        this.$message.error(res.msg);
      }
    },
    editCertCancel() {
      this.showEditCertDialogVisible = false;
    },
    editCertSubmit() {
      this.showEditCertDialogVisible = false;
      this.getProjectList();
    },
    editCertDialogClose() {
      this.selectedProjectRow = {};
    },
    downlaodFile(id, fileName) {
      this.$http(
        {
            method: 'get',
            url: 'project/att/download?id=' + id,
            headers: {
              'Content-Type': 'application/json'
            },
            responseType: 'blob'
        }).then(response => {
            // console.log(response);
            this.download(response, fileName);
        }).catch((error) => {
            this.$message.error(error);
        }
      );
    },
    download (data, fileName) {
        if (data.status == 204) {
          this.$message.error("文件不存在.");
          return;
        }

        let url = window.URL.createObjectURL(new Blob([data.data]));
        let link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    },
    async removeById(id) {
      const confirmResult = await this.$confirm("是否确认删除该文件?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.delete("project/att/remove/" + id);
      if (res.code == 0) {
        this.$message.success("删除成功！");
        this.getProjectFiles();
      } else {
        return this.$message.error("删除失败！");
      }
    },
    addFileDialogShow() {
      this.addFileList = [];
      this.addFileDialogVisible = true;
    },
    addDialogClosed() {
    },
    handleSuccess(res) {
      if (res.code == 0) {
        this.$message.success("上传成功.");
        this.addFileList = [];
        this.addFileDialogVisible = false;
        this.getProjectFiles();
      } else {
        this.$message.error(res.msg);
      }
    },
    submitUpload() {
      this.$refs.upload.submit();
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    beforeFileUpload(file) {
      const isLtM = file.size / 1024 / 1024 < 10;
      if (!isLtM) {
          this.$message.error('上传文件不能超过10MB!');
      }
      return isLtM;
    },
  }
}
</script>

<style lang="less">
.el-upload--text {
  width: 80px;
  height: 0px;
  overflow: visible;
  border: 0px;
}
</style>