<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>设备管理</el-breadcrumb-item>
      <el-breadcrumb-item>全站仪</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row>
        <el-col :span="4">
          <el-select
            v-model="queryParams.projectCode"
            placeholder="请选择项目"
            clearable
            @clear="changeProjectCode"
            @change="changeProjectCode"
          >
            <el-option
              v-for="item in projectInfoList"
              :key="item.projectCode"
              :label="item.projectName"
              :value="item.projectCode"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row>
        <el-tabs
          type="border-card"
          v-model="tabActiveName"
          @tab-click="handleClick"
        >
          <el-tab-pane label="全站仪管理" name="qz1">
            <v-sub-quanzhan ref="qz1ref"></v-sub-quanzhan>
          </el-tab-pane>
          <el-tab-pane label="基准点管理" name="qz2">
            <v-sub-jizhun ref="qz2ref"></v-sub-jizhun>
          </el-tab-pane>
          <el-tab-pane label="测站管理" name="qz3">
            <v-sub-cezhan ref="qz3ref"></v-sub-cezhan>
          </el-tab-pane>
          <el-tab-pane label="点组管理" name="qz4">
            <v-sub-dianzu ref="qz4ref"></v-sub-dianzu>
          </el-tab-pane>
          <el-tab-pane label="点位学习" name="qz5">
            <v-sub-dianwei ref="qz5ref"></v-sub-dianwei>
          </el-tab-pane>
          <el-tab-pane label="全圆测量限差管理" name="qz6">
            <v-sub-qycl-xiancha ref="qz6ref"></v-sub-qycl-xiancha>
          </el-tab-pane>
          <el-tab-pane label="预警限差设置" name="qz10">
            <v-sub-dianwei-alarm ref="qz10ref"></v-sub-dianwei-alarm>
          </el-tab-pane>
          <el-tab-pane label="测量设置" name="qz7">
            <v-sub-celiang ref="qz7ref"></v-sub-celiang>
          </el-tab-pane>
          <el-tab-pane label="采集时间管理" name="qz8"
            >采集时间管理</el-tab-pane
          >
          <el-tab-pane label="采集作业设置" name="qz9"
            ><v-sub-zuoye ref="qz9ref"></v-sub-zuoye></el-tab-pane
          >
        </el-tabs>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import vSubQuanzhan from "./SubQuanzhan.vue";
import vSubJizhun from "./SubJizhun.vue";
import vSubDianwei from "./SubDianwei.vue";
import vSubDianzu from "./SubDianzu.vue";
import vSubCezhan from "./SubCezhan.vue";
import vSubQyclXiancha from "./SubQyclXiancha.vue";
import vSubZuoye from "./SubZuoye.vue";
import vSubDianweiAlarm from "./SubDianweiAlarm.vue";
import vSubCeliang from "./SubCeliang.vue";
export default {
  components: {
    vSubQuanzhan,
    vSubJizhun,
    vSubDianwei,
    vSubDianzu,
    vSubCezhan,
    vSubQyclXiancha,
    vSubZuoye,
    vSubDianweiAlarm,
    vSubCeliang
  },
  data() {
    return {
      tabActiveName: "qz1",
      queryParams: {
        projectCode: "",
        pageNo: 1,
        pageSize: 10,
      },
      projectInfoList: [],
    };
  },
  created() {
    this.getAllProject();
  },
  methods: {
    handleClick(tab, event) {
      this.tabActiveName = tab.name;
      this.changeProjectCode();
    },
    async getAllProject() {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        this.projectInfoList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    changeProjectCode() {
      if (this.tabActiveName === 'qz1') {
        this.$refs.qz1ref.projectCodeChange(this.queryParams.projectCode);
      } else if (this.tabActiveName === 'qz2') {
        this.$refs.qz2ref.projectCodeChange(this.queryParams.projectCode);
      } else if (this.tabActiveName === 'qz3') {
        this.$refs.qz3ref.projectCodeChange(this.queryParams.projectCode);
      } else if (this.tabActiveName === 'qz4') {
        this.$refs.qz4ref.projectCodeChange(this.queryParams.projectCode);
      } else if (this.tabActiveName === 'qz5') {
        this.$refs.qz5ref.projectCodeChange(this.queryParams.projectCode);
      } else if (this.tabActiveName === 'qz6') {
        this.$refs.qz6ref.projectCodeChange(this.queryParams.projectCode);
      } else if (this.tabActiveName === 'qz10') {
        this.$refs.qz10ref.projectCodeChange(this.queryParams.projectCode);
      } else if (this.tabActiveName === 'qz7') {
        this.$refs.qz7ref.projectCodeChange(this.queryParams.projectCode);
      } else if (this.tabActiveName === 'qz9') {
        this.$refs.qz9ref.projectCodeChange(this.queryParams.projectCode);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-tabs {
  margin-top: 10px;
}
</style>