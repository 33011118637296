<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>边端应用</el-breadcrumb-item>
      <el-breadcrumb-item>边端应用列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图 -->
    <el-card>
      <el-row :gutter="10">
        <el-col :span="3">
          <el-select v-model="queryParams.orgId" placeholder="请选择组织" clearable @clear="getAppList" @change="changeOrgSelect">
                <el-option
                  v-for="item in orgInfoList"
                  :key="item.id"
                  :label="item.orgName"
                  :value="item.id">
                </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="queryParams.projectCode" placeholder="请选择项目" clearable @clear="getAppList" @change="getAppList">
                <el-option
                  v-for="item in projectInfoList"
                  :key="item.projectCode"
                  :label="item.projectName"
                  :value="item.projectCode">
                </el-option>
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-input
            placeholder="请输入应用名"
            v-model="queryParams.queryStr"
            clearable
            @clear="getAppList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getAppList"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="5">
          <el-button 
            type="primary" 
            @click="showAddAppDialog" 
            v-has="'sys:front:add'"
            icon="el-icon-plus"
            >添加应用</el-button
          >
        </el-col>
      </el-row>

      <!-- 表格数据 -->
      <el-table :data="appInfoList" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column label="应用名称" prop="platName" width="160px"></el-table-column>
        <el-table-column label="所属项目" prop="projectName"></el-table-column>
        <el-table-column label="appkey" prop="appkey" width="260px"></el-table-column>
        <el-table-column label="有效期" prop="expire" width="120px"></el-table-column>
        <el-table-column label="实际接入/可接入数" prop="nodeLimit" width="140px">
          <template slot-scope="scope">
            <span>
              <el-tooltip
                class="item"
                effect="dark"
                content="点击查看详情"
                placement="top"
                :enterable="false"
              >
              <a @click="showClientList(scope.row)" class="currentNode">{{scope.row.nodeCount}}</a>
              </el-tooltip> / {{scope.row.nodeLimit}}</span>
          </template>
        </el-table-column>
        <el-table-column label="是否有效" width="80px">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.clientStatus"
              @change="appStateChanged(scope.row)"
              :active-value="1"
              :inactive-value="0"
              v-has="'sys:front:edit'"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="createTime" width="160px"></el-table-column>
        <el-table-column label="操作" width="120px">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="修改"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="showEditDialog(scope.row.id)"
                v-has="'sys:front:edit'"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="removeById(scope.row.id)"
                v-has="'sys:front:delete'"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>


      <!-- 分页组件 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>


      <!-- 添加应用 -->
      <el-dialog
        title="添加应用"
        :visible.sync="addAppDialogVisible"
        width="40%"
        @close="addDialogClosed"
        :append-to-body="true"
      >
        <el-form :model="addAppInfoForm" :rules="addRules" ref="addAppRef">
          <el-form-item label="应用名称" label-width="120px" prop="platName">
            <el-input
              autocomplete="off"
              v-model="addAppInfoForm.platName"
              clearable
              placeholder="最多输入20个字"
            ></el-input>
          </el-form-item>
          <el-form-item label="所属组织" label-width="120px" prop="orgId">
            <el-select v-model="addAppInfoForm.orgId" placeholder="请选择组织" @change="changeAddOrgSelect">
                <el-option
                  v-for="item in orgInfoList"
                  :key="item.id"
                  :label="item.orgName"
                  :value="item.id">
                </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属项目" label-width="120px" prop="projectCode">
            <el-select v-model="addAppInfoForm.projectCode" placeholder="请选择项目">
                <el-option
                  v-for="item in projectInfoList"
                  :key="item.projectCode"
                  :label="item.projectName"
                  :value="item.projectCode">
                </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="appkey" label-width="120px" prop="appkey">
            <el-input
              autocomplete="off"
              v-model="addAppInfoForm.appkey"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="appsecret" label-width="120px" prop="appsecret">
            <el-input
              autocomplete="off"
              v-model="addAppInfoForm.appsecret"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="过期时间" label-width="120px" prop="expire">
            <el-date-picker
              v-model="addAppInfoForm.expire"
              type="date"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="可接入数" label-width="120px">
          <el-row>
            <el-col :span="18">
              <el-input
                autocomplete="off"
                v-model="resourceOrder"
                disabled
              ></el-input>
            </el-col>
            <el-col :span="6">
              <el-button-group>
                <el-button icon="el-icon-minus" @click="decrease"></el-button>
                <el-button icon="el-icon-plus" @click="increase"></el-button>
              </el-button-group>
            </el-col>
          </el-row>
        </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addAppDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addAppInfoSubmit()">提 交</el-button>
        </span>
      </el-dialog>



      <!-- 修改应用 -->
      <el-dialog
        title="修改应用"
        :visible.sync="editDialogVisible"
        width="40%"
        @close="editDialogClosed"
        :append-to-body="true"
      >
        <el-form :model="editAppInfoForm" :rules="addRules" ref="editAppRef">
          <el-form-item label="应用名称" label-width="120px" prop="platName">
            <el-input
              autocomplete="off"
              v-model="editAppInfoForm.platName"
              clearable
              placeholder="最多输入20个字"
            ></el-input>
          </el-form-item>
          <el-form-item label="所属组织" label-width="120px" prop="orgId">
            <el-select v-model="editAppInfoForm.orgId" placeholder="请选择组织" @change="changeEditOrgSelect">
                <el-option
                  v-for="item in orgInfoList"
                  :key="item.id"
                  :label="item.orgName"
                  :value="item.id">
                </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属项目" label-width="120px" prop="projectCode">
            <el-select v-model="editAppInfoForm.projectCode" placeholder="请选择项目">
                <el-option
                  v-for="item in projectInfoList"
                  :key="item.projectCode"
                  :label="item.projectName"
                  :value="item.projectCode">
                </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="appkey" label-width="120px" prop="appkey">
            <el-input
              autocomplete="off"
              v-model="editAppInfoForm.appkey"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="appsecret" label-width="120px" prop="appsecret">
            <el-input
              autocomplete="off"
              v-model="editAppInfoForm.appsecret"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="过期时间" label-width="120px" prop="expire">
            <el-date-picker
              v-model="editAppInfoForm.expire"
              type="date"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="可接入数" label-width="120px">
          <el-row>
            <el-col :span="18">
              <el-input
                autocomplete="off"
                v-model="resourceOrder"
                disabled
              ></el-input>
            </el-col>
            <el-col :span="6">
              <el-button-group>
                <el-button icon="el-icon-minus" @click="decrease"></el-button>
                <el-button icon="el-icon-plus" @click="increase"></el-button>
              </el-button-group>
            </el-col>
          </el-row>
        </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="editAppInfoSubmit()">保 存</el-button>
        </span>
      </el-dialog>



      <!-- 节点列表 -->
      <el-dialog
        title="节点列表"
        :visible.sync="clientListDialogVisible"
        width="60%"
      >
        <el-table :data="clientNodeList" border stripe>
          <el-table-column type="index"></el-table-column>
          <el-table-column label="IP" prop="ip" width="100px"></el-table-column>
          <el-table-column label="端口" prop="port" width="80px"></el-table-column>
          <el-table-column label="状态" prop="online" width="80px">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.online === true" type="success"
                >在线</el-tag>
              <el-tag v-else-if="scope.row.online === false" type="info">离线</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column label="磁盘使用" prop="diskTotal">
            <template slot-scope="scope">
              <el-progress :text-inside="true" :stroke-width="20" :percentage="Math.floor((scope.row.diskTotal - scope.row.diskAvailable) * 1.0 / scope.row.diskTotal * 100)"></el-progress>
            </template>
          </el-table-column>
          <el-table-column label="内存使用" prop="memTotal">
            <template slot-scope="scope">
              <el-progress :text-inside="true" :stroke-width="20" :percentage="Math.floor((scope.row.memTotal - scope.row.memAvailable) * 1.0 / scope.row.memTotal * 100)" status="success"></el-progress>
            </template>
          </el-table-column>
          <el-table-column label="更新时间" prop="updateTimeStr" width="160px"></el-table-column>
        </el-table>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      addRules: {
        platName: [{ required: true, message: "请输入应用名称", trigger: "blur" }],
        orgId: [{ required: true, message: "请选择组织", trigger: "blur" }],
        projectCode: [{ required: true, message: "请选择项目", trigger: "blur" }],
        expire: [{ required: true, message: "请输入有效期", trigger: "blur" }]
      },
      queryParams: {
        queryStr: "",
        pageNo: 1,
        pageSize: 10,
        orgId: null,
        projectCode: "",
      },
      total: 0,
      addAppDialogVisible: false,
      editDialogVisible: false,
      clientListDialogVisible: false,
      appInfoList: [],
      addAppInfoForm: {
        platName: "",
        appkey: "",
        appsecret: "",
        expire: "",
        orgId: null,
        projectCode: "",
        nodeLimit: 1,
      },
      editAppInfoForm: {

      },
      orgInfoList: [],
      projectInfoList: [],
      resourceOrder: 1,
      clientNodeList: [],
    }
  },
  created() {
    this.getOrgList();
    // this.getAppList();
  },
  methods: {
    async getOrgList() {
      const { data: res } = await this.$http.get("org/find/all");
      if (res.code == 0) {
        this.orgInfoList = res.data;
      }
    },
    changeOrgSelect() {
      this.getAppList();
      this.loadProjectListByOrgId(this.queryParams.orgId);
    },
    changeAddOrgSelect() {
      this.loadProjectListByOrgId(this.addAppInfoForm.orgId);
    },
    changeEditOrgSelect() {
      this.loadProjectListByOrgId(this.editAppInfoForm.orgId);
    },
    async loadProjectListByOrgId(orgId) {
      const { data: res } = await this.$http.get("project/find/list/byOrg", {
        params: {
          orgId: orgId
        }
      });
      if (res.code == 0) {
        this.projectInfoList = res.data;
      }
    },
    // 公共方法区
    decrease() {
      if (this.resourceOrder > 1) {
        this.resourceOrder--;
      }
    },
    increase() {
      this.resourceOrder++;
    },
    handleSizeChange(newSize) {
      this.queryParams.pageSize = newSize;
      this.getAppList();
    },
    handleCurrentChange(newPage) {
      this.queryParams.pageNo = newPage;
      this.getAppList();
    },
    async getAppList() {
      const { data: res } = await this.$http.get("platform/find/byPage", {
        params: this.queryParams,
      });
      if (res.code == 0) {
        this.appInfoList = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.error(res.msg);
      }
    },
    async appStateChanged(appInfo) {
      const confirmResult = await this.$confirm(
        "此操作将禁止边端使用, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        this.$message.info("已取消操作");
        if (appInfo.clientStatus == 0) {
          appInfo.clientStatus++;
        } else {
          appInfo.clientStatus--;
        }
        return false;
      }

      const { data: res } = await this.$http.get("platform/change/status", {
        params: {
          id: appInfo.id,
          status: appInfo.clientStatus
        }
      });

      if (res.code == 0) {
        this.$message.success("修改成功");
      } else {
        this.$message.error(res.msg);
      }
    },
    editDialogClosed() {
      // this.$refs.editAppRef.resetFields();
      this.resourceOrder = 1;
    },
    async editAppInfoSubmit() {
      this.$refs.editAppRef.validate(async (valid) => {
        if (valid) {
          this.editAppInfoForm.nodeLimit = this.resourceOrder;
          const { data: res } = await this.$http.post(
            "platform/update",
            this.editAppInfoForm
          );
          if (res.code == 0) {
            this.$message.success("修改成功");
            this.getAppList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.editDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    async showAddAppDialog() {
      this.addAppDialogVisible = true;
      const { data: res } = await this.$http.get("platform/get/appkey");
      if (res.code == 0) {
        this.addAppInfoForm.appkey = res.data.appkey;
        this.addAppInfoForm.appsecret = res.data.appsecret;
      } else {
        this.$message.error(res.msg);
      }
    },
    async showEditDialog(id) {
      const { data: res } = await this.$http.get("platform/find/byid/" + id);
      if (res.code == 0) {
        this.editAppInfoForm = res.data;
        this.resourceOrder = this.editAppInfoForm.nodeLimit;
        this.editDialogVisible = true;
        this.loadProjectListByOrgId(this.editAppInfoForm.orgId);
      } else {
        this.$message.error(res.msg);
      }
    },
    async removeById(id) {
      const confirmResult = await this.$confirm("是否确认删除该应用?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.delete("platform/remove/" + id);
      if (res.code == 0) {
        this.$message.success("删除成功！");
        this.getAppList();
      } else {
        return this.$message.error("删除失败！");
      }
    },
    addDialogClosed() {
      this.$refs.addAppRef.resetFields();
      this.resourceOrder = 1;
    },
    async addAppInfoSubmit() {
      this.$refs.addAppRef.validate(async (valid) => {
        if (valid) {
          this.addAppInfoForm.nodeLimit = this.resourceOrder;
          const { data: res } = await this.$http.post(
            "platform/add",
            this.addAppInfoForm
          );
          if (res.code == 0) {
            this.$message.success("添加成功");
            this.getAppList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.addAppDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    showClientList(clientInfo) {
      this.clientNodeList = clientInfo.nodeList;
      this.clientListDialogVisible = true;
      console.log(clientInfo);
    }
  }
}
</script>

<style lang="less" scoped>
.currentNode {
  color: blue;
  font-size: 14px;
  font-weight: bold;
}

.currentNode:hover{
  cursor:pointer;
}
</style>