<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>微信推送配置</el-breadcrumb-item>
    </el-breadcrumb>

    <el-row :gutter="5">
      <el-col :span="6">
        <el-card>
          <div slot="header">
            <span class="span-title">项目列表</span>
          </div>
          <el-tree
            :data="projectList"
            node-key="id"
            default-expand-all
            :expand-on-click-node="false"
            :props="defaultProps"
            @node-click="loadUsers"
            class="tree-sy"
          >
            <span class="custom-tree-node" slot-scope="{ node }">
              <span>{{ node.label }}</span>
            </span>
          </el-tree>
        </el-card>
      </el-col>
      <el-col :span="18">
        <el-card>
          <div slot="header">
            <span class="span-title">订阅用户列表</span>
          </div>
          <el-button
            type="primary"
            @click="addFileDialogShow()"
            icon="el-icon-plus"
            >添加微信用户</el-button
          >

          <!-- 表格数据 -->
          <el-table :data="userList" border stripe>
            <el-table-column label="昵称" prop="nickname"></el-table-column>
            <el-table-column label="别名" prop="aliasname"></el-table-column>
            <el-table-column
              label="创建时间"
              prop="createTime"
              width="160px"
            ></el-table-column>
            <el-table-column label="操作" width="80px">
              <template slot-scope="scope">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="删除"
                  placement="top"
                  :enterable="false"
                >
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    @click="removeById(scope.row.projectCode, scope.row.openid)"
                    v-has="'sys:attachment:mgr'"
                  ></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>

          <!-- 分页组件 -->
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryParams.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="queryParams.pageSize"
            layout="total, sizes, prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </el-card>

        <!-- 添加用户 -->
        <el-dialog
          title="添加用户"
          :visible.sync="addUserDialogVisible"
          v-if="addUserDialogVisible"
          width="60%"
        >
          <v-wxuser-table
            :bindProject="true"
            :projectCode="queryParams.projectCode"
            @pjbind="bindProject"
            @pjunbind="unbindProject"
            ref="wxuserRef"
          ></v-wxuser-table>
        </el-dialog>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import vWxuserTable from "./WxuserTable.vue";
export default {
  components: {
    vWxuserTable
  },
  data () {
    return {
      defaultProps: {
        children: 'children',
        label: 'projectName'
      },
      queryParams: {
        projectCode: "",
        pageNo: 1,
        pageSize: 10
      },
      total: 0,
      projectList: [],
      userList: [],
      addUserDialogVisible: false,
    }
  },
  created () {
    this.getProjectList();
  },
  methods: {
    handleSizeChange (newSize) {
      this.queryParams.pageSize = newSize;
      this.loadUsers(this.queryParams);
    },
    handleCurrentChange (newPage) {
      this.queryParams.pageNo = newPage;
      this.loadUsers(this.queryParams);
    },
    async getProjectList () {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        if (res.data == null) {
          this.projectList = [];
          return;
        }
        this.projectList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async loadUsers (data) {
      this.queryParams.projectCode = data.projectCode;
      console.log(this.queryParams);
      const { data: res } = await this.$http.get("wx/find/project/wxusers", {
        params: this.queryParams
      });
      if (res.code == 0) {
        if (res.data == null) {
          this.userList = [];
          this.total = 0;
          return;
        }

        this.userList = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.error(res.msg);
      }
    },
    async removeById (projectCode, openid) {
      const confirmResult = await this.$confirm("是否确认解除该用户绑定?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消操作");
      }
      const { data: res } = await this.$http.delete("wx/delete/project/openid", {
        params: {
          projectCode: projectCode,
          openid: openid
        }
      });
      if (res.code == 0) {
        this.$message.success("操作成功！");
        this.loadUsers(this.queryParams);
      } else {
        return this.$message.error("操作失败！");
      }
    },
    addFileDialogShow () {
      if (this.queryParams.projectCode == null || this.queryParams.projectCode == '') {
        return this.$message.error("请选择一个项目！");
        return;
      }
      this.addUserDialogVisible = true;
    },
    async bindProject (openid) {
      const { data: res } = await this.$http.get("wx/bind/project/openid", {
        params: {
          projectCode: this.queryParams.projectCode,
          openid: openid
        }
      });
      if (res.code == 0) {
        this.$refs.wxuserRef.refreshData();
        this.$message.success("绑定成功！");
        this.loadUsers(this.queryParams);
      } else {
        this.$message.error(res.msg);
      }
    },
    async unbindProject (openid) {
      const { data: res } = await this.$http.delete("wx/delete/project/openid", {
        params: {
          projectCode: this.queryParams.projectCode,
          openid: openid
        }
      });
      if (res.code == 0) {
        this.$refs.wxuserRef.refreshData();
        this.$message.success("解绑成功！");
        this.loadUsers(this.queryParams);
      } else {
        this.$message.error(res.msg);
      }
    }
  }
}
</script>