<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>设备管理</el-breadcrumb-item>
      <el-breadcrumb-item>超高管理</el-breadcrumb-item>
      <el-breadcrumb-item>超高列表</el-breadcrumb-item>
    </el-breadcrumb>


    <!-- 卡片视图 -->
    <el-card>
      <el-row>
        <el-col :span="4">
          <el-select
            v-model="queryParams.projectCode"
            placeholder="请选择项目"
            clearable
            @clear="getDeviceList"
            @change="getDeviceList"
          >
            <el-option
              v-for="item in projectInfoList"
              :key="item.projectCode"
              :label="item.projectName"
              :value="item.projectCode"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-button
            @click="exportDevices"
            type="success"
            v-has="'sys:device:chaogao:export'"
            icon="el-icon-download"
            >导出</el-button
          >
      </el-row>




      <!-- 表格数据 -->
      <el-table :data="deviceInfoList" border stripe>
        <el-table-column type="expand">
          <template slot-scope="props">
            <span v-if="props.row.remark == null || props.row.remark == ''">无备注</span>
            <span v-else>{{ props.row.remark }}</span>
          </template>
        </el-table-column>
        <el-table-column label="设备名称" prop="name"></el-table-column>
        <el-table-column label="设备行政编号" prop="deviceGovCode"></el-table-column>
        <el-table-column label="高程设备名" prop="gcDevName"></el-table-column>
        <el-table-column label="库水位设备名" prop="swDevName"></el-table-column>
        <el-table-column label="主测点" prop="isMain" width="80px">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.isMain === 0" type="info"
              >否</el-tag
            >
            <el-tag v-else-if="scope.row.isMain === 1" type="success"
              >是</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="是否有效" width="80px">
          <template slot-scope="scope">
            <el-switch
              disabled
              v-model="scope.row.availableBl"
              @change="deviceStateChanged(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="最新采集时间" prop="collectTime" width="150px"></el-table-column>
        <el-table-column label="创建日期" prop="createTime" width="100px">
          <template slot-scope="scope">
            {{common.dateFormat('YYYY-MM-dd', new Date(Date.parse(scope.row.createTime)))}}
          </template>
        </el-table-column>
        <!-- <el-table-column label="操作" width="120px">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="修改"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                @click="showEditDialog(scope.row.deviceCode)"
                v-has="'sys:device:chaogao:edit'"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                v-has="'sys:device:chaogao:delete'"
                @click="removeByDeviceCode(scope.row.deviceCode)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column> -->
      </el-table>

      <!-- 分页组件 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>





      <!-- 添加超高 -->
      <el-dialog
        title="添加超高"
        :visible.sync="addDialogVisible"
        width="50%"
        v-if="addDialogVisible"
        @close="addDialogClosed"
      >
        <div style="height:350px; overflow: auto;">
        <el-form :model="deviceInfoForm" :rules="devRules" ref="addInfoRef">
          <el-form-item label="所属项目" label-width="120px" prop="projectCode">
            <el-select
              v-model="deviceInfoForm.projectCode"
              placeholder="请选择项目"
              clearable
              @change="changeProject"
            >
              <el-option
                v-for="item in projectInfoList"
                :key="item.projectCode"
                :label="item.projectName"
                :value="item.projectCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-row>
            <el-col :span="12">
              <el-form-item label="设备名称" label-width="120px" prop="name">
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.name"
                  clearable
                  placeholder="最多输入25个字"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否主测点" label-width="120px" prop="isMain">
                <el-radio-group v-model="deviceInfoForm.isMain">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="设备行政编号" label-width="120px" prop="deviceGovCode">
            <el-input
              autocomplete="off"
              v-model="deviceInfoForm.deviceGovCode"
              clearable
              placeholder="格式：AABBCCDDDDEEFF，用于上报应急管理系统"
            ></el-input><br/>
            <span style="color: rgb(175, 175, 175);">
              省[AA]市[BB]县/区[CC]尾矿库[DDDD]监测设备类型[EE]设备位号[FF]
            </span>
          </el-form-item>
          <el-form-item label="高程设备" label-width="120px" prop="gcDevCode">
            <el-select @change="changeGc" v-model="deviceInfoForm.gcDevCode" placeholder="请选择高程设备" clearable>
              <el-option
                v-for="item in gaochengDeviceList"
                :key="item.deviceCode"
                :label="item.name"
                :value="item.deviceCode"
                >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="库水位设备" label-width="120px" prop="swDevCode">
            <el-select @change="changeSw" v-model="deviceInfoForm.swDevCode" placeholder="请选择库水位设备" clearable>
              <el-option
                v-for="item in shuiweiDeviceList"
                :key="item.deviceCode"
                :label="item.name"
                :value="item.deviceCode"
                >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注" label-width="120px" prop="remark">
            <el-input
              type="textarea"
              v-model="deviceInfoForm.remark"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addInfoSubmit()">提 交</el-button>
        </span>
      </el-dialog>




      <!-- 编辑干滩 -->
      <el-dialog
        title="编辑干滩"
        :visible.sync="editDialogVisible"
        width="50%"
        v-if="editDialogVisible"
      >
        <div style="height:350px; overflow: auto;">
        <el-form :model="deviceInfoForm" :rules="devRules" ref="editInfoRef">
          <el-form-item label="所属项目" label-width="120px" prop="projectCode">
            <el-select
              v-model="deviceInfoForm.projectCode"
              placeholder="请选择项目"
              clearable
            >
              <el-option
                v-for="item in projectInfoList"
                :key="item.projectCode"
                :label="item.projectName"
                :value="item.projectCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-row>
            <el-col :span="12">
              <el-form-item label="设备名称" label-width="120px" prop="name">
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.name"
                  clearable
                  placeholder="最多输入25个字"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否主测点" label-width="120px" prop="isMain">
                <el-radio-group v-model="deviceInfoForm.isMain">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="设备行政编号" label-width="120px" prop="deviceGovCode">
            <el-input
              autocomplete="off"
              v-model="deviceInfoForm.deviceGovCode"
              clearable
              placeholder="格式：AABBCCDDDDEEFF，用于上报应急管理系统"
            ></el-input><br/>
            <span style="color: rgb(175, 175, 175);">
              省[AA]市[BB]县/区[CC]尾矿库[DDDD]监测设备类型[EE]设备位号[FF]
            </span>
          </el-form-item>
          <el-form-item label="高程设备" label-width="120px" prop="gcDevCode">
            <el-select @change="changeGc" v-model="deviceInfoForm.gcDevCode" placeholder="请选择高程设备" clearable>
              <el-option
                v-for="item in gaochengDeviceList"
                :key="item.deviceCode"
                :label="item.name"
                :value="item.deviceCode"
                >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="库水位设备" label-width="120px" prop="swDevCode">
            <el-select @change="changeSw" v-model="deviceInfoForm.swDevCode" placeholder="请选择库水位设备" clearable>
              <el-option
                v-for="item in shuiweiDeviceList"
                :key="item.deviceCode"
                :label="item.name"
                :value="item.deviceCode"
                >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注" label-width="120px" prop="remark">
            <el-input
              type="textarea"
              v-model="deviceInfoForm.remark"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="editInfoSubmit()">保 存</el-button>
        </span>
      </el-dialog>

      <el-dialog
        title="导出提示"
        :visible.sync="showDownloadVisible"
        width="30%">
        <span>数据导出已执行，完成后文件将在【下载中心】展示。</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showDownloadVisible = false">取 消</el-button>
          <el-button type="primary" @click="showDownloadVisible = false">确 定</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      addDialogVisible: false,
      editDialogVisible: false,
      showDownloadVisible: false,
      queryParams: {
        projectCode: "",
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      devRules: {
        projectCode: [{ required: true, message: "请选择项目", trigger: "blur" }],
        gcDevCode: [{ required: true, message: "请选择高程设备", trigger: "blur" }],
        name: [{ required: true, message: "请输入名字", trigger: "blur" }],
        swDevCode: [{ required: true, message: "请选择库水位设备", trigger: "blur" }],
      },
      projectInfoList: [],
      deviceInfoList: [],
      deviceInfoForm: {},
      wuweiDeviceInfoList: [],
      gaochengDeviceList: [],
      shuiweiDeviceList: [],
    }
  },
  created() {
    this.getAllProject();
  },
  methods: {
    handleSizeChange(newSize) {
      this.queryParams.pageSize = newSize;
      this.getDeviceList();
    },
    handleCurrentChange(newPage) {
      this.queryParams.pageNo = newPage;
      this.getDeviceList();
    },
    async getAllProject() {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        this.projectInfoList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getDeviceList() {
      const { data: res } = await this.$http.get("device/chaogao/find/page", {
        params: this.queryParams
      });
      if (res.code == 0) {
        if (res.data == null) {
          this.deviceInfoList = [];
          this.total = 0;
          return;
        }

        this.deviceInfoList = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.error(res.msg);
      }
    },
    async showEditDialog(deviceCode) {
      this.deviceInfoForm = {};
      const { data: res } = await this.$http.get("device/chaogao/find/bycode/" + deviceCode);
      if (res.code == 0) {
        this.deviceInfoForm = res.data;
        this.changeProject(this.deviceInfoForm.projectCode);
        this.editDialogVisible = true;
      } else {
        this.$message.error(res.msg);
      }
    },
    async removeByDeviceCode(deviceCode) {
      const confirmResult = await this.$confirm("是否确认删除该设备?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.delete("device/chaogao/remove/" + deviceCode);
      if (res.code == 0) {
        this.$message.success("删除成功！");
        this.getDeviceList();
      } else {
        return this.$message.error(res.msg);
      }
    },
    showAddDialog() {
      this.deviceInfoForm = {};
      this.addDialogVisible = true;
    },
    addDialogClosed() {
      this.$refs.addInfoRef.resetFields();
    },
    async addInfoSubmit() {
      this.$refs.addInfoRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "device/chaogao/add",
            this.deviceInfoForm
          );
          if (res.code == 0) {
            this.$message.success("添加成功");
            this.getDeviceList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.addDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    async editInfoSubmit() {
      this.$refs.editInfoRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "device/chaogao/update",
            this.deviceInfoForm
          );
          if (res.code == 0) {
            this.$message.success("修改成功");
            this.getDeviceList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.editDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    async deviceStateChanged(devInfo) {
      const confirmResult = await this.$confirm(
        "此操作将影响平台收集设备数据, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        this.$message.info("已取消操作");
        devInfo.availableBl = !devInfo.availableBl;
        return false;
      }

      const { data: res } = await this.$http.get("device/change/status", {
        params: {
          deviceCode: devInfo.deviceCode,
          available: devInfo.availableBl
        }
      });

      if (res.code == 0) {
        this.$message.success("修改成功");
      } else {
        this.$message.error(res.msg);
      }
    },
    async changeProject(projectCode) {
      const { data: res } = await this.$http.get("device/wuwei/find/page", {
        params: {
          pageNo: 1,
          pageSize: 100,
          projectCode: projectCode
        }
      });
      if (res.code == 0) {
        this.wuweiDeviceInfoList = res.data.list;
        // 筛选对应的物位计类型
        this.wuweiFilter();
      } else {
        this.$message.error(res.msg);
      }
    },
    wuweiFilter() {
      this.gaochengDeviceList = [];
      this.shuiweiDeviceList = [];
      this.wuweiDeviceInfoList.forEach(o => {
        if (o.wwType == 2) {
          // 库水位
          this.shuiweiDeviceList.push(o);
        } else if (o.wwType == 3) {
          // 高程
          this.gaochengDeviceList.push(o);
        }
      });
    },
    changeGc(deviceCode) {
      this.wuweiDeviceInfoList.forEach(o => {
        if (o.deviceCode == deviceCode) {
          this.deviceInfoForm.gcDevName = o.name;
          return;
        }
      });
    },
    changeSw(deviceCode) {
      this.wuweiDeviceInfoList.forEach(o => {
        if (o.deviceCode == deviceCode) {
          this.deviceInfoForm.swDevName = o.name;
          return;
        }
      });
    },
    async exportDevices(){
      if (this.queryParams.projectCode == null || this.queryParams.projectCode == "") {
        this.$message.error("请选择项目.");
        return;
      }
      const { data: res } = await this.$http.get("file/chaogao/export", {
        params: {
          projectCode: this.queryParams.projectCode
        }
      });
      if (res.code == 0) {
        this.showDownloadVisible = true;
      } else {
        this.$message.error(res.msg);
      }
    },
  }
}
</script>