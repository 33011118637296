<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>数据分析</el-breadcrumb-item>
      <el-breadcrumb-item>雨量分析</el-breadcrumb-item>
    </el-breadcrumb>

    <el-row :gutter="5">
      <el-col :span="4">
        <!-- 断面 -->
        <el-card>
          <el-row :gutter="10">
            <el-col :span="24">
              <el-select
                v-model="queryParams.projectCode"
                placeholder="请选择项目"
                clearable
                @clear="getDeviceList"
                @change="getDeviceList"
              >
                <el-option
                  v-for="item in projectInfoList"
                  :key="item.projectCode"
                  :label="item.projectName"
                  :value="item.projectCode"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>

          <el-tree
            :data="deviceData"
            node-key="deviceCode"
            default-expand-all
            :expand-on-click-node="false"
            :props="defaultProps"
            @node-click="loadDeviceStatisData"
            class="tree-sy"
          >
            <span class="custom-tree-node" slot-scope="{ node }">
              <span>{{ node.label }}</span>
            </span>
          </el-tree>
        </el-card>
      </el-col>
      <el-col :span="20">
        <el-row>
          <el-card>
            <el-divider content-position="left">
              <span style="color: rgb(158 158 158)"
                >降雨量分析（分析时间为T+1，统计24小时前的数据）</span
              >
            </el-divider>
            <div style="height: 460px; overflow: auto">
              <div
                id="diagramChart"
                class="chart"
                style="width: 99%; height: 99%"
              ></div>
            </div>
          </el-card>
        </el-row>
        <el-row>
          <el-card>
            <div style="height: 30px; font-size: 14px; line-height: 30px">
              统计维度：
              <el-radio v-model="statisDate" :label="1" @change="loadData"
                >年</el-radio
              >
              <el-radio v-model="statisDate" :label="2" @change="loadData"
                >月</el-radio
              >
              <el-radio v-model="statisDate" :label="3" @change="loadData"
                >日</el-radio
              >
            </div>
          </el-card>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data () {
    return {
      defaultProps: {
        children: "children",
        label: "name",
      },
      queryParams: {
        projectCode: "",
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      projectInfoList: [],
      deviceData: [],
      currentSelectNode: {},
      statisDate: 3,
      statisDatas: [],
    }
  },
  created () {
    this.getAllProject();
  },
  methods: {
    async getAllProject () {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        this.projectInfoList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getDeviceList () {
      const { data: res } = await this.$http.get("device/yuliang/find/page", {
        params: {
          pageNo: 1,
          pageSize: 100,
          projectCode: this.queryParams.projectCode
        }
      });
      if (res.code == 0) {
        if (res.data == null) {
          this.deviceData = [];
          return;
        }
        this.deviceData = res.data.list;
      } else {
        this.$message.error(res.msg);
      }
    },
    loadDeviceStatisData (data) {
      this.currentSelectNode = data;
      this.loadData();
    },
    async loadData () {
      const { data: res } = await this.$http.get("device/yuliang/statis/data", {
        params: {
          deviceCode: this.currentSelectNode.deviceCode,
          dateType: this.statisDate
        }
      });
      if (res.code == 0) {
        if (res.data == null) {
          this.statisDatas = [];
          return;
        }
        this.statisDatas = res.data;
        this.drawData(res.data);
      } else {
        this.$message.error(res.msg);
      }
    },
    drawData (dataList) {
      // 基于准备好的dom，初始化echarts实例
      if (this.diagramChart == null) {
        this.diagramChart = this.$echarts.init(
          document.getElementById("diagramChart")
        );
      }

      if (dataList == null || dataList.length == 0) {
        this.diagramChart.clear();
        return;
      }

      // 无限接近0的值
      let valueData = [];
      let timeData = [];
      let devName = this.currentSelectNode.name;
      let devTime = "";
      for (let i = 0; i < dataList.length; i++) {
        let v = this.common.floatFormat(dataList[i].totalValue, 4);
        if (v == 0) {
          // 过滤0的值
          continue;
        } else {
          valueData.push(v);
        }

        timeData.push(dataList[i].timeText);
        devTime = dataList[i].timeText;
      }

      // Y轴类型
      let yAxisType = 'value';
      let option = {
        title: {
          text: "降雨量柱状图（单位：mm）",
          textStyle: {
            fontSize: 14,
          },
          subtext: "设备：" + devName,
          subtextStyle: {
            fontSize: 12,
          }
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: [],
        },
        grid: {
          top: "90",
          bottom: "80",
          left: "60",
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          data: timeData,
          axisLabel: {
            rotate: this.statisDate === 1 ? 0 : -90,
            formatter: this.statisDate === 1 ? '{value} 年' : '{value}'
          }
        },
        yAxis: {
          type: yAxisType,
          axisLabel: {
            formatter: '{value} mm'
          },
        },
        series: [
          {
            name: "降雨量",
            type: "bar",
            data: valueData,
            label: {
                show: true,
                position: 'top'
              }
          },
        ],
      };

      // 绘制图表
      this.diagramChart.clear();
      this.diagramChart.setOption(option);
    },
  }
}
</script>

<style lang="less" scoped>
.tree-sy {
  margin-top: 15px;
  font-size: 14px;
}
</style>