<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>消息管理</el-breadcrumb-item>
      <el-breadcrumb-item>站内消息</el-breadcrumb-item>
    </el-breadcrumb>

    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="未读消息" name="unread">
        <unreadtab v-if="isUnreadActive"></unreadtab>
      </el-tab-pane>
      <el-tab-pane label="已读消息" name="read">
        <readtab v-if="isReadActive"></readtab>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import InternalRead from "@/components/page/message/InternalRead.vue";
import InternalUnread from "@/components/page/message/InternalUnread.vue";
export default {
  components: {
    readtab: InternalRead,
    unreadtab: InternalUnread,
  },
  data() {
    return {
      activeName: "unread",
      isReadActive: false,
      isUnreadActive: true,
      unreadCount: 0,
    };
  },
  methods: {
    handleClick(tab, event) {
      if (tab.name == "read") {
        this.isReadActive = true;
        this.isUnreadActive = false;
      } else if (tab.name == "unread") {
        this.isReadActive = false;
        this.isUnreadActive = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
</style>