<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb v-if="hiddenPath === false" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>实时监测</el-breadcrumb-item>
      <el-breadcrumb-item>表面位移(全站仪)</el-breadcrumb-item>
    </el-breadcrumb>

    <el-row :gutter="5">
      <el-col :span="4">
        <el-card>
          <el-row :gutter="10">
            <el-col :span="24">
              <el-select
                v-model="queryParams.projectCode"
                placeholder="请选择项目"
                clearable
                @clear="getDeviceList"
                @change="getDeviceList"
              >
                <el-option
                  v-for="item in projectInfoList"
                  :key="item.projectCode"
                  :label="item.projectName"
                  :value="item.projectCode"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>

          <el-tree
            :data="deviceData"
            node-key="deviceCode"
            default-expand-all
            :expand-on-click-node="false"
            :props="defaultProps"
            @node-click="loadDeviceRealTimeData"
            class="tree-sy"
          >
            <span class="custom-tree-node" slot-scope="{ node }">
              <span>{{ node.label }}</span>
            </span>
          </el-tree>
        </el-card>
      </el-col>
      <el-col :span="20">
        <el-row>
          <el-card>
            <el-divider content-position="left">
              <span style="color: rgb(158 158 158)">表面位移动态曲线图</span>
            </el-divider>
            <el-row>
              <div style="height: 200px; overflow: hidden">
                <el-col :span="8">
                  <div
                    id="varXChart"
                    class="chart"
                    style="width: 98%; height: 200px"
                  ></div>
                </el-col>
                <el-col :span="8">
                  <div
                    id="varYChart"
                    class="chart"
                    style="width: 98%; height: 200px"
                  ></div>
                </el-col>
                <el-col :span="8">
                  <div
                    id="varZChart"
                    class="chart"
                    style="width: 98%; height: 200px"
                  ></div>
                </el-col>
              </div>
            </el-row>
            <el-row>
              <div style="height: 200px; overflow: hidden">
                <el-col :span="8">
                  <div
                    id="sXChart"
                    class="chart"
                    style="width: 98%; height: 200px"
                  ></div>
                </el-col>
                <el-col :span="8">
                  <div
                    id="sYChart"
                    class="chart"
                    style="width: 98%; height: 200px"
                  ></div>
                </el-col>
                <el-col :span="8">
                  <div
                    id="sZChart"
                    class="chart"
                    style="width: 98%; height: 200px"
                  ></div>
                </el-col>
              </div>
            </el-row>
          </el-card>
        </el-row>
        <el-row>
          <el-card>
            <el-divider content-position="left">
              <span style="color: rgb(158 158 158)">监测信息</span>
            </el-divider>
            <div style="font-size: 14px; line-height: 30px">
              <el-row>
                <el-col :span="4"
                  >设备名称：{{ currentSelectNode.dwName }}</el-col
                >
                <el-col :span="5"
                  >类型：{{
                    this.common.quanzhanNodeType(currentSelectNode.dwType)
                  }}</el-col
                >
                <el-col :span="5"
                  >水平矢量：{{
                    this.common.weiyiSl(currentData.gapx, currentData.gapy)
                  }}mm</el-col
                >
                <el-col :span="5"
                  >水平变化：{{
                    this.common.weiyisp(currentData.gapx, currentData.gapy)
                  }}</el-col
                >
                <el-col :span="5"
                  >垂直变化：{{
                    this.common.weiyicz(currentData.gapz)
                  }}</el-col
                >
              </el-row>
              <el-row>
                <el-col
                >当前值：
                  △X:{{ currentData.gapx }}，
                  △Y:{{currentData.gapy}}，
                  △Z:{{ currentData.gapz }}， 
                  本次位移水平矢量:{{this.common.weiyiSl(currentData.gapx, currentData.gapy)}}，
                  本次位移变化方向:{{this.common.weiyisp(currentData.gapx, currentData.gapy)}}{{this.common.weiyicz(currentData.gapz)}}<br/>
                  ∑X:{{currentData.aclx}}，
                  ∑Y:{{ currentData.acly }}，
                  ∑Z:{{ currentData.aclz }}
                  累计位移水平矢量:{{this.common.weiyiSl(currentData.aclx, currentData.acly)}}，
                  累计位移变化方向:{{this.common.weiyisp(currentData.aclx, currentData.acly)}}{{this.common.weiyicz(currentData.aclz)}}
                  </el-col
                >
              </el-row>
              <el-row>
                <el-col
                  >△X预警值:蓝色预警：{{
                    this.common.alarmTypeToString(
                      alarmConfig.vxblueAlarmValue,
                      alarmConfig.vxblueComparisonOperator
                    )
                  }}（mm），黄色预警：{{
                    this.common.alarmTypeToString(
                      alarmConfig.vxyellowAlarmValue,
                      alarmConfig.vxyellowComparisonOperator
                    )
                  }}（mm），橙色预警：{{
                    this.common.alarmTypeToString(
                      alarmConfig.vxorangeAlarmValue,
                      alarmConfig.vxorangeComparisonOperator
                    )
                  }}（mm），红色预警：{{
                    this.common.alarmTypeToString(
                      alarmConfig.vxredAlarmValue,
                      alarmConfig.vxredComparisonOperator
                    )
                  }}（mm）</el-col
                >
              </el-row>
              <el-row>
                <el-col
                  >△Y预警值:蓝色预警：{{
                    this.common.alarmTypeToString(
                      alarmConfig.vyblueAlarmValue,
                      alarmConfig.vyblueComparisonOperator
                    )
                  }}（mm），黄色预警：{{
                    this.common.alarmTypeToString(
                      alarmConfig.vyyellowAlarmValue,
                      alarmConfig.vyyellowComparisonOperator
                    )
                  }}（mm），橙色预警：{{
                    this.common.alarmTypeToString(
                      alarmConfig.vyorangeAlarmValue,
                      alarmConfig.vyorangeComparisonOperator
                    )
                  }}（mm），红色预警：{{
                    this.common.alarmTypeToString(
                      alarmConfig.vyredAlarmValue,
                      alarmConfig.vyredComparisonOperator
                    )
                  }}（mm）</el-col
                >
              </el-row>
              <el-row>
                <el-col
                  >△Z预警值:蓝色预警：{{
                    this.common.alarmTypeToString(
                      alarmConfig.vzblueAlarmValue,
                      alarmConfig.vzblueComparisonOperator
                    )
                  }}（mm），黄色预警：{{
                    this.common.alarmTypeToString(
                      alarmConfig.vzyellowAlarmValue,
                      alarmConfig.vzyellowComparisonOperator
                    )
                  }}（mm），橙色预警：{{
                    this.common.alarmTypeToString(
                      alarmConfig.vzorangeAlarmValue,
                      alarmConfig.vzorangeComparisonOperator
                    )
                  }}（mm），红色预警：{{
                    this.common.alarmTypeToString(
                      alarmConfig.vzredAlarmValue,
                      alarmConfig.vzredComparisonOperator
                    )
                  }}（mm）</el-col
                >
              </el-row>
              <el-row>
                <el-col
                  >∑X预警值:蓝色预警：{{
                    this.common.alarmTypeToString(
                      alarmConfig.sxblueAlarmValue,
                      alarmConfig.sxblueComparisonOperator
                    )
                  }}（mm），黄色预警：{{
                    this.common.alarmTypeToString(
                      alarmConfig.sxyellowAlarmValue,
                      alarmConfig.sxyellowComparisonOperator
                    )
                  }}（mm），橙色预警：{{
                    this.common.alarmTypeToString(
                      alarmConfig.sxorangeAlarmValue,
                      alarmConfig.sxorangeComparisonOperator
                    )
                  }}（mm），红色预警：{{
                    this.common.alarmTypeToString(
                      alarmConfig.sxredAlarmValue,
                      alarmConfig.sxredComparisonOperator
                    )
                  }}（mm）</el-col
                >
              </el-row>
              <el-row>
                <el-col
                  >∑Y预警值:蓝色预警：{{
                    this.common.alarmTypeToString(
                      alarmConfig.syblueAlarmValue,
                      alarmConfig.syblueComparisonOperator
                    )
                  }}（mm），黄色预警：{{
                    this.common.alarmTypeToString(
                      alarmConfig.syyellowAlarmValue,
                      alarmConfig.syyellowComparisonOperator
                    )
                  }}（mm），橙色预警：{{
                    this.common.alarmTypeToString(
                      alarmConfig.syorangeAlarmValue,
                      alarmConfig.syorangeComparisonOperator
                    )
                  }}（mm），红色预警：{{
                    this.common.alarmTypeToString(
                      alarmConfig.syredAlarmValue,
                      alarmConfig.syredComparisonOperator
                    )
                  }}（mm）</el-col
                >
              </el-row>
              <el-row>
                <el-col
                  >∑Z预警值:蓝色预警：{{
                    this.common.alarmTypeToString(
                      alarmConfig.szblueAlarmValue,
                      alarmConfig.szblueComparisonOperator
                    )
                  }}（mm），黄色预警：{{
                    this.common.alarmTypeToString(
                      alarmConfig.szyellowAlarmValue,
                      alarmConfig.szyellowComparisonOperator
                    )
                  }}（mm），橙色预警：{{
                    this.common.alarmTypeToString(
                      alarmConfig.szorangeAlarmValue,
                      alarmConfig.szorangeComparisonOperator
                    )
                  }}（mm），红色预警：{{
                    this.common.alarmTypeToString(
                      alarmConfig.szredAlarmValue,
                      alarmConfig.szredComparisonOperator
                    )
                  }}（mm）</el-col
                >
              </el-row>
            </div>
          </el-card>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    hiddenPath: Boolean
  },
  data () {
    return {
      defaultProps: {
        children: "children",
        label: "dwName",
      },
      queryParams: {
        projectCode: "",
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      projectInfoList: [],
      deviceData: [],
      currentSelectNode: {},
      alarmConfig: {
        yellowAlarmValue: "",
        yellowComparisonOperator: null,
        orangeAlarmValue: "",
        orangeComparisonOperator: null,
        redAlarmValue: "",
        redComparisonOperator: null,
      },
      varXChart: null,
      varYChart: null,
      varZChart: null,
      sXChart: null,
      sYChart: null,
      sZChart: null,
      currentData: {
      },
    }
  },
  created () {
    this.getAllProject();
  },
  methods: {
    // 由程序处罚的加载
    async defaultLoadData(deviceInfo) {
      this.queryParams.projectCode = deviceInfo.projectCode;
      this.getDeviceList();
      this.loadDeviceRealTimeData(deviceInfo);
    },
    async getAllProject () {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        this.projectInfoList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getDeviceList () {
      const { data: res } = await this.$http.get("device/qz/dw/find/page", {
        params: {
          pageNo: 1,
          pageSize: 100,
          projectCode: this.queryParams.projectCode
        }
      });
      if (res.code == 0) {
        if (res.data == null) {
          this.deviceData = [];
          return;
        }
        this.deviceData = res.data.list;
      } else {
        this.$message.error(res.msg);
      }
    },
    loadDeviceRealTimeData (data) {
      this.currentSelectNode = data;
      this.loadStatData(data.dwCode);
      this.loadAlarmConfig(data.dwCode);
    },
    loadStatData (deviceCode) {
      // 加载报表数据
      this.getDataList(deviceCode);
    },
    async loadAlarmConfig (deviceCode) {
      const { data: res } = await this.$http.get("alarm/find/gnss/config", {
        params: {
          deviceCode: deviceCode
        }
      });
      if (res.code == 0) {
        this.alarmConfig = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getDataList (deviceCode) {
      // 获取最新的20条数据
      const { data: res } = await this.$http.post("device/qz/dw/history/data/diagram", {
        projectCode: this.queryParams.projectCode,
        deviceCode: deviceCode,
        pageNo: 1,
        pageSize: 100
      });
      if (res.code == 0) {
        this.drawData(res.data.list, res.data.alarmConfig);
      } else {
        this.$message.error(res.msg);
      }
    },
    drawData (dataList, alarmConfig) {
      let varXList = [];
      let varYList = [];
      let varZList = [];
      let sXList = [];
      let sYList = [];
      let sZList = [];
      this.currentData = {};
      if (dataList != null) {
        // 获取最新值
        this.currentData = dataList[0];
        dataList.forEach(d => {
          varXList.push({
            currentValue: d.gapx,
            collectTime: d.collectTime,
            deviceName: d.dwName
          });
          varYList.push({
            currentValue: d.gapy,
            collectTime: d.collectTime,
            deviceName: d.dwName
          });
          varZList.push({
            currentValue: d.gapz,
            collectTime: d.collectTime,
            deviceName: d.dwName
          });
          sXList.push({
            currentValue: d.aclx,
            collectTime: d.collectTime,
            deviceName: d.dwName
          });
          sYList.push({
            currentValue: d.acly,
            collectTime: d.collectTime,
            deviceName: d.dwName
          });
          sZList.push({
            currentValue: d.aclz,
            collectTime: d.collectTime,
            deviceName: d.dwName
          });
        });
      }

      this.drawVarX(varXList, alarmConfig);
      this.drawVarY(varYList, alarmConfig);
      this.drawVarZ(varZList, alarmConfig);
      this.drawSX(sXList, alarmConfig);
      this.drawSY(sYList, alarmConfig);
      this.drawSZ(sZList, alarmConfig);
    },
    drawVarX (dataList, alarmConfig) {
      // 基于准备好的dom，初始化echarts实例
      if (this.varXChart == null) {
        this.varXChart = this.$echarts.init(
          document.getElementById("varXChart")
        );
      }

      if (dataList == null || dataList.length == 0) {
        this.varXChart.clear();
        return;
      }

      let blueData = [];
      let yellowData = [];
      let orangeData = [];
      let redData = [];

      // 无限接近0的值
      let valueData = [];
      let timeData = [];
      let devName = "";
      let devTime = "";
      for (let i = (dataList.length - 1); i < dataList.length && i >= 0; i--) {
        let v = this.common.floatFormat(dataList[i].currentValue, 6);
        if (v == 0) {
          // 过滤0的值
          continue;
        } else {
          valueData.push(v);
        }

        timeData.push(dataList[i].collectTime);
        devName = dataList[i].deviceName;
        devTime = dataList[i].collectTime;
        if (alarmConfig != null) {
          if (alarmConfig.vxblueAlarmValue != null && alarmConfig.vxblueAlarmValue != '') {
            blueData.push(this.common.floatFormat(alarmConfig.vxblueAlarmValue, 2));
          }
          if (alarmConfig.vxyellowAlarmValue != null && alarmConfig.vxyellowAlarmValue != '') {
            yellowData.push(this.common.floatFormat(alarmConfig.vxyellowAlarmValue, 2));
          }
          if (alarmConfig.vxorangeAlarmValue != null && alarmConfig.vxorangeAlarmValue != '') {
            orangeData.push(this.common.floatFormat(alarmConfig.vxorangeAlarmValue, 2));
          }
          if (alarmConfig.vxredAlarmValue != null && alarmConfig.vxredAlarmValue != '') {
            redData.push(this.common.floatFormat(alarmConfig.vxredAlarmValue, 2));
          }
        }
      }

      // Y轴类型
      let yAxisType = '';
      if (blueData.length > 0 || yellowData.length > 0 || orangeData.length > 0 || redData.length > 0) {
        // 有预警线的类型
        // yAxisType = 'log';
        yAxisType = 'value';
      } else {
        yAxisType = 'value';
      }

      let option = {
        title: {
          text: "△X实时监测曲线图（单位：mm）",
          textStyle: {
            fontSize: 14,
          },
          subtext: "设备：" + devName + ", 采集时间：" + devTime,
          subtextStyle: {
            fontSize: 12,
          }
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: [],
        },
        grid: {
          top: "90",
          bottom: "30",
          left: "60",
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {},
          },
        },
        dataZoom: { // 放大和缩放
          type: 'inside'
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: timeData,
        },
        yAxis: {
          type: yAxisType,
          axisLabel: {
            formatter: '{value} mm'
          },
        },
        series: [
          {
            name: "△X",
            type: "line",
            smooth: true,
            data: valueData,
            markPoint: {
              data: [
                { type: "max", name: "最大值" },
                { type: "min", name: "最小值" },
              ],
            },
          },
        ],
      };

      // if (blueData.length > 0) {
      //   option.series.push({
      //     name: "蓝色预警线",
      //     type: "line",
      //     data: blueData,
      //     lineStyle: {
      //       color: 'blue',
      //       type: 'dashed'
      //     },
      //     itemStyle: {
      //       color: 'blue',
      //     }
      //   });
      // }

      // if (yellowData.length > 0) {
      //   option.series.push({
      //     name: "黄色预警线",
      //     type: "line",
      //     data: yellowData,
      //     lineStyle: {
      //       color: 'yellow',
      //       type: 'dashed'
      //     },
      //     itemStyle: {
      //       color: 'yellow',
      //     }
      //   });
      // }

      // if (orangeData.length > 0) {
      //   option.series.push({
      //     name: "橙色预警线",
      //     type: "line",
      //     data: orangeData,
      //     lineStyle: {
      //       color: 'orange',
      //       type: 'dashed'
      //     },
      //     itemStyle: {
      //       color: 'orange',
      //     }
      //   });
      // }

      // if (redData.length > 0) {
      //   option.series.push({
      //     name: "红色预警线",
      //     type: "line",
      //     data: redData,
      //     lineStyle: {
      //       color: 'red',
      //       type: 'dashed'
      //     },
      //     itemStyle: {
      //       color: 'red',
      //     }
      //   });
      // }

      // 绘制图表
      this.varXChart.clear();
      this.varXChart.setOption(option);
    },
    drawVarY (dataList, alarmConfig) {
      // 基于准备好的dom，初始化echarts实例
      if (this.varYChart == null) {
        this.varYChart = this.$echarts.init(
          document.getElementById("varYChart")
        );
      }

      if (dataList == null || dataList.length == 0) {
        this.varYChart.clear();
        return;
      }

      let blueData = [];
      let yellowData = [];
      let orangeData = [];
      let redData = [];

      // 无限接近0的值
      let valueData = [];
      let timeData = [];
      let devName = "";
      let devTime = "";
      for (let i = (dataList.length - 1); i < dataList.length && i >= 0; i--) {
        let v = this.common.floatFormat(dataList[i].currentValue, 6);
        if (v == 0) {
          // 过滤0的值
          continue;
        } else {
          valueData.push(v);
        }

        timeData.push(dataList[i].collectTime);
        devName = dataList[i].deviceName;
        devTime = dataList[i].collectTime;
        if (alarmConfig != null) {
          if (alarmConfig.vyblueAlarmValue != null && alarmConfig.vyblueAlarmValue != '') {
            blueData.push(this.common.floatFormat(alarmConfig.vyblueAlarmValue, 2));
          }
          if (alarmConfig.vyyellowAlarmValue != null && alarmConfig.vyyellowAlarmValue != '') {
            yellowData.push(this.common.floatFormat(alarmConfig.vyyellowAlarmValue, 2));
          }
          if (alarmConfig.vyorangeAlarmValue != null && alarmConfig.vyorangeAlarmValue != '') {
            orangeData.push(this.common.floatFormat(alarmConfig.vyorangeAlarmValue, 2));
          }
          if (alarmConfig.vyredAlarmValue != null && alarmConfig.vyredAlarmValue != '') {
            redData.push(this.common.floatFormat(alarmConfig.vyredAlarmValue, 2));
          }
        }
      }

      // Y轴类型
      let yAxisType = '';
      if (blueData.length > 0 || yellowData.length > 0 || orangeData.length > 0 || redData.length > 0) {
        // 有预警线的类型
        // yAxisType = 'log';
        yAxisType = 'value';
      } else {
        yAxisType = 'value';
      }

      let option = {
        title: {
          text: "△Y实时监测曲线图（单位：mm）",
          textStyle: {
            fontSize: 14,
          },
          subtext: "设备：" + devName + ", 采集时间：" + devTime,
          subtextStyle: {
            fontSize: 12,
          }
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: [],
        },
        grid: {
          top: "90",
          bottom: "30",
          left: "60",
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {},
          },
        },
        dataZoom: { // 放大和缩放
          type: 'inside'
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: timeData,
        },
        yAxis: {
          type: yAxisType,
          axisLabel: {
            formatter: '{value} mm'
          },
        },
        series: [
          {
            name: "△Y",
            type: "line",
            smooth: true,
            data: valueData,
            markPoint: {
              data: [
                { type: "max", name: "最大值" },
                { type: "min", name: "最小值" },
              ],
            },
          },
        ],
      };

      // if (blueData.length > 0) {
      //   option.series.push({
      //     name: "蓝色预警线",
      //     type: "line",
      //     data: blueData,
      //     lineStyle: {
      //       color: 'blue',
      //       type: 'dashed'
      //     },
      //     itemStyle: {
      //       color: 'blue',
      //     }
      //   });
      // }

      // if (yellowData.length > 0) {
      //   option.series.push({
      //     name: "黄色预警线",
      //     type: "line",
      //     data: yellowData,
      //     lineStyle: {
      //       color: 'yellow',
      //       type: 'dashed'
      //     },
      //     itemStyle: {
      //       color: 'yellow',
      //     }
      //   });
      // }

      // if (orangeData.length > 0) {
      //   option.series.push({
      //     name: "橙色预警线",
      //     type: "line",
      //     data: orangeData,
      //     lineStyle: {
      //       color: 'orange',
      //       type: 'dashed'
      //     },
      //     itemStyle: {
      //       color: 'orange',
      //     }
      //   });
      // }

      // if (redData.length > 0) {
      //   option.series.push({
      //     name: "红色预警线",
      //     type: "line",
      //     data: redData,
      //     lineStyle: {
      //       color: 'red',
      //       type: 'dashed'
      //     },
      //     itemStyle: {
      //       color: 'red',
      //     }
      //   });
      // }

      // 绘制图表
      this.varYChart.clear();
      this.varYChart.setOption(option);
    },
    drawVarZ (dataList, alarmConfig) {
      // 基于准备好的dom，初始化echarts实例
      if (this.varZChart == null) {
        this.varZChart = this.$echarts.init(
          document.getElementById("varZChart")
        );
      }

      if (dataList == null || dataList.length == 0) {
        this.varZChart.clear();
        return;
      }

      let blueData = [];
      let yellowData = [];
      let orangeData = [];
      let redData = [];

      // 无限接近0的值
      let valueData = [];
      let timeData = [];
      let devName = "";
      let devTime = "";
      for (let i = (dataList.length - 1); i < dataList.length && i >= 0; i--) {
        let v = this.common.floatFormat(dataList[i].currentValue, 6);
        if (v == 0) {
          // 过滤0的值
          continue;
        } else {
          valueData.push(v);
        }

        timeData.push(dataList[i].collectTime);
        devName = dataList[i].deviceName;
        devTime = dataList[i].collectTime;
        if (alarmConfig != null) {
          if (alarmConfig.vzblueAlarmValue != null && alarmConfig.vzblueAlarmValue != '') {
            blueData.push(this.common.floatFormat(alarmConfig.vzblueAlarmValue, 2));
          }
          if (alarmConfig.vzyellowAlarmValue != null && alarmConfig.vzyellowAlarmValue != '') {
            yellowData.push(this.common.floatFormat(alarmConfig.vzyellowAlarmValue, 2));
          }
          if (alarmConfig.vzorangeAlarmValue != null && alarmConfig.vzorangeAlarmValue != '') {
            orangeData.push(this.common.floatFormat(alarmConfig.vzorangeAlarmValue, 2));
          }
          if (alarmConfig.vzredAlarmValue != null && alarmConfig.vzredAlarmValue != '') {
            redData.push(this.common.floatFormat(alarmConfig.vzredAlarmValue, 2));
          }
        }
      }

      // Y轴类型
      let yAxisType = '';
      if (yellowData.length > 0 || orangeData.length > 0 || redData.length > 0) {
        // 有预警线的类型
        // yAxisType = 'log';
        yAxisType = 'value';
      } else {
        yAxisType = 'value';
      }

      let option = {
        title: {
          text: "△Z实时监测曲线图（单位：mm）",
          textStyle: {
            fontSize: 14,
          },
          subtext: "设备：" + devName + ", 采集时间：" + devTime,
          subtextStyle: {
            fontSize: 12,
          }
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: [],
        },
        grid: {
          top: "90",
          bottom: "30",
          left: "60",
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {},
          },
        },
        dataZoom: { // 放大和缩放
          type: 'inside'
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: timeData,
        },
        yAxis: {
          type: yAxisType,
          axisLabel: {
            formatter: '{value} mm'
          },
        },
        series: [
          {
            name: "△Z",
            type: "line",
            smooth: true,
            data: valueData,
            markPoint: {
              data: [
                { type: "max", name: "最大值" },
                { type: "min", name: "最小值" },
              ],
            },
          },
        ],
      };

      // if (blueData.length > 0) {
      //   option.series.push({
      //     name: "蓝色预警线",
      //     type: "line",
      //     data: blueData,
      //     lineStyle: {
      //       color: 'blue',
      //       type: 'dashed'
      //     },
      //     itemStyle: {
      //       color: 'blue',
      //     }
      //   });
      // }

      // if (yellowData.length > 0) {
      //   option.series.push({
      //     name: "黄色预警线",
      //     type: "line",
      //     data: yellowData,
      //     lineStyle: {
      //       color: 'yellow',
      //       type: 'dashed'
      //     },
      //     itemStyle: {
      //       color: 'yellow',
      //     }
      //   });
      // }

      // if (orangeData.length > 0) {
      //   option.series.push({
      //     name: "橙色预警线",
      //     type: "line",
      //     data: orangeData,
      //     lineStyle: {
      //       color: 'orange',
      //       type: 'dashed'
      //     },
      //     itemStyle: {
      //       color: 'orange',
      //     }
      //   });
      // }

      // if (redData.length > 0) {
      //   option.series.push({
      //     name: "红色预警线",
      //     type: "line",
      //     data: redData,
      //     lineStyle: {
      //       color: 'red',
      //       type: 'dashed'
      //     },
      //     itemStyle: {
      //       color: 'red',
      //     }
      //   });
      // }

      // 绘制图表
      this.varZChart.clear();
      this.varZChart.setOption(option);
    },
    drawSX (dataList, alarmConfig) {
      // 基于准备好的dom，初始化echarts实例
      if (this.sXChart == null) {
        this.sXChart = this.$echarts.init(
          document.getElementById("sXChart")
        );
      }

      if (dataList == null || dataList.length == 0) {
        this.sXChart.clear();
        return;
      }

      let blueData = [];
      let yellowData = [];
      let orangeData = [];
      let redData = [];

      // 无限接近0的值
      let valueData = [];
      let timeData = [];
      let devName = "";
      let devTime = "";
      for (let i = (dataList.length - 1); i < dataList.length && i >= 0; i--) {
        let v = this.common.floatFormat(dataList[i].currentValue, 6);
        if (v == 0) {
          // 过滤0的值
          continue;
        } else {
          valueData.push(v);
        }

        timeData.push(dataList[i].collectTime);
        devName = dataList[i].deviceName;
        devTime = dataList[i].collectTime;
        if (alarmConfig != null) {
          if (alarmConfig.sxblueAlarmValue != null && alarmConfig.sxblueAlarmValue != '') {
            blueData.push(this.common.floatFormat(alarmConfig.sxblueAlarmValue, 2));
          }
          if (alarmConfig.sxyellowAlarmValue != null && alarmConfig.sxyellowAlarmValue != '') {
            yellowData.push(this.common.floatFormat(alarmConfig.sxyellowAlarmValue, 2));
          }
          if (alarmConfig.sxorangeAlarmValue != null && alarmConfig.sxorangeAlarmValue != '') {
            orangeData.push(this.common.floatFormat(alarmConfig.sxorangeAlarmValue, 2));
          }
          if (alarmConfig.sxredAlarmValue != null && alarmConfig.sxredAlarmValue != '') {
            redData.push(this.common.floatFormat(alarmConfig.sxredAlarmValue, 2));
          }
        }
      }

      // Y轴类型
      let yAxisType = '';
      if (blueData.length > 0 || yellowData.length > 0 || orangeData.length > 0 || redData.length > 0) {
        // 有预警线的类型
        // yAxisType = 'log';
        yAxisType = 'value';
      } else {
        yAxisType = 'value';
      }

      let option = {
        title: {
          text: "∑X实时监测曲线图（单位：mm）",
          textStyle: {
            fontSize: 14,
          },
          subtext: "设备：" + devName + ", 采集时间：" + devTime,
          subtextStyle: {
            fontSize: 12,
          }
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: [],
        },
        grid: {
          top: "90",
          bottom: "30",
          left: "60",
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {},
          },
        },
        dataZoom: { // 放大和缩放
          type: 'inside'
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: timeData,
        },
        yAxis: {
          type: yAxisType,
          axisLabel: {
            formatter: '{value}'
          },
        },
        series: [
          {
            name: "∑X",
            type: "line",
            smooth: true,
            data: valueData,
            markPoint: {
              data: [
                { type: "max", name: "最大值" },
                { type: "min", name: "最小值" },
              ],
            },
          },
        ],
      };

      // if (blueData.length > 0) {
      //   option.series.push({
      //     name: "蓝色预警线",
      //     type: "line",
      //     data: blueData,
      //     lineStyle: {
      //       color: 'blue',
      //       type: 'dashed'
      //     },
      //     itemStyle: {
      //       color: 'blue',
      //     }
      //   });
      // }

      // if (yellowData.length > 0) {
      //   option.series.push({
      //     name: "黄色预警线",
      //     type: "line",
      //     data: yellowData,
      //     lineStyle: {
      //       color: 'yellow',
      //       type: 'dashed'
      //     },
      //     itemStyle: {
      //       color: 'yellow',
      //     }
      //   });
      // }

      // if (orangeData.length > 0) {
      //   option.series.push({
      //     name: "橙色预警线",
      //     type: "line",
      //     data: orangeData,
      //     lineStyle: {
      //       color: 'orange',
      //       type: 'dashed'
      //     },
      //     itemStyle: {
      //       color: 'orange',
      //     }
      //   });
      // }

      // if (redData.length > 0) {
      //   option.series.push({
      //     name: "红色预警线",
      //     type: "line",
      //     data: redData,
      //     lineStyle: {
      //       color: 'red',
      //       type: 'dashed'
      //     },
      //     itemStyle: {
      //       color: 'red',
      //     }
      //   });
      // }

      // 绘制图表
      this.sXChart.clear();
      this.sXChart.setOption(option);
    },
    drawSY (dataList, alarmConfig) {
      // 基于准备好的dom，初始化echarts实例
      if (this.sYChart == null) {
        this.sYChart = this.$echarts.init(
          document.getElementById("sYChart")
        );
      }

      if (dataList == null || dataList.length == 0) {
        this.sYChart.clear();
        return;
      }

      let blueData = [];
      let yellowData = [];
      let orangeData = [];
      let redData = [];

      // 无限接近0的值
      let valueData = [];
      let timeData = [];
      let devName = "";
      let devTime = "";
      for (let i = (dataList.length - 1); i < dataList.length && i >= 0; i--) {
        let v = this.common.floatFormat(dataList[i].currentValue, 6);
        if (v == 0) {
          // 过滤0的值
          continue;
        } else {
          valueData.push(v);
        }

        timeData.push(dataList[i].collectTime);
        devName = dataList[i].deviceName;
        devTime = dataList[i].collectTime;
        if (alarmConfig != null) {
          if (alarmConfig.syblueAlarmValue != null && alarmConfig.syblueAlarmValue != '') {
            blueData.push(this.common.floatFormat(alarmConfig.syblueAlarmValue, 2));
          }
          if (alarmConfig.syyellowAlarmValue != null && alarmConfig.syyellowAlarmValue != '') {
            yellowData.push(this.common.floatFormat(alarmConfig.syyellowAlarmValue, 2));
          }
          if (alarmConfig.syorangeAlarmValue != null && alarmConfig.syorangeAlarmValue != '') {
            orangeData.push(this.common.floatFormat(alarmConfig.syorangeAlarmValue, 2));
          }
          if (alarmConfig.syredAlarmValue != null && alarmConfig.syredAlarmValue != '') {
            redData.push(this.common.floatFormat(alarmConfig.syredAlarmValue, 2));
          }
        }
      }

      // Y轴类型
      let yAxisType = '';
      if (blueData.length > 0 || yellowData.length > 0 || orangeData.length > 0 || redData.length > 0) {
        // 有预警线的类型
        // yAxisType = 'log';
        yAxisType = 'value';
      } else {
        yAxisType = 'value';
      }

      let option = {
        title: {
          text: "∑Y实时监测曲线图（单位：mm）",
          textStyle: {
            fontSize: 14,
          },
          subtext: "设备：" + devName + ", 采集时间：" + devTime,
          subtextStyle: {
            fontSize: 12,
          }
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: [],
        },
        grid: {
          top: "90",
          bottom: "30",
          left: "60",
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {},
          },
        },
        dataZoom: { // 放大和缩放
          type: 'inside'
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: timeData,
        },
        yAxis: {
          type: yAxisType,
          axisLabel: {
            formatter: '{value}'
          },
        },
        series: [
          {
            name: "∑Y",
            type: "line",
            smooth: true,
            data: valueData,
            markPoint: {
              data: [
                { type: "max", name: "最大值" },
                { type: "min", name: "最小值" },
              ],
            },
          },
        ],
      };

      // if (blueData.length > 0) {
      //   option.series.push({
      //     name: "蓝色预警线",
      //     type: "line",
      //     data: blueData,
      //     lineStyle: {
      //       color: 'blue',
      //       type: 'dashed'
      //     },
      //     itemStyle: {
      //       color: 'blue',
      //     }
      //   });
      // }

      // if (yellowData.length > 0) {
      //   option.series.push({
      //     name: "黄色预警线",
      //     type: "line",
      //     data: yellowData,
      //     lineStyle: {
      //       color: 'yellow',
      //       type: 'dashed'
      //     },
      //     itemStyle: {
      //       color: 'yellow',
      //     }
      //   });
      // }

      // if (orangeData.length > 0) {
      //   option.series.push({
      //     name: "橙色预警线",
      //     type: "line",
      //     data: orangeData,
      //     lineStyle: {
      //       color: 'orange',
      //       type: 'dashed'
      //     },
      //     itemStyle: {
      //       color: 'orange',
      //     }
      //   });
      // }

      // if (redData.length > 0) {
      //   option.series.push({
      //     name: "红色预警线",
      //     type: "line",
      //     data: redData,
      //     lineStyle: {
      //       color: 'red',
      //       type: 'dashed'
      //     },
      //     itemStyle: {
      //       color: 'red',
      //     }
      //   });
      // }

      // 绘制图表
      this.sYChart.clear();
      this.sYChart.setOption(option);
    },
    drawSZ (dataList, alarmConfig) {
      // 基于准备好的dom，初始化echarts实例
      if (this.sZChart == null) {
        this.sZChart = this.$echarts.init(
          document.getElementById("sZChart")
        );
      }

      if (dataList == null || dataList.length == 0) {
        this.sZChart.clear();
        return;
      }

      let blueData = [];
      let yellowData = [];
      let orangeData = [];
      let redData = [];

      // 无限接近0的值
      let valueData = [];
      let timeData = [];
      let devName = "";
      let devTime = "";
      for (let i = (dataList.length - 1); i < dataList.length && i >= 0; i--) {
        let v = this.common.floatFormat(dataList[i].currentValue, 6);
        if (v == 0) {
          // 过滤0的值
          continue;
        } else {
          valueData.push(v);
        }

        timeData.push(dataList[i].collectTime);
        devName = dataList[i].deviceName;
        devTime = dataList[i].collectTime;
        if (alarmConfig != null) {
          if (alarmConfig.szblueAlarmValue != null && alarmConfig.szblueAlarmValue != '') {
            blueData.push(this.common.floatFormat(alarmConfig.szblueAlarmValue, 2));
          }
          if (alarmConfig.szyellowAlarmValue != null && alarmConfig.szyellowAlarmValue != '') {
            yellowData.push(this.common.floatFormat(alarmConfig.szyellowAlarmValue, 2));
          }
          if (alarmConfig.szorangeAlarmValue != null && alarmConfig.szorangeAlarmValue != '') {
            orangeData.push(this.common.floatFormat(alarmConfig.szorangeAlarmValue, 2));
          }
          if (alarmConfig.szredAlarmValue != null && alarmConfig.szredAlarmValue != '') {
            redData.push(this.common.floatFormat(alarmConfig.szredAlarmValue, 2));
          }
        }
      }

      // Y轴类型
      let yAxisType = '';
      if (blueData.length > 0 || yellowData.length > 0 || orangeData.length > 0 || redData.length > 0) {
        // 有预警线的类型
        // yAxisType = 'log';
        yAxisType = 'value';
      } else {
        yAxisType = 'value';
      }

      let option = {
        title: {
          text: "∑Z实时监测曲线图（单位：mm）",
          textStyle: {
            fontSize: 14,
          },
          subtext: "设备：" + devName + ", 采集时间：" + devTime,
          subtextStyle: {
            fontSize: 12,
          }
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: [],
        },
        grid: {
          top: "90",
          bottom: "30",
          left: "60",
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {},
          },
        },
        dataZoom: { // 放大和缩放
          type: 'inside'
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: timeData,
        },
        yAxis: {
          type: yAxisType,
          axisLabel: {
            formatter: '{value}'
          },
        },
        series: [
          {
            name: "∑Z",
            type: "line",
            smooth: true,
            data: valueData,
            markPoint: {
              data: [
                { type: "max", name: "最大值" },
                { type: "min", name: "最小值" },
              ],
            },
          },
        ],
      };

      // if (blueData.length > 0) {
      //   option.series.push({
      //     name: "蓝色预警线",
      //     type: "line",
      //     data: blueData,
      //     lineStyle: {
      //       color: 'blue',
      //       type: 'dashed'
      //     },
      //     itemStyle: {
      //       color: 'blue',
      //     }
      //   });
      // }

      // if (yellowData.length > 0) {
      //   option.series.push({
      //     name: "黄色预警线",
      //     type: "line",
      //     data: yellowData,
      //     lineStyle: {
      //       color: 'yellow',
      //       type: 'dashed'
      //     },
      //     itemStyle: {
      //       color: 'yellow',
      //     }
      //   });
      // }

      // if (orangeData.length > 0) {
      //   option.series.push({
      //     name: "橙色预警线",
      //     type: "line",
      //     data: orangeData,
      //     lineStyle: {
      //       color: 'orange',
      //       type: 'dashed'
      //     },
      //     itemStyle: {
      //       color: 'orange',
      //     }
      //   });
      // }

      // if (redData.length > 0) {
      //   option.series.push({
      //     name: "红色预警线",
      //     type: "line",
      //     data: redData,
      //     lineStyle: {
      //       color: 'red',
      //       type: 'dashed'
      //     },
      //     itemStyle: {
      //       color: 'red',
      //     }
      //   });
      // }

      // 绘制图表
      this.sZChart.clear();
      this.sZChart.setOption(option);
    },
  }
}
</script>
<style lang="less" scoped>
.tree-sy {
  margin-top: 15px;
  font-size: 14px;
}
</style>