<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>微信用户管理</el-breadcrumb-item>
    </el-breadcrumb>

    <v-wxuser-table :bindProject="false"></v-wxuser-table>
  </div>
</template>

<script>
import vWxuserTable from "./WxuserTable.vue";
export default {
  components: {
    vWxuserTable
  },
  data () {
    return {

    }
  },
  created () {

  },
  methods: {

  }
}
</script>