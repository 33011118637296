<template>
  <div>
    <el-row :gutter="5">
      <el-col :span="5">
        <el-card>
          <div slot="header">
            <span class="span-title">设备列表</span>
          </div>
          <el-tree
            :data="deviceInfoList"
            node-key="id"
            default-expand-all
            :expand-on-click-node="false"
            :props="defaultProps"
            @node-click="loadDeviceConfig"
            class="tree-sy"
          >
            <span class="custom-tree-node" slot-scope="{ node }">
              <span>{{ node.label }}</span>
            </span>
          </el-tree>
        </el-card>
      </el-col>
      <el-col :span="19">
        <el-card>
          <div slot="header">
            <span class="span-title">设备配置</span>
            <!-- <el-button style="float: right; padding: 3px 0" type="text" @click="addInfoSubmit">保存配置</el-button> -->
          </div>
          <el-form
            :model="configData"
            :rules="configRules"
            ref="configInfoRef"
            disabled
          >
            <el-row>
              <el-col :span="8">
                <el-form-item
                  label="采集时间"
                  label-width="120px"
                  prop="gpsParseInterval"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.gpsParseInterval"
                    clearable
                    ><template slot="append">min</template></el-input
                  >
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="软件狗类型"
                  label-width="120px"
                  prop="gpsSoftType"
                >
                  <el-select
                    v-model="configData.gpsSoftType"
                    placeholder="请选择类型"
                    clearable
                  >
                    <el-option label="单频" :value="2"></el-option>
                    <el-option label="双频" :value="3"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="基线类型"
                  label-width="120px"
                  prop="gpsBaseLineType"
                >
                  <el-select
                    v-model="configData.gpsBaseLineType"
                    placeholder="请选择类型"
                    clearable
                  >
                    <el-option label="短基线" :value="0"></el-option>
                    <el-option label="长基线" :value="1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item
                  label="静态高度截止角"
                  label-width="120px"
                  prop="gpsCutAngle"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.gpsCutAngle"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="处理间隔"
                  label-width="120px"
                  prop="gpsCalInterval"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.gpsCalInterval"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="分段最小历元"
                  label-width="120px"
                  prop="gpsMinimumSegmentEpoch"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.gpsMinimumSegmentEpoch"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item
                  label="STH文件保留时间"
                  label-width="130px"
                  prop="gpsFileDelInterval"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.gpsFileDelInterval"
                    clearable
                    ><template slot="append">天</template></el-input
                  >
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="动态高度截止角"
                  label-width="120px"
                  prop="gpsRtCutAngle"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.gpsRtCutAngle"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="动态数据存储间隔"
                  label-width="130px"
                  prop="gpsDynamicSaveInterval"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.gpsDynamicSaveInterval"
                    clearable
                    ><template slot="append">分</template></el-input
                  >
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item
                  label="动态数据预警间隔"
                  label-width="130px"
                  prop="gpsDynamicAlertInterval"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.gpsDynamicAlertInterval"
                    clearable
                    ><template slot="append">分</template></el-input
                  >
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="目标椭球"
                  label-width="120px"
                  prop="ellipsoidName"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.ellipsoidName"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="长半轴"
                  label-width="120px"
                  prop="ellipsoidDba"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.ellipsoidDba"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item
                  label="扁率倒数"
                  label-width="120px"
                  prop="ellipsoidDbreflat"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.ellipsoidDbreflat"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="投影方式"
                  label-width="120px"
                  prop="projectType"
                >
                  <el-select
                    v-model="configData.projectType"
                    placeholder="请选择类型"
                    clearable
                  >
                    <el-option label="高斯投影" :value="0"></el-option>
                    <el-option label="UTM投影" :value="1"></el-option>
                    <el-option label="横轴墨卡托投影" :value="2"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="中央子午线"
                  label-width="120px"
                  prop="dptmDbcentmerd"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.dptmDbcentmerd"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="X常数" label-width="120px" prop="dptmDbn">
                  <el-input
                    autocomplete="off"
                    v-model="configData.dptmDbn"
                    clearable
                    ><template slot="append">m</template></el-input
                  >
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Y常数" label-width="120px" prop="dptmDbe">
                  <el-input
                    autocomplete="off"
                    v-model="configData.dptmDbe"
                    clearable
                    ><template slot="append">m</template></el-input
                  >
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="尺度比"
                  label-width="120px"
                  prop="dptmDbscale"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.dptmDbscale"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item
                  label="投影高"
                  label-width="120px"
                  prop="dptmDbprjalt"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.dptmDbprjalt"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="基准维度"
                  label-width="120px"
                  prop="dptmDbbaselat"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.dptmDbbaselat"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="垂直平差使用"
                  label-width="120px"
                  prop="useVerBalan"
                >
                  <el-select
                    v-model="configData.useVerBalan"
                    placeholder="请选择类型"
                    clearable
                  >
                    <el-option label="不使用" :value="0"></el-option>
                    <el-option label="使用" :value="1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item
                  label="平差模式"
                  label-width="120px"
                  prop="verBalanMode"
                >
                  <el-select
                    v-model="configData.verBalanMode"
                    placeholder="请选择类型"
                    clearable
                  >
                    <el-option label="倾斜平面" :value="0"></el-option>
                    <el-option label="水准面模型" :value="1"></el-option>
                    <el-option label="水准面+斜面" :value="2"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="北原点"
                  label-width="120px"
                  prop="verBalanDborgn"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.verBalanDborgn"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="东原点"
                  label-width="120px"
                  prop="verBalanDborge"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.verBalanDborge"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item
                  label="平差常数"
                  label-width="120px"
                  prop="verBalanDbh"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.verBalanDbh"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="北斜坡"
                  label-width="120px"
                  prop="verBalanDbnorthslope"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.verBalanDbnorthslope"
                    clearable
                    ><template slot="append">ppm</template></el-input
                  >
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="东斜坡"
                  label-width="120px"
                  prop="verBalanDbeastslope"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.verBalanDbeastslope"
                    clearable
                    ><template slot="append">ppm</template></el-input
                  >
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item
                  label="X平移"
                  label-width="120px"
                  prop="transform7Dbx"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.transform7Dbx"
                    clearable
                    ><template slot="append">m</template></el-input
                  >
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="Y平移"
                  label-width="120px"
                  prop="transform7Dby"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.transform7Dby"
                    clearable
                    ><template slot="append">m</template></el-input
                  >
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="Z平移"
                  label-width="120px"
                  prop="transform7Dbz"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.transform7Dbz"
                    clearable
                    ><template slot="append">m</template></el-input
                  >
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item
                  label="X旋转"
                  label-width="120px"
                  prop="transform7Dbrotx"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.transform7Dbrotx"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="Y旋转"
                  label-width="120px"
                  prop="transform7Dbroty"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.transform7Dbroty"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="Z旋转"
                  label-width="120px"
                  prop="transform7Dbrotz"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.transform7Dbrotz"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item
                  label="尺度比"
                  label-width="120px"
                  prop="transform7Dbscl"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.transform7Dbscl"
                    clearable
                    ><template slot="append">ppm</template></el-input
                  >
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="七参数使用"
                  label-width="120px"
                  prop="use7"
                >
                  <el-select
                    v-model="configData.use7"
                    placeholder="请选择类型"
                    clearable
                  >
                    <el-option label="不使用" :value="0"></el-option>
                    <el-option label="使用" :value="1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="四参数使用"
                  label-width="120px"
                  prop="use4"
                >
                  <el-select
                    v-model="configData.use4"
                    placeholder="请选择类型"
                    clearable
                  >
                    <el-option label="不使用" :value="0"></el-option>
                    <el-option label="使用" :value="1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item
                  label="X平移"
                  label-width="120px"
                  prop="transform4Dbn"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.transform4Dbn"
                    clearable
                    ><template slot="append">m</template></el-input
                  >
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="Y平移"
                  label-width="120px"
                  prop="transform4Dbe"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.transform4Dbe"
                    clearable
                    ><template slot="append">m</template></el-input
                  >
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="旋转角"
                  label-width="120px"
                  prop="transform4Dbrot"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.transform4Dbrot"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item
                  label="尺度比"
                  label-width="120px"
                  prop="transform4Dbscl"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.transform4Dbscl"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="X原点"
                  label-width="120px"
                  prop="transform4Dborgn"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.transform4Dborgn"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="Y原点"
                  label-width="120px"
                  prop="transform4Dborge"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.transform4Dborge"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="A0" label-width="120px" prop="fitA0">
                  <el-input
                    autocomplete="off"
                    v-model="configData.fitA0"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="A1" label-width="120px" prop="fitA1">
                  <el-input
                    autocomplete="off"
                    v-model="configData.fitA1"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="A2" label-width="120px" prop="fitA2">
                  <el-input
                    autocomplete="off"
                    v-model="configData.fitA2"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="A3" label-width="120px" prop="fitA3">
                  <el-input
                    autocomplete="off"
                    v-model="configData.fitA3"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="A4" label-width="120px" prop="fitA4">
                  <el-input
                    autocomplete="off"
                    v-model="configData.fitA4"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="A5" label-width="120px" prop="fitA5">
                  <el-input
                    autocomplete="off"
                    v-model="configData.fitA5"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="X0" label-width="120px" prop="fitN">
                  <el-input
                    autocomplete="off"
                    v-model="configData.fitN"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Y0" label-width="120px" prop="fitE">
                  <el-input
                    autocomplete="off"
                    v-model="configData.fitE"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="拟合参数使用"
                  label-width="120px"
                  prop="useFit"
                >
                  <el-select
                    v-model="configData.useFit"
                    placeholder="请选择类型"
                    clearable
                  >
                    <el-option label="不使用" :value="0"></el-option>
                    <el-option label="使用" :value="1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item
                  label="东坐标校正"
                  label-width="120px"
                  prop="adjustDe"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.adjustDe"
                    clearable
                    ><template slot="append">m</template></el-input
                  >
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="北坐标校正"
                  label-width="120px"
                  prop="adjustDn"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.adjustDn"
                    clearable
                    ><template slot="append">m</template></el-input
                  >
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="高程校正"
                  label-width="120px"
                  prop="adjustDh"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.adjustDh"
                    clearable
                    ><template slot="append">m</template></el-input
                  >
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item
                  label="校正参数使用"
                  label-width="120px"
                  prop="useAdjust"
                >
                  <el-select
                    v-model="configData.useAdjust"
                    placeholder="请选择类型"
                    clearable
                  >
                    <el-option label="不使用" :value="0"></el-option>
                    <el-option label="使用" :value="1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="水准面模型使用"
                  label-width="120px"
                  prop="useElvCalc"
                >
                  <el-select
                    v-model="configData.useElvCalc"
                    placeholder="请选择类型"
                    clearable
                  >
                    <el-option label="不使用" :value="0"></el-option>
                    <el-option label="使用" :value="1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="转换方法"
                  label-width="120px"
                  prop="elvCalcType"
                >
                  <el-select
                    v-model="configData.elvCalcType"
                    placeholder="请选择类型"
                    clearable
                  >
                    <el-option label="双线性差值" :value="0"></el-option>
                    <el-option label="双二次差值" :value="1"></el-option>
                    <el-option label="样条差值" :value="2"></el-option>
                    <el-option label="特殊的加权差值" :value="3"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item
                  label="水准文件"
                  label-width="120px"
                  prop="elvCalcFilename"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.elvCalcFilename"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="观测值固定比率"
                  label-width="120px"
                  prop="obsFixedPercentage"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.obsFixedPercentage"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="RMS" label-width="120px" prop="rms">
                  <el-input
                    autocomplete="off"
                    v-model="configData.rms"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item
                  label="固定解比例"
                  label-width="120px"
                  prop="fixRatio"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.fixRatio"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="解算类型"
                  label-width="120px"
                  prop="gpsResolverType"
                >
                  <el-select
                    v-model="configData.gpsResolverType"
                    placeholder="请选择类型"
                    clearable
                  >
                    <el-option label="L1/B1" :value="0"></el-option>
                    <el-option label="L1+L2/B1+B2" :value="1"></el-option>
                    <el-option label="无电离层组合L5/B3" :value="2"></el-option>
                    <el-option label="L1+L2+L5/B1+B2+B3" :value="3"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8"> </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item
                  label="解算文件夹路径"
                  label-width="120px"
                  prop="gpsParsePath"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.gpsParsePath"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <!-- <div style="text-align: center;">
            <el-button @click="resetForm">重 置</el-button>
            <el-button type="primary" @click="addInfoSubmit">保存配置</el-button>
          </div> -->
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  data () {
    return {
      defaultProps: {
        children: 'children',
        label: 'showName'
      },
      configRules: {

      },
      queryParams: {
        projectCode: "",
        pageNo: 1,
        pageSize: 1000
      },
      deviceInfoList: [],
      configData: {},
      currentRowDevice: {},
    }
  },
  created () {

  },
  methods: {
    projectCodeChange (projectCode) {
      this.queryParams.projectCode = projectCode;
      this.getDeviceList();
    },
    async getDeviceList () {
      const { data: res } = await this.$http.get("device/gnss/find/page", {
        params: this.queryParams
      });
      if (res.code == 0) {
        if (res.data == null) {
          this.deviceInfoList = [];
          return;
        }
        this.deviceInfoList = res.data.list;
      } else {
        this.$message.error(res.msg);
      }
    },
    async loadDeviceConfig (data) {
      this.currentRowDevice = data;
      const { data: res } = await this.$http.get("device/gnss/cfg/find/bycode", {
        params: {
          deviceCode: data.deviceCode
        }
      });
      if (res.code == 0) {
        if (res.data != null) {
          this.configData = res.data;
        } else {
          this.configData = {};
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    resetForm () {
      this.$refs.configInfoRef.resetFields();
    },
    async addInfoSubmit () {
      this.$refs.configInfoRef.validate(async (valid) => {
        if (valid) {
          this.configData.deviceCode = this.currentRowDevice.deviceCode;
          this.configData.platformId = this.currentRowDevice.platformId;
          this.configData.name = this.currentRowDevice.name;
          const { data: res } = await this.$http.post(
            "device/gnss/cfg/save",
            this.configData
          );
          if (res.code == 0) {
            this.$message.success("配置成功");
          } else {
            this.$message.error(res.msg);
            return false;
          }
        } else {
          return false;
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>
</style>