<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>边端应用</el-breadcrumb-item>
      <el-breadcrumb-item>边端状态预警</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图 -->
    <el-card>
      <el-row :gutter="10">
        <el-col :span="3">
          <el-select
            v-model="queryParams.projectCode"
            placeholder="请选择项目"
            clearable
            @clear="getAppList"
            @change="getAppList"
          >
            <el-option
              v-for="item in projectInfoList"
              :key="item.projectCode"
              :label="item.projectName"
              :value="item.projectCode"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-button
            type="primary"
            @click="showAddAppDialog"
            v-has="'sys:front:add'"
            icon="el-icon-plus"
            >添加配置</el-button
          >
        </el-col>
      </el-row>

      <!-- 表格数据 -->
      <el-table :data="appInfoList" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column label="所属项目" prop="projectName"></el-table-column>
        <el-table-column
          label="联系人"
          prop="realName"
          width="260px"
        ></el-table-column>
        <el-table-column
          label="联系方式"
          prop="phone"
          width="260px"
        ></el-table-column>
        <el-table-column label="是否有效" width="80px">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.isAvail"
              @change="appStateChanged(scope.row)"
              :active-value="1"
              :inactive-value="0"
              v-has="'sys:front:edit'"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          label="创建时间"
          prop="updateTime"
          width="160px"
        ></el-table-column>
        <el-table-column label="操作" width="80px">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="removeById(scope.row.id)"
                v-has="'sys:front:delete'"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页组件 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>

      <!-- 添加配置 -->
      <el-dialog
        title="添加配置"
        :visible.sync="addAppDialogVisible"
        width="40%"
        @close="addDialogClosed"
        :append-to-body="true"
      >
        <el-form :model="addAppInfoForm" :rules="addRules" ref="addAppRef">
          <el-form-item label="选择项目" label-width="120px" prop="projectCode">
            <el-select
              v-model="addAppInfoForm.projectCode"
              placeholder="请选择项目"
            >
              <el-option
                v-for="item in projectInfoList"
                :key="item.projectCode"
                :label="item.projectName"
                :value="item.projectCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择联系人" label-width="120px" prop="userId">
            <el-select
              v-model="queryParams.orgId"
              placeholder="请选择组织"
              @change="changeOrgSelect"
            >
              <el-option
                v-for="item in orgInfoList"
                :key="item.id"
                :label="item.orgName"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="addAppInfoForm.userId"
              placeholder="请选择联系人"
            >
              <el-option
                v-for="item in userInfoList"
                :key="item.id"
                :label="`${item.realName}(${item.phone})`"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addAppDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addAppInfoSubmit()"
            >提 交</el-button
          >
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      addRules: {
        projectCode: [{ required: true, message: "请选择项目", trigger: "blur" }],
        userId: [{ required: true, message: "请选择联系人", trigger: "blur" }]
      },
      queryParams: {
        pageNo: 1,
        pageSize: 10,
        projectCode: "",
        orgId: null
      },
      total: 0,
      addAppDialogVisible: false,
      appInfoList: [],
      addAppInfoForm: {
        userId: null,
        projectCode: ""
      },
      projectInfoList: [],
      userInfoList: [],
      orgInfoList: []
    }
  },
  created () {
    this.getOrgList();
    this.getAllProject();
    this.getAppList();
  },
  methods: {
    async getOrgList () {
      const { data: res } = await this.$http.get("org/find/all");
      if (res.code == 0) {
        this.orgInfoList = res.data;
      }
    },
    async getAllProject () {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        this.projectInfoList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    handleSizeChange (newSize) {
      this.queryParams.pageSize = newSize;
      this.getAppList();
    },
    handleCurrentChange (newPage) {
      this.queryParams.pageNo = newPage;
      this.getAppList();
    },
    async getAppList () {
      const { data: res } = await this.$http.get("platform/find/client/alarm/config", {
        params: this.queryParams,
      });
      if (res.code == 0) {
        this.appInfoList = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.error(res.msg);
      }
    },
    async appStateChanged (appInfo) {
      const confirmResult = await this.$confirm(
        "此操作将禁止边端使用, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        this.$message.info("已取消操作");
        if (appInfo.isAvail == 0) {
          appInfo.isAvail++;
        } else {
          appInfo.isAvail--;
        }
        return false;
      }

      const { data: res } = await this.$http.get("platform//update/config", {
        params: {
          id: appInfo.id,
          status: appInfo.isAvail
        }
      });

      if (res.code == 0) {
        this.$message.success("修改成功");
      } else {
        this.$message.error(res.msg);
      }
    },
    async showAddAppDialog () {
      this.addAppDialogVisible = true;
      this.queryParams.orgId = null;
      // const { data: res } = await this.$http.get("user/find/all/mgrs");
      // if (res.code == 0) {
      //   this.userInfoList = res.data;
      // } else {
      //   this.$message.error(res.msg);
      // }
    },
    async removeById (id) {
      const confirmResult = await this.$confirm("是否确认删除该配置?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.delete("platform/remove/config/" + id);
      if (res.code == 0) {
        this.$message.success("删除成功！");
        this.getAppList();
      } else {
        return this.$message.error("删除失败！");
      }
    },
    addDialogClosed () {
      this.$refs.addAppRef.resetFields();
      this.resourceOrder = 1;
    },
    async addAppInfoSubmit () {
      this.$refs.addAppRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "platform/add/config",
            this.addAppInfoForm
          );
          if (res.code == 0) {
            this.$message.success("配置成功");
            this.getAppList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.addAppDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    async changeOrgSelect () {
      const { data: res } = await this.$http.get("user/find/user/byPage", {
        params: {
          pageNo: 1,
          pageSize: 100,
          orgId: this.queryParams.orgId,
          queryStr: ""
        },
      });
      if (res.code == 0) {
        this.userInfoList = res.data.list;
        this.addAppInfoForm.userId = null;
      } else {
        this.$message.error(res.msg);
      }
    },
  }
}
</script>

<style lang="less" scoped>
.currentNode {
  color: blue;
  font-size: 14px;
  font-weight: bold;
}

.currentNode:hover {
  cursor: pointer;
}
</style>