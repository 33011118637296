<template>
  <div>
    <el-row>
      <el-select
        v-model="queryParams.projectCode"
        placeholder="请选择项目"
        clearable
        @clear="getBarLevelList"
        @change="getBarLevelList"
      >
        <el-option
          v-for="item in projectInfoList"
          :key="item.projectCode"
          :label="item.projectName"
          :value="item.projectCode"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="searchBarCode"
        placeholder="请选择坝级"
        clearable
        @clear="getStatisData"
        @change="getStatisData"
      >
        <el-option
          v-for="item in barLevelData"
          :key="item.barCode"
          :label="item.name"
          :value="item.barCode"
        >
        </el-option>
      </el-select>
    </el-row>
    <el-row>
      <div style="height: 450px; margin-top: 30px; overflow: auto">
        <div
          id="diagramChartQS"
          class="chart"
          style="width: 99%; height: 99%"
        ></div>
      </div>
      <!-- 表格数据 -->
      <el-table :data="dataInfoList" border>
        <el-table-column type="index"></el-table-column>
        <el-table-column label="设备名称" prop="deviceName" width="180px"></el-table-column>
        <el-table-column label="年份" prop="stYear" width="120px"></el-table-column>
        <el-table-column label="最小值（米）" prop="minValue"></el-table-column>
        <el-table-column label="最大值（米）" prop="maxValue"></el-table-column>
        <el-table-column label="平均值（米）" prop="averageValue"></el-table-column>
      </el-table>
    </el-row>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  data() {
    return {
      queryParams: {
        projectCode: "",
        pageNo: 1,
        pageSize: 10,
      },
      projectInfoList: [],
      barLevelData: [],
      searchBarCode: null,
      dataInfoList: [],
    };
  },
  created() {
    this.getAllProject();
  },
  methods: {
    async getAllProject() {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        this.projectInfoList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getBarLevelList() {
      const { data: res } = await this.$http.get(
        "barlevel/find/byPage",
        {
          params: {
            pageNo: 1,
            pageSize: 100,
            projectCode: this.queryParams.projectCode
          },
        }
      );
      if (res.code == 0) {
        if (res.data == null) {
          this.barLevelData = [];
          return;
        }
        this.barLevelData = res.data.list;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getStatisData() {
      const { data: res } = await this.$http.get("statis/shenya/qushi", {
        params: {
          barCode: this.searchBarCode,
        }
      });
      if (res.code == 0 && res.data != null) {
        this.dataInfoList = res.data;
        this.drawDiagram(res.data);
      } else {
        this.dataInfoList = [];
        this.drawDiagram(null);
      }
    },
    drawDiagram(dataList) {
      // 基于准备好的dom，初始化echarts实例
      if (this.diagramChart == null) {
        this.diagramChart = this.$echarts.init(
          document.getElementById("diagramChartQS")
        );
      }

      if (dataList == null || dataList.length == 0) {
        this.diagramChart.clear();
        return;
      }

      var app = {};
      var option;

      app.config = {
        rotate: 90,
        align: "left",
        verticalAlign: "middle",
        position: "insideBottom",
        distance: 15,
      };

      var labelOption = {
        show: true,
        position: app.config.position,
        distance: app.config.distance,
        align: app.config.align,
        verticalAlign: app.config.verticalAlign,
        rotate: app.config.rotate,
        formatter: "{c}",
        fontSize: 16,
        rich: {
          name: {},
        },
      };

      var legendSet = new Set();
      var legendLabel = [];
      var xlabel = new Set();
      dataList.forEach(o => {
        legendSet.add(o.stYear);
        xlabel.add(o.deviceName);
      });

      var seriesAry = [];
      legendSet.forEach(o => {
        var ds = [];
        dataList.forEach(dt => {
          if (dt.stYear == o) {
            ds.push(dt.averageValue);
          }
        });
        legendLabel.push(''+o);
        seriesAry.push({
            name: o,
            type: "bar",
            barGap: 0,
            label: labelOption,
            emphasis: {
              focus: "series",
            },
            data: ds,
          });
      });

      option = {
        title: {
          text: "浸润线年平均值趋势分析（单位：m）",
          textStyle: {
            fontSize: 14,
          }
        },
        grid: {
          top: "90",
          bottom: "30",
          left: "60"
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          data: legendLabel,
        },
        toolbox: {
          show: true,
          feature: {
            mark: { show: true },
            saveAsImage: { show: true },
          },
        },
        xAxis: [
          {
            type: "category",
            axisTick: { show: false },
            data: Array.from(xlabel),
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: seriesAry,
      };

      // 绘制图表
      this.diagramChart.clear();
      this.diagramChart.setOption(option);
    },
  },
};
</script>