<template>
  <!-- 测站管理 -->
  <div>

    <!-- 表格数据 -->
    <el-table :data="tableInfoList" border stripe @expand-change="expandRow">
      <el-table-column type="expand">
        <template slot-scope="props">
          <!-- 后视点数据 -->
          <el-table 
            :data="props.row.houshidianInfoList" 
            border 
            stripe>
            <el-table-column label="后视点名" prop="dwName"></el-table-column>
            <el-table-column label="点位类型" prop="dwType">
              <template slot-scope="scope">
                <span v-if="scope.row.dwType == 1">监测点</span>
                <span v-else>控制点</span>
              </template>
            </el-table-column>
            <el-table-column label="东坐标Y" prop="codtY" width="120px"></el-table-column>
            <el-table-column label="北坐标X" prop="codtX"></el-table-column>
            <el-table-column label="高程H" prop="gaocheng"></el-table-column>
            <el-table-column label="棱镜高" prop="xieju"></el-table-column>
            <el-table-column label="实测东坐标" prop=""></el-table-column>
            <el-table-column label="实测北坐标" prop=""></el-table-column>
            <el-table-column label="实测高程" prop=""></el-table-column>
            <el-table-column label="实测水平角" prop=""></el-table-column>
            <el-table-column label="实测竖直角" prop=""></el-table-column>
            <el-table-column label="实测斜距" prop=""></el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column label="名称" prop="czName" width="120px"></el-table-column>
      <el-table-column label="基准点名称" prop="jzdName" width="120px"></el-table-column>
      <el-table-column label="全站仪名称" prop="qzName" width="120px"></el-table-column>
      <el-table-column label="东坐标Y" prop="codtY"></el-table-column>
      <el-table-column label="北坐标X" prop="codtX"></el-table-column>
      <el-table-column label="高程H" prop="gaocheng"></el-table-column>
      <el-table-column label="仪器高" prop="yqGao"></el-table-column>
      <el-table-column label="定向方位角" prop="fangweijiao" width="120px"></el-table-column>
      <el-table-column label="东残差" prop="dongCc"></el-table-column>
      <el-table-column label="北残差" prop="beiCc"></el-table-column>
      <el-table-column label="北残差" prop="beiCc"></el-table-column>
      <el-table-column label="高残差" prop="gaoCc"></el-table-column>
      <el-table-column label="定向方式" prop="dingxiangFs">
          <template slot-scope="scope">
            <span v-if="scope.row.dingxiangFs == 1">单点定向</span>
            <span v-else>后方交会</span>
          </template>
      </el-table-column>
      <!-- <el-table-column label="操作" width="300px" fixed="right">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            content="初始化全站仪"
            placement="top"
            :enterable="false"
          >
            <el-button
              type="danger"
              icon="el-icon-refresh-left"
              @click="initQuanzhan(scope.row.id)"
              v-has="'sys:device:qz:cz:config'"
            ></el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="选择后视点"
            placement="top"
            :enterable="false"
          >
            <el-button
              type="primary"
              icon="el-icon-finished"
              @click="selectHoushidian(scope.row, scope.row.czCode, scope.row.projectCode)"
              v-has="'sys:device:qz:cz:config'"
            ></el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="定向观测"
            placement="top"
            :enterable="false"
          >
            <el-button
              type="warning"
              icon="el-icon-position"
              @click="dxGuance(scope.row.id)"
              v-has="'sys:device:qz:cz:config'"
            ></el-button>
          </el-tooltip>


          <el-tooltip
            class="item"
            effect="dark"
            content="修改"
            placement="top"
            :enterable="false"
          >
            <el-button
              type="primary"
              icon="el-icon-edit"
              @click="showEditDialog(scope.row.id)"
              v-has="'sys:device:qz:cz:edit'"
            ></el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="删除"
            placement="top"
            :enterable="false"
          >
            <el-button
              type="danger"
              icon="el-icon-delete"
              v-has="'sys:device:qz:cz:delete'"
              @click="removeById(scope.row.id)"
            ></el-button>
          </el-tooltip>
        </template>
      </el-table-column> -->
    </el-table>

    <!-- 分页组件 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryParams.pageNo"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="queryParams.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="total"
    >
    </el-pagination>


      <!-- 添加测站 -->
      <el-dialog
        title="添加测站"
        :visible.sync="addDialogVisible"
        width="50%"
        v-if="addDialogVisible"
        @close="addDialogClosed"
      >
        <div style="height:350px; overflow: auto;">
        <el-form :model="dataFormObj" :rules="formRules" ref="addInfoRef">
          <el-form-item label="所属项目" label-width="120px" prop="projectCode">
            <el-select
              v-model="dataFormObj.projectCode"
              placeholder="请选择项目"
              clearable
              @change="changeThisProject"
            >
              <el-option
                v-for="item in projectInfoList"
                :key="item.projectCode"
                :label="item.projectName"
                :value="item.projectCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="名称" label-width="120px" prop="czName">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.czName"
              clearable
              placeholder="最多输入15个字"
            ></el-input>
          </el-form-item>
          <el-form-item label="全站仪" label-width="120px" prop="qzCode">
            <el-select v-model="dataFormObj.qzCode" placeholder="请选择控制点" clearable @change="changeQz">
              <el-option
                v-for="item in quanzhanInfoList"
                :key="item.deviceCode"
                :label="item.name"
                :value="item.deviceCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="基准点" label-width="120px" prop="jzdCode">
            <el-select v-model="dataFormObj.jzdCode" placeholder="请选择控制点" clearable @change="changeJzd">
              <el-option
                v-for="item in jizhunInfoList"
                :key="item.jzdCode"
                :label="item.jzdName"
                :value="item.jzdCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="东坐标Y" label-width="120px" prop="codtY">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.codtY"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="北坐标X" label-width="120px" prop="codtX">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.codtX"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="高程H" label-width="120px" prop="gaocheng">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.gaocheng"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="仪器高" label-width="120px" prop="yqGao">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.yqGao"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="定向方位角" label-width="120px" prop="fangweijiao">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.fangweijiao"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="定向方式" label-width="120px" prop="dingxiangFs">
            <el-select v-model="dataFormObj.dingxiangFs" placeholder="请选择定向方式" clearable>
              <el-option label="单点定向" :value="1"></el-option>
              <el-option label="后方交会" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="东残差" label-width="120px" prop="dongCc">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.dongCc"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="北残差" label-width="120px" prop="beiCc">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.beiCc"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="高残差" label-width="120px" prop="gaoCc">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.gaoCc"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="网格系数" label-width="120px" prop="wangge">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.wangge"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="备注" label-width="120px" prop="remark">
            <el-input
              type="textarea"
              v-model="dataFormObj.remark"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addInfoSubmit()">提 交</el-button>
        </span>
      </el-dialog>




      <!-- 编辑测站 -->
      <el-dialog
        title="编辑测站"
        :visible.sync="editDialogVisible"
        width="50%"
        v-if="editDialogVisible"
      >
        <div style="height:350px; overflow: auto;">
        <el-form :model="dataFormObj" :rules="formRules" ref="editInfoRef">
          <el-form-item label="所属项目" label-width="120px" prop="projectCode">
            <el-select
              v-model="dataFormObj.projectCode"
              placeholder="请选择项目"
              clearable
              @change="changeThisProject"
            >
              <el-option
                v-for="item in projectInfoList"
                :key="item.projectCode"
                :label="item.projectName"
                :value="item.projectCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="名称" label-width="120px" prop="czName">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.czName"
              clearable
              placeholder="最多输入15个字"
            ></el-input>
          </el-form-item>
          <el-form-item label="全站仪" label-width="120px" prop="qzCode">
            <el-select v-model="dataFormObj.qzCode" placeholder="请选择控制点" clearable @change="changeQz">
              <el-option
                v-for="item in quanzhanInfoList"
                :key="item.deviceCode"
                :label="item.name"
                :value="item.deviceCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="基准点" label-width="120px" prop="jzdCode">
            <el-select v-model="dataFormObj.jzdCode" placeholder="请选择控制点" clearable @change="changeJzd">
              <el-option
                v-for="item in jizhunInfoList"
                :key="item.jzdCode"
                :label="item.jzdName"
                :value="item.jzdCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="东坐标Y" label-width="120px" prop="codtY">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.codtY"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="北坐标X" label-width="120px" prop="codtX">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.codtX"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="高程H" label-width="120px" prop="gaocheng">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.gaocheng"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="仪器高" label-width="120px" prop="yqGao">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.yqGao"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="定向方位角" label-width="120px" prop="fangweijiao">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.fangweijiao"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="定向方式" label-width="120px" prop="dingxiangFs">
            <el-select v-model="dataFormObj.dingxiangFs" placeholder="请选择定向方式" clearable>
              <el-option label="单点定向" :value="1"></el-option>
              <el-option label="后方交会" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="东残差" label-width="120px" prop="dongCc">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.dongCc"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="北残差" label-width="120px" prop="beiCc">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.beiCc"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="高残差" label-width="120px" prop="gaoCc">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.gaoCc"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="网格系数" label-width="120px" prop="wangge">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.wangge"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="备注" label-width="120px" prop="remark">
            <el-input
              type="textarea"
              v-model="dataFormObj.remark"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="editInfoSubmit()">提 交</el-button>
        </span>
      </el-dialog>




      <!-- 选择后视点 -->
      <el-dialog
        title="选择后视点"
        :visible.sync="selectHoushiVisible"
        width="45%"
        v-if="selectHoushiVisible"
      >
          <el-transfer 
            v-model="dianweiRef" 
            :titles="['可选监测点', '已选监测点']"
            :props="{
              key: 'dwCode',
              label: 'dwName'
            }"
            :data="dianweiList"
            @change="dianweiChange"
          ></el-transfer>
      </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      addDialogVisible: false,
      editDialogVisible: false,
      selectHoushiVisible: false,
      tableInfoList: [],
      queryParams: {
        projectCode: "",
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      formRules: {
        projectCode: [{ required: true, message: "请选择项目", trigger: "blur" }],
        qzCode: [{ required: true, message: "请选择全站仪", trigger: "blur" }],
        jzdCode: [{ required: true, message: "请选择基准点", trigger: "blur" }],
        czName: [{ required: true, message: "请输入名字", trigger: "blur" }],
        codtY: [{ required: true, message: "请输入东坐标Y", trigger: "blur" }],
        codtX: [{ required: true, message: "请输入北坐标X", trigger: "blur" }],
        gaocheng: [{ required: true, message: "请输入高程H", trigger: "blur" }],
        yqGao: [{ required: true, message: "请输入仪器高", trigger: "blur" }],
        fangweijiao: [{ required: true, message: "请输入定向方位角", trigger: "blur" }],
        dingxiangFs: [{ required: true, message: "请输入定向方式", trigger: "blur" }],
      },
      dataFormObj: {},
      projectInfoList: [],
      quanzhanInfoList: [],
      jizhunInfoList: [],
      dianweiList: [],
      dianweiRef: [],
      currentSelectCezhan: {},
    };
  },
  created() {
    this.getAllProject();
    this.getTableDataList();
  },
  methods: {
    async getAllProject() {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        this.projectInfoList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    handleSizeChange(newSize) {
      this.queryParams.pageSize = newSize;
      this.getTableDataList();
    },
    handleCurrentChange(newPage) {
      this.queryParams.pageNo = newPage;
      this.getTableDataList();
    },
    projectCodeChange(projectCode) {
      this.queryParams.projectCode = projectCode;
      this.getTableDataList();
    },
    changeThisProject() {
      this.getQuanzhanInfoList();
      this.getJizhunList();
    },
    async getQuanzhanInfoList() {
      const { data: res } = await this.$http.get("device/quanzhan/find/page", {
        params: {
          pageNo: 1,
          pageSize: 100,
          projectCode: this.dataFormObj.projectCode,
        }
      });
      if (res.code == 0) {
        if (res.data == null) {
          this.quanzhanInfoList = [];
          return;
        }
        this.quanzhanInfoList = res.data.list;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getJizhunList() {
      const { data: res } = await this.$http.get("device/qz/jzd/find/page", {
        params: {
          pageNo: 1,
          pageSize: 100,
          projectCode: this.dataFormObj.projectCode,
        }
      });
      if (res.code == 0) {
        if (res.data == null) {
          this.jizhunInfoList = [];
          return;
        }
        this.jizhunInfoList = res.data.list;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getTableDataList() {
      const { data: res } = await this.$http.get("device/qz/cz/find/page", {
        params: this.queryParams
      });
      if (res.code == 0) {
        if (res.data == null) {
          this.tableInfoList = [];
          this.total = 0;
          return;
        }

        // 为了确保数据行展示时动态更新后视点数据
        // 事先声明 后视点 列表即可
        res.data.list.forEach(o => {
          o.houshidianInfoList = [];
        });
        this.tableInfoList = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.error(res.msg);
      }
    },
    showAddDialog() {
      this.dataFormObj = {};
      this.addDialogVisible = true;
    },
    addDialogClosed() {
      this.$refs.addInfoRef.resetFields();
    },
    async addInfoSubmit() {
      this.$refs.addInfoRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "device/qz/cz/add",
            this.dataFormObj
          );
          if (res.code == 0) {
            this.$message.success("添加成功");
            this.getTableDataList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.addDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    async showEditDialog(id) {
      this.dataFormObj = {};
      const { data: res } = await this.$http.get("device/qz/cz/find/byid/" + id);
      if (res.code == 0) {
        this.dataFormObj = res.data;
        this.changeThisProject();
        this.editDialogVisible = true;
      } else {
        this.$message.error(res.msg);
      }
    },
    async editInfoSubmit() {
      this.$refs.editInfoRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "device/qz/cz/update",
            this.dataFormObj
          );
          if (res.code == 0) {
            this.$message.success("修改成功");
            this.getTableDataList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.editDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    async removeById(id) {
      const confirmResult = await this.$confirm("是否确认删除该设备?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.delete("device/qz/cz/remove/" + id);
      if (res.code == 0) {
        this.$message.success("删除成功！");
        this.getTableDataList();
      } else {
        return this.$message.error(res.msg);
      }
    },
    changeQz() {
      this.quanzhanInfoList.forEach(o => {
        if (o.deviceCode == this.dataFormObj.qzCode) {
          this.dataFormObj.qzName = o.name;
        }
      });
    },
    changeJzd() {
      this.jizhunInfoList.forEach(o => {
        if (o.jzdCode == this.dataFormObj.jzdCode) {
          this.dataFormObj.jzdName = o.jzdName;
        }
      });
    },
    async initQuanzhan(id) {
      const confirmResult = await this.$confirm("是否确认初始化该测站全站仪?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消");
      }
      const { data: res } = await this.$http.get("device/qz/cz/init/quanzhan/" + id);
      if (res.code == 0) {
        this.$message.success("操作成功！");
      } else {
        return this.$message.error(res.msg);
      }
    },
    dxGuance(id) {

    },
    selectHoushidian(row, czCode, projectCode) {
      this.getUnbindDianweiList(projectCode, czCode);
      this.getMyBindDianweiList(czCode);
      this.selectHoushiVisible = true;
      this.currentSelectCezhan = row;
    },
    expandRow(row, ep) {
      if (ep.length > 0) {
        this.loadHoushiDian(row.czCode);
      }
    },
    async loadHoushiDian(czCode) {
        const { data: res } = await this.$http.get("device/qz/cz/find/houshi/list", {
          params: {
            czCode: czCode
          }
        });
        if (res.code == 0) {
          this.tableInfoList.forEach(o => {
            if (o.czCode == czCode) {
              o.houshidianInfoList = res.data;
            }
          });
        } else {
          return this.$message.error(res.msg);
        }
    },
    async getUnbindDianweiList(projectCode, czCode) {
      const { data: res } = await this.$http.get("device/qz/cz/find/unbind/list", {
        params: {
          projectCode: projectCode,
          czCode: czCode,
        }
      });
      if (res.code == 0) {
        if (res.data == null) {
          return;
        }
        this.dianweiList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getMyBindDianweiList(czCode) {
      const { data: res } = await this.$http.get("device/qz/cz/find/bind/list", {
        params: {
          czCode: czCode,
        }
      });
      if (res.code == 0) {
        if (res.data == null) {
          return;
        }
        this.dianweiRef = [];
        res.data.forEach(o => {
          this.dianweiRef.push(o.dwCode);
        });
      } else {
        this.$message.error(res.msg);
      }
    },
    async dianweiChange(dt) {
      let body = {
          czCode: this.currentSelectCezhan.czCode,
          dwCodes: dt,
      };
      const { data: res } = await this.$http.post("device/qz/cz/bind/dianwei", body);
      if (res.code == 0) {
        this.loadHoushiDian(this.currentSelectCezhan.czCode);
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>