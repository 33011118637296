<template>
  <div>
    <el-row :gutter="5">
      <el-col :span="4">
        <el-card>
          <div slot="header">
            <span class="span-title">设备列表</span>
          </div>
          <el-tree
            :data="deviceInfoList"
            node-key="id"
            default-expand-all
            :expand-on-click-node="false"
            :props="defaultProps"
            @node-click="loadDeviceConfig"
            class="tree-sy"
          >
            <span class="custom-tree-node" slot-scope="{ node }">
              <span>{{ node.label }}</span>
            </span>
          </el-tree>
        </el-card>
      </el-col>
      <el-col :span="20">
        <el-card>
          <div slot="header">
            <span class="span-title">设备配置</span>
            <!-- <el-button style="float: right; padding: 3px 0" type="text" @click="addInfoSubmit">保存配置</el-button> -->
          </div>
          <el-form
            :model="configData"
            :rules="configRules"
            ref="configInfoRef"
            disabled
          >
            <el-divider content-position="left">△X（mm）预警设置</el-divider>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label="蓝色"
                  label-width="80px"
                  prop="vxblueAlarmValue"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.vxblueAlarmValue"
                    clearable
                  >
                    <el-select
                      v-model="configData.vxblueComparisonOperator"
                      slot="prepend"
                      placeholder="选择"
                      style="width: 80px"
                    >
                      <el-option label="=" :value="1"></el-option>
                      <el-option label=">" :value="2"></el-option>
                      <el-option label="<" :value="3"></el-option>
                      <el-option label="≥" :value="4"></el-option>
                      <el-option label="≤" :value="5"></el-option>
                      <el-option label="<>" :value="6"></el-option>
                    </el-select>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="黄色"
                  label-width="80px"
                  prop="vxyellowAlarmValue"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.vxyellowAlarmValue"
                    clearable
                  >
                    <el-select
                      v-model="configData.vxyellowComparisonOperator"
                      slot="prepend"
                      placeholder="选择"
                      style="width: 80px"
                    >
                      <el-option label="=" :value="1"></el-option>
                      <el-option label=">" :value="2"></el-option>
                      <el-option label="<" :value="3"></el-option>
                      <el-option label="≥" :value="4"></el-option>
                      <el-option label="≤" :value="5"></el-option>
                      <el-option label="<>" :value="6"></el-option>
                    </el-select>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label="橙色"
                  label-width="80px"
                  prop="vxorangeAlarmValue"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.vxorangeAlarmValue"
                    clearable
                  >
                    <el-select
                      v-model="configData.vxorangeComparisonOperator"
                      slot="prepend"
                      placeholder="选择"
                      style="width: 80px"
                    >
                      <el-option label="=" :value="1"></el-option>
                      <el-option label=">" :value="2"></el-option>
                      <el-option label="<" :value="3"></el-option>
                      <el-option label="≥" :value="4"></el-option>
                      <el-option label="≤" :value="5"></el-option>
                      <el-option label="<>" :value="6"></el-option>
                    </el-select>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="红色"
                  label-width="80px"
                  prop="vxredAlarmValue"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.vxredAlarmValue"
                    clearable
                  >
                    <el-select
                      v-model="configData.vxredComparisonOperator"
                      slot="prepend"
                      placeholder="选择"
                      style="width: 80px"
                    >
                      <el-option label="=" :value="1"></el-option>
                      <el-option label=">" :value="2"></el-option>
                      <el-option label="<" :value="3"></el-option>
                      <el-option label="≥" :value="4"></el-option>
                      <el-option label="≤" :value="5"></el-option>
                      <el-option label="<>" :value="6"></el-option>
                    </el-select>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-divider content-position="left">△Y（mm）预警设置</el-divider>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label="蓝色"
                  label-width="80px"
                  prop="vyblueAlarmValue"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.vyblueAlarmValue"
                    clearable
                  >
                    <el-select
                      v-model="configData.vyblueComparisonOperator"
                      slot="prepend"
                      placeholder="选择"
                      style="width: 80px"
                    >
                      <el-option label="=" :value="1"></el-option>
                      <el-option label=">" :value="2"></el-option>
                      <el-option label="<" :value="3"></el-option>
                      <el-option label="≥" :value="4"></el-option>
                      <el-option label="≤" :value="5"></el-option>
                      <el-option label="<>" :value="6"></el-option>
                    </el-select>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="黄色"
                  label-width="80px"
                  prop="vyyellowAlarmValue"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.vyyellowAlarmValue"
                    clearable
                  >
                    <el-select
                      v-model="configData.vyyellowComparisonOperator"
                      slot="prepend"
                      placeholder="选择"
                      style="width: 80px"
                    >
                      <el-option label="=" :value="1"></el-option>
                      <el-option label=">" :value="2"></el-option>
                      <el-option label="<" :value="3"></el-option>
                      <el-option label="≥" :value="4"></el-option>
                      <el-option label="≤" :value="5"></el-option>
                      <el-option label="<>" :value="6"></el-option>
                    </el-select>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label="橙色"
                  label-width="80px"
                  prop="vyorangeAlarmValue"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.vyorangeAlarmValue"
                    clearable
                  >
                    <el-select
                      v-model="configData.vyorangeComparisonOperator"
                      slot="prepend"
                      placeholder="选择"
                      style="width: 80px"
                    >
                      <el-option label="=" :value="1"></el-option>
                      <el-option label=">" :value="2"></el-option>
                      <el-option label="<" :value="3"></el-option>
                      <el-option label="≥" :value="4"></el-option>
                      <el-option label="≤" :value="5"></el-option>
                      <el-option label="<>" :value="6"></el-option>
                    </el-select>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="红色"
                  label-width="80px"
                  prop="vyredAlarmValue"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.vyredAlarmValue"
                    clearable
                  >
                    <el-select
                      v-model="configData.vyredComparisonOperator"
                      slot="prepend"
                      placeholder="选择"
                      style="width: 80px"
                    >
                      <el-option label="=" :value="1"></el-option>
                      <el-option label=">" :value="2"></el-option>
                      <el-option label="<" :value="3"></el-option>
                      <el-option label="≥" :value="4"></el-option>
                      <el-option label="≤" :value="5"></el-option>
                      <el-option label="<>" :value="6"></el-option>
                    </el-select>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-divider content-position="left">△Z（mm）预警设置</el-divider>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label="蓝色"
                  label-width="80px"
                  prop="vzblueAlarmValue"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.vzblueAlarmValue"
                    clearable
                  >
                    <el-select
                      v-model="configData.vzblueComparisonOperator"
                      slot="prepend"
                      placeholder="选择"
                      style="width: 80px"
                    >
                      <el-option label="=" :value="1"></el-option>
                      <el-option label=">" :value="2"></el-option>
                      <el-option label="<" :value="3"></el-option>
                      <el-option label="≥" :value="4"></el-option>
                      <el-option label="≤" :value="5"></el-option>
                      <el-option label="<>" :value="6"></el-option>
                    </el-select>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="黄色"
                  label-width="80px"
                  prop="vzyellowAlarmValue"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.vzyellowAlarmValue"
                    clearable
                  >
                    <el-select
                      v-model="configData.vzyellowComparisonOperator"
                      slot="prepend"
                      placeholder="选择"
                      style="width: 80px"
                    >
                      <el-option label="=" :value="1"></el-option>
                      <el-option label=">" :value="2"></el-option>
                      <el-option label="<" :value="3"></el-option>
                      <el-option label="≥" :value="4"></el-option>
                      <el-option label="≤" :value="5"></el-option>
                      <el-option label="<>" :value="6"></el-option>
                    </el-select>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label="橙色"
                  label-width="80px"
                  prop="vzorangeAlarmValue"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.vzorangeAlarmValue"
                    clearable
                  >
                    <el-select
                      v-model="configData.vzorangeComparisonOperator"
                      slot="prepend"
                      placeholder="选择"
                      style="width: 80px"
                    >
                      <el-option label="=" :value="1"></el-option>
                      <el-option label=">" :value="2"></el-option>
                      <el-option label="<" :value="3"></el-option>
                      <el-option label="≥" :value="4"></el-option>
                      <el-option label="≤" :value="5"></el-option>
                      <el-option label="<>" :value="6"></el-option>
                    </el-select>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="红色"
                  label-width="80px"
                  prop="vzredAlarmValue"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.vzredAlarmValue"
                    clearable
                  >
                    <el-select
                      v-model="configData.vzredComparisonOperator"
                      slot="prepend"
                      placeholder="选择"
                      style="width: 80px"
                    >
                      <el-option label="=" :value="1"></el-option>
                      <el-option label=">" :value="2"></el-option>
                      <el-option label="<" :value="3"></el-option>
                      <el-option label="≥" :value="4"></el-option>
                      <el-option label="≤" :value="5"></el-option>
                      <el-option label="<>" :value="6"></el-option>
                    </el-select>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-divider content-position="left">∑X（mm）预警设置</el-divider>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label="蓝色"
                  label-width="80px"
                  prop="sxblueAlarmValue"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.sxblueAlarmValue"
                    clearable
                  >
                    <el-select
                      v-model="configData.sxblueComparisonOperator"
                      slot="prepend"
                      placeholder="选择"
                      style="width: 80px"
                    >
                      <el-option label="=" :value="1"></el-option>
                      <el-option label=">" :value="2"></el-option>
                      <el-option label="<" :value="3"></el-option>
                      <el-option label="≥" :value="4"></el-option>
                      <el-option label="≤" :value="5"></el-option>
                      <el-option label="<>" :value="6"></el-option>
                    </el-select>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="黄色"
                  label-width="80px"
                  prop="sxyellowAlarmValue"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.sxyellowAlarmValue"
                    clearable
                  >
                    <el-select
                      v-model="configData.sxyellowComparisonOperator"
                      slot="prepend"
                      placeholder="选择"
                      style="width: 80px"
                    >
                      <el-option label="=" :value="1"></el-option>
                      <el-option label=">" :value="2"></el-option>
                      <el-option label="<" :value="3"></el-option>
                      <el-option label="≥" :value="4"></el-option>
                      <el-option label="≤" :value="5"></el-option>
                      <el-option label="<>" :value="6"></el-option>
                    </el-select>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label="橙色"
                  label-width="80px"
                  prop="sxorangeAlarmValue"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.sxorangeAlarmValue"
                    clearable
                  >
                    <el-select
                      v-model="configData.sxorangeComparisonOperator"
                      slot="prepend"
                      placeholder="选择"
                      style="width: 80px"
                    >
                      <el-option label="=" :value="1"></el-option>
                      <el-option label=">" :value="2"></el-option>
                      <el-option label="<" :value="3"></el-option>
                      <el-option label="≥" :value="4"></el-option>
                      <el-option label="≤" :value="5"></el-option>
                      <el-option label="<>" :value="6"></el-option>
                    </el-select>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="红色"
                  label-width="80px"
                  prop="sxredAlarmValue"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.sxredAlarmValue"
                    clearable
                  >
                    <el-select
                      v-model="configData.sxredComparisonOperator"
                      slot="prepend"
                      placeholder="选择"
                      style="width: 80px"
                    >
                      <el-option label="=" :value="1"></el-option>
                      <el-option label=">" :value="2"></el-option>
                      <el-option label="<" :value="3"></el-option>
                      <el-option label="≥" :value="4"></el-option>
                      <el-option label="≤" :value="5"></el-option>
                      <el-option label="<>" :value="6"></el-option>
                    </el-select>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-divider content-position="left">∑Y（mm）预警设置</el-divider>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label="蓝色"
                  label-width="80px"
                  prop="syblueAlarmValue"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.syblueAlarmValue"
                    clearable
                  >
                    <el-select
                      v-model="configData.syblueComparisonOperator"
                      slot="prepend"
                      placeholder="选择"
                      style="width: 80px"
                    >
                      <el-option label="=" :value="1"></el-option>
                      <el-option label=">" :value="2"></el-option>
                      <el-option label="<" :value="3"></el-option>
                      <el-option label="≥" :value="4"></el-option>
                      <el-option label="≤" :value="5"></el-option>
                      <el-option label="<>" :value="6"></el-option>
                    </el-select>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="黄色"
                  label-width="80px"
                  prop="syyellowAlarmValue"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.syyellowAlarmValue"
                    clearable
                  >
                    <el-select
                      v-model="configData.syyellowComparisonOperator"
                      slot="prepend"
                      placeholder="选择"
                      style="width: 80px"
                    >
                      <el-option label="=" :value="1"></el-option>
                      <el-option label=">" :value="2"></el-option>
                      <el-option label="<" :value="3"></el-option>
                      <el-option label="≥" :value="4"></el-option>
                      <el-option label="≤" :value="5"></el-option>
                      <el-option label="<>" :value="6"></el-option>
                    </el-select>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label="橙色"
                  label-width="80px"
                  prop="syorangeAlarmValue"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.syorangeAlarmValue"
                    clearable
                  >
                    <el-select
                      v-model="configData.syorangeComparisonOperator"
                      slot="prepend"
                      placeholder="选择"
                      style="width: 80px"
                    >
                      <el-option label="=" :value="1"></el-option>
                      <el-option label=">" :value="2"></el-option>
                      <el-option label="<" :value="3"></el-option>
                      <el-option label="≥" :value="4"></el-option>
                      <el-option label="≤" :value="5"></el-option>
                      <el-option label="<>" :value="6"></el-option>
                    </el-select>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="红色"
                  label-width="80px"
                  prop="syredAlarmValue"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.syredAlarmValue"
                    clearable
                  >
                    <el-select
                      v-model="configData.syredComparisonOperator"
                      slot="prepend"
                      placeholder="选择"
                      style="width: 80px"
                    >
                      <el-option label="=" :value="1"></el-option>
                      <el-option label=">" :value="2"></el-option>
                      <el-option label="<" :value="3"></el-option>
                      <el-option label="≥" :value="4"></el-option>
                      <el-option label="≤" :value="5"></el-option>
                      <el-option label="<>" :value="6"></el-option>
                    </el-select>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-divider content-position="left">∑Z（mm）预警设置</el-divider>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label="蓝色"
                  label-width="80px"
                  prop="szblueAlarmValue"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.szblueAlarmValue"
                    clearable
                  >
                    <el-select
                      v-model="configData.szblueComparisonOperator"
                      slot="prepend"
                      placeholder="选择"
                      style="width: 80px"
                    >
                      <el-option label="=" :value="1"></el-option>
                      <el-option label=">" :value="2"></el-option>
                      <el-option label="<" :value="3"></el-option>
                      <el-option label="≥" :value="4"></el-option>
                      <el-option label="≤" :value="5"></el-option>
                      <el-option label="<>" :value="6"></el-option>
                    </el-select>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="黄色"
                  label-width="80px"
                  prop="szyellowAlarmValue"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.szyellowAlarmValue"
                    clearable
                  >
                    <el-select
                      v-model="configData.szyellowComparisonOperator"
                      slot="prepend"
                      placeholder="选择"
                      style="width: 80px"
                    >
                      <el-option label="=" :value="1"></el-option>
                      <el-option label=">" :value="2"></el-option>
                      <el-option label="<" :value="3"></el-option>
                      <el-option label="≥" :value="4"></el-option>
                      <el-option label="≤" :value="5"></el-option>
                      <el-option label="<>" :value="6"></el-option>
                    </el-select>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label="橙色"
                  label-width="80px"
                  prop="szorangeAlarmValue"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.szorangeAlarmValue"
                    clearable
                  >
                    <el-select
                      v-model="configData.szorangeComparisonOperator"
                      slot="prepend"
                      placeholder="选择"
                      style="width: 80px"
                    >
                      <el-option label="=" :value="1"></el-option>
                      <el-option label=">" :value="2"></el-option>
                      <el-option label="<" :value="3"></el-option>
                      <el-option label="≥" :value="4"></el-option>
                      <el-option label="≤" :value="5"></el-option>
                      <el-option label="<>" :value="6"></el-option>
                    </el-select>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="红色"
                  label-width="80px"
                  prop="szredAlarmValue"
                >
                  <el-input
                    autocomplete="off"
                    v-model="configData.szredAlarmValue"
                    clearable
                  >
                    <el-select
                      v-model="configData.szredComparisonOperator"
                      slot="prepend"
                      placeholder="选择"
                      style="width: 80px"
                    >
                      <el-option label="=" :value="1"></el-option>
                      <el-option label=">" :value="2"></el-option>
                      <el-option label="<" :value="3"></el-option>
                      <el-option label="≥" :value="4"></el-option>
                      <el-option label="≤" :value="5"></el-option>
                      <el-option label="<>" :value="6"></el-option>
                    </el-select>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-divider content-position="left">是否屏蔽</el-divider>
                <el-form-item label-width="80px" label="" prop="isShield">
                  <el-radio-group v-model="configData.isShield">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-divider content-position="left"
                  >累计触发<el-tooltip
                    class="item"
                    effect="dark"
                    content="监测值连续异常次数超过‘累计触发’才会触发预警."
                    placement="top"
                  >
                    <i
                      class="el-icon-warning"
                      style="float: right; padding: 3px 0"
                    ></i> </el-tooltip
                ></el-divider>
                <el-form-item prop="accumulated">
                  <el-select
                    v-model="configData.accumulated"
                    placeholder="请选择"
                  >
                    <el-option label="1" :value="1"></el-option>
                    <el-option label="2" :value="2"></el-option>
                    <el-option label="3" :value="3"></el-option>
                    <el-option label="4" :value="4"></el-option>
                    <el-option label="5" :value="5"></el-option>
                    <el-option label="6" :value="6"></el-option>
                    <el-option label="7" :value="7"></el-option>
                    <el-option label="8" :value="8"></el-option>
                    <el-option label="9" :value="9"></el-option>
                    <el-option label="10" :value="10"></el-option>
                    <el-option label="11" :value="11"></el-option>
                    <el-option label="12" :value="12"></el-option>
                    <el-option label="13" :value="13"></el-option>
                    <el-option label="14" :value="14"></el-option>
                    <el-option label="15" :value="15"></el-option>
                    <el-option label="16" :value="16"></el-option>
                    <el-option label="17" :value="17"></el-option>
                    <el-option label="18" :value="18"></el-option>
                    <el-option label="19" :value="19"></el-option>
                    <el-option label="20" :value="20"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <!-- <div style="text-align: center;">
            <el-button @click="resetForm">重 置</el-button>
            <el-button type="primary" @click="addInfoSubmit">保存配置</el-button>
          </div> -->
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  data () {
    return {
      defaultProps: {
        children: 'children',
        label: 'showName'
      },
      configRules: {

      },
      queryParams: {
        projectCode: "",
        pageNo: 1,
        pageSize: 1000
      },
      deviceInfoList: [],
      configData: {},
      currentRowDevice: {},
    }
  },
  created () {

  },
  methods: {
    projectCodeChange (projectCode) {
      this.queryParams.projectCode = projectCode;
      this.getDeviceList();
    },
    async getDeviceList () {
      const { data: res } = await this.$http.get("device/gnss/find/page", {
        params: this.queryParams
      });
      if (res.code == 0) {
        if (res.data == null) {
          this.deviceInfoList = [];
          return;
        }
        this.deviceInfoList = res.data.list;
      } else {
        this.$message.error(res.msg);
      }
    },
    async loadDeviceConfig (data) {
      this.currentRowDevice = data;
      const { data: res } = await this.$http.get("alarm/find/gnss/config", {
        params: {
          deviceCode: data.deviceCode
        }
      });
      if (res.code == 0) {
        if (res.data != null) {
          this.configData = res.data;
        } else {
          this.configData = {};
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    resetForm () {
      this.$refs.configInfoRef.resetFields();
    },
    async addInfoSubmit () {
      this.configData.deviceCode = this.currentRowDevice.deviceCode;
      const { data: res } = await this.$http.post(
        "alarm/gnss/config",
        this.configData
      );
      if (res.code == 0) {
        this.$message.success("设置成功");
      } else {
        this.$message.error(res.msg);
        return false;
      }
    }
  }
}
</script>

<style lang="less" scoped>
</style>