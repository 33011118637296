<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb v-if="hiddenPath === false" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>数据分析</el-breadcrumb-item>
      <el-breadcrumb-item>浸润线分析</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-tabs
        type="border-card"
        v-model="tabActiveName"
        @tab-click="handleClick"
      >
        <el-tab-pane label="趋势分析" name="tab1">
          <v-shenya-qushi ref="tab1ref"></v-shenya-qushi>
        </el-tab-pane>
        <el-tab-pane label="透视分析" name="tab2">
          <v-shenya-toushi ref="tab2ref"></v-shenya-toushi>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import vShenyaQushi from "./ShenyaQushi.vue";
import vShenyaToushi from "./ShenyaToushi.vue";
export default {
  components: {
    vShenyaQushi,
    vShenyaToushi
  },
  props: {
    hiddenPath: Boolean
  },
  data() {
    return {
      tabActiveName: "tab1",
    };
  },
  created() {},
  methods: {
    handleClick(tab, event) {
      this.tabActiveName = tab.name;
    },
  },
};
</script>

<style lang="less" scoped>
</style>