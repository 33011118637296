<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>数据分析</el-breadcrumb-item>
      <el-breadcrumb-item>表面位移分析</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row>
        <el-select
          v-model="queryParams.projectCode"
          placeholder="请选择项目"
          clearable
          @clear="getBarLevelList"
          @change="getBarLevelList"
        >
          <el-option
            v-for="item in projectInfoList"
            :key="item.projectCode"
            :label="item.projectName"
            :value="item.projectCode"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="searchBarCode"
          placeholder="请选择坝级"
          clearable
          @clear="getStatisData"
          @change="getStatisData"
        >
          <el-option
            v-for="item in barLevelData"
            :key="item.barCode"
            :label="item.name"
            :value="item.barCode"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="statisYear"
          placeholder="请选择年份"
          @change="getStatisData"
        >
          <el-option label="2019" :value="2019"></el-option>
          <el-option label="2020" :value="2020"></el-option>
          <el-option label="2021" :value="2021"></el-option>
          <el-option label="2022" :value="2022"></el-option>
          <el-option label="2023" :value="2023"></el-option>
          <el-option label="2024" :value="2024"></el-option>
          <el-option label="2025" :value="2025"></el-option>
          <el-option label="2026" :value="2026"></el-option>
          <el-option label="2027" :value="2027"></el-option>
          <el-option label="2028" :value="2028"></el-option>
          <el-option label="2029" :value="2029"></el-option>
          <el-option label="2030" :value="2030"></el-option>
        </el-select>
        <el-select
          v-model="statisMonth"
          placeholder="请选择月份"
          @change="getStatisData"
          @clear="getStatisData"
          clearable
        >
          <el-option label="1" :value="1"></el-option>
          <el-option label="2" :value="2"></el-option>
          <el-option label="3" :value="3"></el-option>
          <el-option label="4" :value="4"></el-option>
          <el-option label="5" :value="5"></el-option>
          <el-option label="6" :value="6"></el-option>
          <el-option label="7" :value="7"></el-option>
          <el-option label="8" :value="8"></el-option>
          <el-option label="9" :value="9"></el-option>
          <el-option label="10" :value="10"></el-option>
          <el-option label="11" :value="11"></el-option>
          <el-option label="12" :value="12"></el-option>
        </el-select>
      </el-row>
      <el-row>
        <div style="height: 510px; margin-top: 30px; overflow: auto">
          <div
            id="diagramChartTS"
            class="chart"
            style="width: 99%; height: 99%"
          ></div>
        </div>
      </el-row>
    </el-card>
  </div>
</template>

<script>
var icnow = new Date();
export default {
  data () {
    return {
      queryParams: {
        projectCode: "",
        deviceCode: "",
        pageNo: 1,
        pageSize: 10,
      },
      projectInfoList: [],
      deviceInfoList: [],
      dataInfoList: [],
      statisYear: icnow.getFullYear(),
      statisMonth: icnow.getMonth() + 1,
      barLevelData: [],
      searchBarCode: null,
    }
  },
  created () {
    this.getAllProject();
  },
  methods: {
    async getAllProject () {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        this.projectInfoList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getBarLevelList () {
      const { data: res } = await this.$http.get(
        "barlevel/find/byPage",
        {
          params: {
            pageNo: 1,
            pageSize: 100,
            projectCode: this.queryParams.projectCode
          },
        }
      );
      if (res.code == 0) {
        if (res.data == null) {
          this.barLevelData = [];
          return;
        }
        this.barLevelData = res.data.list;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getStatisData () {
      const { data: res } = await this.$http.get("statis/weiyi/find/data", {
        params: {
          barCode: this.searchBarCode,
          year: this.statisYear,
          month: this.statisMonth,
        }
      });
      if (res.code == 0 && res.data != null) {
        this.dataInfoList = res.data;
        console.log(res.data);
        this.drawDiagram(res.data);
      } else {
        this.dataInfoList = [];
        this.drawDiagram(null);
      }
    },
    drawDiagram (dataList) {
      // 基于准备好的dom，初始化echarts实例
      if (this.diagramChart == null) {
        this.diagramChart = this.$echarts.init(
          document.getElementById("diagramChartTS")
        );
      }

      if (dataList == null || dataList.length == 0) {
        this.diagramChart.clear();
        return;
      }

      let vxData = [];
      let vyData = [];
      let vzData = [];
      let sxData = [];
      let syData = [];
      let szData = [];
      let xLineData = [];
      for (let i = 0; i < dataList.length; i++) {
        vxData.push(dataList[i].vx);
        vyData.push(dataList[i].vy);
        vzData.push(dataList[i].vz);
        sxData.push(dataList[i].sx);
        syData.push(dataList[i].sy);
        szData.push(dataList[i].sz);

        xLineData.push(dataList[i].dotName);
      }

      // Y轴类型
      let yAxisType = 'value';
      let option = {
        title: {
          text: "表面位移统计（单位：mm）",
          textStyle: {
            fontSize: 14,
          },
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["X变化量", "Y变化量", "Z变化量", "X累计量", "Y累计量", "Z累计量"],
        },
        grid: {
          top: "90",
          bottom: "30",
          left: "100"
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: xLineData,
        },
        yAxis: {
          type: yAxisType,
          // boundaryGap: [0, '100%'],
          axisLabel: {
            formatter: '{value} mm'
          },
        },
        // dataZoom: [{
        //     type: 'inside',
        //     start: 0,
        //     end: 20
        // }, {
        //     start: 0,
        //     end: 20
        // }],
        series: [
          {
            name: "X变化量",
            type: "line",
            data: vxData
          },
          {
            name: "Y变化量",
            type: "line",
            data: vyData
          },
          {
            name: "Z变化量",
            type: "line",
            data: vzData
          },
          {
            name: "X累计量",
            type: "line",
            data: sxData
          },
          {
            name: "Y累计量",
            type: "line",
            data: syData
          },
          {
            name: "Z累计量",
            type: "line",
            data: szData
          },
        ],
      };

      // 绘制图表
      this.diagramChart.clear();
      this.diagramChart.setOption(option);
    }
  }
}
</script>