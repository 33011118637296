<template>
  <!-- GNSS管理 -->
  <div>
    <el-row>
      <el-col :span="8">
        <el-button
          @click="exportDevices"
          type="success"
          icon="el-icon-download"
          v-has="'sys:device:gnss:export'"
          >导出</el-button
        >
      </el-col>
    </el-row>

    <!-- 表格数据 -->
    <el-table :data="deviceInfoList" border stripe>
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-form label-position="left" inline>
            <el-row>
              <el-col :span="4">
                <el-form-item label="基站类型：">
                  <span v-if="props.row.type == 0">基准站</span>
                  <span v-else>移动站</span>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="关联基站：">
                  <span>{{ props.row.relatingBaseName }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="远程连接模式：">
                  <span v-if="props.row.communicateType == 0">串口</span>
                  <span v-if="props.row.communicateType == 1">客户端</span>
                  <span v-else>服务端</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="4">
                <el-form-item label="IP地址：">
                  <span>{{ props.row.ipAddr }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="端口：">
                  <span>{{ props.row.tcpPort }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="串口号：">
                  <span>{{ props.row.serlPort }}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="4">
                <el-form-item label="波特率：">
                  <span>{{ props.row.baud }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="主机机号：">
                  <span>{{ props.row.hostNumber }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="滤波阈值：">
                  <span>{{ props.row.filterThreshold }}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="4">
                <el-form-item label="用户名：">
                  <span>{{ props.row.username }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="密码：">
                  <span>{{ props.row.password }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="S10DZ解算间隔：">
                  <span>{{ props.row.calInterval }}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="4">
                <el-form-item label="初始化次数：">
                  <span>{{ props.row.initTimes }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="挂载点：">
                  <span>{{ props.row.mountPoint }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="振幅阈值：">
                  <span>{{ props.row.amplitudeThreshold }}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="4">
                <el-form-item label="经度：">
                  <span>{{ props.row.lon }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="纬度：">
                  <span>{{ props.row.lat }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="高程：">
                  <span>{{ props.row.alt }}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="4">
                <el-form-item label="源椭球空间直角坐标x初始值：">
                  <span>{{ props.row.initSX }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="源椭球空间直角坐标y初始值：">
                  <span>{{ props.row.initSY }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="源椭球空间直角坐标z初始值：">
                  <span>{{ props.row.initSZ }}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="4">
                <el-form-item label="目标椭球地方平面坐标x初始值：">
                  <span>{{ props.row.initDX }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="目标椭球地方平面坐标y初始值：">
                  <span>{{ props.row.initDY }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="目标椭球地方平面坐标h初始值：">
                  <span>{{ props.row.initDH }}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="4">
                <el-form-item label="源椭球空间直角坐标动态x初始值：">
                  <span>{{ props.row.initSXDyn }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="源椭球空间直角坐标动态y初始值：">
                  <span>{{ props.row.initSYDyn }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="源椭球空间直角坐标动态z初始值：">
                  <span>{{ props.row.initSZDyn }}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="4">
                <el-form-item label="示例图x坐标：">
                  <span>{{ props.row.xPixel }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="示例图y坐标：">
                  <span>{{ props.row.yPixel }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="4"> </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="备注：">
                  <span>{{ props.row.remark }}</span>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column label="图片">
        <template slot-scope="scope">
          <el-popover placement="right" width="300" trigger="hover">
            <img
              :src="COMMON_CONFIG.LOAD_DEVICE_IMG_URL + scope.row.deviceImg"
              height="300"
              width="300"
            />
            <img
              slot="reference"
              :src="COMMON_CONFIG.LOAD_DEVICE_IMG_URL + scope.row.deviceImg"
              height="50"
              width="50"
            />
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
        label="名称"
        prop="showName"
        width="180px"
      ></el-table-column>
      <el-table-column label="站点类型" prop="type">
        <template slot-scope="scope">
          <span v-if="scope.row.type == 0">基准站</span>
          <span v-else>移动站</span>
        </template>
      </el-table-column>
      <el-table-column label="平台编号" prop="platformId"></el-table-column>
      <el-table-column
        label="关联基站"
        prop="relatingBaseName"
      ></el-table-column>
      <el-table-column label="远程连接" prop="communicateType">
        <template slot-scope="scope">
          <span v-if="scope.row.communicateType == 2">服务器</span>
          <span v-else-if="scope.row.communicateType == 1">客户端</span>
          <span v-else>串口</span>
        </template>
      </el-table-column>
      <el-table-column label="IP地址" prop="ipAddr"></el-table-column>
      <el-table-column label="端口" prop="tcpPort"></el-table-column>
      <el-table-column label="串口号" prop="serlPort"></el-table-column>
      <el-table-column label="波特率" prop="baud"></el-table-column>
      <el-table-column
        label="S10DZ解算间隔"
        prop="calInterval"
        width="120px"
      ></el-table-column>
      <el-table-column label="主机机号" prop="hostNumber"></el-table-column>
      <el-table-column label="关联串口" prop="comCode" width="90px">
        <template slot-scope="scope">
          <el-tag
            v-if="scope.row.comCode === null || scope.row.comCode === ''"
            type="info"
            >未关联</el-tag
          >
          <el-popover
            v-else
            placement="top-start"
            width="200"
            trigger="hover"
            @show="showComInfo(scope.row.comCode)"
            :content="comDeviceInfo"
          >
            <el-tag slot="reference" type="success">已关联</el-tag>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="是否有效" width="80px">
        <template slot-scope="scope">
          <el-switch
            disabled
            v-model="scope.row.availableBl"
            @change="deviceStateChanged(scope.row)"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column
        label="最新采集时间"
        prop="collectTime"
        width="150px"
      ></el-table-column>
      <el-table-column label="创建日期" prop="createTime" width="100px">
        <template slot-scope="scope">
          {{
            common.dateFormat(
              "YYYY-MM-dd",
              new Date(Date.parse(scope.row.createTime))
            )
          }}
        </template>
      </el-table-column>
      <!-- <el-table-column label="操作" width="180px" fixed="right">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="关联串口"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="success"
                icon="el-icon-connection"
                @click="comSettingDialog(scope.row.deviceCode)"
                v-has="'sys:device:gnss:refcom'"
              ></el-button>
            </el-tooltip>

            <el-tooltip
              class="item"
              effect="dark"
              content="修改"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                @click="showEditDialog(scope.row.deviceCode)"
                v-has="'sys:device:gnss:edit'"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                v-has="'sys:device:gnss:delete'"
                @click="removeByDeviceCode(scope.row.deviceCode)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column> -->
    </el-table>

    <!-- 分页组件 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryParams.pageNo"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="queryParams.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="total"
    >
    </el-pagination>

    <!-- 添加GNSS -->
    <el-dialog
      title="添加GNSS"
      :visible.sync="addDialogVisible"
      width="60%"
      v-if="addDialogVisible"
      @close="addDialogClosed"
    >
      <div style="height: 400px; overflow: auto">
        <el-form :model="deviceInfoForm" :rules="devRules" ref="addInfoRef">
          <el-form-item label="设备图片" label-width="120px"> </el-form-item>
          <el-row>
            <el-col :span="11">
              <el-form-item
                label="所属项目"
                label-width="120px"
                prop="projectCode"
              >
                <el-select
                  @change="getJizhanList(deviceInfoForm.projectCode)"
                  v-model="deviceInfoForm.projectCode"
                  placeholder="请选择项目"
                  clearable
                >
                  <el-option
                    v-for="item in projectInfoList"
                    :key="item.projectCode"
                    :label="item.projectName"
                    :value="item.projectCode"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="13">
              <el-form-item
                label="所属平台编号"
                label-width="120px"
                prop="platformId"
              >
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.platformId"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item
                label="设备型号"
                label-width="120px"
                prop="deviceModel"
              >
                <el-select
                  v-model="deviceInfoForm.deviceModel"
                  placeholder="请选择型号"
                  clearable
                >
                  <el-option
                    v-for="item in deviceModelList"
                    :key="item.modelCode"
                    :label="item.modelName"
                    :value="item.modelCode"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="13">
              <el-form-item label="设备名称" label-width="120px" prop="name">
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.name"
                  clearable
                  placeholder="最多输入25个字"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item
            label="设备行政编号"
            label-width="120px"
            prop="deviceGovCode"
          >
            <el-input
              autocomplete="off"
              v-model="deviceInfoForm.deviceGovCode"
              clearable
              placeholder="格式：AABBCCDDDDEEFF，用于上报应急管理系统"
            ></el-input
            ><br />
            <span style="color: rgb(175, 175, 175)">
              省[AA]市[BB]县/区[CC]尾矿库[DDDD]监测设备类型[EE]设备位号[FF]
            </span>
          </el-form-item>
          <el-form-item label="所属坝级" label-width="120px" prop="barCode">
            <el-select
              v-model="deviceInfoForm.barCode"
              placeholder="请选择坝级"
              clearable
            >
              <el-option
                v-for="item in barLevelData"
                :key="item.barCode"
                :label="item.name"
                :value="item.barCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-row>
            <el-col :span="11">
              <el-form-item label="站点类型" label-width="120px" prop="type">
                <el-select
                  v-model="deviceInfoForm.type"
                  placeholder="请选择站点类型"
                  clearable
                >
                  <el-option label="基准站" :value="0"></el-option>
                  <el-option label="移动站" :value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="13">
              <el-form-item
                label="关联基站"
                label-width="120px"
                prop="relatingBase"
              >
                <el-select
                  @change="changeJizhan"
                  v-model="deviceInfoForm.relatingBase"
                  placeholder="请选择基站"
                  clearable
                >
                  <el-option
                    v-for="item in jizhanList"
                    :key="item.deviceCode"
                    :label="item.name"
                    :value="item.deviceCode"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item label="波特率" label-width="120px" prop="baud">
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.baud"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="13">
              <el-form-item
                label="远程连接模式"
                label-width="120px"
                prop="communicateType"
              >
                <el-select
                  v-model="deviceInfoForm.communicateType"
                  placeholder="请选择远程连接模式"
                  clearable
                >
                  <el-option label="服务器" :value="2"></el-option>
                  <el-option label="客户端" :value="1"></el-option>
                  <el-option label="串口" :value="0"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item label="IP地址" label-width="120px" prop="ipAddr">
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.ipAddr"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="13">
              <el-form-item label="串口号" label-width="120px" prop="serlPort">
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.serlPort"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item label="端口" label-width="120px" prop="tcpPort">
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.tcpPort"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="13">
              <el-form-item
                label="主机机号"
                label-width="120px"
                prop="hostNumber"
              >
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.hostNumber"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item
                label="S10DZ解算间隔"
                label-width="120px"
                prop="calInterval"
              >
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.calInterval"
                  clearable
                >
                  <template slot="append">秒</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="13">
              <el-form-item
                label="滤波阈值"
                label-width="120px"
                prop="filterThreshold"
              >
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.filterThreshold"
                  clearable
                  ><template slot="append">%</template></el-input
                >
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item label="用户名" label-width="120px" prop="username">
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.username"
                  clearable
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="13">
              <el-form-item label="密码" label-width="120px" prop="password">
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.password"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item
                label="初始化次数"
                label-width="120px"
                prop="initTimes"
              >
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.initTimes"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="挂载点"
                label-width="120px"
                prop="mountPoint"
              >
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.mountPoint"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="振幅阈值"
                label-width="120px"
                prop="amplitudeThreshold"
              >
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.amplitudeThreshold"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="经度" label-width="120px" prop="lon">
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.lon"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="纬度" label-width="120px" prop="lat">
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.lat"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="高程" label-width="120px" prop="alt">
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.alt"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item
                label="源椭球空间直角坐标x初始值"
                label-width="120px"
                prop="initSX"
              >
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.initSX"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="源椭球空间直角坐标y初始值"
                label-width="120px"
                prop="initSY"
              >
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.initSY"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="源椭球空间直角坐标z初始值"
                label-width="120px"
                prop="initSZ"
              >
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.initSZ"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item
                label="目标椭球地方平面坐标x初始值"
                label-width="120px"
                prop="initDX"
              >
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.initDX"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="目标椭球地方平面坐标y初始值"
                label-width="120px"
                prop="initDY"
              >
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.initDY"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="目标椭球地方平面坐标h初始值"
                label-width="120px"
                prop="initDH"
              >
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.initDH"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item
                label="源椭球空间直角坐标动态x初始值"
                label-width="120px"
                prop="initSXDyn"
              >
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.initSXDyn"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="源椭球空间直角坐标动态y初始值"
                label-width="120px"
                prop="initSYDyn"
              >
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.initSYDyn"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="源椭球空间直角坐标动态z初始值"
                label-width="120px"
                prop="initSZDyn"
              >
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.initSZDyn"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item
                label="示例图x坐标"
                label-width="120px"
                prop="xPixel"
              >
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.xPixel"
                  clearable
                  ><template slot="append">像素</template></el-input
                >
              </el-form-item>
            </el-col>
            <el-col :span="13">
              <el-form-item
                label="示例图y坐标"
                label-width="120px"
                prop="yPixel"
              >
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.yPixel"
                  clearable
                  ><template slot="append">像素</template></el-input
                >
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="备注" label-width="120px" prop="remark">
            <el-input
              type="textarea"
              v-model="deviceInfoForm.remark"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addInfoSubmit()">提 交</el-button>
      </span>
    </el-dialog>

    <!-- 编辑GNSS -->
    <el-dialog
      title="编辑GNSS"
      :visible.sync="editDialogVisible"
      width="60%"
      v-if="editDialogVisible"
    >
      <div style="height: 350px; overflow: auto">
        <el-form :model="deviceInfoForm" :rules="devRules" ref="editInfoRef">
          <el-form-item label="设备图片" label-width="120px"> </el-form-item>
          <el-row>
            <el-col :span="11">
              <el-form-item
                label="所属项目"
                label-width="120px"
                prop="projectCode"
              >
                <el-select
                  v-model="deviceInfoForm.projectCode"
                  placeholder="请选择项目"
                  clearable
                >
                  <el-option
                    v-for="item in projectInfoList"
                    :key="item.projectCode"
                    :label="item.projectName"
                    :value="item.projectCode"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="13">
              <el-form-item
                label="所属平台编号"
                label-width="120px"
                prop="platformId"
              >
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.platformId"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item
                label="设备型号"
                label-width="120px"
                prop="deviceModel"
              >
                <el-select
                  v-model="deviceInfoForm.deviceModel"
                  placeholder="请选择型号"
                  clearable
                >
                  <el-option
                    v-for="item in deviceModelList"
                    :key="item.modelCode"
                    :label="item.modelName"
                    :value="item.modelCode"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="13">
              <el-form-item label="设备名称" label-width="120px" prop="name">
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.name"
                  clearable
                  placeholder="最多输入25个字"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item
            label="设备行政编号"
            label-width="120px"
            prop="deviceGovCode"
          >
            <el-input
              autocomplete="off"
              v-model="deviceInfoForm.deviceGovCode"
              clearable
              placeholder="格式：AABBCCDDDDEEFF，用于上报应急管理系统"
            ></el-input
            ><br />
            <span style="color: rgb(175, 175, 175)">
              省[AA]市[BB]县/区[CC]尾矿库[DDDD]监测设备类型[EE]设备位号[FF]
            </span>
          </el-form-item>
          <el-form-item label="所属坝级" label-width="120px" prop="barCode">
            <el-select
              v-model="deviceInfoForm.barCode"
              placeholder="请选择坝级"
              clearable
            >
              <el-option
                v-for="item in barLevelData"
                :key="item.barCode"
                :label="item.name"
                :value="item.barCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-row>
            <el-col :span="11">
              <el-form-item label="站点类型" label-width="120px" prop="type">
                <el-select
                  v-model="deviceInfoForm.type"
                  placeholder="请选择站点类型"
                  clearable
                >
                  <el-option label="基准站" :value="0"></el-option>
                  <el-option label="移动站" :value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="13">
              <el-form-item
                label="关联基站"
                label-width="120px"
                prop="relatingBase"
              >
                <el-select
                  @change="changeJizhan"
                  v-model="deviceInfoForm.relatingBase"
                  placeholder="请选择基站"
                  clearable
                >
                  <el-option
                    v-for="item in jizhanList"
                    :key="item.deviceCode"
                    :label="item.name"
                    :value="item.deviceCode"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item label="波特率" label-width="120px" prop="baud">
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.baud"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="13">
              <el-form-item
                label="远程连接模式"
                label-width="120px"
                prop="communicateType"
              >
                <el-select
                  v-model="deviceInfoForm.communicateType"
                  placeholder="请选择远程连接模式"
                  clearable
                >
                  <el-option label="服务器" :value="2"></el-option>
                  <el-option label="客户端" :value="1"></el-option>
                  <el-option label="串口" :value="0"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item label="IP地址" label-width="120px" prop="ipAddr">
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.ipAddr"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="13">
              <el-form-item label="串口号" label-width="120px" prop="serlPort">
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.serlPort"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item label="端口" label-width="120px" prop="tcpPort">
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.tcpPort"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="13">
              <el-form-item
                label="主机机号"
                label-width="120px"
                prop="hostNumber"
              >
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.hostNumber"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item
                label="S10DZ解算间隔"
                label-width="120px"
                prop="calInterval"
              >
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.calInterval"
                  clearable
                >
                  <template slot="append">秒</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="13">
              <el-form-item
                label="滤波阈值"
                label-width="120px"
                prop="filterThreshold"
              >
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.filterThreshold"
                  clearable
                  ><template slot="append">%</template></el-input
                >
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item label="用户名" label-width="120px" prop="username">
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.username"
                  clearable
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="13">
              <el-form-item label="密码" label-width="120px" prop="password">
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.password"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item
                label="初始化次数"
                label-width="120px"
                prop="initTimes"
              >
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.initTimes"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="挂载点"
                label-width="120px"
                prop="mountPoint"
              >
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.mountPoint"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="振幅阈值"
                label-width="120px"
                prop="amplitudeThreshold"
              >
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.amplitudeThreshold"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="经度" label-width="120px" prop="lon">
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.lon"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="纬度" label-width="120px" prop="lat">
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.lat"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="高程" label-width="120px" prop="alt">
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.alt"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item
                label="源椭球空间直角坐标x初始值"
                label-width="120px"
                prop="initSX"
              >
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.initSX"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="源椭球空间直角坐标y初始值"
                label-width="120px"
                prop="initSY"
              >
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.initSY"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="源椭球空间直角坐标z初始值"
                label-width="120px"
                prop="initSZ"
              >
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.initSZ"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item
                label="目标椭球地方平面坐标x初始值"
                label-width="120px"
                prop="initDX"
              >
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.initDX"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="目标椭球地方平面坐标y初始值"
                label-width="120px"
                prop="initDY"
              >
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.initDY"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="目标椭球地方平面坐标h初始值"
                label-width="120px"
                prop="initDH"
              >
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.initDH"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item
                label="源椭球空间直角坐标动态x初始值"
                label-width="120px"
                prop="initSXDyn"
              >
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.initSXDyn"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="源椭球空间直角坐标动态y初始值"
                label-width="120px"
                prop="initSYDyn"
              >
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.initSYDyn"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="源椭球空间直角坐标动态z初始值"
                label-width="120px"
                prop="initSZDyn"
              >
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.initSZDyn"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item
                label="示例图x坐标"
                label-width="120px"
                prop="xPixel"
              >
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.xPixel"
                  clearable
                  ><template slot="append">像素</template></el-input
                >
              </el-form-item>
            </el-col>
            <el-col :span="13">
              <el-form-item
                label="示例图y坐标"
                label-width="120px"
                prop="yPixel"
              >
                <el-input
                  autocomplete="off"
                  v-model="deviceInfoForm.yPixel"
                  clearable
                  ><template slot="append">像素</template></el-input
                >
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="备注" label-width="120px" prop="remark">
            <el-input
              type="textarea"
              v-model="deviceInfoForm.remark"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editInfoSubmit()">保 存</el-button>
      </span>
    </el-dialog>

    <el-dialog title="导出提示" :visible.sync="showDownloadVisible" width="30%">
      <span>数据导出已执行，完成后文件将在【下载中心】展示。</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDownloadVisible = false">取 消</el-button>
        <el-button type="primary" @click="showDownloadVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  data () {
    return {
      addDialogVisible: false,
      editDialogVisible: false,
      showUploadVisible: false,
      showDownloadVisible: false,
      showSelectComDialogVisible: false,
      devRules: {
        projectCode: [{ required: true, message: "请选择项目", trigger: "blur" }],
        deviceModel: [{ required: true, message: "请选择型号", trigger: "blur" }],
        name: [{ required: true, message: "请输入名字", trigger: "blur" }],
      },
      queryParams: {
        projectCode: "",
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      projectInfoList: [],
      deviceModelList: [],
      deviceInfoList: [],
      deviceInfoForm: {
      },
      jizhanList: [],
      comDeviceInfo: "",
      currentDeviceCode: "",
      barLevelData: [],
    }
  },
  created () {
    this.getAllProject();
    this.getDeviceModel();
  },
  methods: {
    handleSizeChange (newSize) {
      this.queryParams.pageSize = newSize;
      this.getDeviceList();
    },
    handleCurrentChange (newPage) {
      this.queryParams.pageNo = newPage;
      this.getDeviceList();
    },
    projectCodeChange (projectCode) {
      this.queryParams.projectCode = projectCode;
      this.getDeviceList();
    },
    async getDeviceModel () {
      const { data: res } = await this.$http.get("device/find/models", {
        params: {
          deviceType: 33
        }
      });
      if (res.code == 0) {
        this.deviceModelList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getAllProject () {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        this.projectInfoList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getDeviceList () {
      const { data: res } = await this.$http.get("device/gnss/find/page", {
        params: this.queryParams
      });
      if (res.code == 0) {
        if (res.data == null) {
          this.deviceInfoList = [];
          return;
        }
        this.deviceInfoList = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getBarLevelList (projectCode) {
      const { data: res } = await this.$http.get(
        "barlevel/find/byPage",
        {
          params: {
            pageNo: 1,
            pageSize: 100,
            projectCode: projectCode
          },
        }
      );
      if (res.code == 0) {
        if (res.data == null) {
          this.barLevelData = [];
          return;
        }
        this.barLevelData = res.data.list;
        console.log(this.barLevelData);
      } else {
        this.$message.error(res.msg);
      }
    },
    showAddDialog () {
      this.deviceInfoForm = {};
      this.addDialogVisible = true;
    },
    async exportDevices () {
      if (this.queryParams.projectCode == null || this.queryParams.projectCode == "") {
        this.$message.error("请选择项目.");
        return;
      }
      const { data: res } = await this.$http.get("file/gnss/export", {
        params: {
          projectCode: this.queryParams.projectCode
        }
      });
      if (res.code == 0) {
        this.showDownloadVisible = true;
      } else {
        this.$message.error(res.msg);
      }
    },
    async showComInfo (comCode) {
      const { data: res } = await this.$http.get("virtual/com/find/bycode", {
        params: {
          comCode: comCode
        },
        showLoading: false
      });
      if (res.code == 0) {
        this.comDeviceInfo = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async editInfoSubmit () {
      this.$refs.editInfoRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "device/gnss/update",
            this.deviceInfoForm
          );
          if (res.code == 0) {
            this.$message.success("修改成功");
            this.getDeviceList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.editDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    async deviceStateChanged (devInfo) {
      const confirmResult = await this.$confirm(
        "此操作将影响平台收集设备数据, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        this.$message.info("已取消操作");
        devInfo.availableBl = !devInfo.availableBl;
        return false;
      }

      const { data: res } = await this.$http.get("device/change/status", {
        params: {
          deviceCode: devInfo.deviceCode,
          available: devInfo.availableBl
        }
      });

      if (res.code == 0) {
        this.$message.success("修改成功");
      } else {
        this.$message.error(res.msg);
      }
    },
    comSettingDialog (deviceCode) {
      this.currentDeviceCode = deviceCode;
      this.showSelectComDialogVisible = true;
    },
    afterBindCom () {
      this.getDeviceList();
    },
    async showEditDialog (deviceCode) {
      this.deviceInfoForm = {};
      const { data: res } = await this.$http.get("device/gnss/find/bycode/" + deviceCode);
      if (res.code == 0) {
        this.deviceInfoForm = res.data;
        this.getJizhanList(res.data.projectCode);
        this.editDialogVisible = true;
      } else {
        this.$message.error(res.msg);
      }
    },
    async removeByDeviceCode (deviceCode) {
      const confirmResult = await this.$confirm("是否确认删除该设备?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.delete("device/gnss/remove/" + deviceCode);
      if (res.code == 0) {
        this.$message.success("删除成功！");
        this.getDeviceList();
      } else {
        return this.$message.error(res.msg);
      }
    },
    addDialogClosed () {
      this.$refs.addInfoRef.resetFields();
    },
    addDeviceImgChange (url) {
      this.deviceInfoForm.deviceImg = url;
    },
    addDeviceImgRemove () {
      this.deviceInfoForm.deviceImg = "";
    },
    async addInfoSubmit () {
      this.$refs.addInfoRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "device/gnss/add",
            this.deviceInfoForm
          );
          if (res.code == 0) {
            this.$message.success("添加成功");
            this.getDeviceList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.addDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    showUploadView () {
      this.showUploadVisible = true;
    },
    batchAddDevices () {
      this.$refs.deviceInfoUploadRef.submitUpload();
    },
    batchAddDevicesSuccess () {
      this.getDeviceList();
    },
    async getJizhanList (projectCode) {
      this.getBarLevelList(projectCode);
      const { data: res } = await this.$http.get("device/gnss/find/jz/list", {
        params: {
          projectCode: projectCode
        }
      });
      if (res.code == 0) {
        if (res.data == null) {
          this.jizhanList = [];
          return;
        }

        this.jizhanList = res.data;
      }
    },
    changeJizhan (deviceCode) {
      this.jizhanList.forEach(o => {
        if (o.deviceCode == deviceCode) {
          this.deviceInfoForm.relatingBaseName = o.name;
          return;
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>
</style>