<template>
  <!-- 基准点管理 -->
  <div>
      <el-row>
        <el-col :span="8">
          <el-button
            @click="exportDevices"
            type="success"
            icon="el-icon-download"
            >导出</el-button
          >
        </el-col>
      </el-row>


      <!-- 表格数据 -->
      <el-table :data="tableInfoList" border stripe>
        <el-table-column type="expand">
          <template slot-scope="props">
            <span v-if="props.row.remark == null || props.row.remark == ''">无备注</span>
            <span v-else>{{ props.row.remark }}</span>
          </template>
        </el-table-column>
        <el-table-column label="名称" prop="jzdName"></el-table-column>
        <el-table-column label="东坐标Y" prop="codtY" width="120px"></el-table-column>
        <el-table-column label="北坐标X" prop="codtX"></el-table-column>
        <el-table-column label="高程H" prop="gaocheng"></el-table-column>
        <el-table-column label="备注" prop="remark"></el-table-column>
        <el-table-column label="创建日期" prop="createTime" width="100px">
          <template slot-scope="scope">
            {{common.dateFormat('YYYY-MM-dd', new Date(Date.parse(scope.row.createTime)))}}
          </template>
        </el-table-column>
        <!-- <el-table-column label="操作" width="120px">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="修改"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                @click="showEditDialog(scope.row.id)"
                v-has="'sys:device:qz:jzd:edit'"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                v-has="'sys:device:qz:jzd:delete'"
                @click="removeById(scope.row.id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column> -->
      </el-table>

      <!-- 分页组件 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>

      <!-- 添加基准点 -->
      <el-dialog
        title="添加基准点"
        :visible.sync="addDialogVisible"
        width="50%"
        v-if="addDialogVisible"
        @close="addDialogClosed"
      >
        <div style="height:350px; overflow: auto;">
        <el-form :model="dataFormObj" :rules="formRules" ref="addInfoRef">
          <el-form-item label="所属项目" label-width="120px" prop="projectCode">
            <el-select
              v-model="dataFormObj.projectCode"
              placeholder="请选择项目"
              clearable
            >
              <el-option
                v-for="item in projectInfoList"
                :key="item.projectCode"
                :label="item.projectName"
                :value="item.projectCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="名称" label-width="120px" prop="jzdName">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.jzdName"
              clearable
              placeholder="最多输入15个字"
            ></el-input>
          </el-form-item>
          <el-form-item label="东坐标Y" label-width="120px" prop="codtY">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.codtY"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="北坐标X" label-width="120px" prop="codtX">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.codtX"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="高程H" label-width="120px" prop="gaocheng">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.gaocheng"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="备注" label-width="120px" prop="remark">
            <el-input
              type="textarea"
              v-model="dataFormObj.remark"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addInfoSubmit()">提 交</el-button>
        </span>
      </el-dialog>




      <!-- 编辑基准点 -->
      <el-dialog
        title="编辑基准点"
        :visible.sync="editDialogVisible"
        width="50%"
        v-if="editDialogVisible"
      >
        <div style="height:350px; overflow: auto;">
        <el-form :model="dataFormObj" :rules="formRules" ref="editInfoRef">
          <el-form-item label="所属项目" label-width="120px" prop="projectCode">
            <el-select
              v-model="dataFormObj.projectCode"
              placeholder="请选择项目"
              clearable
            >
              <el-option
                v-for="item in projectInfoList"
                :key="item.projectCode"
                :label="item.projectName"
                :value="item.projectCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="名称" label-width="120px" prop="jzdName">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.jzdName"
              clearable
              placeholder="最多输入15个字"
            ></el-input>
          </el-form-item>
          <el-form-item label="东坐标Y" label-width="120px" prop="codtY">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.codtY"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="北坐标X" label-width="120px" prop="codtX">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.codtX"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="高程H" label-width="120px" prop="gaocheng">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.gaocheng"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="备注" label-width="120px" prop="remark">
            <el-input
              type="textarea"
              v-model="dataFormObj.remark"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="editInfoSubmit()">提 交</el-button>
        </span>
      </el-dialog>


      <el-dialog
        title="导出提示"
        :visible.sync="showDownloadVisible"
        width="30%">
        <span>数据导出已执行，完成后文件将在【下载中心】展示。</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showDownloadVisible = false">取 消</el-button>
          <el-button type="primary" @click="showDownloadVisible = false">确 定</el-button>
        </span>
      </el-dialog>

  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  data() {
    return {
      addDialogVisible: false,
      editDialogVisible: false,
      showDownloadVisible: false,
      showUploadVisible: false,
      tableInfoList: [],
      queryParams: {
        projectCode: "",
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      formRules: {
        projectCode: [{ required: true, message: "请选择项目", trigger: "blur" }],
        jzdName: [{ required: true, message: "请输入名字", trigger: "blur" }],
        codtY: [{ required: true, message: "请输入东坐标Y", trigger: "blur" }],
        codtX: [{ required: true, message: "请输入北坐标X", trigger: "blur" }],
        gaocheng: [{ required: true, message: "请输入高程H", trigger: "blur" }],
      },
      dataFormObj: {},
    }
  },
  created() {
    this.getAllProject();
    this.getTableDataList();
  },
  methods: {
    async getAllProject() {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        this.projectInfoList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    handleSizeChange(newSize) {
      this.queryParams.pageSize = newSize;
      this.getTableDataList();
    },
    handleCurrentChange(newPage) {
      this.queryParams.pageNo = newPage;
      this.getTableDataList();
    },
    projectCodeChange(projectCode) {
      this.queryParams.projectCode = projectCode;
      this.getTableDataList();
    },
    async getTableDataList() {
      const { data: res } = await this.$http.get("device/qz/jzd/find/page", {
        params: this.queryParams
      });
      if (res.code == 0) {
        if (res.data == null) {
          this.tableInfoList = [];
          this.total = 0;
          return;
        }
        this.tableInfoList = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.error(res.msg);
      }
    },
    showAddDialog() {
      this.dataFormObj = {};
      this.addDialogVisible = true;
    },
    addDialogClosed() {
      this.$refs.addInfoRef.resetFields();
    },
    async addInfoSubmit() {
      this.$refs.addInfoRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "device/qz/jzd/add",
            this.dataFormObj
          );
          if (res.code == 0) {
            this.$message.success("添加成功");
            this.getTableDataList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.addDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    async showEditDialog(id) {
      this.deviceInfoForm = {};
      const { data: res } = await this.$http.get("device/qz/jzd/find/byid/" + id);
      if (res.code == 0) {
        this.dataFormObj = res.data;
        this.editDialogVisible = true;
      } else {
        this.$message.error(res.msg);
      }
    },
    async editInfoSubmit() {
      this.$refs.editInfoRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "device/qz/jzd/update",
            this.dataFormObj
          );
          if (res.code == 0) {
            this.$message.success("修改成功");
            this.getTableDataList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.editDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    async removeById(id) {
      const confirmResult = await this.$confirm("是否确认删除该设备?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.delete("device/qz/jzd/remove/" + id);
      if (res.code == 0) {
        this.$message.success("删除成功！");
        this.getTableDataList();
      } else {
        return this.$message.error(res.msg);
      }
    },
    async exportDevices(){
      if (this.queryParams.projectCode == null || this.queryParams.projectCode == "") {
        this.$message.error("请选择项目.");
        return;
      }
      const { data: res } = await this.$http.get("file/jzd/export", {
        params: {
          projectCode: this.queryParams.projectCode
        }
      });
      if (res.code == 0) {
        this.showDownloadVisible = true;
      } else {
        this.$message.error(res.msg);
      }
    },
    showUploadView() {
      this.showUploadVisible = true;
    },
    batchAddDevices() {
      this.$refs.deviceInfoUploadRef.submitUpload();
    },
    batchAddDevicesSuccess() {
      this.getTableDataList();
    },
  }
}
</script>

<style lang="less" scoped>
</style>