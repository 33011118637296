<template>
  <!-- 点位管理 -->
  <div>
      <!-- <el-row>
        <el-col :span="8">
          <el-button
            type="primary"
            @click="showAddDialog"
            v-has="'sys:device:qz:dw:add'"
            icon="el-icon-plus"
            >添加作业</el-button
          >
        </el-col>
      </el-row> -->


      <!-- 表格数据 -->
      <el-table :data="tableInfoList" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column label="作业名称" prop="name"></el-table-column>
        <el-table-column label="全站仪名" prop="qzName"></el-table-column>
        <el-table-column label="测站名" prop="czName"></el-table-column>
        <el-table-column label="点组" prop="dzName"></el-table-column>
        <el-table-column label="全圆方向观测限差名称" prop="qyXcName"></el-table-column>
        <el-table-column label="全圆方向观测测站限差名" prop="qyCzXcName"></el-table-column>
        <el-table-column label="创建时间" prop="createTime" width="150px"></el-table-column>
        <!-- <el-table-column label="操作" width="120px">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="修改"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                @click="showEditDialog(scope.row.id)"
                v-has="'sys:device:qz:dw:edit'"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                v-has="'sys:device:qz:dw:delete'"
                @click="removeById(scope.row.id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column> -->
      </el-table>

      <!-- 分页组件 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>

      <!-- 添加作业 -->
      <el-dialog
        title="添加作业"
        :visible.sync="addDialogVisible"
        width="50%"
        v-if="addDialogVisible"
        @close="addDialogClosed"
      >
        <div style="height:350px; overflow: auto;">
        <el-form :model="dataFormObj" :rules="formRules" ref="addInfoRef">
          <el-form-item label="所属项目" label-width="120px" prop="projectCode">
            <el-select
              v-model="dataFormObj.projectCode"
              placeholder="请选择项目"
              clearable
              @change="changeThisProject"
            >
              <el-option
                v-for="item in projectInfoList"
                :key="item.projectCode"
                :label="item.projectName"
                :value="item.projectCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="名称" label-width="120px" prop="name">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.name"
              clearable
              placeholder="最多输入15个字"
            ></el-input>
          </el-form-item>
          <el-form-item label="所属测站" label-width="120px" prop="czCode">
            <el-select v-model="dataFormObj.czCode" placeholder="请选择点组" clearable>
              <el-option
                v-for="item in cezhanList"
                :key="item.czCode"
                :label="item.czName"
                :value="item.czCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属点组" label-width="120px" prop="dzCode">
            <el-select v-model="dataFormObj.dzCode" placeholder="请选择点组" clearable>
              <el-option
                v-for="item in dianzuList"
                :key="item.dzCode"
                :label="item.dzName"
                :value="item.dzCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注" label-width="120px" prop="remark">
            <el-input
              type="textarea"
              v-model="dataFormObj.remark"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addInfoSubmit()">提 交</el-button>
        </span>
      </el-dialog>




      <!-- 编辑作业 -->
      <el-dialog
        title="编辑作业"
        :visible.sync="editDialogVisible"
        width="50%"
        v-if="editDialogVisible"
      >
        <div style="height:350px; overflow: auto;">
        <el-form :model="dataFormObj" :rules="formRules" ref="editInfoRef">
          <el-form-item label="所属项目" label-width="120px" prop="projectCode">
            <el-select
              v-model="dataFormObj.projectCode"
              placeholder="请选择项目"
              clearable
              @change="changeThisProject"
            >
              <el-option
                v-for="item in projectInfoList"
                :key="item.projectCode"
                :label="item.projectName"
                :value="item.projectCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="名称" label-width="120px" prop="name">
            <el-input
              autocomplete="off"
              v-model="dataFormObj.name"
              clearable
              placeholder="最多输入15个字"
            ></el-input>
          </el-form-item>
          <el-form-item label="所属测站" label-width="120px" prop="czCode">
            <el-select v-model="dataFormObj.czCode" placeholder="请选择点组" clearable>
              <el-option
                v-for="item in cezhanList"
                :key="item.czCode"
                :label="item.czName"
                :value="item.czCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属点组" label-width="120px" prop="dzCode">
            <el-select v-model="dataFormObj.dzCode" placeholder="请选择点组" clearable>
              <el-option
                v-for="item in dianzuList"
                :key="item.dzCode"
                :label="item.dzName"
                :value="item.dzCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注" label-width="120px" prop="remark">
            <el-input
              type="textarea"
              v-model="dataFormObj.remark"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="editInfoSubmit()">提 交</el-button>
        </span>
      </el-dialog>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  data() {
    return {
      addDialogVisible: false,
      editDialogVisible: false,
      showDownloadVisible: false,
      showUploadVisible: false,
      tableInfoList: [],
      queryParams: {
        projectCode: "",
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      formRules: {
        projectCode: [{ required: true, message: "请选择项目", trigger: "blur" }],
        name: [{ required: true, message: "请输入名字", trigger: "blur" }],
        czCode: [{ required: true, message: "请选择测站", trigger: "blur" }],
        dzCode: [{ required: true, message: "请选择点组", trigger: "blur" }],
      },
      dataFormObj: {},
      projectInfoList: [],
      cezhanList: [],
      dianzuList: [],
    }
  },
  created() {
    this.getAllProject();
    this.getTableDataList();
  },
  methods: {
    async getAllProject() {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        this.projectInfoList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    handleSizeChange(newSize) {
      this.queryParams.pageSize = newSize;
      this.getTableDataList();
    },
    handleCurrentChange(newPage) {
      this.queryParams.pageNo = newPage;
      this.getTableDataList();
    },
    async changeThisProject() {
      // 获取测站列表
      const { data: res } = await this.$http.get("device/qz/cz/find/page", {
        params: {
          projectCode: this.dataFormObj.projectCode,
          pageNo: 1,
          pageSize: 999
        }
      });
      if (res.code == 0) {
        this.cezhanList = res.data.list;
      } else {
        this.$message.error(res.msg);
      }

      // 获取点组列表
      const { data: res2 } = await this.$http.get("device/qz/dz/find/page", {
        params: {
          projectCode: this.dataFormObj.projectCode,
          pageNo: 1,
          pageSize: 999
        }
      });
      if (res2.code == 0) {
        this.dianzuList = res2.data.list;
      } else {
        this.$message.error(res2.msg);
      }
    },
    projectCodeChange(projectCode) {
      this.queryParams.projectCode = projectCode;
      this.getTableDataList();
    },
    async getTableDataList() {
      const { data: res } = await this.$http.get("device/qz/job/find/page", {
        params: this.queryParams
      });
      if (res.code == 0) {
        if (res.data == null) {
          this.tableInfoList = [];
          return;
        }
        this.tableInfoList = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.error(res.msg);
      }
    },
    showAddDialog() {
      this.dataFormObj = {};
      this.addDialogVisible = true;
    },
    addDialogClosed() {
      this.$refs.addInfoRef.resetFields();
    },
    async addInfoSubmit() {
      this.$refs.addInfoRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "device/qz/job/add",
            this.dataFormObj
          );
          if (res.code == 0) {
            this.$message.success("添加成功");
            this.getTableDataList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.addDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    async showEditDialog(id) {
      this.dataFormObj = {};
      const { data: res } = await this.$http.get("device/qz/job/find/byid/" + id);
      if (res.code == 0) {
        this.dataFormObj = res.data;
        this.changeThisProject();
        this.editDialogVisible = true;
      } else {
        this.$message.error(res.msg);
      }
    },
    async editInfoSubmit() {
      this.$refs.editInfoRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "device/qz/job/update",
            this.dataFormObj
          );
          if (res.code == 0) {
            this.$message.success("修改成功");
            this.getTableDataList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.editDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    async removeById(id) {
      const confirmResult = await this.$confirm("是否确认删除该设备?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.delete("device/qz/job/remove/" + id);
      if (res.code == 0) {
        this.$message.success("删除成功！");
        this.getTableDataList();
      } else {
        return this.$message.error(res.msg);
      }
    },
  }
}
</script>

<style lang="less" scoped>
</style>