<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>地图-示意图</el-breadcrumb-item>
    </el-breadcrumb>

    <el-row :gutter="5">
      <el-col :span="6">
        <el-card>
          <div slot="header">
            <span class="span-title">项目列表</span>
          </div>
          <el-tree
            :data="projectList"
            node-key="id"
            default-expand-all
            :expand-on-click-node="false"
            :props="defaultProps"
            @node-click="loadProjectDiagram"
            class="tree-sy"
          >
            <span class="custom-tree-node" slot-scope="{ node }">
              <span>{{ node.label }}</span>
            </span>
          </el-tree>
        </el-card>
      </el-col>
      <el-col :span="18">
        <el-card>
          <div slot="header">
            <span class="span-title">示意图</span>
          </div>
          <div class="map-view">
            <v-map-diagram 
              :canEdit="false" 
              :showController="true"
              ref="projectMapRef"
              @onLoadMarker="onLoadMarker"
              @onLoadProjectDiagram="onLoadProjectDiagram"
              @onMarkerClick="onMarkerClick"></v-map-diagram>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import vMapDiagram from "./MapDiagram.vue";
export default {
  components: {
    vMapDiagram,
  },
  inheritAttrs: false,
  data() {
    return {
      defaultProps: {
        children: 'children',
        label: 'projectName'
      },
      queryParams: {
        queryStr: "",
        pageNo: 1,
        pageSize: 10,
      },
      projectList: [],
      projectInfo: null,
      deviceMapList: [], // 设备地图上的数据
      projectDiagramInfo: {
        forceOnBl: true,
        forceOn: 1,
        diagramContent: null
      }
    };
  },
  created() {
    this.getProjectList();
  },
  methods: {
    loadProjectDiagram (data) {
      this.$refs.projectMapRef.clearMarkers();
      this.$refs.projectMapRef.setProjectInfo(data);
    },
    async getProjectList () {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        if (res.data == null) {
          this.projectList = [];
          return;
        }
        this.projectList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    delete2Diagram(data) {
      this.$refs.projectMapRef.delete2Diagram(data);
    },
    async saveData() {
      this.$refs.projectMapRef.saveData(this.projectDiagramInfo);
    },
    onLoadMarker(data) {
      this.deviceMapList = data;
    },
    onLoadProjectDiagram(data) {
      this.projectDiagramInfo = data;
      if (data === null) {
        this.$message.error('该项目无示意图');
      }
    },
    onMarkerClick(data) {
      console.log(data);
    }
  },
};
</script>
   
<style scoped>
.tree-sy {
  margin-top: 15px;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.map-view {
  width: 100%;
  height: calc(100vh - 250px);
}
</style>