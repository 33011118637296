<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>设备管理</el-breadcrumb-item>
      <el-breadcrumb-item>摄像头管理</el-breadcrumb-item>
      <el-breadcrumb-item>摄像头列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图 -->
    <el-card>
      <el-row>
        <el-col :span="4">
          <el-select
            v-model="queryParams.projectCode"
            placeholder="请选择项目"
            clearable
            @clear="getDeviceList"
            @change="getDeviceList"
          >
            <el-option
              v-for="item in projectInfoList"
              :key="item.projectCode"
              :label="item.projectName"
              :value="item.projectCode"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>

      <!-- 表格数据 -->
      <div class="dataTable" ref="tableBox">
      <el-table :data="deviceInfoList" border stripe v-if="tableHeight"
        :max-height="tableHeight + 'px'">
        <el-table-column type="index"></el-table-column>
        <el-table-column label="图片">
          <template slot-scope="scope">
            <el-popover placement="right" width="300" trigger="hover">
              <img
                :src="COMMON_CONFIG.LOAD_DEVICE_IMG_URL + scope.row.deviceImg"
                height="300"
                width="300"
              />
              <img
                slot="reference"
                :src="COMMON_CONFIG.LOAD_DEVICE_IMG_URL + scope.row.deviceImg"
                height="50"
                width="50"
              />
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="设备名称" prop="name"></el-table-column>
        <el-table-column label="摄像头IP" prop="camIp"></el-table-column>
        <el-table-column label="RTSP端口" prop="camPort" width="100px"></el-table-column>
        <el-table-column label="是否鉴权" prop="mediaAuth" width="80px">
          <template slot-scope="scope">
            <span v-if="scope.row.mediaAuth === 1">是</span>
            <span v-else>否</span>
          </template>
        </el-table-column>
        <el-table-column label="媒体服务IP" prop="mediaIp"></el-table-column>
        <el-table-column label="API端口" prop="mediaPort" width="100px"></el-table-column>
        <el-table-column label="流端口" prop="streamPort" width="100px"></el-table-column>
        <el-table-column label="创建日期" prop="createTime" width="100px">
          <template slot-scope="scope">
            {{
              common.dateFormat(
                "YYYY-MM-dd",
                new Date(Date.parse(scope.row.createTime))
              )
            }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="70px" fixed="right">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="预览"
              placement="top"
              :enterable="false"
            >
              <el-button type="primary" icon="el-icon-video-play" @click="playCamera(scope.row.deviceCode)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页组件 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
        style="right:0;bottom:0;position:absolute;"
      >
      </el-pagination>
      </div>
    </el-card>

        <!-- 视频监控对话框 -->
    <el-dialog
      title="视频预览"
      :visible.sync="videoDialogVisible"
      :width="'780px'"
      v-if="videoDialogVisible"
      :before-close="closeVideo">
      <v-video-main :data="currentShowCamera"></v-video-main>
    </el-dialog>
  </div>
</template>

<script>
import vVideoMain from "../video/VideoMain.vue";
export default {
  components: {
    vVideoMain
  },
  data () {
    return {
      videoDialogVisible: false,
      queryParams: {
        projectCode: "",
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      projectInfoList: [],
      deviceInfoList: [],
      deviceModelList: [],
      tableHeight:0,
      currentShowCamera: {}
    }
  },
  created () {
    this.getAllProject();
    this.getDeviceModel();
  },
  mounted(){
    this.tableHeight = this.$refs.tableBox.clientHeight - 40;
  },
  methods: {
    handleSizeChange (newSize) {
      this.queryParams.pageSize = newSize;
      this.getDeviceList();
    },
    handleCurrentChange (newPage) {
      this.queryParams.pageNo = newPage;
      this.getDeviceList();
    },
    async getDeviceModel () {
      const { data: res } = await this.$http.get("device/find/models", {
        params: {
          deviceType: 95
        }
      });
      if (res.code == 0) {
        this.deviceModelList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getAllProject () {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        this.projectInfoList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getDeviceList () {
      const { data: res } = await this.$http.get("device/camera/find/page", {
        params: this.queryParams
      });
      if (res.code == 0) {
        if (res.data == null) {
          this.deviceInfoList = [];
          this.total = 0;
          return;
        }

        this.deviceInfoList = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.error(res.msg);
      }
    },
    async playCamera(deviceCode) {
      const { data: res } = await this.$http.get("device/camera/pull/stream/" + deviceCode);
      if (res.code == 0) {
        this.currentShowCamera = res.data;
        this.videoDialogVisible = true;
      } else {
        this.$message.error(res.msg);
      }
    },
    async closeStream(deviceCode) {
      const { data: res } = await this.$http.get("device/camera/close/stream/" + deviceCode);
      if (res.code == 0) {
        console.log(res.data);
      }
    },
    closeVideo(done) {
      this.closeStream(this.currentShowCamera.stream_name);
      done();
    }
  }
}
</script>

<style lang="less" scoped>
  .dataTable {
    width: 100%;
    height: calc(100vh - 240px);
    position: relative;
  }
</style>