<template>
  <div>
    <el-row :gutter="10">
      <el-col :span="18" v-has="'home:project:main'">
        <el-card>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-card shadow="never">
                <div slot="header">
                  <i class="el-icon-bell"></i
                  ><span class="span-title">预警统计</span>
                </div>
                <!-- 总数 -->
                <el-row>
                  <el-col :span="12">
                    <router-link
                      :to="{ path: '/alarm/list', query: { index: 1 } }"
                    >
                      <div class="total-num">
                        {{ alarmStatistics.undealTotal }}
                      </div>
                    </router-link>
                    <div class="total-title">待处理预警</div>
                  </el-col>
                  <el-col :span="12">
                    <router-link
                      :to="{ path: '/alarm/list', query: { index: 5 } }"
                    >
                      <div class="total-num">
                        {{ alarmStatistics.tdUndealTotal }}
                      </div>
                    </router-link>
                    <div class="total-title">今日新增</div>
                  </el-col>
                </el-row>
                <!-- 详细 -->
                <el-row>
                  <el-col :span="6">
                    <router-link
                      :to="{ path: '/alarm/list', query: { index: 6 } }"
                    >
                      <div class="detail-num" style="color: blue">
                        {{ alarmStatistics.blueTotal }}
                      </div>
                    </router-link>
                    <div class="detail-title">蓝色预警</div>
                  </el-col>
                  <el-col :span="6">
                    <router-link
                      :to="{ path: '/alarm/list', query: { index: 2 } }"
                    >
                      <div class="detail-num" style="color: rgb(255, 238, 0)">
                        {{ alarmStatistics.yellowTotal }}
                      </div>
                    </router-link>
                    <div class="detail-title">黄色预警</div>
                  </el-col>
                  <el-col :span="6">
                    <router-link
                      :to="{ path: '/alarm/list', query: { index: 3 } }"
                    >
                      <div class="detail-num" style="color: #ff8a1c">
                        {{ alarmStatistics.orangeTotal }}
                      </div>
                    </router-link>
                    <div class="detail-title">橙色预警</div>
                  </el-col>
                  <el-col :span="6">
                    <router-link
                      :to="{ path: '/alarm/list', query: { index: 4 } }"
                    >
                      <div class="detail-num">
                        {{ alarmStatistics.redTotal }}
                      </div>
                    </router-link>
                    <div class="detail-title">红色预警</div>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
            <el-col :span="12">
              <el-card shadow="never">
                <div slot="header">
                  <i class="el-icon-circle-close"></i
                  ><span class="span-title">设备异常统计</span>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="异常设备定义：24小时内未采集到数据的设备."
                    placement="top"
                  >
                    <i
                      class="el-icon-warning"
                      style="float: right; padding: 3px 0"
                    ></i>
                  </el-tooltip>
                </div>
                <el-row>
                  <el-col :span="5" v-has="'sys:mtr:gnss'">
                    <el-popover
                      placement="right"
                      width="150"
                      trigger="hover"
                      @show="showExceptionDevice(33)"
                    >
                      <div v-for="n in deviceNames" :key="n">{{ n }}</div>
                      <div class="er-detail-num" slot="reference">
                        {{ exceptionDevice.gnss }}
                      </div>
                    </el-popover>
                    <div class="er-detail-title">GNSS</div>
                  </el-col>
                  <el-col :span="5" v-has="'sys:mtr:quanzhan'">
                    <el-popover
                      placement="right"
                      width="150"
                      trigger="hover"
                      @show="showExceptionDevice(30)"
                    >
                      <div v-for="n in deviceNames" :key="n">{{ n }}</div>
                    <div class="er-detail-num" slot="reference">
                      {{ exceptionDevice.quanzhan }}
                    </div>
                    </el-popover>
                    <div class="er-detail-title">全站仪点位</div>
                  </el-col>
                  <el-col :span="5" v-has="'sys:mtr:cexie'">
                    <el-popover
                      placement="right"
                      width="150"
                      trigger="hover"
                      @show="showExceptionDevice(40)"
                    >
                      <div v-for="n in deviceNames" :key="n">{{ n }}</div>
                      <div class="er-detail-num" slot="reference">
                        {{ exceptionDevice.cexie }}
                      </div>
                    </el-popover>
                    <div class="er-detail-title">测斜仪</div>
                  </el-col>
                  <el-col :span="5" v-has="'sys:mtr:shenya'">
                    <el-popover
                      placement="right"
                      width="150"
                      trigger="hover"
                      @show="showExceptionDevice(50)"
                    >
                      <div v-for="n in deviceNames" :key="n">{{ n }}</div>
                      <div class="er-detail-num" slot="reference">
                        {{ exceptionDevice.shenya }}
                      </div>
                    </el-popover>
                    <div class="er-detail-title">渗压计</div>
                  </el-col>
                  <!-- </el-row>
                <el-row> -->
                  <el-col :span="5" v-has="'sys:mtr:gantan'">
                    <el-popover
                      placement="right"
                      width="150"
                      trigger="hover"
                      @show="showExceptionDevice(10)"
                    >
                      <div v-for="n in deviceNames" :key="n">{{ n }}</div>
                      <div class="er-detail-num" slot="reference">
                        {{ exceptionDevice.gantan }}
                      </div>
                    </el-popover>
                    <div class="er-detail-title">干滩</div>
                  </el-col>
                  <el-col :span="5" v-has="'sys:mtr:chaogao'">
                    <el-popover
                      placement="right"
                      width="150"
                      trigger="hover"
                      @show="showExceptionDevice(21)"
                    >
                      <div v-for="n in deviceNames" :key="n">{{ n }}</div>
                      <div class="er-detail-num" slot="reference">
                        {{ exceptionDevice.chaogao }}
                      </div>
                    </el-popover>
                    <div class="er-detail-title">超高</div>
                  </el-col>
                  <el-col :span="5" v-has="'sys:mtr:yuliang'">
                    <el-popover
                      placement="right"
                      width="150"
                      trigger="hover"
                      @show="showExceptionDevice(60)"
                    >
                      <div v-for="n in deviceNames" :key="n">{{ n }}</div>
                      <div class="er-detail-num" slot="reference">
                        {{ exceptionDevice.yuliang }}
                      </div>
                    </el-popover>
                    <div class="er-detail-title">雨量计</div>
                  </el-col>
                  <el-col :span="5" v-has="'sys:mtr:zhuodu'">
                    <el-popover
                      placement="right"
                      width="150"
                      trigger="hover"
                      @show="showExceptionDevice(91)"
                    >
                      <div v-for="n in deviceNames" :key="n">{{ n }}</div>
                      <div class="er-detail-num" slot="reference">
                        {{ exceptionDevice.zuodu }}
                      </div>
                    </el-popover>
                    <div class="er-detail-title">浊度仪</div>
                  </el-col>
                  <el-col :span="5" v-has="'sys:mtr:wuwei'">
                    <el-popover
                      placement="right"
                      width="150"
                      trigger="hover"
                      @show="showExceptionDevice(20)"
                    >
                      <div v-for="n in deviceNames" :key="n">{{ n }}</div>
                      <div class="er-detail-num" slot="reference">
                        {{ exceptionDevice.wuwei }}
                      </div>
                    </el-popover>
                    <div class="er-detail-title">水位计</div>
                  </el-col>
                  <el-col :span="5" v-has="'sys:mtr:mingqu'">
                    <el-popover
                      placement="right"
                      width="150"
                      trigger="hover"
                      @show="showExceptionDevice(22)"
                    >
                      <div v-for="n in deviceNames" :key="n">{{ n }}</div>
                      <div class="er-detail-num" slot="reference">
                        {{ exceptionDevice.mingqu }}
                      </div>
                    </el-popover>
                    <div class="er-detail-title">明渠流量</div>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
          </el-row>

          <!-- 预警列表 -->
          <el-row>
            <!-- <el-card style="margin-top: 10px; height: 424px">
              <div slot="header">
                <i class="el-icon-document"></i
                ><span class="span-title">预警列表</span>
                <router-link to="/alarm/list">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="更多"
                    placement="top"
                  >
                    <i
                      class="el-icon-more-outline"
                      style="float: right; padding: 3px 0"
                    ></i>
                  </el-tooltip>
                </router-link>
              </div>
              <el-table
                :data="alarmInfoList"
                border
                stripe
                style="margin-top: 0px; padding-top: 0px"
              >
                <el-table-column type="index"></el-table-column>
                <el-table-column
                  label="设备类型"
                  prop="deviceType"
                  width="90px"
                >
                  <template slot-scope="scope">
                    <span v-if="scope.row.deviceType == 10">干滩</span>
                    <span v-else-if="scope.row.deviceType == 20">库水位</span>
                    <span v-else-if="scope.row.deviceType == 21">超高</span>
                    <span v-else-if="scope.row.deviceType == 30"
                      >全站仪位移</span
                    >
                    <span v-else-if="scope.row.deviceType == 33">GNSS位移</span>
                    <span v-else-if="scope.row.deviceType == 40">内部测斜</span>
                    <span v-else-if="scope.row.deviceType == 50">浸润线</span>
                    <span v-else-if="scope.row.deviceType == 60">降雨量</span>
                    <span v-else-if="scope.row.deviceType == 91">混浊度</span>
                    <span v-else-if="scope.row.deviceType == 22">明渠流量</span>
                    <span v-else></span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="设备名称"
                  prop="deviceName"
                ></el-table-column>
                <el-table-column label="预警等级" prop="alarmLv" width="90px">
                  <template slot-scope="scope">
                    <el-tag v-if="scope.row.alarmLv == 0">蓝色预警</el-tag>
                    <el-tag
                      v-else-if="scope.row.alarmLv == 1"
                      class="yellowClass"
                      >黄色预警</el-tag
                    >
                    <el-tag v-else-if="scope.row.alarmLv == 2" type="warning"
                      >橙色预警</el-tag
                    >
                    <el-tag v-else type="danger">红色预警</el-tag>
                  </template>
                </el-table-column>
                <el-table-column label="实际值/参考值" prop="alarmValue">
                  <template slot-scope="scope">
                    {{ scope.row.realValue }}
                    <span v-if="scope.row.comparisonOperator == 1">=</span>
                    <span v-else-if="scope.row.comparisonOperator == 2">></span>
                    <span v-else-if="scope.row.comparisonOperator == 3"
                      >&lt;</span
                    >
                    <span v-else-if="scope.row.comparisonOperator == 4">≥</span>
                    <span v-else-if="scope.row.comparisonOperator == 5">≤</span>
                    <span v-else>&lt;></span>
                    {{ scope.row.alarmValue }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="触发时间"
                  prop="alarmTime"
                  width="150px"
                ></el-table-column>
                <el-table-column
                  label="处理状态"
                  prop="alarmStatus"
                  width="80px"
                >
                  <template slot-scope="scope">
                    <el-tag v-if="scope.row.alarmStatus == 1">已处理</el-tag>
                    <el-tag v-else type="danger">未处理</el-tag>
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="80px">
                  <template slot-scope="scope">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="处理预警"
                      placement="top"
                      :enterable="false"
                      v-if="scope.row.alarmStatus == 0"
                    >
                      <el-button
                        type="success"
                        icon="el-icon-setting"
                        @click="dealAlarm(scope.row.id)"
                        v-has="'sys:alarm:deal'"
                      ></el-button>
                    </el-tooltip>
                  </template>
                </el-table-column>
              </el-table>
            </el-card> -->
            <el-card style="margin-top: 10px">
              <div slot="header">
                <i class="el-icon-document"></i
                ><span class="span-title">监测情况</span>
              </div>
              <el-table
                :data="dataSummaryInfoList"
                border
                stripe
                style="margin-top: 0px; padding-top: 0px"
              >
                <el-table-column
                  label="设备类型"
                  prop="deviceType"
                  width="90px"
                >
                  <template slot-scope="scope">
                    <span v-if="scope.row.deviceType == 10">干滩</span>
                    <span v-else-if="scope.row.deviceType == 20">库水位</span>
                    <span v-else-if="scope.row.deviceType == 21">超高</span>
                    <span v-else-if="scope.row.deviceType == 30"
                      >全站仪位移</span
                    >
                    <span v-else-if="scope.row.deviceType == 33">GNSS位移</span>
                    <span v-else-if="scope.row.deviceType == 40">内部测斜</span>
                    <span v-else-if="scope.row.deviceType == 50">浸润线</span>
                    <span v-else-if="scope.row.deviceType == 60">降雨量</span>
                    <span v-else-if="scope.row.deviceType == 91">混浊度</span>
                    <span v-else-if="scope.row.deviceType == 22">明渠流量</span>
                    <span v-else></span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="设备名称"
                  prop="deviceName"
                  width="120px"
                ></el-table-column>
                <el-table-column label="采集数值" prop="valueDes">
                </el-table-column>
                <el-table-column
                  label="采集时间"
                  prop="collectTime"
                  width="150px"
                ></el-table-column>
              </el-table>
            </el-card>
          </el-row>
        </el-card>
      </el-col>

      <!-- 此处为管理员才能看到 -->
      <el-col :span="18" v-has="'home:manager:main'">
        <el-row :gutter="5">
          <el-col :span="8">
            <el-card>
              <div class="total-num">{{ platformSummary.projectTotal }}</div>
              <div class="total-title">服务项目数(个)</div>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card>
              <div class="total-num">{{ platformSummary.deviceTotal }}</div>
              <div class="total-title">
                设备总量(个)<a class="deviceDetail" @click="showDeviceDetail"
                  >明细</a
                >
              </div>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card>
              <div class="total-num">{{ platformSummary.dataTotal }}</div>
              <div class="total-title">数据采集量(条)</div>
            </el-card>
          </el-col>
        </el-row>
        <el-row>
          <el-card style="margin-top: 10px">
            <div slot="header">
              <span class="span-title">项目概况</span>
            </div>
            <el-table
              :data="projectStatisInfoList"
              border
              stripe
              style="margin-top: 0px; padding-top: 0px"
            >
              <el-table-column type="index"></el-table-column>
              <el-table-column
                label="项目名称"
                prop="projectName"
              ></el-table-column>
              <el-table-column label="设备量" prop="deviceNum">
              </el-table-column>
              <el-table-column
                label="历史预警数"
                prop="allAlarm"
              ></el-table-column>
              <el-table-column
                label="待处理预警"
                prop="undealAlarm"
              ></el-table-column>
              <el-table-column
                label="创建时间"
                prop="createTime"
              ></el-table-column>
            </el-table>
            <!-- 分页组件 -->
            <el-pagination
              :current-page="projectStatisQueryParams.pageNo"
              :page-size="projectStatisQueryParams.pageSize"
              layout="total, prev, pager, next"
              :total="projectStatisTotal"
            >
            </el-pagination>
          </el-card>
        </el-row>
      </el-col>
      <el-col :span="6">
        <el-card>
          <el-row class="currentTime">
            {{ newTime }}
          </el-row>
          <el-row class="currentDate">
            {{ newDate }}
          </el-row>
        </el-card>
        <el-card style="margin-top: 10px" v-has="'home:project:main'">
          <div slot="header">
            <i class="el-icon-warning-outline"></i
            ><span class="span-title">项目概况</span>
            <router-link :to="{ path: '/projectDetail', query: {} }">
              <el-button style="float: right; padding: 3px 0" type="text"
                >详情</el-button
              >
            </router-link>
          </div>
          <el-form ref="form" label-width="80px">
            <el-form-item label="干滩长度" v-has="'sys:mtr:gantan'">
              <span style="width: 100px; font-size: 20px">{{
                projectSummary.gantan
              }}</span
              ><span style="float: right">米</span>
            </el-form-item>
            <el-form-item label="干滩坡度" v-has="'sys:mtr:gantan'">
              <span style="width: 100px; font-size: 20px">{{
                projectSummary.pb
              }}</span>
            </el-form-item>
            <el-form-item label="库水位" v-has="'sys:mtr:wuwei'">
              <span style="width: 100px; font-size: 20px">{{
                projectSummary.shuiwei
              }}</span
              ><span style="float: right">米</span>
            </el-form-item>
            <el-form-item label="安全超高" v-has="'sys:mtr:chaogao'">
              <span style="width: 100px; font-size: 20px">{{
                projectSummary.chaogao
              }}</span
              ><span style="float: right">米</span>
            </el-form-item>
            <el-form-item label="降雨量" v-has="'sys:mtr:yuliang'">
              <el-row>
                <el-col :span="14">
                  <span>当日：{{ projectSummary.ylNumDay }}</span
                  ><span>毫米</span>
                </el-col>
                <el-col :span="10">
                  <span>最新：{{ projectSummary.ylNum }}</span
                  ><span>毫米</span>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item label="明渠流量" v-has="'sys:mtr:mingqu'">
              <el-row>
                <el-col :span="14">
                  <span>当日：{{ projectSummary.mqNumDay }}</span
                  ><span>m³</span>
                </el-col>
                <el-col :span="10">
                  <span>最新：{{ projectSummary.mqNum }}</span
                  ><span>m³</span>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item label="全站仪" v-has="'sys:mtr:quanzhan'">
              <el-tag
                v-if="projectSummary.quanzhanAlarm == 9"
                type="success"
                plain
                >无预警</el-tag
              >
              <router-link
                v-else-if="projectSummary.quanzhanAlarm == 1"
                :to="{
                  path: '/alarm/list',
                  query: { index: 2, deviceType: 30 },
                }"
              >
                <el-tag class="yellowClass" plain>黄色预警</el-tag>
              </router-link>
              <router-link
                v-else-if="projectSummary.quanzhanAlarm == 2"
                :to="{
                  path: '/alarm/list',
                  query: { index: 3, deviceType: 30 },
                }"
              >
                <el-tag type="warning" plain>橙色预警</el-tag>
              </router-link>
              <router-link
                v-else-if="projectSummary.quanzhanAlarm == 3"
                :to="{
                  path: '/alarm/list',
                  query: { index: 4, deviceType: 30 },
                }"
              >
                <el-tag type="danger" plain>红色预警</el-tag>
              </router-link>
              <router-link
                v-else-if="projectSummary.quanzhanAlarm == 0"
                :to="{
                  path: '/alarm/list',
                  query: { index: 6, deviceType: 30 },
                }"
              >
                <el-tag plain>蓝色预警</el-tag>
              </router-link>
            </el-form-item>
            <el-form-item label="GNSS" v-has="'sys:mtr:gnss'">
              <el-tag v-if="projectSummary.gnssAlarm == 9" type="success" plain
                >无预警</el-tag
              >
              <router-link
                v-else-if="projectSummary.gnssAlarm == 1"
                :to="{
                  path: '/alarm/list',
                  query: { index: 2, deviceType: 33 },
                }"
              >
                <el-tag class="yellowClass" plain>黄色预警</el-tag>
              </router-link>
              <router-link
                v-else-if="projectSummary.gnssAlarm == 2"
                :to="{
                  path: '/alarm/list',
                  query: { index: 3, deviceType: 33 },
                }"
              >
                <el-tag type="warning" plain>橙色预警</el-tag>
              </router-link>
              <router-link
                v-else-if="projectSummary.gnssAlarm == 3"
                :to="{
                  path: '/alarm/list',
                  query: { index: 4, deviceType: 33 },
                }"
              >
                <el-tag type="danger" plain>红色预警</el-tag>
              </router-link>
              <router-link
                v-else-if="projectSummary.gnssAlarm == 0"
                :to="{
                  path: '/alarm/list',
                  query: { index: 6, deviceType: 33 },
                }"
              >
                <el-tag plain>蓝色预警</el-tag>
              </router-link>
            </el-form-item>
            <el-form-item label="内部位移" v-has="'sys:mtr:cexie'">
              <el-tag v-if="projectSummary.cexieAlarm == 9" type="success" plain
                >无预警</el-tag
              >
              <router-link
                v-else-if="projectSummary.cexieAlarm == 1"
                :to="{
                  path: '/alarm/list',
                  query: { index: 2, deviceType: 40 },
                }"
              >
                <el-tag class="yellowClass" plain>黄色预警</el-tag>
              </router-link>
              <router-link
                v-else-if="projectSummary.cexieAlarm == 2"
                :to="{
                  path: '/alarm/list',
                  query: { index: 3, deviceType: 40 },
                }"
              >
                <el-tag type="warning" plain>橙色预警</el-tag>
              </router-link>
              <router-link
                v-else-if="projectSummary.cexieAlarm == 3"
                :to="{
                  path: '/alarm/list',
                  query: { index: 4, deviceType: 40 },
                }"
              >
                <el-tag type="danger" plain>红色预警</el-tag>
              </router-link>
              <router-link
                v-else-if="projectSummary.cexieAlarm == 0"
                :to="{
                  path: '/alarm/list',
                  query: { index: 6, deviceType: 40 },
                }"
              >
                <el-tag plain>蓝色预警</el-tag>
              </router-link>
            </el-form-item>
            <el-form-item label="浸润线" v-has="'sys:mtr:shenya'">
              <el-tag
                v-if="projectSummary.shenyaAlarm == 9"
                type="success"
                plain
                >无预警</el-tag
              >
              <router-link
                v-else-if="projectSummary.shenyaAlarm == 1"
                :to="{
                  path: '/alarm/list',
                  query: { index: 2, deviceType: 50 },
                }"
              >
                <el-tag class="yellowClass" plain>黄色预警</el-tag>
              </router-link>
              <router-link
                v-else-if="projectSummary.shenyaAlarm == 2"
                :to="{
                  path: '/alarm/list',
                  query: { index: 3, deviceType: 50 },
                }"
              >
                <el-tag type="warning" plain>橙色预警</el-tag>
              </router-link>
              <router-link
                v-else-if="projectSummary.shenyaAlarm == 3"
                :to="{
                  path: '/alarm/list',
                  query: { index: 4, deviceType: 50 },
                }"
              >
                <el-tag type="danger" plain>红色预警</el-tag>
              </router-link>
              <router-link
                v-else-if="projectSummary.shenyaAlarm == 0"
                :to="{
                  path: '/alarm/list',
                  query: { index: 6, deviceType: 50 },
                }"
              >
                <el-tag plain>蓝色预警</el-tag>
              </router-link>
            </el-form-item>
          </el-form>
        </el-card>

        <!-- 此处为管理员才能看到 -->
        <el-card style="margin-top: 10px" v-has="'home:manager:main'">
          <div slot="header">
            <span class="span-title">边端应用列表</span>
          </div>
          <el-table
            :data="appInfoList"
            border
            stripe
            style="margin-top: 0px; padding-top: 0px"
          >
            <el-table-column label="名称" prop="platName"></el-table-column>
            <el-table-column label="状态" width="80px">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.nodeCount >= 1" type="success"
                  >在线</el-tag
                >
                <el-tag v-else-if="scope.row.nodeCount === 0" type="info"
                  >离线</el-tag
                >
              </template></el-table-column
            >
          </el-table>
          <!-- 分页组件 -->
          <el-pagination
            :current-page="appQueryParams.pageNo"
            :page-size="appQueryParams.pageSize"
            layout="total, prev, pager, next"
            :total="appTotal"
          >
          </el-pagination>
        </el-card>
      </el-col>
    </el-row>

    <!-- 添加处理意见 -->
    <el-dialog
      title="预警处理意见"
      :visible.sync="addDialogVisible"
      width="50%"
      @close="addDialogClosed"
    >
      <div>
        <el-form :model="alarmInfoForm" :rules="formRules" ref="addInfoRef">
          <el-form-item label="处理意见" label-width="120px" prop="opReason">
            <el-input
              type="textarea"
              v-model="alarmInfoForm.opReason"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addInfoSubmit()">提 交</el-button>
      </span>
    </el-dialog>

    <!-- 设备明细统计 -->
    <el-dialog
      title="设备明细"
      :visible.sync="deviceDetailDialogVisible"
      v-if="deviceDetailDialogVisible"
      width="70%"
    >
      <v-dashboard-device-bar></v-dashboard-device-bar>
    </el-dialog>
  </div>
</template>
 
<script>
import vDashboardDeviceBar from "./DashboardDeviceBar.vue";
const days = ['天', '一', '二', '三', '四', '五', '六']; // 星期数组
var icnow = new Date();      // 初始化时间
var interval;                // 定义全局定时器，用于清除定时器
var mainTimer;
export default {
  components: {
    vDashboardDeviceBar
  },
  data () {
    return {
      addDialogVisible: false,
      deviceDetailDialogVisible: false,
      formRules: {
        opReason: [{ required: true, message: "请输入处理意见", trigger: "blur" }],
      },
      queryParams: {
        projectCode: "",
        deviceType: null,
        alarmLv: null,
        alarmStatus: 0,
        pageNo: 1,
        pageSize: 6,
        startTime: null,
        endTime: null,
      },
      year: icnow.getFullYear(),
      month: icnow.getMonth() + 1,
      date: icnow.getDate(),
      day: days[icnow.getDay()],
      time: "00:00:00",
      alarmInfoList: [],
      dataSummaryInfoList: [],
      alarmStatistics: {
        undealTotal: 0,
        tdUndealTotal: 0,
        yellowTotal: 0,
        orangeTotal: 0,
        redTotal: 0
      },
      exceptionDevice: {
        total: 0,
        quanzhan: 0,
        cexie: 0,
        shenya: 0,
        yuliang: 0,
        zuodu: 0,
        wuwei: 0,
        gantan: 0,
        chaogao: 0,
        mingqu: 0,
      },
      projectSummary: {
        quanzhanAlarm: 0,
        cexieAlarm: 0,
        shenyaAlarm: 0,
        ylNumDay: "",
        ylNum: "",
        mqNumDay: "",
        mqNum: "",
        gantan: "",
        shuiwei: "",
        chaogao: "",
        pb1: "",
        pb2: "",
      },
      alarmInfoForm: {
        opReason: '',
      },
      currentAlarmId: null,
      deviceNames: [],
      platformSummary: {
        projectTotal: 0,
        deviceTotal: 0,
        dataTotal: 0
      },
      appInfoList: [],
      appTotal: 0,
      appQueryParams: {
        queryStr: "",
        pageNo: 1,
        pageSize: 30,
        orgId: null,
        projectCode: "",
      },
      projectStatisInfoList: [],
      projectStatisTotal: 0,
      projectStatisQueryParams: {
        pageNo: 1,
        pageSize: 30,
      },
    }
  },
  created () {
    this.getmainInfo();
    interval = setInterval(() => {
      let icnow = new Date();
      this.year = icnow.getFullYear();
      this.month = icnow.getMonth() + 1;
      this.date = icnow.getDate();
      this.day = days[icnow.getDay()];
      this.time = icnow.toTimeString().substring(0, 8);
    }, 1000);
    mainTimer = setInterval(() => {
      this.getmainInfo();
    }, 30000);
  },
  computed: {
    // 当前时间
    newTime: function () {
      return this.time;
    },
    newDate: function () {
      return this.year + '年' + this.month + '月' + this.date + '日 星期' + this.day;
    }
  },
  methods: {
    getmainInfo () {
      // this.getAlarmList();
      this.getAlarmStatis();
      this.getExceptionDevice();
      this.getProjectSummary();
      this.getPlatformSummary();
      this.getAppList();
      this.getProjectStatisList();
      this.getDataSummaryList();
    },
    async getDataSummaryList () {
      const { data: res } = await this.$http.get("device/realtime/data/summary", {
        showLoading: false
      });
      if (res.code == 0) {
        this.dataSummaryInfoList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getAlarmList () {
      const { data: res } = await this.$http.post("alarm/find/records/main", this.queryParams, {
        showLoading: false
      });
      if (res.code == 0) {
        this.alarmInfoList = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getAlarmStatis () {
      const { data: res } = await this.$http.get("alarm/alarmRecord/statis", {
        showLoading: false
      });
      if (res.code == 0) {
        this.alarmStatistics = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getProjectSummary () {
      const { data: res } = await this.$http.get("device/project/summary", {
        showLoading: false
      });
      if (res.code == 0) {
        this.projectSummary = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getExceptionDevice () {
      const { data: res } = await this.$http.get("device/exception/device/statis", {
        showLoading: false
      });
      if (res.code == 0) {
        this.exceptionDevice = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    dealAlarm (id) {
      this.addDialogVisible = true;
      this.currentAlarmId = id;
    },
    addDialogClosed () {
      this.$refs.addInfoRef.resetFields();
    },
    async addInfoSubmit () {
      this.alarmInfoForm.id = this.currentAlarmId;
      this.$refs.addInfoRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "alarm/deal/alarm",
            this.alarmInfoForm,
            {
              showLoading: false
            }
          );
          if (res.code == 0) {
            this.$message.success("处理成功");
            this.getmainInfo();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.addDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    async showExceptionDevice (type) {
      const { data: res } = await this.$http.get("device/exception/device/names", {
        params: {
          deviceType: type
        },
        showLoading: false
      });
      if (res.code == 0) {
        this.deviceNames = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },

    // 管理员权限仪表盘
    async getPlatformSummary () {
      const { data: res } = await this.$http.get("device/find/platform/summary", {
        params: {},
        showLoading: false
      });
      if (res.code == 0) {
        this.platformSummary = res.data;
      }
    },
    async getAppList () {
      const { data: res } = await this.$http.get("platform/find/byPage", {
        params: this.appQueryParams,
        showLoading: false
      });
      if (res.code == 0) {
        this.appInfoList = res.data.list;
        this.appTotal = res.data.total;
      }
    },
    async getProjectStatisList () {
      const { data: res } = await this.$http.get("device/find/project/mgr/statis", {
        params: this.projectStatisQueryParams,
        showLoading: false
      });
      if (res.code == 0) {
        this.projectStatisInfoList = res.data.list;
        this.projectStatisTotal = res.data.total;
      }
    },
    showDeviceDetail () {
      this.deviceDetailDialogVisible = true;
    }
  },
  beforeDestroy () {
    clearInterval(interval);
    clearInterval(mainTimer);
  }
}
</script>
 
<style scoped>
.currentDate {
  text-align: right;
  font-size: 22px;
}
.currentTime {
  text-align: right;
  font-size: 50px;
}
.span-title {
  margin-left: 5px;
}
.total-title {
  font-size: 20px;
  text-align: center;
  color: #606266;
  width: 100%;
  height: 30px;
}
.total-num {
  font-size: 40px;
  font-weight: bold;
  color: #f56c6c;
  text-align: center;
  width: 100%;
  height: 50px;
}
.detail-title {
  font-size: 16px;
  text-align: center;
  color: #909399;
  width: 100%;
  height: 20px;
}
.detail-num {
  margin-top: 15px;
  font-size: 20px;
  font-weight: bold;
  color: red;
  text-align: center;
  width: 100%;
  height: 30px;
}
.er-num {
  margin-top: 20px;
  font-size: 40px;
  font-weight: bold;
  color: #f56c6c;
  text-align: center;
  width: 100%;
  height: 50px;
}
.er-title {
  font-size: 20px;
  text-align: center;
  color: #606266;
  width: 100%;
  height: 50px;
}
.er-detail-num {
  margin-top: 12px;
  font-size: 20px;
  font-weight: bold;
  color: #f56c6c;
  text-align: center;
  width: 100%;
  height: 30px;
}
.er-detail-title {
  font-size: 14px;
  text-align: center;
  color: #909399;
  width: 100%;
  height: 30px;
}
.yellowClass {
  background-color: rgb(255, 254, 191);
  border-color: rgb(230, 215, 18);
  color: rgb(177, 165, 0);
}
.deviceDetail {
  font-size: 12px;
  color: blue;
  margin-left: 10px;
  cursor: pointer;
}
</style>