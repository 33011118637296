<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>资源管理</el-breadcrumb-item>
      <el-breadcrumb-item>资源列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图 -->
    <el-card>
      <el-row :gutter="10">
        <el-col :span="5">
          <el-button
            type="primary"
            icon="el-icon-plus"
            @click="showAddDialog(0,'')"
            v-has="'sys:resource:add'"
            >添加资源</el-button
          >
        </el-col>
      </el-row>

      <tree-table
        ref="treeTable"
        sum-text="sum"
        index-text="#"
        :data="resourceData"
        :columns="columns"
        :stripe="props.stripe"
        :border="props.border"
        :show-header="props.showHeader"
        :show-summary="props.showSummary"
        :show-row-hover="props.showRowHover"
        :show-index="props.showIndex"
        :tree-type="props.treeType"
        :is-fold="props.isFold"
        :expand-type="props.expandType"
        expand-key="rsName"
        select-type="radio"
        @radio-click="handleRadioClick"
        :selection-type="props.selectable"
        class="tree-table-top"
      >
        <template slot="icon" slot-scope="scope">
          <li v-if="scope.row.icon !== ''" :class="scope.row.icon"></li>
        </template>
        <template slot="rsType" slot-scope="scope">
          <el-tag v-if="scope.row.rsType === 0">模块</el-tag>
          <el-tag v-if="scope.row.rsType === 1" type="success">菜单</el-tag>
          <el-tag v-if="scope.row.rsType === 2" type="warning">按钮</el-tag>
        </template>
        <template slot="available" slot-scope="scope">
          <el-tag v-if="scope.row.available === 0" type="info">无效</el-tag>
          <el-tag v-if="scope.row.available === 1" type="success">有效</el-tag>
        </template>
        <template slot="op" slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            content="添加资源"
            placement="top"
            :enterable="false"
          >
            <el-button
              type="primary"
              icon="el-icon-plus"
              size="mini"
              @click="showAddDialog(scope.row.id, scope.row.rsName)"
              v-has="'sys:resource:add'"
            ></el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="编辑资源"
            placement="top"
            :enterable="false"
          >
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="showEditDialog(scope.row.id)"
              v-has="'sys:resource:edit'"
            ></el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="删除"
            placement="top"
            :enterable="false"
          >
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="removeById(scope.row.id, scope.row.children)"
              v-has="'sys:resource:delete'"
            ></el-button>
          </el-tooltip>
        </template>
      </tree-table>
    </el-card>

    <!-- 添加资源 -->
    <el-dialog
      title="添加资源"
      :visible.sync="addRsDialogVisible"
      width="40%"
      @close="addDialogClosed"
    >
      <el-form
        :model="addResourceInfoForm"
        :rules="addRules"
        ref="addResourceRef"
      >
        <el-form-item label="上级菜单" label-width="120px">
          <el-input
            autocomplete="off"
            v-model="addResourceInfoForm.parentName"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="菜单类型" label-width="120px">
          <el-radio-group v-model="addResourceInfoForm.rsType">
            <el-radio :label="0">模块</el-radio>
            <el-radio :label="1">菜单</el-radio>
            <el-radio :label="2">按钮</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="菜单名称" label-width="120px" prop="rsName">
          <el-input
            autocomplete="off"
            v-model="addResourceInfoForm.rsName"
            clearable
            placeholder="最多输入50个字"
          ></el-input>
        </el-form-item>
        <el-form-item label="链接地址" label-width="120px" prop="url">
          <el-input
            autocomplete="off"
            v-model="addResourceInfoForm.url"
            clearable
            placeholder="相对路径，如 /resource"
          ></el-input>
        </el-form-item>
        <el-form-item label="权限标识" label-width="120px" prop="permission">
          <el-input
            autocomplete="off"
            v-model="addResourceInfoForm.permission"
            clearable
            placeholder="格式：目录:菜单:操作，如 sys:menu:add "
          ></el-input>
        </el-form-item>
        <el-form-item label="排序号" label-width="120px">
          <el-row>
            <el-col :span="18">
              <el-input
                autocomplete="off"
                v-model="resourceOrder"
                disabled
              ></el-input>
            </el-col>
            <el-col :span="6">
              <el-button-group>
                <el-button icon="el-icon-minus" @click="decrease"></el-button>
                <el-button icon="el-icon-plus" @click="increase"></el-button>
              </el-button-group>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="图标" label-width="120px">
          <el-input
            autocomplete="off"
            v-model="addResourceInfoForm.icon"
            clearable
            placeholder="css样式，如 el-icon-star-on"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态" label-width="120px">
          <el-radio-group v-model="addResourceInfoForm.available">
            <el-radio :label="0">无效</el-radio>
            <el-radio :label="1">有效</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addRsDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addResourceInfoSubmit()"
          >提 交</el-button
        >
      </span>
    </el-dialog>





    <!-- 修改资源 -->
    <el-dialog
      title="修改资源"
      :visible.sync="editRsDialogVisible"
      width="40%"
      @close="editDialogClosed"
    >
      <el-form
        :model="editResourceInfoForm"
        :rules="addRules"
        ref="editResourceRef"
      >
        <el-form-item label="上级菜单" label-width="120px">
          <el-input
            autocomplete="off"
            v-model="editResourceInfoForm.parentName"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="菜单类型" label-width="120px">
          <el-radio-group v-model="editResourceInfoForm.rsType">
            <el-radio :label="0">模块</el-radio>
            <el-radio :label="1">菜单</el-radio>
            <el-radio :label="2">按钮</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="菜单名称" label-width="120px" prop="rsName">
          <el-input
            autocomplete="off"
            v-model="editResourceInfoForm.rsName"
            clearable
            placeholder="最多输入50个字"
          ></el-input>
        </el-form-item>
        <el-form-item label="链接地址" label-width="120px" prop="url">
          <el-input
            autocomplete="off"
            v-model="editResourceInfoForm.url"
            clearable
            placeholder="相对路径，如 /resource"
          ></el-input>
        </el-form-item>
        <el-form-item label="权限标识" label-width="120px" prop="permission">
          <el-input
            autocomplete="off"
            v-model="editResourceInfoForm.permission"
            clearable
            placeholder="格式：目录:菜单:操作，如 sys:menu:add "
          ></el-input>
        </el-form-item>
        <el-form-item label="排序号" label-width="120px">
          <el-row>
            <el-col :span="18">
              <el-input
                autocomplete="off"
                v-model="resourceOrder"
                disabled
              ></el-input>
            </el-col>
            <el-col :span="6">
              <el-button-group>
                <el-button icon="el-icon-minus" @click="decrease"></el-button>
                <el-button icon="el-icon-plus" @click="increase"></el-button>
              </el-button-group>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="图标" label-width="120px">
          <el-input
            autocomplete="off"
            v-model="editResourceInfoForm.icon"
            clearable
            placeholder="css样式，如 el-icon-star-on"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态" label-width="120px">
          <el-radio-group v-model="editResourceInfoForm.available">
            <el-radio :label="0">无效</el-radio>
            <el-radio :label="1">有效</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editRsDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editResourceInfoSubmit()"
          >保 存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      addRules: {
        rsName: [{ required: true, message: "请输入名称", trigger: "blur" }],
      },

      // 集合区
      addResourceInfoForm: {
        parentId: 0,
        parentName: "根目录",
        rsType: 0,
        rsName: "",
        url: "",
        permission: "",
        icon: "",
        available: 1,
        sort: 1,
      },
      editResourceInfoForm: {

      },

      // 变量区
      addRsDialogVisible: false,
      editRsDialogVisible: false,
      resourceOrder: 1,
      props: {
        stripe: true,
        border: true,
        showHeader: true,
        showSummary: false,
        showRowHover: true,
        showIndex: false,
        treeType: true,
        isFold: true,
        expandType: false,
        selectable: false,
      },
      resourceData: [],
      columns: [
        {
          title: "名称",
          key: "rsName",
          width: "300px",
        },
        {
          title: "图标",
          key: "icon",
          type: "template",
          template: "icon",
          width: "60px",
        },
        {
          title: "类型",
          key: "rsType",
          type: "template",
          template: "rsType",
          width: "100px",
        },
        {
          title: "状态",
          key: "available",
          type: "template",
          template: "available",
          width: "100px",
        },
        {
          title: "地址",
          key: "url",
        },
        {
          title: "权限标识",
          key: "permission",
        },
        {
          title: "操作",
          key: "op",
          type: "template",
          template: "op",
          width: "200px",
        },
      ],
    };
  },
  computed: {},
  created() {
    this.loadResourceTree();
  },
  methods: {
    // 公共方法区
    decrease() {
      if (this.resourceOrder > 1) {
        this.resourceOrder--;
      }
    },
    increase() {
      this.resourceOrder++;
    },
    handleRadioClick(row) {
      // this.addResourceInfoForm.parentId = row.row.id;
      // this.addResourceInfoForm.parentName = row.row.rsName;
      // console.log(row);
    },
    async loadResourceTree() {
      const { data: res } = await this.$http.get("resource/find/all/tree");
      if (res.code == 0) {
        this.resourceData = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },

    // 新增业务方法区
    async addResourceInfoSubmit() {
      this.$refs.addResourceRef.validate(async (valid) => {
        if (valid) {
          this.addResourceInfoForm.sort = this.resourceOrder;
          const { data: res } = await this.$http.post(
            "resource/add",
            this.addResourceInfoForm
          );
          if (res.code == 0) {
            this.$message.success("添加成功");
            this.loadResourceTree();
            this.resourceOrder = 1;
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.addRsDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    addDialogClosed() {
      this.addResourceInfoForm.parentId = 0;
      this.addResourceInfoForm.parentName = "根目录";
      this.$refs.addResourceRef.resetFields();
    },
    showAddDialog(id, name) {
      if (id == 0) {
        this.addResourceInfoForm.parentId = 0;
        this.addResourceInfoForm.parentName = '根目录';
      } else {
        this.addResourceInfoForm.parentId = id;
        this.addResourceInfoForm.parentName = name;
      }
      
      this.addRsDialogVisible = true;
    },

    // 编辑业务方法区
    async showEditDialog(id) {
      const { data: res } = await this.$http.get("resource/find/" + id);
      if (res.code == 0) {
        this.editResourceInfoForm = res.data;
        if (this.editResourceInfoForm.parentId == 0) {
          this.editResourceInfoForm.parentName = '根目录';
        }
        this.resourceOrder = this.editResourceInfoForm.sort;
        this.editRsDialogVisible = true;
      } else {
        this.$message.error(res.msg);
      }
    },
    editDialogClosed() {
      this.$refs.editResourceRef.resetFields();
    },
    async editResourceInfoSubmit() {
      this.$refs.editResourceRef.validate(async (valid) => {
        if (valid) {
          this.editResourceInfoForm.sort = this.resourceOrder;
          const { data: res } = await this.$http.post(
            "resource/update",
            this.editResourceInfoForm
          );
          if (res.code == 0) {
            this.$message.success("修改成功");
            this.loadResourceTree();
            this.resourceOrder = 1;
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.editRsDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    async removeById(id, children) {
      if (children.length > 0) {
        this.$message.error('父级资源不能直接删除，请先删除子资源.');
        return false;
      }

      const confirmResult = await this.$confirm("是否确认删除该资源?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.delete("resource/remove/" + id);
      if (res.code == 0) {
        this.$message.success("删除成功！");
        this.loadResourceTree();
      } else {
        return this.$message.error("删除失败！");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.tree-table-top {
  margin-top: 15px;
}
</style>