import { render, staticRenderFns } from "./Zhuodu.vue?vue&type=template&id=67669617&scoped=true&"
import script from "./Zhuodu.vue?vue&type=script&lang=js&"
export * from "./Zhuodu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67669617",
  null
  
)

export default component.exports