<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>智能巡检</el-breadcrumb-item>
      <el-breadcrumb-item>巡检路线</el-breadcrumb-item>
    </el-breadcrumb>

    <el-row>
      <el-card>
        <el-select
            v-model="queryParams.projectCode"
            placeholder="请选择项目"
            clearable
            @change="getTableDataList"
          >
            <el-option
              v-for="item in projectInfoList"
              :key="item.projectCode"
              :label="item.projectName"
              :value="item.projectCode"
            >
            </el-option>
          </el-select>
      </el-card>
    </el-row>
    

    <el-row :gutter="5">
      <el-col :span="8">
        <el-card>
          <div slot="header">
            <span>巡检路线</span>
          </div>

          <!-- 表格数据 -->
          <div class="dataTable" ref="tableBox">
          <el-table :data="tableInfoList" border stripe @row-click="selectDzRow" v-if="tableHeight"
          :max-height="tableHeight + 'px'">
            <el-table-column type="index"></el-table-column>
            <el-table-column label="路线名称" prop="lineName" width="100px"></el-table-column>
            <el-table-column label="备注" prop="remark"></el-table-column>
          </el-table>

          <!-- 分页组件 -->
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryParams.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="queryParams.pageSize"
            layout="total, sizes, prev, pager, next"
            :total="total"
            style="right:0;bottom:0;position:absolute;"
          >
          </el-pagination>
          </div>
        </el-card>
      </el-col>
      <el-col :span="16">
        <el-card>
          <div slot="header">
            <span>巡检项</span>
          </div>
          <div class="select-gnss">
            <el-table :data="checkItemList" border stripe v-if="tableHeight"
              :max-height="tableHeight + 'px'">
              <el-table-column type="index"></el-table-column>
              <el-table-column label="所属路线" prop="lineName" width="180px"></el-table-column>
              <el-table-column label="顺序" prop="lineSort" width="60px"></el-table-column>
              <el-table-column label="巡检项名" prop="itemName" width="180px"></el-table-column>
              <el-table-column label="巡检内容" prop="checkContent"></el-table-column>
            </el-table>
            
            <!-- 分页组件 -->
            <el-pagination
              @size-change="handleItemSizeChange"
              @current-change="handleItemCurrentChange"
              :current-page="queryItemParams.pageNo"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="queryItemParams.pageSize"
              layout="total, sizes, prev, pager, next"
              :total="totalItem"
              style="right:0;bottom:0;position:absolute;"
            >
            </el-pagination>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableInfoList: [],
      queryParams: {
        projectCode: "",
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      queryItemParams: {
        projectCode: "",
        typeCode: "",
        lineCode: "",
        pageNo: 1,
        pageSize: 10,
      },
      totalItem: 0,
      projectInfoList: [],
      currentSelectRow: {},
      tableHeight:0,
      checkItemList: [],
    }
  },
  created() {
    this.getAllProject();
  },
  mounted(){
    this.tableHeight = this.$refs.tableBox.clientHeight - 40;
  },
  methods: {
    async getAllProject() {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        this.projectInfoList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    handleSizeChange(newSize) {
      this.queryParams.pageSize = newSize;
      this.getTableDataList();
    },
    handleCurrentChange(newPage) {
      this.queryParams.pageNo = newPage;
      this.getTableDataList();
    },
    handleItemSizeChange(newSize) {
      this.queryItemParams.pageSize = newSize;
      this.getCheckItemList();
    },
    handleItemCurrentChange(newPage) {
      this.queryItemParams.pageNo = newPage;
      this.getCheckItemList();
    },
    async getTableDataList() {
      const { data: res } = await this.$http.get("check/line/find/byPage", {
        params: this.queryParams
      });
      if (res.code == 0) {
        if (res.data == null) {
          this.tableInfoList = [];
          return;
        }
        this.tableInfoList = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.error(res.msg);
      }
    },
    selectDzRow(row, column, event) {
      this.currentSelectRow = row;
      this.getCheckItemList();
    },
    async getCheckItemList() {
      this.queryItemParams.projectCode = this.queryParams.projectCode;
      this.queryItemParams.lineCode = this.currentSelectRow.lineCode;
      const { data: res } = await this.$http.get("check/item/find/byline", {
        params: this.queryItemParams
      });
      if (res.code == 0) {
        if (res.data == null) {
          this.checkItemList = [];
          return;
        }
        this.checkItemList = res.data.list;
        this.totalItem = res.data.total;
      } else {
        this.$message.error(res.msg);
      }
    }
  }
}
</script>
  
<style lang="less" scoped>
    .dataTable {
      width: 100%;
      height: calc(100vh - 270px);
      position: relative;
    }
  
    .select-gnss {
      height: calc(100vh - 270px);
      position: relative;
    }
  </style>