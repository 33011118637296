<template>
  <!-- 测量设置 -->
  <div>
    <el-row>
      <el-col :span="8">
        <el-button
          type="primary"
          @click="showAddDialog"
          v-has="'sys:device:qz:cl:add'"
          icon="el-icon-plus"
          >添加设置</el-button
        >
      </el-col>
    </el-row>

    <!-- 表格数据 -->
      <el-table :data="tableInfoList" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column label="测站自检" prop="isCzzj">
          <template slot-scope="scope">
            <span v-if="scope.row.isCzzj == 1">开启</span>
            <span v-else>关闭</span>
          </template>
        </el-table-column>
        <el-table-column label="测站自检数" prop="czZj" width="130px"></el-table-column>
        <el-table-column label="更新学习点" prop="isXxd" width="130px">
          <template slot-scope="scope">
            <span v-if="scope.row.isXxd == 1">开启</span>
            <span v-else>关闭</span>
          </template>
        </el-table-column>
        <el-table-column label="自动关机" prop="isGuanji">
          <template slot-scope="scope">
            <span v-if="scope.row.isGuanji == 1">开启</span>
            <span v-else>关闭</span>
          </template>
        </el-table-column>
        <el-table-column label="遮挡重试" prop="isZdCs">
          <template slot-scope="scope">
            <span v-if="scope.row.isZdCs == 1">开启</span>
            <span v-else>关闭</span>
          </template>
        </el-table-column>
        <el-table-column label="遮挡暂停时间（秒）" prop="zdZt" width="130px"></el-table-column>
        <el-table-column label="遮挡重试次数" prop="zdCs" width="130px"></el-table-column>
        <el-table-column label="失败重试" prop="isSbCs">
          <template slot-scope="scope">
            <span v-if="scope.row.isSbCs == 1">开启</span>
            <span v-else>关闭</span>
          </template>
        </el-table-column>
        <el-table-column label="失败重试次数" prop="sbCs" width="130px"></el-table-column>
        <el-table-column label="超限重试次数" prop="cxCs" width="130px"></el-table-column>
        <el-table-column label="创建时间" prop="createTime" width="150px"></el-table-column>
        <!-- <el-table-column label="操作" width="120px" fixed="right">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="修改"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                @click="showEditDialog(scope.row.id)"
                v-has="'sys:device:qz:cl:edit'"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                v-has="'sys:device:qz:cl:delete'"
                @click="removeById(scope.row.id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column> -->
      </el-table>

      <!-- 分页组件 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>



      <!-- 添加设置 -->
      <el-dialog
        title="添加测量设置"
        :visible.sync="addDialogVisible"
        width="50%"
        v-if="addDialogVisible"
        @close="addDialogClosed"
      >
        <div style="height:350px; overflow: auto;">
        <el-form :model="dataFormObj" :rules="formRules" ref="addInfoRef" label-width="120px">
          <el-form-item label="所属项目" prop="projectCode">
            <el-select
              v-model="dataFormObj.projectCode"
              placeholder="请选择项目"
              clearable
            >
              <el-option
                v-for="item in projectInfoList"
                :key="item.projectCode"
                :label="item.projectName"
                :value="item.projectCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-divider content-position="left">测站检查</el-divider>
          <el-row>
            <el-col :span="8">
              <el-form-item label="" prop="isCzzj" label-width="10px">
                <el-radio-group v-model="dataFormObj.isCzzj">
                  <el-radio :label="1">开启</el-radio>
                  <el-radio :label="0">关闭</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              每周期开始前进行个
              <el-input
                    autocomplete="off"
                    v-model="dataFormObj.czZj"
                    clearable
                    class="numInput"
                  ></el-input>
              测回后方交会的测站自检。
            </el-col>
          </el-row>
          <el-divider content-position="left">监测点更新</el-divider>
          <el-row>
            <el-col :span="8">
              <el-form-item label="" prop="isXxd" label-width="10px">
                <el-radio-group v-model="dataFormObj.isXxd">
                  <el-radio :label="1">开启</el-radio>
                  <el-radio :label="0">关闭</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              测量完成后是否更新学习点数据。
            </el-col>
          </el-row>
          <el-divider content-position="left">自动关机</el-divider>
          <el-row>
            <el-col :span="8">
              <el-form-item label="" prop="isGuanji" label-width="10px">
                <el-radio-group v-model="dataFormObj.isGuanji">
                  <el-radio :label="1">开启</el-radio>
                  <el-radio :label="0">关闭</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              周期结束关闭全站仪。
            </el-col>
          </el-row>
          <el-divider content-position="left">目标被遮挡时的动作</el-divider>
          <el-row>
            <el-col :span="8">
              <el-form-item label="" prop="isZdCs" label-width="10px">
                <el-radio-group v-model="dataFormObj.isZdCs">
                  <el-radio :label="1">开启</el-radio>
                  <el-radio :label="0">关闭</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              暂停测量
              <el-input
                    autocomplete="off"
                    v-model="dataFormObj.zdZt"
                    clearable
                    class="numInput"
                  ></el-input>
              秒，后重新测量该点，最多尝试
              <el-input
                    autocomplete="off"
                    v-model="dataFormObj.zdCs"
                    clearable
                    class="numInput"
                  ></el-input>
              次。
            </el-col>
          </el-row>
          <el-divider content-position="left">测量失败动作</el-divider>
          <el-row>
            <el-col :span="8">
              <el-form-item label="" prop="isSbCs" label-width="10px">
                <el-radio-group v-model="dataFormObj.isSbCs">
                  <el-radio :label="1">开启</el-radio>
                  <el-radio :label="0">关闭</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              测量如果失败，则重测该点，最多尝试
              <el-input
                    autocomplete="off"
                    v-model="dataFormObj.sbCs"
                    clearable
                    class="numInput"
                  ></el-input>次。
            </el-col>
          </el-row>
          <el-divider content-position="left">检查测量数据是否超限</el-divider>
          <el-row>
            <el-col :span="24">
              如果测量的数据超限，则重新测量该点组，最多尝试
              <el-input
                    autocomplete="off"
                    v-model="dataFormObj.cxCs"
                    clearable
                    class="numInput"
                  ></el-input>次。
            </el-col>
          </el-row>
        </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addInfoSubmit()">提 交</el-button>
        </span>
      </el-dialog>



      <!-- 编辑测量设置 -->
      <el-dialog
        title="编辑测量设置"
        :visible.sync="editDialogVisible"
        width="50%"
        v-if="editDialogVisible"
      >
        <div style="height:350px; overflow: auto;">
        <el-form :model="dataFormObj" :rules="formRules" ref="editInfoRef" label-width="130px">
          <el-form-item label="所属项目" prop="projectCode">
            <el-select
              v-model="dataFormObj.projectCode"
              placeholder="请选择项目"
              clearable
            >
              <el-option
                v-for="item in projectInfoList"
                :key="item.projectCode"
                :label="item.projectName"
                :value="item.projectCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-divider content-position="left">测站检查</el-divider>
          <el-row>
            <el-col :span="8">
              <el-form-item label="" prop="isCzzj" label-width="10px">
                <el-radio-group v-model="dataFormObj.isCzzj">
                  <el-radio :label="1">开启</el-radio>
                  <el-radio :label="0">关闭</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              每周期开始前进行个
              <el-input
                    autocomplete="off"
                    v-model="dataFormObj.czZj"
                    clearable
                    class="numInput"
                  ></el-input>
              测回后方交会的测站自检。
            </el-col>
          </el-row>
          <el-divider content-position="left">监测点更新</el-divider>
          <el-row>
            <el-col :span="8">
              <el-form-item label="" prop="isXxd" label-width="10px">
                <el-radio-group v-model="dataFormObj.isXxd">
                  <el-radio :label="1">开启</el-radio>
                  <el-radio :label="0">关闭</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              测量完成后是否更新学习点数据。
            </el-col>
          </el-row>
          <el-divider content-position="left">自动关机</el-divider>
          <el-row>
            <el-col :span="8">
              <el-form-item label="" prop="isGuanji" label-width="10px">
                <el-radio-group v-model="dataFormObj.isGuanji">
                  <el-radio :label="1">开启</el-radio>
                  <el-radio :label="0">关闭</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              周期结束关闭全站仪。
            </el-col>
          </el-row>
          <el-divider content-position="left">目标被遮挡时的动作</el-divider>
          <el-row>
            <el-col :span="8">
              <el-form-item label="" prop="isZdCs" label-width="10px">
                <el-radio-group v-model="dataFormObj.isZdCs">
                  <el-radio :label="1">开启</el-radio>
                  <el-radio :label="0">关闭</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              暂停测量
              <el-input
                    autocomplete="off"
                    v-model="dataFormObj.zdZt"
                    clearable
                    class="numInput"
                  ></el-input>
              秒，后重新测量该点，最多尝试
              <el-input
                    autocomplete="off"
                    v-model="dataFormObj.zdCs"
                    clearable
                    class="numInput"
                  ></el-input>
              次。
            </el-col>
          </el-row>
          <el-divider content-position="left">测量失败动作</el-divider>
          <el-row>
            <el-col :span="8">
              <el-form-item label="" prop="isSbCs" label-width="10px">
                <el-radio-group v-model="dataFormObj.isSbCs">
                  <el-radio :label="1">开启</el-radio>
                  <el-radio :label="0">关闭</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              测量如果失败，则重测该点，最多尝试
              <el-input
                    autocomplete="off"
                    v-model="dataFormObj.sbCs"
                    clearable
                    class="numInput"
                  ></el-input>次。
            </el-col>
          </el-row>
          <el-divider content-position="left">检查测量数据是否超限</el-divider>
          <el-row>
            <el-col :span="24">
              如果测量的数据超限，则重新测量该点组，最多尝试
              <el-input
                    autocomplete="off"
                    v-model="dataFormObj.cxCs"
                    clearable
                    class="numInput"
                  ></el-input>次。
            </el-col>
          </el-row>
        </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="editInfoSubmit()">提 交</el-button>
        </span>
      </el-dialog>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  data() {
    return {
      addDialogVisible: false,
      editDialogVisible: false,
      tableInfoList: [],
      queryParams: {
        projectCode: "",
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      formRules: {
        projectCode: [{ required: true, message: "请选择项目", trigger: "blur" }],
      },
      dataFormObj: {},
      projectInfoList: [],
    };
  },
  created() {
    this.getAllProject();
    this.getTableDataList();
  },
  methods: {
    async getAllProject() {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        this.projectInfoList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    handleSizeChange(newSize) {
      this.queryParams.pageSize = newSize;
      this.getTableDataList();
    },
    handleCurrentChange(newPage) {
      this.queryParams.pageNo = newPage;
      this.getTableDataList();
    },
    projectCodeChange(projectCode) {
      this.queryParams.projectCode = projectCode;
      this.getTableDataList();
    },
    async getTableDataList() {
      const { data: res } = await this.$http.get("device/qz/clsz/find/page", {
        params: this.queryParams
      });
      if (res.code == 0) {
        if (res.data == null) {
          this.tableInfoList = [];
          this.total = 0;
          return;
        }
        this.tableInfoList = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.error(res.msg);
      }
    },
    showAddDialog() {
      this.dataFormObj = {};
      this.addDialogVisible = true;
    },
    addDialogClosed() {
      this.$refs.addInfoRef.resetFields();
    },
    async addInfoSubmit() {
      this.$refs.addInfoRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "device/qz/clsz/add",
            this.dataFormObj
          );
          if (res.code == 0) {
            this.$message.success("添加成功");
            this.getTableDataList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.addDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    async showEditDialog(id) {
      this.dataFormObj = {};
      const { data: res } = await this.$http.get("device/qz/clsz/find/byid/" + id);
      if (res.code == 0) {
        this.dataFormObj = res.data;
        this.editDialogVisible = true;
      } else {
        this.$message.error(res.msg);
      }
    },
    async editInfoSubmit() {
      this.$refs.editInfoRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "device/qz/clsz/update",
            this.dataFormObj
          );
          if (res.code == 0) {
            this.$message.success("修改成功");
            this.getTableDataList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.editDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    async removeById(id) {
      const confirmResult = await this.$confirm("是否确认删除该设备?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.delete("device/qz/clsz/remove/" + id);
      if (res.code == 0) {
        this.$message.success("删除成功！");
        this.getTableDataList();
      } else {
        return this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.numInput {
  width: 60px;
}
</style>