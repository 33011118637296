<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>连接配置</el-breadcrumb-item>
      <el-breadcrumb-item>虚拟串口</el-breadcrumb-item>
      <el-breadcrumb-item>串口列表</el-breadcrumb-item>
    </el-breadcrumb>

    <el-row :gutter="5">
      <el-col :span="5">
        <!-- 串口组 -->
        <el-card>
          <el-row :gutter="10">
            <el-col :span="20">
              <el-select
                v-model="comGroupQueryParams.projectCode"
                placeholder="请选择项目"
                clearable
                @clear="getGroupList"
                @change="getGroupList"
              >
                <el-option
                  v-for="item in projectInfoList"
                  :key="item.projectCode"
                  :label="item.projectName"
                  :value="item.projectCode"
                >
                </el-option>
              </el-select>
            </el-col>
            <!-- <el-col :span="10">
              <el-button
                type="primary"
                @click="addComGroupDialogShow()"
                v-has="'sys:device:com:gp:add'"
                icon="el-icon-plus"
                >添加串口组</el-button
              >
            </el-col> -->
          </el-row>

          <el-tree
            :data="comGroupData"
            node-key="id"
            default-expand-all
            :expand-on-click-node="false"
            :props="defaultProps"
            @node-click="loadVirtualComList"
            class="tree-sy"
          >
            <span class="custom-tree-node" slot-scope="{ node }">
              <span>{{ node.label }}</span>
              <!-- <span>
                <el-button 
                  type="text" 
                  size="mini" 
                  v-has="'sys:device:com:gp:edit'"
                  @click="() => editNode(data)">
                  编辑
                </el-button>
                <el-button
                  type="text"
                  size="mini"
                  v-has="'sys:device:com:gp:delete'"
                  @click="() => removeNode(node, data)"
                >
                  删除
                </el-button>
              </span> -->
            </span>
          </el-tree>
        </el-card>
      </el-col>
      <el-col :span="19">
        <!-- 串口列表 -->
        <el-card>
          <!-- <el-button
            type="primary"
            @click="addComDialogShow()"
            v-has="'sys:device:com:add'"
            icon="el-icon-plus"
            >添加串口</el-button
          > -->

          <!-- 表格数据 -->
          <el-table :data="comList" border stripe>
            <el-table-column type="index"></el-table-column>
            <el-table-column label="名称" prop="name"></el-table-column>
            <el-table-column label="串口号" prop="comIndex" width="80px"></el-table-column>
            <el-table-column label="工作模式" prop="workStatus" width="100px">
              <template slot-scope="scope">
                <el-tooltip
                  v-if="scope.row.workStatus === 0"
                  class="item"
                  effect="dark"
                  content="串口服务器以client方式连上边端应用"
                  placement="top"
                  :enterable="false"
                >
                  <el-tag>tcp client</el-tag
                  >
                </el-tooltip>
                <el-tooltip
                  v-else-if="scope.row.workStatus === 1"
                  class="item"
                  effect="dark"
                  content="边端以client的方式连接上串口服务器"
                  placement="top"
                  :enterable="false"
                >
                  <el-tag type="success">tcp server</el-tag
                  >
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="IP" prop="ip" width="120px"></el-table-column>
            <el-table-column label="端口" prop="port" width="80px"></el-table-column>
            <el-table-column label="是否连接" prop="online" width="80px">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.online === 0" type="info"
                  >未连接</el-tag
                >
                <el-tag v-else-if="scope.row.online === 1" type="success"
                  >已连接</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column label="关联设备" width="90px">
              <template slot-scope="scope">
                <el-tag
                  v-if="scope.row.deviceCode === null || scope.row.deviceCode === ''"
                  type="info"
                  >未关联</el-tag
                >
                <el-popover
                  v-else
                  placement="top-start"
                  width="200"
                  trigger="hover"
                  @show="showDeviceInfo(scope.row.deviceCode)"
                  :content="comDeviceInfo">
                  <el-tag slot="reference" type="success">已关联</el-tag>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column label="备注" prop="remark"></el-table-column>
            <!-- <el-table-column label="操作" width="180px">
              <template slot-scope="scope">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="启动连接"
                  placement="top"
                  :enterable="false"
                  v-if="scope.row.online == 0 && scope.row.workStatus == 1"
                >
                  <el-button
                    type="success"
                    icon="el-icon-video-play"
                    @click="connectServer(scope.row.comCode)"
                    v-has="'sys:device:ct:start'"
                  ></el-button>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="关闭连接"
                  placement="top"
                  :enterable="false"
                  v-if="scope.row.online == 1 && scope.row.workStatus == 1"
                >
                  <el-button
                    type="danger"
                    icon="el-icon-video-pause"
                    @click="unconnectServer(scope.row.comCode)"
                    v-has="'sys:device:ct:end'"
                  ></el-button>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="修改"
                  placement="top"
                  :enterable="false"
                >
                  <el-button
                    type="primary"
                    icon="el-icon-edit"
                    @click="showComEditDialog(scope.row.id)"
                    v-has="'sys:device:com:gp:edit'"
                  ></el-button>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="删除"
                  placement="top"
                  :enterable="false"
                >
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    v-has="'sys:device:com:gp:delete'"
                    @click="removeComById(scope.row.id,scope.row.deviceCode)"
                  ></el-button>
                </el-tooltip>
              </template>
            </el-table-column> -->
          </el-table>

          <!-- 分页组件 -->
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="comQueryParams.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="comQueryParams.pageSize"
            layout="total, sizes, prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </el-card>
      </el-col>
    </el-row>

    <!-- 添加串口组 -->
    <el-dialog
      title="添加串口组"
      :visible.sync="addComGroupDialogVisible"
      width="40%"
      @close="addGroupDialogClosed"
    >
      <el-form :model="groupInfoForm" :rules="groupRules" ref="groupInfoRef">
        <el-form-item label="名称" label-width="120px" prop="name">
          <el-input
            autocomplete="off"
            v-model="groupInfoForm.name"
            clearable
            placeholder="最多输入30个字"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" label-width="120px" prop="remark">
          <el-input
            autocomplete="off"
            v-model="groupInfoForm.remark"
            clearable
            placeholder="最多输入50个字"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addComGroupDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addGroupInfo()">提 交</el-button>
      </span>
    </el-dialog>

    <!-- 编辑串口组 -->
    <el-dialog
      title="编辑串口组"
      :visible.sync="editComGroupDialogVisible"
      width="40%"
      @close="editGroupDialogClosed"
    >
      <el-form :model="groupInfoForm" :rules="groupRules" ref="groupEditInfoRef">
        <el-form-item label="名称" label-width="120px" prop="name">
          <el-input
            autocomplete="off"
            v-model="groupInfoForm.name"
            clearable
            placeholder="最多输入30个字"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" label-width="120px" prop="remark">
          <el-input
            autocomplete="off"
            v-model="groupInfoForm.remark"
            clearable
            placeholder="最多输入50个字"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editComGroupDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editGroupInfo()">保 存</el-button>
      </span>
    </el-dialog>

    <!-- 添加串口 -->
    <el-dialog
      title="添加串口"
      :visible.sync="addComDialogVisible"
      width="40%"
      @close="addComDialogClosed"
    >
      <el-form :model="comInfoForm" :rules="comRules" ref="comInfoRef">
        <el-form-item label="所属串口组" label-width="120px">
          {{comQueryParams.groupName}}
        </el-form-item>
        <el-form-item label="名称" label-width="120px" prop="name">
          <el-input
            autocomplete="off"
            v-model="comInfoForm.name"
            clearable
            placeholder="最多输入30个字"
          ></el-input>
        </el-form-item>
        <el-form-item label="串口号" label-width="120px" prop="comIndex">
            <el-select v-model="comInfoForm.comIndex" placeholder="请选择串口号" clearable>
              <el-option label="COM1" value="COM1"></el-option>
              <el-option label="COM2" value="COM2"></el-option>
              <el-option label="COM3" value="COM3"></el-option>
              <el-option label="COM4" value="COM4"></el-option>
              <el-option label="COM5" value="COM5"></el-option>
              <el-option label="COM6" value="COM6"></el-option>
              <el-option label="COM7" value="COM7"></el-option>
              <el-option label="COM8" value="COM8"></el-option>
            </el-select><br/>
            <span style="color: rgb(175, 175, 175);">
              对应串口服务器的串口号
            </span>
        </el-form-item>
        <el-form-item label="工作模式" label-width="120px" prop="workStatus">
            <el-select v-model="comInfoForm.workStatus" placeholder="请选择工作模式" clearable>
              <el-option label="tcp client" value="0"></el-option>
              <el-option label="tcp server" value="1"></el-option>
            </el-select><br/>
            <span style="color: rgb(175, 175, 175);">
              选择tcp client模式时边端作为服务器；<br/>
              选择tcp server时边端作为客户端</span>
        </el-form-item>
        <el-form-item label="IP" label-width="120px" prop="ip">
          <el-input
            autocomplete="off"
            v-model="comInfoForm.ip"
            clearable
            placeholder="如：192.168.1.100"
          ></el-input>
        </el-form-item>
        <el-form-item label="端口" label-width="120px" prop="port">
          <el-input
            autocomplete="off"
            v-model="comInfoForm.port"
            clearable
            placeholder="如：9000"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" label-width="120px" prop="remark">
          <el-input
            autocomplete="off"
            v-model="comInfoForm.remark"
            clearable
            placeholder="最多输入50个字"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addComDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addComInfoSubmit()">提 交</el-button>
      </span>
    </el-dialog>

    <!-- 编辑串口 -->
    <el-dialog
      title="编辑串口"
      :visible.sync="editComDialogVisible"
      width="40%"
      @close="editComDialogClosed"
    >
      <el-form :model="comInfoForm" :rules="comRules" ref="comEditInfoRef">
        <el-form-item label="名称" label-width="120px" prop="name">
          <el-input
            autocomplete="off"
            v-model="comInfoForm.name"
            clearable
            placeholder="最多输入30个字"
          ></el-input>
        </el-form-item>
        <el-form-item label="串口号" label-width="120px" prop="comIndex">
            <el-select v-model="comInfoForm.comIndex" placeholder="请选择串口号" clearable>
              <el-option label="COM1" value="COM1"></el-option>
              <el-option label="COM2" value="COM2"></el-option>
              <el-option label="COM3" value="COM3"></el-option>
              <el-option label="COM4" value="COM4"></el-option>
              <el-option label="COM5" value="COM5"></el-option>
              <el-option label="COM6" value="COM6"></el-option>
              <el-option label="COM7" value="COM7"></el-option>
              <el-option label="COM8" value="COM8"></el-option>
            </el-select><br/>
            <span style="color: rgb(175, 175, 175);">
              对应串口服务器的串口号
            </span>
        </el-form-item>
        <el-form-item label="工作模式" label-width="120px" prop="workStatus">
            <el-select v-model="comInfoForm.workStatus" placeholder="请选择工作模式" clearable>
              <el-option label="tcp client" :value="0"></el-option>
              <el-option label="tcp server" :value="1"></el-option>
            </el-select><br/>
            <span style="color: rgb(175, 175, 175);">
              选择tcp client模式时边端作为服务器；<br/>
              选择tcp server时边端作为客户端</span>
        </el-form-item>
        <el-form-item label="IP" label-width="120px" prop="ip">
          <el-input
            autocomplete="off"
            v-model="comInfoForm.ip"
            clearable
            placeholder="如：192.168.1.100"
          ></el-input>
        </el-form-item>
        <el-form-item label="端口" label-width="120px" prop="port">
          <el-input
            autocomplete="off"
            v-model="comInfoForm.port"
            clearable
            placeholder="如：9000"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" label-width="120px" prop="remark">
          <el-input
            autocomplete="off"
            v-model="comInfoForm.remark"
            clearable
            placeholder="最多输入50个字"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editComDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editComInfoSubmit()">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      groupRules: {
        name: [
          { required: true, message: "请输入名字", trigger: "blur" }
        ],
      },
      comRules: {
        name: [{ required: true, message: "请输入名字", trigger: "blur" }],
        comIndex: [{ required: true, message: "请输入串口号", trigger: "blur" }],
        workStatus: [{ required: true, message: "请输入工作模式", trigger: "blur" }],
        ip: [{ required: true, message: "请输入IP", trigger: "blur" }],
        port: [{ required: true, message: "请输入端口", trigger: "blur" }],
      },
      comGroupQueryParams: {
        projectCode: "",
      },
      comQueryParams: {
        groupCode: "",
        groupName: "",
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      projectInfoList: [],
      comGroupData: [],
      addComGroupDialogVisible: false,
      editComGroupDialogVisible: false,
      addComDialogVisible: false,
      editComDialogVisible: false,
      orgInfoList: [],
      comList: [],
      groupInfoForm: {
        name: "",
        remark: "",
        projectCode: ""
      },
      comInfoForm: {},
      comDeviceInfo: "",
    };
  },
  created() {
    this.getAllProject();
  },
  methods: {
    async getAllProject() {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        this.projectInfoList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getGroupList() {
      const { data: res } = await this.$http.get("virtual/com/find/group/list", {
        params: this.comGroupQueryParams
      });
      if (res.code == 0) {
        this.comGroupData = res.data;
        this.comQueryParams.groupCode = "";
      } else {
        this.$message.error(res.msg);
      }
      this.comList = [];
    },
    addComGroupDialogShow() {
      if (this.comGroupQueryParams.projectCode.length == 0) {
          this.$message.error('请先选择一个项目');
          return false;
      }
      this.groupInfoForm = {};
      this.addComGroupDialogVisible = true;
    },
    async editNode(data) {
      const { data: res } = await this.$http.get("virtual/com/find/group/id/" + data.id);
      if (res.code == 0) {
        this.groupInfoForm = res.data;
        this.editComGroupDialogVisible = true;
      } else {
        this.$message.error(res.msg);
      }
    },

    async removeNode(node, data) {
      const confirmResult = await this.$confirm("是否确认删除该分组?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.delete("virtual/com/remove/group/" + data.id);
      if (res.code == 0) {
        this.$message.success("删除成功！");
        this.getGroupList();
      } else {
        return this.$message.error(res.msg);
      }
    },
    loadVirtualComList(data) {
      this.comQueryParams.groupCode = data.groupCode;
      this.comQueryParams.groupName = data.name;
      this.getVirtualComList();
    },
    async getVirtualComList() {
      const { data: res } = await this.$http.get("virtual/com/find/page", {
        params: this.comQueryParams
      });
      if (res.code == 0) {
        this.comList = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.error(res.msg);
      }
    },
    addComDialogShow() {
      if(this.comQueryParams.groupCode.length == 0
        || this.comGroupQueryParams.projectCode.length == 0) {
        this.$message.error('请先选择一个串口组');
        return false;
      }
      this.comInfoForm = {};
      this.addComDialogVisible = true;
    },
    addComDialogClosed() {
      this.$refs.comInfoRef.resetFields();
    },
    addComInfoSubmit() {
      this.comInfoForm.comGroupCode = this.comQueryParams.groupCode;
      this.comInfoForm.projectCode = this.comGroupQueryParams.projectCode;
      this.$refs.comInfoRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "virtual/com/add",
            this.comInfoForm
          );
          if (res.code == 0) {
            this.$message.success("添加成功");
            this.getVirtualComList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.addComDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    async showComEditDialog(id) {
      const { data: res } = await this.$http.get("virtual/com/find/byid/" + id);
      if (res.code == 0) {
        this.comInfoForm = res.data;
        this.editComDialogVisible = true;
      } else {
        this.$message.error(res.msg);
      }
    },
    editComDialogClosed() {
      // this.$refs.comEditInfoRef.resetFields();
    },
    async editComInfoSubmit() {
      this.$refs.comEditInfoRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "virtual/com/update",
            this.comInfoForm
          );
          if (res.code == 0) {
            this.$message.success("修改成功");
            this.getVirtualComList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.editComDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    async removeComById(id, deviceCode) {
      if (deviceCode != null && deviceCode.length > 0) {
        return this.$message.error("先解除与设备的绑定！");
        return false;
      }
      const confirmResult = await this.$confirm("是否确认删除该串口?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.delete("virtual/com/remove/" + id);
      if (res.code == 0) {
        this.$message.success("删除成功！");
        this.getVirtualComList();
      } else {
        return this.$message.error("删除失败！");
      }
    },
    handleSizeChange(newSize) {
      this.comQueryParams.pageSize = newSize;
      this.getVirtualComList();
    },
    handleCurrentChange(newPage) {
      this.comQueryParams.pageNo = newPage;
      this.getVirtualComList();
    },
    addGroupDialogClosed() {
      this.$refs.groupInfoRef.resetFields();
    },
    addGroupInfo() {
      if (this.comGroupQueryParams.projectCode.length == 0) {
          this.$message.error('请先选择一个项目');
          return false;
      }

      this.$refs.groupInfoRef.validate(async (valid) => {
        if (valid) {
          this.groupInfoForm.projectCode = this.comGroupQueryParams.projectCode;
          const { data: res } = await this.$http.post(
            "virtual/com/add/group",
            this.groupInfoForm
          );
          if (res.code == 0) {
            this.$message.success("添加成功");
            this.getGroupList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.addComGroupDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    editGroupDialogClosed() {
      // this.$refs.groupEditInfoRef.resetFields();
    },
    async editGroupInfo() {
      this.$refs.groupEditInfoRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "virtual/com/update/group",
            this.groupInfoForm
          );
          if (res.code == 0) {
            this.$message.success("修改成功");
            this.getGroupList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.editComGroupDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    async showDeviceInfo(deviceCode) {
      const { data: res } = await this.$http.get("device/find/bycode", {
        params: {
          deviceCode: deviceCode
        },
        showLoading: false
      });
      if (res.code == 0) {
        this.comDeviceInfo = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async connectServer(comCode) {
      const { data: res } = await this.$http.get("virtual/com/connect/server/" + comCode);
      if (res.code == 0) {
        this.$message.success("启动成功，稍后再查看状态！");
        this.getVirtualComList();
      } else {
        return this.$message.error(res.msg);
      }
    },
    async unconnectServer(comCode) {
      const confirmResult = await this.$confirm("是否确认关闭该连接吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消操作");
      }
      const { data: res } = await this.$http.get("virtual/com/unconnect/server/" + comCode);
      if (res.code == 0) {
        this.$message.success("关闭成功！");
        this.getVirtualComList();
      } else {
        return this.$message.error(res.msg);
      }
    }
  },
};
</script>

<style lang="less" scoped>
.tree-sy {
  margin-top: 15px;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>