<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>组织管理</el-breadcrumb-item>
      <el-breadcrumb-item>组织列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图 -->
    <el-card>
      <el-row :gutter="10">
        <el-col :span="5">
          <el-input
            placeholder="请输入组织名称"
            v-model="queryParams.queryStr"
            clearable
            @clear="getOrgList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getOrgList"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="5">
          <el-button 
            type="primary" 
            @click="showAddDialg" 
            v-has="'sys:org:add'"
            icon="el-icon-plus"
            >添加组织</el-button
          >
        </el-col>
      </el-row>

      <!-- 表格数据 -->
      <el-table :data="orgList" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column label="组织名称" prop="orgName"></el-table-column>
        <el-table-column label="企业类型" prop="industrycharacterno">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.industrycharacterno === 'C5001'">国有</el-tag>
            <el-tag v-else-if="scope.row.industrycharacterno === 'C5002'">集体</el-tag>
            <el-tag v-else-if="scope.row.industrycharacterno === 'C5003'">民营</el-tag>
            <el-tag v-else-if="scope.row.industrycharacterno === 'C5004'">私营</el-tag>
            <el-tag v-else-if="scope.row.industrycharacterno === 'C5005'">合资</el-tag>
            <el-tag v-else-if="scope.row.industrycharacterno === 'C5006'">独资</el-tag>
            <el-tag v-else-if="scope.row.industrycharacterno === 'C5099'">其他</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="企业所在地" prop="unitadress"></el-table-column>
        <el-table-column label="法人代表" prop="unitperson"></el-table-column>
        <el-table-column label="法人电话" prop="unitpersontel"></el-table-column>
        <el-table-column label="是否有效" width="80px">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.availableBl"
              @change="orgStateChanged(scope.row)"
              v-has="'sys:org:edit'"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="remark"></el-table-column>
        <el-table-column label="创建时间" prop="createTime" width="160px"></el-table-column>
        <el-table-column label="操作" width="140px">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="修改"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                @click="showEditDialog(scope.row.id)"
                v-has="'sys:org:edit'"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                v-has="'sys:org:delete'"
                @click="removeOrgById(scope.row.id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页组件 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>

      <!-- 添加组织 -->
      <el-dialog
        title="添加组织"
        :visible.sync="addOrgDialogVisible"
        width="40%"
        @close="addDialogClosed"
      >
        <el-form :model="orgInfoForm" :rules="rules" ref="orgInfoRef">
          <el-form-item label="组织名称" label-width="120px" prop="orgName">
            <el-input
              autocomplete="off"
              v-model="orgInfoForm.orgName"
              clearable
              placeholder="最多输入50个字"
            ></el-input>
          </el-form-item>
          <el-form-item label="企业类型" label-width="120px" prop="industrycharacterno">
            <el-select v-model="orgInfoForm.industrycharacterno" placeholder="请选择企业类型" clearable>
              <el-option label="国有" value="C5001"></el-option>
              <el-option label="集体" value="C5002"></el-option>
              <el-option label="民营" value="C5003"></el-option>
              <el-option label="私营" value="C5004"></el-option>
              <el-option label="合资" value="C5005"></el-option>
              <el-option label="独资" value="C5006"></el-option>
              <el-option label="其他" value="C5099"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="企业所在地" label-width="120px" prop="unitadress">
            <el-input
              autocomplete="off"
              v-model="orgInfoForm.unitadress"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="法人代表" label-width="120px" prop="unitperson">
            <el-input
              autocomplete="off"
              v-model="orgInfoForm.unitperson"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="法人电话" label-width="120px" prop="unitpersontel">
            <el-input
              autocomplete="off"
              v-model="orgInfoForm.unitpersontel"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="备注" label-width="120px" prop="remark">
            <el-input
              autocomplete="off"
              v-model="orgInfoForm.remark"
              clearable
              placeholder="最多输入200个字"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addOrgDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addOrgInfo()">提 交</el-button>
        </span>
      </el-dialog>

      <!-- 修改组织 -->
      <el-dialog
        title="修改组织"
        :visible.sync="editOrgDialogVisible"
        width="40%"
        @close="editDialogClosed"
      >
        <el-form :model="orgEditInfoForm" :rules="rules" ref="orgEditInfoRef">
          <el-form-item label="组织名称" label-width="120px" prop="orgName">
            <el-input
              autocomplete="off"
              v-model="orgEditInfoForm.orgName"
              clearable
              placeholder="最多输入50个字"
            ></el-input>
          </el-form-item>
          <el-form-item label="企业类型" label-width="120px" prop="industrycharacterno">
            <el-select v-model="orgEditInfoForm.industrycharacterno" placeholder="请选择企业类型" clearable>
              <el-option label="国有" value="C5001"></el-option>
              <el-option label="集体" value="C5002"></el-option>
              <el-option label="民营" value="C5003"></el-option>
              <el-option label="私营" value="C5004"></el-option>
              <el-option label="合资" value="C5005"></el-option>
              <el-option label="独资" value="C5006"></el-option>
              <el-option label="其他" value="C5099"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="企业所在地" label-width="120px" prop="unitadress">
            <el-input
              autocomplete="off"
              v-model="orgEditInfoForm.unitadress"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="法人代表" label-width="120px" prop="unitperson">
            <el-input
              autocomplete="off"
              v-model="orgEditInfoForm.unitperson"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="法人电话" label-width="120px" prop="unitpersontel">
            <el-input
              autocomplete="off"
              v-model="orgEditInfoForm.unitpersontel"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="备注" label-width="120px" prop="remark">
            <el-input
              autocomplete="off"
              v-model="orgEditInfoForm.remark"
              clearable
              placeholder="最多输入200个字"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editOrgDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="editOrgInfo()">保 存</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    var addOrgNameExist = async (rule, value, callback) => {
      const { data: res } = await this.$http.get('org/name/exist', {
        params: {
          orgName: value,
          orgId: this.orgId
        }
      });
      if (res.code == 0) {
        // 未被使用时返回0
        if (res.data == 0) {
          return callback()
        }
      }

      // 返回一个错误提示
      callback(new Error('名称已经被使用'))
    }
    return {
      rules: {
        orgName: [
          { required: true, message: "请输入组织名称", trigger: "blur" },
          { validator: addOrgNameExist, trigger: 'blur' },
          {
            min: 2,
            max: 50,
            message: "最多只能输入50个字",
            trigger: "blur",
          },
        ],
        remark: [
          {
            min: 0,
            max: 200,
            message: "最多只能输入200个字",
            trigger: "blur",
          },
        ],
        industrycharacterno: [{ required: true, message: "请输入企业类型", trigger: "blur" }],
        unitadress: [{ required: true, message: "请输入企业所在地", trigger: "blur" }],
        unitperson: [{ required: true, message: "请输入法人代表", trigger: "blur" }],
        unitpersontel: [{ required: true, message: "请输入法人电话", trigger: "blur" }],
      },
      queryParams: {
        queryStr: "",
        pageNo: 1,
        pageSize: 10,
      },
      orgInfoForm: {
        orgName: "",
        remark: "",
        industrycharacterno: "",
        unitadress: "",
        unitperson: "",
        unitpersontel: "",
      },
      orgEditInfoForm: {},
      orgList: [],
      total: 0,
      addOrgDialogVisible: false,
      editOrgDialogVisible: false,
      orgId: 0,
    };
  },
  created() {
    this.getOrgList();
  },
  methods: {
    async getOrgList() {
      const { data: res } = await this.$http.get("org/find/byPage", {
        params: this.queryParams,
      });
      if (res.code == 0) {
        this.orgList = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.error(res.msg);
      }
    },
    handleSizeChange(newSize) {
      this.queryParams.pageSize = newSize;
      this.getOrgList();
    },
    handleCurrentChange(newPage) {
      this.queryParams.pageNo = newPage;
      this.getOrgList();
    },
    async orgStateChanged(orgInfo) {
      const confirmResult = await this.$confirm(
        "此操作将影响组织用户相关功能, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        this.$message.info("已取消操作");
        orgInfo.availableBl = !orgInfo.availableBl;
        return false;
      }

      const { data: res } = await this.$http.get("org/change/status", {
        params: {
          orgId: orgInfo.id,
          available: orgInfo.availableBl
        }
      });

      if (res.code == 0) {
        this.$message.success("修改成功");
      } else {
        this.$message.error(res.msg);
      }
    },
    addOrgInfo() {
      this.$refs.orgInfoRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "org/add",
            this.orgInfoForm
          );
          if (res.code == 0) {
            this.$message.success("添加成功");
            this.getOrgList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.addOrgDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    addDialogClosed() {
      this.$refs.orgInfoRef.resetFields();
    },
    async removeOrgById(id) {
      const confirmResult = await this.$confirm("是否确认删除该组织?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.delete("org/remove/" + id);
      if (res.code == 0) {
        this.$message.success("删除组织成功！");
        this.getOrgList();
      } else {
        return this.$message.error("删除组织失败！");
      }
    },
    editDialogClosed() {
      this.$refs.orgEditInfoRef.resetFields();
    },
    editOrgInfo() {
      this.$refs.orgEditInfoRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "org/edit",
            this.orgEditInfoForm
          );
          if (res.code == 0) {
            this.$message.success("修改成功");
            this.getOrgList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.editOrgDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    async showEditDialog(id) {
      const { data: res } = await this.$http.get("org/find/one/" + id);
      if (res.code == 0) {
        this.orgEditInfoForm = res.data;
        this.orgId = res.data.id;
        this.editOrgDialogVisible = true;
      } else {
        this.$message.error(res.msg);
        return false;
      }
    },
    showAddDialg() {
      this.orgId = 0;
      this.addOrgDialogVisible = true;
    }
  },
};
</script>

<style lang="less" scoped>
</style>