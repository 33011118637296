<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>设备管理</el-breadcrumb-item>
      <el-breadcrumb-item>浊度仪管理</el-breadcrumb-item>
      <el-breadcrumb-item>浊度仪列表</el-breadcrumb-item>
    </el-breadcrumb>




    <!-- 卡片视图 -->
    <el-card>
      <el-row>
        <el-col :span="4">
          <el-select
            v-model="queryParams.projectCode"
            placeholder="请选择项目"
            clearable
            @clear="getDeviceList"
            @change="getDeviceList"
          >
            <el-option
              v-for="item in projectInfoList"
              :key="item.projectCode"
              :label="item.projectName"
              :value="item.projectCode"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <!-- <el-button
            type="primary"
            @click="showAddDialog"
            v-has="'sys:device:zhuodu:add'"
            icon="el-icon-plus"
            >添加浊度仪</el-button
          > -->
          <!-- <el-button
            @click="showUploadView"
            type="warning"
            v-has="'sys:device:zhuodu:import'"
            icon="el-icon-upload"
            >导入</el-button
          > -->
          <el-button
            @click="exportDevices"
            type="success"
            icon="el-icon-download"
            v-has="'sys:device:zhuodu:export'"
            >导出</el-button
          >
        </el-col>
      </el-row>




      <!-- 表格数据 -->
      <el-table :data="deviceInfoList" border stripe>
        <el-table-column type="expand">
          <template slot-scope="props">
            <span v-if="props.row.remark == null || props.row.remark == ''">无备注</span>
            <span v-else>{{ props.row.remark }}</span>
          </template>
        </el-table-column>
        <el-table-column label="图片">
          <template slot-scope="scope">
            <el-popover
              placement="right"
              width="300"
              trigger="hover">
              <img :src="COMMON_CONFIG.LOAD_DEVICE_IMG_URL + scope.row.deviceImg" height="300" width="300"/>
              <img slot="reference" :src="COMMON_CONFIG.LOAD_DEVICE_IMG_URL + scope.row.deviceImg" height="50" width="50"/>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="设备名称" prop="name"></el-table-column>
        <el-table-column label="设备行政编号" prop="deviceGovCode" width="100px"></el-table-column>
        <el-table-column label="通道号" prop="channelNo"></el-table-column>
        <el-table-column label="输入类型" prop="inType">
          <template slot-scope="scope">
            <span v-if="scope.row.inType == 1">-15mV~+15mV</span>
            <span v-else-if="scope.row.inType == 2">-50mV~+50mV</span>
            <span v-else-if="scope.row.inType == 3">-100mV~+100mV</span>
            <span v-else-if="scope.row.inType == 4">-500mV~+500mV</span>
            <span v-else-if="scope.row.inType == 5">-1V~+1V</span>
            <span v-else-if="scope.row.inType == 6">-5V~+5V/-10V~+10V</span>
            <span v-else-if="scope.row.inType == 7">-20mA~+20mA备用</span>
            <span v-else-if="scope.row.inType == 8">4~20mA备用</span>
            <span v-else-if="scope.row.inType == 9">-20mA~+20mA外置电阻</span>
            <span v-else-if="scope.row.inType == 10">4~20mA外置电阻</span>
            <span v-else-if="scope.row.inType == 11">J型</span>
            <span v-else-if="scope.row.inType == 12">K型</span>
            <span v-else-if="scope.row.inType == 13">T型</span>
            <span v-else-if="scope.row.inType == 14">E型</span>
            <span v-else-if="scope.row.inType == 15">R型</span>
            <span v-else-if="scope.row.inType == 16">S型</span>
            <span v-else-if="scope.row.inType == 17">B型</span>
            <span v-else-if="scope.row.inType == 18">钨铼WEB3/25</span>
            <span v-else-if="scope.row.inType == 19">钨铼WEB3/26</span>
          </template>
        </el-table-column>
        <el-table-column label="初始值（米）" prop="initValue" width="100px"></el-table-column>
        <el-table-column label="采集间隔（min）" prop="collectInterval" width="120px"></el-table-column>
        <el-table-column label="关联MCU" prop="mcuDeviceCode" width="90px">
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.mcuDeviceCode === null || scope.row.mcuDeviceCode === ''"
              type="info"
              >未关联</el-tag
            >
            <el-popover
                  v-else
                  placement="top-start"
                  width="200"
                  trigger="hover"
                  @show="showMcuInfo(scope.row.mcuDeviceCode)"
                  :content="mcuDeviceInfo">
                  <el-tag slot="reference" type="success">已关联</el-tag>
                </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="关联串口" prop="comCode" width="90px">
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.comCode === null || scope.row.comCode === ''"
              type="info"
              >未关联</el-tag
            >
            <el-popover
                  v-else
                  placement="top-start"
                  width="200"
                  trigger="hover"
                  @show="showComInfo(scope.row.comCode)"
                  :content="comDeviceInfo">
                  <el-tag slot="reference" type="success">已关联</el-tag>
                </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="是否有效" width="80px">
          <template slot-scope="scope">
            <el-switch
              disabled
              v-model="scope.row.availableBl"
              @change="deviceStateChanged(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="最新采集时间" prop="collectTime" width="150px"></el-table-column>
        <el-table-column label="创建日期" prop="createTime" width="100px">
          <template slot-scope="scope">
            {{common.dateFormat('YYYY-MM-dd', new Date(Date.parse(scope.row.createTime)))}}
          </template>
        </el-table-column>
        <!-- <el-table-column label="操作" width="360px" fixed="right">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="关联MCU"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="warning"
                @click="mcuSettingDialog(scope.row)"
                v-has="'sys:device:zhuodu:bind'"
              >MCU</el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="关联串口"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="success"
                icon="el-icon-connection"
                @click="comSettingDialog(scope.row.deviceCode)"
                v-has="'sys:device:zhuodu:bind'"
              ></el-button>
            </el-tooltip>


            <el-tooltip
              class="item"
              effect="dark"
              content="设置地址"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-s-flag"
                @click="resetAddressShow(scope.row)"
                v-has="'sys:device:zhuodu:bind'"
              ></el-button>
            </el-tooltip>


            <el-tooltip
              class="item"
              effect="dark"
              content="零点校准"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-aim"
                @click="lingdianjiaozhun(scope.row.deviceCode)"
                v-has="'sys:device:zhuodu:bind'"
              ></el-button>
            </el-tooltip>

            <el-tooltip
              class="item"
              effect="dark"
              content="修改"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                @click="showEditDialog(scope.row.deviceCode)"
                v-has="'sys:device:zhuodu:edit'"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                v-has="'sys:device:zhuodu:delete'"
                @click="removeByDeviceCode(scope.row.deviceCode)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column> -->
      </el-table>

      <!-- 分页组件 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>





      <!-- 添加浊度仪 -->
      <el-dialog
        title="添加浊度仪"
        :visible.sync="addDialogVisible"
        width="50%"
        v-if="addDialogVisible"
        @close="addDialogClosed"
      >
        <div style="height:350px; overflow: auto;">
        <el-form :model="deviceInfoForm" :rules="devRules" ref="addInfoRef">
          <el-form-item label="设备图片" label-width="120px">
            <!-- <v-device-img-upload
              @imgOnChange="addDeviceImgChange"
              @imgOnRemove="addDeviceImgRemove"
              >
            </v-device-img-upload> -->
          </el-form-item>
          <el-form-item label="所属项目" label-width="120px" prop="projectCode">
            <el-select
              v-model="deviceInfoForm.projectCode"
              placeholder="请选择项目"
              clearable
            >
              <el-option
                v-for="item in projectInfoList"
                :key="item.projectCode"
                :label="item.projectName"
                :value="item.projectCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备型号" label-width="120px" prop="deviceModel">
            <el-select v-model="deviceInfoForm.deviceModel" placeholder="请选择型号" clearable>
              <el-option
                v-for="item in deviceModelList"
                :key="item.modelCode"
                :label="item.modelName"
                :value="item.modelCode"
                >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备名称" label-width="120px" prop="name">
            <el-input
              autocomplete="off"
              v-model="deviceInfoForm.name"
              clearable
              placeholder="最多输入25个字"
            ></el-input>
          </el-form-item>
          <el-form-item label="设备行政编号" label-width="120px" prop="deviceGovCode">
            <el-input
              autocomplete="off"
              v-model="deviceInfoForm.deviceGovCode"
              clearable
              placeholder="格式：AABBCCDDDDEEFF，用于上报应急管理系统"
            ></el-input><br/>
            <span style="color: rgb(175, 175, 175);">
              省[AA]市[BB]县/区[CC]尾矿库[DDDD]监测设备类型[EE]设备位号[FF]
            </span>
          </el-form-item>
          <el-form-item label="通道号" label-width="120px" prop="channelNo">
            <el-select v-model="deviceInfoForm.channelNo" placeholder="请选择通道号" clearable>
              <el-option label="1" :value="1"></el-option>
              <el-option label="2" :value="2"></el-option>
              <el-option label="3" :value="3"></el-option>
              <el-option label="4" :value="4"></el-option>
              <el-option label="5" :value="5"></el-option>
              <el-option label="6" :value="6"></el-option>
              <el-option label="7" :value="7"></el-option>
              <el-option label="8" :value="8"></el-option>
              <el-option label="9" :value="9"></el-option>
              <el-option label="10" :value="10"></el-option>
              <el-option label="11" :value="11"></el-option>
              <el-option label="12" :value="12"></el-option>
              <el-option label="13" :value="13"></el-option>
              <el-option label="14" :value="14"></el-option>
              <el-option label="15" :value="15"></el-option>
              <el-option label="16" :value="16"></el-option>
            </el-select><br/>
            <span style="color: rgb(175, 175, 175);">
              设备通道号默认为6，请先检查实际设备的地址.
            </span>
          </el-form-item>
          <el-form-item label="输入类型" label-width="120px" prop="inType">
            <el-select v-model="deviceInfoForm.inType" placeholder="请选择输入类型" clearable>
              <el-option label="-15mV~+15mV" :value="1"></el-option>
              <el-option label="-50mV~+50mV" :value="2"></el-option>
              <el-option label="-100mV~+100mV" :value="3"></el-option>
              <el-option label="-500mV~+500mV" :value="4"></el-option>
              <el-option label="-1V~+1V" :value="5"></el-option>
              <el-option label="-5V~+5V/-10V~+10V" :value="6"></el-option>
              <el-option label="-20mA~+20mA备用" :value="7"></el-option>
              <el-option label="4~20mA备用" :value="8"></el-option>
              <el-option label="-20mA~+20mA外置电阻" :value="9"></el-option>
              <el-option label="4~20mA外置电阻" :value="10"></el-option>
              <el-option label="J型" :value="11"></el-option>
              <el-option label="K型" :value="12"></el-option>
              <el-option label="T型" :value="13"></el-option>
              <el-option label="E型" :value="14"></el-option>
              <el-option label="R型" :value="15"></el-option>
              <el-option label="S型" :value="16"></el-option>
              <el-option label="B型" :value="17"></el-option>
              <el-option label="钨铼WEB3/25" :value="18"></el-option>
              <el-option label="钨铼WEB3/26" :value="19"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="初始值" label-width="120px" prop="initValue">
            <el-input
              autocomplete="off"
              v-model="deviceInfoForm.initValue"
              clearable
            >
            <template slot="append">米</template>
            </el-input>
          </el-form-item>
          <el-form-item label="采集间隔" label-width="120px" prop="collectInterval">
            <el-input
              autocomplete="off"
              v-model="deviceInfoForm.collectInterval"
              clearable
            >
            <template slot="append">min</template>
            </el-input>
          </el-form-item>
          <el-form-item label="备注" label-width="120px" prop="remark">
            <el-input
              type="textarea"
              v-model="deviceInfoForm.remark"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addInfoSubmit()">提 交</el-button>
        </span>
      </el-dialog>




      <!-- 编辑浊度仪 -->
      <el-dialog
        title="编辑浊度仪"
        :visible.sync="editDialogVisible"
        width="50%"
        v-if="editDialogVisible"
      >
        <div style="height:350px; overflow: auto;">
        <el-form :model="deviceInfoForm" :rules="devRules" ref="editInfoRef">
          <el-form-item label="设备图片" label-width="120px">
            <!-- <v-device-img-upload
              :defaultImgUrl="deviceInfoForm.deviceImg"
              @imgOnChange="addDeviceImgChange"
              @imgOnRemove="addDeviceImgRemove"
              >
            </v-device-img-upload> -->
          </el-form-item>
          <el-form-item label="所属项目" label-width="120px" prop="projectCode">
            <el-select
              v-model="deviceInfoForm.projectCode"
              placeholder="请选择项目"
              clearable
            >
              <el-option
                v-for="item in projectInfoList"
                :key="item.projectCode"
                :label="item.projectName"
                :value="item.projectCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备型号" label-width="120px" prop="deviceModel">
            <el-select v-model="deviceInfoForm.deviceModel" placeholder="请选择型号" clearable>
              <el-option
                v-for="item in deviceModelList"
                :key="item.modelCode"
                :label="item.modelName"
                :value="item.modelCode"
                >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备名称" label-width="120px" prop="name">
            <el-input
              autocomplete="off"
              v-model="deviceInfoForm.name"
              clearable
              placeholder="最多输入25个字"
            ></el-input>
          </el-form-item>
          <el-form-item label="设备行政编号" label-width="120px" prop="deviceGovCode">
            <el-input
              autocomplete="off"
              v-model="deviceInfoForm.deviceGovCode"
              clearable
              placeholder="格式：AABBCCDDDDEEFF，用于上报应急管理系统"
            ></el-input><br/>
            <span style="color: rgb(175, 175, 175);">
              省[AA]市[BB]县/区[CC]尾矿库[DDDD]监测设备类型[EE]设备位号[FF]
            </span>
          </el-form-item>
          <el-form-item label="通道号" label-width="120px" prop="channelNo">
            <el-select v-model="deviceInfoForm.channelNo" placeholder="请选择通道号" clearable>
              <el-option label="1" :value="1"></el-option>
              <el-option label="2" :value="2"></el-option>
              <el-option label="3" :value="3"></el-option>
              <el-option label="4" :value="4"></el-option>
              <el-option label="5" :value="5"></el-option>
              <el-option label="6" :value="6"></el-option>
              <el-option label="7" :value="7"></el-option>
              <el-option label="8" :value="8"></el-option>
              <el-option label="9" :value="9"></el-option>
              <el-option label="10" :value="10"></el-option>
              <el-option label="11" :value="11"></el-option>
              <el-option label="12" :value="12"></el-option>
              <el-option label="13" :value="13"></el-option>
              <el-option label="14" :value="14"></el-option>
              <el-option label="15" :value="15"></el-option>
              <el-option label="16" :value="16"></el-option>
            </el-select><br/>
            <span style="color: rgb(175, 175, 175);">
              设备通道号默认为6，请先检查实际设备的地址.
            </span>
          </el-form-item>
          <el-form-item label="输入类型" label-width="120px" prop="inType">
            <el-select v-model="deviceInfoForm.inType" placeholder="请选择输入类型" clearable>
              <el-option label="-15mV~+15mV" :value="1"></el-option>
              <el-option label="-50mV~+50mV" :value="2"></el-option>
              <el-option label="-100mV~+100mV" :value="3"></el-option>
              <el-option label="-500mV~+500mV" :value="4"></el-option>
              <el-option label="-1V~+1V" :value="5"></el-option>
              <el-option label="-5V~+5V/-10V~+10V" :value="6"></el-option>
              <el-option label="-20mA~+20mA备用" :value="7"></el-option>
              <el-option label="4~20mA备用" :value="8"></el-option>
              <el-option label="-20mA~+20mA外置电阻" :value="9"></el-option>
              <el-option label="4~20mA外置电阻" :value="10"></el-option>
              <el-option label="J型" :value="11"></el-option>
              <el-option label="K型" :value="12"></el-option>
              <el-option label="T型" :value="13"></el-option>
              <el-option label="E型" :value="14"></el-option>
              <el-option label="R型" :value="15"></el-option>
              <el-option label="S型" :value="16"></el-option>
              <el-option label="B型" :value="17"></el-option>
              <el-option label="钨铼WEB3/25" :value="18"></el-option>
              <el-option label="钨铼WEB3/26" :value="19"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="初始值" label-width="120px" prop="initValue">
            <el-input
              autocomplete="off"
              v-model="deviceInfoForm.initValue"
              clearable
            >
            <template slot="append">米</template>
            </el-input>
          </el-form-item>
          <el-form-item label="采集间隔" label-width="120px" prop="collectInterval">
            <el-input
              autocomplete="off"
              v-model="deviceInfoForm.collectInterval"
              clearable
            >
            <template slot="append">min</template>
            </el-input>
          </el-form-item>
          <el-form-item label="备注" label-width="120px" prop="remark">
            <el-input
              type="textarea"
              v-model="deviceInfoForm.remark"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="editInfoSubmit()">保 存</el-button>
        </span>
      </el-dialog>



      <!-- 关联串口 -->
      <el-dialog
        title="关联串口"
        :visible.sync="showSelectComDialogVisible"
        v-if="showSelectComDialogVisible"
        width="60%"
      >
        <!-- <v-selected-com :deviceCode="currentDeviceCode" @dataSubmit="afterBindCom"></v-selected-com> -->
      </el-dialog>


      <!-- 关联MCU -->
      <el-dialog
        title="关联MCU"
        :visible.sync="showBindMcuDialogVisible"
        v-if="showBindMcuDialogVisible"
        width="40%"
      >
        <!-- <v-bind-mcu 
          :deviceCode="currentDeviceCode" 
          :projectCode="currentProjectCode"
          @bindCancel="cancelBindMcu"
          @dataSubmit="bindMcuSubmit"
        >
        </v-bind-mcu> -->
      </el-dialog>

      <el-dialog
        title="导出提示"
        :visible.sync="showDownloadVisible"
        width="30%">
        <span>数据导出已执行，完成后文件将在【下载中心】展示。</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showDownloadVisible = false">取 消</el-button>
          <el-button type="primary" @click="showDownloadVisible = false">确 定</el-button>
        </span>
      </el-dialog>


      <el-dialog
        title="设备导入"
        :visible.sync="showUploadVisible"
        v-if="showUploadVisible"
        width="30%">  
        <!-- <v-device-info-upload :deviceType="91" @uploadOk="batchAddDevicesSuccess" ref="deviceInfoUploadRef"></v-device-info-upload> -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="showUploadVisible = false">取 消</el-button>
          <el-button type="primary" @click="batchAddDevices">确 定</el-button>
        </span>
      </el-dialog>


      <!-- 浊度仪设置地址 -->
      <el-dialog
        title="重置设备地址"
        :visible.sync="showResetAddressVisible"
        width="50%"
      >
        <el-form :model="resetAddressForm" :rules="devRules" ref="resetAddrRef">
          <el-form-item label="设备名称" label-width="120px" prop="name">
            <el-input
              autocomplete="off"
              v-model="resetAddressForm.name"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="通道号" label-width="120px" prop="channelNo">
            <el-select v-model="resetAddressForm.channelNo" placeholder="请选择通道号" clearable>
              <el-option label="1" :value="1"></el-option>
              <el-option label="2" :value="2"></el-option>
              <el-option label="3" :value="3"></el-option>
              <el-option label="4" :value="4"></el-option>
              <el-option label="5" :value="5"></el-option>
              <el-option label="6" :value="6"></el-option>
              <el-option label="7" :value="7"></el-option>
              <el-option label="8" :value="8"></el-option>
              <el-option label="9" :value="9"></el-option>
              <el-option label="10" :value="10"></el-option>
              <el-option label="11" :value="11"></el-option>
              <el-option label="12" :value="12"></el-option>
              <el-option label="13" :value="13"></el-option>
              <el-option label="14" :value="14"></el-option>
              <el-option label="15" :value="15"></el-option>
              <el-option label="16" :value="16"></el-option>
            </el-select><br/>
            <span style="color: rgb(175, 175, 175);">
              提示：只有在设备成功接收到指令，并响应后才会更新该地址。
            </span>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showResetAddressVisible = false">取 消</el-button>
          <el-button type="primary" @click="resetAddressSubmit()">提 交</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showResetAddressVisible: false,
      showUploadVisible: false,
      showDownloadVisible: false,
      addDialogVisible: false,
      editDialogVisible: false,
      showSelectComDialogVisible: false,
      showBindMcuDialogVisible: false,
      queryParams: {
        projectCode: "",
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      devRules: {
        projectCode: [{ required: true, message: "请选择项目", trigger: "blur" }],
        deviceModel: [{ required: true, message: "请选择型号", trigger: "blur" }],
        channelNo: [{ required: true, message: "请选择通道号", trigger: "blur" }],
        inType: [{ required: true, message: "请选择输入类型", trigger: "blur" }],
        name: [{ required: true, message: "请输入名字", trigger: "blur" }],
        initValue: [{ required: true, message: "请输入初始值", trigger: "blur" }],
        collectInterval: [{ required: true, message: "请输入采集间隔", trigger: "blur" }],
      },
      projectInfoList: [],
      deviceInfoList: [],
      deviceInfoForm: {},
      currentDeviceCode: "",
      currentProjectCode: "",
      comDeviceInfo: "",
      mcuDeviceInfo: "",
      deviceModelList: [],
      resetAddressForm: {
        deviceCode: null,
        name: null,
        channelNo: null
      },
    }
  },
  created() {
    this.getAllProject();
    this.getDeviceModel();
  },
  methods: {
    handleSizeChange(newSize) {
      this.queryParams.pageSize = newSize;
      this.getDeviceList();
    },
    handleCurrentChange(newPage) {
      this.queryParams.pageNo = newPage;
      this.getDeviceList();
    },
    async getDeviceModel() {
      const { data: res } = await this.$http.get("device/find/models", {
        params: {
          deviceType: 91
        }
      });
      if (res.code == 0) {
        this.deviceModelList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getAllProject() {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        this.projectInfoList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getDeviceList() {
      const { data: res } = await this.$http.get("device/zhuodu/find/page", {
        params: this.queryParams
      });
      if (res.code == 0) {
        if (res.data == null) {
          this.deviceInfoList = [];
          this.total = 0;
          return;
        }

        this.deviceInfoList = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.error(res.msg);
      }
    },
    comSettingDialog(deviceCode) {
      this.currentDeviceCode = deviceCode;
      this.showSelectComDialogVisible = true;
    },
    afterBindCom() {
      this.getDeviceList();
    },
    async showEditDialog(deviceCode) {
      const { data: res } = await this.$http.get("device/zhuodu/find/bycode/" + deviceCode);
      if (res.code == 0) {
        this.deviceInfoForm = res.data;
        this.editDialogVisible = true;
      } else {
        this.$message.error(res.msg);
      }
    },
    async removeByDeviceCode(deviceCode) {
      const confirmResult = await this.$confirm("是否确认删除该设备?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.delete("device/zhuodu/remove/" + deviceCode);
      if (res.code == 0) {
        this.$message.success("删除成功！");
        this.getDeviceList();
      } else {
        return this.$message.error(res.msg);
      }
    },
    showAddDialog() {
      this.deviceInfoForm = {};
      this.addDialogVisible = true;
    },
    addDialogClosed() {
      this.$refs.addInfoRef.resetFields();
    },
    async addInfoSubmit() {
      this.$refs.addInfoRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "device/zhuodu/add",
            this.deviceInfoForm
          );
          if (res.code == 0) {
            this.$message.success("添加成功");
            this.getDeviceList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.addDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    async editInfoSubmit() {
      this.$refs.editInfoRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "device/zhuodu/update",
            this.deviceInfoForm
          );
          if (res.code == 0) {
            this.$message.success("修改成功");
            this.getDeviceList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.editDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    async deviceStateChanged(devInfo) {
      const confirmResult = await this.$confirm(
        "此操作将影响平台收集设备数据, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        this.$message.info("已取消操作");
        devInfo.availableBl = !devInfo.availableBl;
        return false;
      }

      const { data: res } = await this.$http.get("device/change/status", {
        params: {
          deviceCode: devInfo.deviceCode,
          available: devInfo.availableBl
        }
      });

      if (res.code == 0) {
        this.$message.success("修改成功");
      } else {
        this.$message.error(res.msg);
      }
    },
    async showComInfo(comCode) {
      const { data: res } = await this.$http.get("virtual/com/find/bycode", {
        params: {
          comCode: comCode
        },
        showLoading: false
      });
      if (res.code == 0) {
        this.comDeviceInfo = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    mcuSettingDialog(deviceInfo) {
      this.currentProjectCode = deviceInfo.projectCode;
      this.currentDeviceCode = deviceInfo.deviceCode;
      this.showBindMcuDialogVisible = true;
    },
    cancelBindMcu() {
      this.showBindMcuDialogVisible = false;
    },
    bindMcuSubmit() {
      this.showBindMcuDialogVisible = false;
      this.getDeviceList();
    },
    async showMcuInfo(mcuDeviceCode) {
      const { data: res } = await this.$http.get("device/find/bycode", {
        params: {
          deviceCode: mcuDeviceCode
        },
        showLoading: false
      });
      if (res.code == 0) {
        this.mcuDeviceInfo = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    addDeviceImgChange(url) {
      this.deviceInfoForm.deviceImg = url;
    },
    addDeviceImgRemove() {
      this.deviceInfoForm.deviceImg = "";
    },
    async exportDevices(){
      if (this.queryParams.projectCode == null || this.queryParams.projectCode == "") {
        this.$message.error("请选择项目.");
        return;
      }
      const { data: res } = await this.$http.get("file/zhuodu/export", {
        params: {
          projectCode: this.queryParams.projectCode
        }
      });
      if (res.code == 0) {
        this.showDownloadVisible = true;
      } else {
        this.$message.error(res.msg);
      }
    },
    showUploadView() {
      this.showUploadVisible = true;
    },
    batchAddDevices() {
      this.$refs.deviceInfoUploadRef.submitUpload();
    },
    batchAddDevicesSuccess() {
      this.getDeviceList();
    },
    async lingdianjiaozhun(deviceCode) {
      const confirmResult = await this.$confirm("是否要校准该设备?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消");
      }

      const { data: res } = await this.$http.get("device/zhuodu/ld/jz", {
        params: {
          deviceCode: deviceCode
        }
      });

      if (res.code == 0) {
        this.$message.success("校准指令已下发");
      } else {
        this.$message.error(res.msg);
      }
    },
    resetAddressShow(device) {
      this.resetAddressForm.deviceCode = device.deviceCode;
      this.resetAddressForm.name = device.name;
      this.resetAddressForm.channelNo = device.channelNo;
      this.showResetAddressVisible = true;
    },
    async resetAddressSubmit() {
      this.$refs.resetAddrRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "device/zhuodu/reset/addr",
            this.resetAddressForm
          );
          if (res.code == 0) {
            this.$message.success("指令已下发");
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.showResetAddressVisible = false;
        } else {
          return false;
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>

</style>