<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>设备管理</el-breadcrumb-item>
      <el-breadcrumb-item>测斜仪管理</el-breadcrumb-item>
      <el-breadcrumb-item>测斜仪列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图 -->
    <el-card>
      <el-row>
        <el-col :span="4">
          <el-select
            v-model="queryParams.projectCode"
            placeholder="请选择项目"
            clearable
            @clear="searchByProject"
            @change="searchByProject"
          >
            <el-option
              v-for="item in projectInfoList"
              :key="item.projectCode"
              :label="item.projectName"
              :value="item.projectCode"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-select 
            @change="getDeviceList"
            v-model="queryParams.khCode" 
            placeholder="请选择孔号" 
            clearable>
              <el-option
                v-for="item in konghaoList"
                :key="item.khCode"
                :label="item.khName"
                :value="item.khCode"
              >
              </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <el-button
            @click="exportDevices"
            type="success"
            icon="el-icon-download"
            v-has="'sys:device:cexie:export'"
            >导出</el-button
          >
        </el-col>
      </el-row>



      <!-- 表格数据 -->
      <el-table :data="deviceInfoList" border stripe>
        <el-table-column type="expand">
          <template slot-scope="props">
            <span v-if="props.row.remark == null || props.row.remark == ''">无备注</span>
            <span v-else>{{ props.row.remark }}</span>
          </template>
        </el-table-column>
        <el-table-column label="图片">
          <template slot-scope="scope">
            <el-popover
              placement="right"
              width="300"
              trigger="hover">
              <img :src="COMMON_CONFIG.LOAD_DEVICE_IMG_URL + scope.row.deviceImg" height="300" width="300"/>
              <img slot="reference" :src="COMMON_CONFIG.LOAD_DEVICE_IMG_URL + scope.row.deviceImg" height="50" width="50"/>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="设备名称" prop="name" width="100px"></el-table-column>
        <el-table-column label="设备行政编号" prop="deviceGovCode" width="120px"></el-table-column>
        <el-table-column label="所在位置" prop="weizhi">
          <template slot-scope="scope">
            <span v-if="scope.row.weizhi == 1">上部</span>
            <span v-else-if="scope.row.weizhi == 2">中部</span>
            <span v-else>下部</span>
          </template>
        </el-table-column>
        <el-table-column label="基准模数（F）" prop="jzmoshuzhi" width="100px"></el-table-column>
        <el-table-column label="标距（mm）" prop="biaoju" width="100px"></el-table-column>
        <el-table-column label="孔号" prop="khName"></el-table-column>
        <el-table-column label="计算修正值A" prop="xza" width="100px"></el-table-column>
        <el-table-column label="计算修正值B" prop="xzb" width="100px"></el-table-column>
        <el-table-column label="计算修正值C" prop="xzc" width="100px"></el-table-column>
        <el-table-column label="计算修正值D" prop="xzd" width="100px"></el-table-column>
        <el-table-column label="关联MCU" prop="mcuDeviceCode" width="90px">
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.mcuDeviceCode === null || scope.row.mcuDeviceCode === ''"
              type="info"
              >未关联</el-tag
            >
            <el-popover
                  v-else
                  placement="top-start"
                  width="200"
                  trigger="hover"
                  @show="showMcuInfo(scope.row.mcuDeviceCode)"
                  :content="mcuDeviceInfo">
                  <el-tag slot="reference" type="success">已关联</el-tag>
                </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="关联串口" prop="comCode" width="90px">
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.comCode === null || scope.row.comCode === ''"
              type="info"
              >未关联</el-tag
            >
            <el-popover
                  v-else
                  placement="top-start"
                  width="200"
                  trigger="hover"
                  @show="showComInfo(scope.row.comCode)"
                  :content="comDeviceInfo">
                  <el-tag slot="reference" type="success">已关联</el-tag>
                </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="是否有效" width="80px">
          <template slot-scope="scope">
            <el-switch
              disabled
              v-model="scope.row.availableBl"
              @change="deviceStateChanged(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="最新采集时间" prop="collectTime" width="150px"></el-table-column>
        <el-table-column label="创建日期" prop="createTime" width="100px">
          <template slot-scope="scope">
            {{common.dateFormat('YYYY-MM-dd', new Date(Date.parse(scope.row.createTime)))}}
          </template>
        </el-table-column>
        <!-- <el-table-column label="操作" width="260px" fixed="right">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="关联MCU"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="warning"
                @click="mcuSettingDialog(scope.row)"
                v-has="'sys:device:cexie:bind'"
              >MCU</el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="关联串口"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="success"
                icon="el-icon-connection"
                @click="comSettingDialog(scope.row.deviceCode)"
                v-has="'sys:device:cexie:bind'"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="修改"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                @click="showEditDialog(scope.row.deviceCode)"
                v-has="'sys:device:cexie:edit'"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                v-has="'sys:device:cexie:delete'"
                @click="removeByDeviceCode(scope.row.deviceCode)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column> -->
      </el-table>

      <!-- 分页组件 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>



      <!-- 添加测斜仪 -->
      <el-dialog
        title="添加测斜仪"
        :visible.sync="addDialogVisible"
        width="50%"
        v-if="addDialogVisible"
        @close="addDialogClosed"
      >
        <div style="height:350px; overflow: auto;">
        <el-form :model="deviceInfoForm" :rules="deviceRules" ref="addDeviceInfoRef">
          <el-form-item label="设备图片" label-width="120px">
            <!-- <v-device-img-upload
              @imgOnChange="addDeviceImgChange"
              @imgOnRemove="addDeviceImgRemove"
              >
            </v-device-img-upload> -->
          </el-form-item>
          <el-form-item label="所属项目" label-width="120px" prop="projectCode">
            <el-select
              @change="getKonghaoList(deviceInfoForm.projectCode)"
              v-model="deviceInfoForm.projectCode"
              placeholder="请选择项目"
              clearable
            >
              <el-option
                v-for="item in projectInfoList"
                :key="item.projectCode"
                :label="item.projectName"
                :value="item.projectCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备型号" label-width="120px" prop="deviceModel">
            <el-select v-model="deviceInfoForm.deviceModel" placeholder="请选择型号" clearable>
              <el-option
                v-for="item in deviceModelList"
                :key="item.modelCode"
                :label="item.modelName"
                :value="item.modelCode"
                >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备名称" label-width="120px" prop="name">
            <el-input
              autocomplete="off"
              v-model="deviceInfoForm.name"
              clearable
              placeholder="最多输入25个字"
            ></el-input>
          </el-form-item>
          <el-form-item label="设备行政编号" label-width="120px" prop="deviceGovCode">
            <el-input
              autocomplete="off"
              v-model="deviceInfoForm.deviceGovCode"
              clearable
              placeholder="格式：AABBCCDDDDEEFF，用于上报应急管理系统"
            ></el-input><br/>
            <span style="color: rgb(175, 175, 175);">
              省[AA]市[BB]县/区[CC]尾矿库[DDDD]监测设备类型[EE]设备位号[FF]
            </span>
          </el-form-item>
          <el-form-item label="基准模数值" label-width="120px" prop="jzmoshuzhi">
            <el-input
              autocomplete="off"
              v-model="deviceInfoForm.jzmoshuzhi"
              clearable
            >
            <template slot="append">F</template>
            </el-input>
          </el-form-item>
          <el-form-item label="标距" label-width="120px" prop="biaoju">
            <el-input
              autocomplete="off"
              v-model="deviceInfoForm.biaoju"
              clearable
            >
            <template slot="append">mm</template>
            </el-input>
          </el-form-item>
          <el-form-item label="所属孔号" label-width="120px" prop="konghao">
            <el-row>
              <el-col :span="12">
                <el-select v-model="deviceInfoForm.konghao" placeholder="请选择孔号" clearable>
                  <el-option
                    v-for="item in konghaoList"
                    :key="item.khCode"
                    :label="item.khName"
                    :value="item.khCode"
                  >
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="12">
                <el-row>
                  <el-col :span="4">排序</el-col>
                  <el-col :span="8">
                    <el-input
                      autocomplete="off"
                      v-model="konghaoOrder"
                      disabled
                    ></el-input>
                  </el-col>
                  <el-col :span="12">
                    <el-button-group>
                      <el-button icon="el-icon-minus" @click="decrease"></el-button>
                      <el-button icon="el-icon-plus" @click="increase"></el-button>
                    </el-button-group>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="所在位置" label-width="120px" prop="weizhi">
            <el-select v-model="deviceInfoForm.weizhi" placeholder="请选择位置" clearable>
              <el-option label="上部" :value="1"></el-option>
              <el-option label="中部" :value="2"></el-option>
              <el-option label="下部" :value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="计算修正值A" label-width="120px" prop="xza">
            <el-input
              autocomplete="off"
              v-model="deviceInfoForm.xza"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="计算修正值B" label-width="120px" prop="xzb">
            <el-input
              autocomplete="off"
              v-model="deviceInfoForm.xzb"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="计算修正值C" label-width="120px" prop="xzc">
            <el-input
              autocomplete="off"
              v-model="deviceInfoForm.xzc"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="计算修正值D" label-width="120px" prop="xzd">
            <el-input
              autocomplete="off"
              v-model="deviceInfoForm.xzd"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="备注" label-width="120px" prop="remark">
            <el-input
              type="textarea"
              v-model="deviceInfoForm.remark"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addInfoSubmit()">提 交</el-button>
        </span>
      </el-dialog>


      <!-- 编辑测斜仪 -->
      <el-dialog
        title="编辑测斜仪"
        :visible.sync="editDialogVisible"
        width="50%"
        v-if="editDialogVisible"
      >
        <div style="height:350px; overflow: auto;">
        <el-form :model="deviceInfoForm" :rules="deviceRules" ref="editDeviceInfoRef">
          <el-form-item label="设备图片" label-width="120px">
            <!-- <v-device-img-upload
              :defaultImgUrl="deviceInfoForm.deviceImg"
              @imgOnChange="addDeviceImgChange"
              @imgOnRemove="addDeviceImgRemove"
              >
            </v-device-img-upload> -->
          </el-form-item>
          <el-form-item label="所属项目" label-width="120px" prop="projectCode">
            <el-select
              v-model="deviceInfoForm.projectCode"
              placeholder="请选择项目"
              clearable
            >
              <el-option
                v-for="item in projectInfoList"
                :key="item.projectCode"
                :label="item.projectName"
                :value="item.projectCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备型号" label-width="120px" prop="deviceModel">
            <el-select v-model="deviceInfoForm.deviceModel" placeholder="请选择型号" clearable>
              <el-option
                v-for="item in deviceModelList"
                :key="item.modelCode"
                :label="item.modelName"
                :value="item.modelCode"
                >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备名称" label-width="120px" prop="name">
            <el-input
              autocomplete="off"
              v-model="deviceInfoForm.name"
              clearable
              placeholder="最多输入25个字"
            ></el-input>
          </el-form-item>
          <el-form-item label="设备行政编号" label-width="120px" prop="deviceGovCode">
            <el-input
              autocomplete="off"
              v-model="deviceInfoForm.deviceGovCode"
              clearable
              placeholder="格式：AABBCCDDDDEEFF，用于上报应急管理系统"
            ></el-input><br/>
            <span style="color: rgb(175, 175, 175);">
              省[AA]市[BB]县/区[CC]尾矿库[DDDD]监测设备类型[EE]设备位号[FF]
            </span>
          </el-form-item>
          <el-form-item label="基准模数值" label-width="120px" prop="jzmoshuzhi">
            <el-input
              autocomplete="off"
              v-model="deviceInfoForm.jzmoshuzhi"
              clearable
            >
            <template slot="append">F</template>
            </el-input>
          </el-form-item>
          <el-form-item label="标距" label-width="120px" prop="biaoju">
            <el-input
              autocomplete="off"
              v-model="deviceInfoForm.biaoju"
              clearable
            >
            <template slot="append">mm</template>
            </el-input>
          </el-form-item>
          <el-form-item label="所属孔号" label-width="120px" prop="konghao">
            <el-row>
              <el-col :span="12">
                <el-select v-model="deviceInfoForm.konghao" placeholder="请选择孔号" clearable>
                  <el-option
                    v-for="item in konghaoList"
                    :key="item.khCode"
                    :label="item.khName"
                    :value="item.khCode"
                  >
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="12">
                <el-row>
                  <el-col :span="4">排序</el-col>
                  <el-col :span="8">
                    <el-input
                      autocomplete="off"
                      v-model="konghaoOrder"
                      disabled
                    ></el-input>
                  </el-col>
                  <el-col :span="12">
                    <el-button-group>
                      <el-button icon="el-icon-minus" @click="decrease"></el-button>
                      <el-button icon="el-icon-plus" @click="increase"></el-button>
                    </el-button-group>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="所在位置" label-width="120px" prop="weizhi">
            <el-select v-model="deviceInfoForm.weizhi" placeholder="请选择位置" clearable>
              <el-option label="上部" :value="1"></el-option>
              <el-option label="中部" :value="2"></el-option>
              <el-option label="下部" :value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="计算修正值A" label-width="120px" prop="xza">
            <el-input
              autocomplete="off"
              v-model="deviceInfoForm.xza"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="计算修正值B" label-width="120px" prop="xzb">
            <el-input
              autocomplete="off"
              v-model="deviceInfoForm.xzb"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="计算修正值C" label-width="120px" prop="xzc">
            <el-input
              autocomplete="off"
              v-model="deviceInfoForm.xzc"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="计算修正值D" label-width="120px" prop="xzd">
            <el-input
              autocomplete="off"
              v-model="deviceInfoForm.xzd"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="备注" label-width="120px" prop="remark">
            <el-input
              type="textarea"
              v-model="deviceInfoForm.remark"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="editInfoSubmit()">保 存</el-button>
        </span>
      </el-dialog>


      


      <el-dialog
        title="导出提示"
        :visible.sync="showDownloadVisible"
        width="30%">
        <span>数据导出已执行，完成后文件将在【下载中心】展示。</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showDownloadVisible = false">取 消</el-button>
          <el-button type="primary" @click="showDownloadVisible = false">确 定</el-button>
        </span>
      </el-dialog>

    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showUploadVisible: false,
      showDownloadVisible: false,
      editDialogVisible: false,
      addDialogVisible: false,
      showBindMcuDialogVisible: false,
      showSelectComDialogVisible: false,
      queryParams: {
        projectCode: "",
        khCode: "",
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      deviceRules: {
        projectCode: [{ required: true, message: "请选择项目", trigger: "blur" }],
        deviceModel: [{ required: true, message: "请选择型号", trigger: "blur" }],
        name: [{ required: true, message: "请输入设备名称", trigger: "blur" }],
        jzmoshuzhi: [{ required: true, message: "请输入基准模数值", trigger: "blur" }],
        biaoju: [{ required: true, message: "请输入标距", trigger: "blur" }],
        konghao: [{ required: true, message: "请输入孔号", trigger: "blur" }],
        weizhi: [{ required: true, message: "请选择位置", trigger: "blur" }],
        xza: [{ required: true, message: "请输入修正值A", trigger: "blur" }],
        xzb: [{ required: true, message: "请输入修正值B", trigger: "blur" }],
        xzc: [{ required: true, message: "请输入修正值C", trigger: "blur" }],
        xzd: [{ required: true, message: "请输入修正值D", trigger: "blur" }],
      },
      projectInfoList: [],
      deviceInfoList: [],
      comDeviceInfo: "",
      mcuDeviceInfo: "",
      currentDeviceCode: "",
      currentProjectCode: "",
      deviceInfoForm: {},
      konghaoList: [],
      konghaoOrder: 1,
      deviceModelList: [],
    }
  },
  created() {
    this.getAllProject();
    this.getDeviceModel();
  },
  methods: {
    decrease() {
      if (this.konghaoOrder > 1) {
        this.konghaoOrder--;
      }
    },
    increase() {
      this.konghaoOrder++;
    },
    handleSizeChange(newSize) {
      this.queryParams.pageSize = newSize;
      this.getDeviceList();
    },
    handleCurrentChange(newPage) {
      this.queryParams.pageNo = newPage;
      this.getDeviceList();
    },
    async getDeviceModel() {
      const { data: res } = await this.$http.get("device/find/models", {
        params: {
          deviceType: 40
        }
      });
      if (res.code == 0) {
        this.deviceModelList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getAllProject() {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        this.projectInfoList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    searchByProject(){
      this.getDeviceList();
      this.getKonghaoList(this.queryParams.projectCode);
    },
    async getDeviceList() {
      const { data: res } = await this.$http.post("device/cexie/find/page", this.queryParams);
      if (res.code == 0) {
        if (res.data == null) {
          this.deviceInfoList = [];
          this.total = 0;
          return;
        }

        this.deviceInfoList = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.error(res.msg);
      }
    },
    async showMcuInfo(mcuDeviceCode) {
      const { data: res } = await this.$http.get("device/find/bycode", {
        params: {
          deviceCode: mcuDeviceCode
        },
        showLoading: false
      });
      if (res.code == 0) {
        this.mcuDeviceInfo = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async showComInfo(comCode) {
      const { data: res } = await this.$http.get("virtual/com/find/bycode", {
        params: {
          comCode: comCode
        },
        showLoading: false
      });
      if (res.code == 0) {
        this.comDeviceInfo = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async deviceStateChanged(devInfo) {
      const confirmResult = await this.$confirm(
        "此操作将影响平台收集设备数据, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        this.$message.info("已取消操作");
        devInfo.availableBl = !devInfo.availableBl;
        return false;
      }

      const { data: res } = await this.$http.get("device/change/status", {
        params: {
          deviceCode: devInfo.deviceCode,
          available: devInfo.availableBl
        }
      });

      if (res.code == 0) {
        this.$message.success("修改成功");
      } else {
        this.$message.error(res.msg);
      }
    },
    mcuSettingDialog(deviceInfo) {
      this.currentProjectCode = deviceInfo.projectCode;
      this.currentDeviceCode = deviceInfo.deviceCode;
      this.showBindMcuDialogVisible = true;
    },
    comSettingDialog(deviceCode) {
      this.currentDeviceCode = deviceCode;
      this.showSelectComDialogVisible = true;
    },
    showAddDialog() {
      this.deviceInfoForm = {};
      this.addDialogVisible = true;
    },
    addDialogClosed() {
      this.$refs.addDeviceInfoRef.resetFields();
    },
    async addInfoSubmit() {
      this.deviceInfoForm.sortNum = this.konghaoOrder;
      this.$refs.addDeviceInfoRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "device/cexie/add",
            this.deviceInfoForm
          );
          if (res.code == 0) {
            this.$message.success("添加成功");
            this.getDeviceList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.addDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    addDialogClosed() {
      this.$refs.addDeviceInfoRef.resetFields();
    },
    addDeviceImgChange(url) {
      this.deviceInfoForm.deviceImg = url;
    },
    addDeviceImgRemove() {
      this.deviceInfoForm.deviceImg = "";
    },
    async removeByDeviceCode(deviceCode) {
      const confirmResult = await this.$confirm("是否确认删除该设备?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.delete("device/cexie/remove/" + deviceCode);
      if (res.code == 0) {
        this.$message.success("删除成功！");
        this.getDeviceList();
      } else {
        return this.$message.error(res.msg);
      }
    },
    async showEditDialog(deviceCode) {
      const { data: res } = await this.$http.get("device/cexie/find/bycode/" + deviceCode);
      if (res.code == 0) {
        this.deviceInfoForm = res.data;
        this.konghaoOrder = res.data.sortNum;
        this.getKonghaoList(res.data.projectCode);
        this.editDialogVisible = true;
      } else {
        this.$message.error(res.msg);
      }
    },
    async editInfoSubmit() {
      this.deviceInfoForm.sortNum = this.konghaoOrder;
      this.$refs.editDeviceInfoRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "device/cexie/update",
            this.deviceInfoForm
          );
          if (res.code == 0) {
            this.$message.success("修改成功");
            this.getDeviceList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.editDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    afterBindCom() {
      this.getDeviceList();
    },
    cancelBindMcu() {
      this.showBindMcuDialogVisible = false;
    },
    bindMcuSubmit() {
      this.showBindMcuDialogVisible = false;
      this.getDeviceList();
    },
    async getKonghaoList(projectCode) {
      const { data: res } = await this.$http.get("kh/find/byPage", {
        params: {
          pageNo: 1,
          pageSize: 900,
          projectCode: projectCode
        }
      });
      if (res.code == 0) {
        if (res.data == null) {
          this.konghaoList = [];
          return;
        }

        this.konghaoList = res.data.list;
      }
    },
    async exportDevices(){
      if (this.queryParams.projectCode == null || this.queryParams.projectCode == "") {
        this.$message.error("请选择项目.");
        return;
      }
      const { data: res } = await this.$http.post("file/cexie/export", this.queryParams);
      if (res.code == 0) {
        this.showDownloadVisible = true;
      } else {
        this.$message.error(res.msg);
      }
    },
    showUploadView() {
      this.showUploadVisible = true;
    },
    batchAddDevices() {
      this.$refs.deviceInfoUploadRef.submitUpload();
    },
    batchAddDevicesSuccess() {
      this.getDeviceList();
    }
  }
}
</script>

<style lang="less" scoped>

</style>