<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>设备管理</el-breadcrumb-item>
      <el-breadcrumb-item>MCU管理</el-breadcrumb-item>
      <el-breadcrumb-item>MCU列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图 -->
    <el-card>
      <el-row>
        <el-col :span="4">
          <el-select
            v-model="mcuQueryParams.projectCode"
            placeholder="请选择项目"
            clearable
            @clear="getMCUList"
            @change="getMCUList"
          >
            <el-option
              v-for="item in projectInfoList"
              :key="item.projectCode"
              :label="item.projectName"
              :value="item.projectCode"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="10">
          <!-- <el-button
            type="primary"
            @click="showAddDialog"
            icon="el-icon-plus"
            >添加MCU</el-button
          > -->
          <el-button
            @click="exportDevices"
            type="success"
            icon="el-icon-download"
            v-has="'sys:device:mcu:export'"
            >导出</el-button
          >
        </el-col>
      </el-row>

      <!-- 表格数据 -->
      <el-table :data="mcuInfoList" border stripe @selection-change="handleSelectionChange">
        <el-table-column type="expand">
          <template slot-scope="props">
            <span v-if="props.row.remark == null || props.row.remark == ''">无备注</span>
            <span v-else>{{ props.row.remark }}</span>
          </template>
        </el-table-column>
        <el-table-column label="设备名称" prop="name"></el-table-column>
        <el-table-column label="设备行政编号" prop="deviceGovCode"></el-table-column>
        <el-table-column label="MCU SN" prop="sn"></el-table-column>
        <el-table-column label="箱号" prop="boxIndex"></el-table-column>
        <el-table-column label="采集间隔（min）" prop="collectInterval"></el-table-column>
        <el-table-column label="通讯方式" prop="connectType">
          <template slot-scope="scope">
            <span v-if="scope.row.connectType == 1">串口</span>
            <span v-else>GPRS</span>
          </template>
        </el-table-column>
        <el-table-column label="关联串口" prop="comCode" width="90px">
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.comCode === null || scope.row.comCode === ''"
              type="info"
              >未关联</el-tag
            >
                <el-popover
                  v-else
                  placement="top-start"
                  width="200"
                  trigger="hover"
                  @show="showComInfo(scope.row.comCode)"
                  :content="comDeviceInfo">
                  <el-tag slot="reference" type="success">已关联</el-tag>
                </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="是否有效" width="80px">
          <template slot-scope="scope">
            <el-switch
              disabled
              v-model="scope.row.availableBl"
              @change="deviceStateChanged(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="createTime" width="150px"></el-table-column>
        <!-- <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="关联串口"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="success"
                icon="el-icon-connection"
                @click="comSettingDialog(scope.row.deviceCode)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="修改"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                @click="showEditDialog(scope.row.deviceCode)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                @click="removeByDeviceCode(scope.row.deviceCode)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column> -->
      </el-table>

      <!-- 分页组件 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="mcuQueryParams.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="mcuQueryParams.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>

      <!-- 添加MCU -->
      <el-dialog
        title="添加MCU"
        :visible.sync="addMcuDialogVisible"
        width="50%"
        @close="addDialogClosed"
      >
        <div style="height:350px; overflow: auto;">
        <el-form :model="mcuInfoForm" :rules="mcuRules" ref="addMcuInfoRef">
          <el-form-item label="所属项目" label-width="120px" prop="projectCode">
            <el-select
              v-model="mcuInfoForm.projectCode"
              placeholder="请选择项目"
              clearable
            >
              <el-option
                v-for="item in projectInfoList"
                :key="item.projectCode"
                :label="item.projectName"
                :value="item.projectCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备型号" label-width="120px" prop="deviceModel">
            <el-select v-model="mcuInfoForm.deviceModel" placeholder="请选择型号" clearable>
              <el-option
                v-for="item in deviceModelList"
                :key="item.modelCode"
                :label="item.modelName"
                :value="item.modelCode"
                >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备名称" label-width="120px" prop="name">
            <el-input
              autocomplete="off"
              v-model="mcuInfoForm.name"
              clearable
              placeholder="最多输入25个字"
            ></el-input>
          </el-form-item>
          <el-form-item label="设备行政编号" label-width="120px" prop="deviceGovCode">
            <el-input
              autocomplete="off"
              v-model="mcuInfoForm.deviceGovCode"
              clearable
              placeholder="格式：AABBCCDDDDEEFF，用于上报应急管理系统"
            ></el-input><br/>
            <span style="color: rgb(175, 175, 175);">
              省[AA]市[BB]县/区[CC]尾矿库[DDDD]监测设备类型[EE]设备位号[FF]
            </span>
          </el-form-item>
          <el-form-item label="箱号" label-width="120px" prop="boxIndex">
            <el-input
              autocomplete="off"
              v-model="mcuInfoForm.boxIndex"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="采集间隔" label-width="120px" prop="collectInterval">
            <el-input
              autocomplete="off"
              v-model="mcuInfoForm.collectInterval"
              clearable
            >
            <template slot="append">min</template>
            </el-input>
          </el-form-item>
          <el-form-item label="通讯方式" label-width="120px" prop="connectType">
            <el-select v-model="mcuInfoForm.connectType" placeholder="请选择通讯方式" clearable>
              <el-option label="串口" :value="1"></el-option>
              <el-option label="GPRS" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注" label-width="120px" prop="remark">
            <el-input
              type="textarea"
              v-model="mcuInfoForm.remark"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addMcuDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addMcuInfoSubmit()">提 交</el-button>
        </span>
      </el-dialog>



      <!-- 编辑MCU -->
      <el-dialog
        title="编辑MCU"
        :visible.sync="editMcuDialogVisible"
        width="50%"
      >
        <div style="height:350px; overflow: auto;">
        <el-form :model="mcuInfoForm" :rules="mcuRules" ref="editMcuInfoRef">
          <el-form-item label="所属项目" label-width="120px" prop="projectCode">
            <el-select
              v-model="mcuInfoForm.projectCode"
              placeholder="请选择项目"
              clearable
            >
              <el-option
                v-for="item in projectInfoList"
                :key="item.projectCode"
                :label="item.projectName"
                :value="item.projectCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备型号" label-width="120px" prop="deviceModel">
            <el-select v-model="mcuInfoForm.deviceModel" placeholder="请选择型号" clearable>
              <el-option
                v-for="item in deviceModelList"
                :key="item.modelCode"
                :label="item.modelName"
                :value="item.modelCode"
                >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备名称" label-width="120px" prop="name">
            <el-input
              autocomplete="off"
              v-model="mcuInfoForm.name"
              clearable
              placeholder="最多输入25个字"
            ></el-input>
          </el-form-item>
          <el-form-item label="设备行政编号" label-width="120px" prop="deviceGovCode">
            <el-input
              autocomplete="off"
              v-model="mcuInfoForm.deviceGovCode"
              clearable
              placeholder="格式：AABBCCDDDDEEFF，用于上报应急管理系统"
            ></el-input><br/>
            <span style="color: rgb(175, 175, 175);">
              省[AA]市[BB]县/区[CC]尾矿库[DDDD]监测设备类型[EE]设备位号[FF]
            </span>
          </el-form-item>
          <el-form-item label="箱号" label-width="120px" prop="boxIndex">
            <el-input
              autocomplete="off"
              v-model="mcuInfoForm.boxIndex"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="采集间隔" label-width="120px" prop="collectInterval">
            <el-input
              autocomplete="off"
              v-model="mcuInfoForm.collectInterval"
              clearable
            >
            <template slot="append">min</template>
            </el-input>
          </el-form-item>
          <el-form-item label="通讯方式" label-width="120px" prop="connectType">
            <el-select v-model="mcuInfoForm.connectType" placeholder="请选择通讯方式" clearable>
              <el-option label="串口" :value="1"></el-option>
              <el-option label="GPRS" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注" label-width="120px" prop="remark">
            <el-input
              type="textarea"
              v-model="mcuInfoForm.remark"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editMcuDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="editMcuInfoSubmit()">保 存</el-button>
        </span>
      </el-dialog>

      <el-dialog
        title="导出提示"
        :visible.sync="showDownloadVisible"
        width="30%">
        <span>数据导出已执行，完成后文件将在【下载中心】展示。</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showDownloadVisible = false">取 消</el-button>
          <el-button type="primary" @click="showDownloadVisible = false">确 定</el-button>
        </span>
      </el-dialog>


      <el-dialog
        title="设备导入"
        :visible.sync="showUploadVisible"
        v-if="showUploadVisible"
        width="30%">  
        <v-device-info-upload :deviceType="90" @uploadOk="batchAddDevicesSuccess" ref="deviceInfoUploadRef"></v-device-info-upload>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showUploadVisible = false">取 消</el-button>
          <el-button type="primary" @click="batchAddDevices">确 定</el-button>
        </span>
      </el-dialog>


    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showUploadVisible: false,
      showDownloadVisible: false,
      addMcuDialogVisible: false,
      editMcuDialogVisible: false,
      showSelectComDialogVisible: false,
      mcuQueryParams: {
        projectCode: "",
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      mcuRules: {
        name: [{ required: true, message: "请输入名字", trigger: "blur" }],
        deviceModel: [{ required: true, message: "请选择型号", trigger: "blur" }],
        boxIndex: [{ required: true, message: "请输入箱号", trigger: "blur" }],
        connectType: [{ required: true, message: "请输入通讯方式", trigger: "blur" }],
        projectCode: [{ required: true, message: "请选择项目", trigger: "blur" }],
      },
      projectInfoList: [],
      mcuInfoList: [],
      mcuInfoForm: {},
      currentDeviceCode: "",
      comDeviceInfo: "",
      mcuCodes: [],
      deviceModelList: [],
    };
  },
  created() {
    this.getAllProject();
    this.getDeviceModel();
  },
  methods: {
    handleSizeChange(newSize) {
      this.mcuQueryParams.pageSize = newSize;
      this.getMCUList();
    },
    handleCurrentChange(newPage) {
      this.mcuQueryParams.pageNo = newPage;
      this.getMCUList();
    },
    async getDeviceModel() {
      const { data: res } = await this.$http.get("device/find/models", {
        params: {
          deviceType: 90
        }
      });
      if (res.code == 0) {
        this.deviceModelList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getAllProject() {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        this.projectInfoList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getMCUList() {
      const { data: res } = await this.$http.get("device/mcu/find/page", {
        params: this.mcuQueryParams
      });
      if (res.code == 0) {
        if (res.data == null) {
          this.mcuInfoList = [];
          this.total = 0;
          return;
        }

        this.mcuInfoList = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.error(res.msg);
      }
    },
    showAddDialog() {
      this.mcuInfoForm = {};
      this.addMcuDialogVisible = true;
    },
    async showEditDialog(deviceCode) {
      const { data: res } = await this.$http.get("device/mcu/find/bycode/" + deviceCode);
      if (res.code == 0) {
        this.mcuInfoForm = res.data;
        this.editMcuDialogVisible = true;
      } else {
        this.$message.error(res.msg);
      }
    },
    async removeByDeviceCode(deviceCode) {
      const confirmResult = await this.$confirm("是否确认删除该设备?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.delete("device/mcu/remove/" + deviceCode);
      if (res.code == 0) {
        this.$message.success("删除成功！");
        this.getMCUList();
      } else {
        return this.$message.error(res.msg);
      }
    },
    addDialogClosed() {
      this.$refs.addMcuInfoRef.resetFields();
    },
    async addMcuInfoSubmit() {
      this.$refs.addMcuInfoRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "device/mcu/add",
            this.mcuInfoForm
          );
          if (res.code == 0) {
            this.$message.success("添加成功");
            this.getMCUList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.addMcuDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    async editMcuInfoSubmit() {
      this.$refs.editMcuInfoRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "device/mcu/update",
            this.mcuInfoForm
          );
          if (res.code == 0) {
            this.$message.success("修改成功");
            this.getMCUList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.editMcuDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    comSettingDialog(deviceCode) {
      this.currentDeviceCode = deviceCode;
      this.showSelectComDialogVisible = true;
    },
    afterBindCom() {
      this.getMCUList();
    },
    async deviceStateChanged(devInfo) {
      const confirmResult = await this.$confirm(
        "此操作将影响平台收集设备数据, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        this.$message.info("已取消操作");
        devInfo.availableBl = !devInfo.availableBl;
        return false;
      }

      const { data: res } = await this.$http.get("device/change/status", {
        params: {
          deviceCode: devInfo.deviceCode,
          available: devInfo.availableBl
        }
      });

      if (res.code == 0) {
        this.$message.success("修改成功");
      } else {
        this.$message.error(res.msg);
      }
    },
    async showComInfo(comCode) {
      const { data: res } = await this.$http.get("virtual/com/find/bycode", {
        params: {
          comCode: comCode
        },
        showLoading: false
      });
      if (res.code == 0) {
        this.comDeviceInfo = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    toggleSelection(rows) {
      if (rows) {
          rows.forEach(row => {
            this.$refs.multipleTable.toggleRowSelection(row);
          });
        } else {
          this.$refs.multipleTable.clearSelection();
        }
      },
    handleSelectionChange(val) {
      this.mcuCodes = [];
      val.forEach(e => {
        this.mcuCodes.push(e.deviceCode);
      });
    },
    async initMcuInfo() {
      if (this.mcuCodes.length == 0) {
        this.$message.error("请选择一个mcu设备.");
        return;
      }

      const { data: res } = await this.$http.post("device/mcu/init/info", this.mcuCodes);
      if (res.code == 0) {
        this.$message.success(res.msg);
      } else {
        this.$message.error(res.msg);
      }
    },
    async exportDevices(){
      if (this.mcuQueryParams.projectCode == null || this.mcuQueryParams.projectCode == "") {
        this.$message.error("请选择项目.");
        return;
      }
      const { data: res } = await this.$http.get("file/mcu/export", {
        params: {
          projectCode: this.mcuQueryParams.projectCode
        }
      });
      if (res.code == 0) {
        this.showDownloadVisible = true;
      } else {
        this.$message.error(res.msg);
      }
    },
    showUploadView() {
      this.showUploadVisible = true;
    },
    batchAddDevices() {
      this.$refs.deviceInfoUploadRef.submitUpload();
    },
    batchAddDevicesSuccess() {
      this.getDeviceList();
    }
  }
};
</script>

<style lang="less" scoped>
</style>