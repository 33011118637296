<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>数据比对</el-breadcrumb-item>
      <el-breadcrumb-item>表面位移比对</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row>
        <el-col :span="3">
          <el-select
            v-model="queryParams.projectCode"
            placeholder="请选择项目"
            clearable
          >
            <el-option
              v-for="item in projectInfoList"
              :key="item.projectCode"
              :label="item.projectName"
              :value="item.projectCode"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select
            v-model="queryParams.dotType"
            placeholder="请选择类型"
            clearable
            @clear="getDeviceList"
            @change="getDeviceList"
          >
            <el-option label="GNSS监测点" :value="0"></el-option>
            <el-option label="全站仪监测点" :value="1"></el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select
            v-model="queryParams.deviceCode"
            placeholder="请选择设备"
            clearable
            @clear="getDataList"
            @change="getDataList"
          >
            <el-option
              v-for="item in deviceInfoList"
              :key="item.deviceCode"
              :label="item.name"
              :value="item.deviceCode"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="15">
          <el-date-picker
            format="yyyy-MM-dd HH:mm:ss"
            v-model="timeRange"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="triggerDate"
          >
          </el-date-picker>
          <el-button
            style="margin-left: 10px"
            type="success"
            icon="el-icon-plus"
            @click="showAddDialog"
            >添加比对</el-button
          >
        </el-col>
      </el-row>

      <!-- 表格数据 -->
      <el-table :data="dataInfoList" border>
        <el-table-column type="index" fixed></el-table-column>
        <el-table-column
          label="设备名称"
          prop="deviceName"
          fixed
        ></el-table-column>
        <el-table-column
          label="人工X变形值（mm）"
          prop="rgXv"
          width="140"
        ></el-table-column>
        <el-table-column
          label="人工Y变形值（mm）"
          prop="rgYv"
          width="140"
        ></el-table-column>
        <el-table-column
          label="人工Z变形值（mm）"
          prop="rgZv"
          width="140"
        ></el-table-column>
        <el-table-column
          label="人工测时间"
          prop="rgTime"
          width="150"
        ></el-table-column>
        <el-table-column
          label="系统X变形值（mm）"
          prop="sysXv"
          width="140"
        ></el-table-column>
        <el-table-column
          label="系统Y变形值（mm）"
          prop="sysYv"
          width="140"
        ></el-table-column>
        <el-table-column
          label="系统Z变形值（mm）"
          prop="sysZv"
          width="140"
        ></el-table-column>
        <el-table-column
          label="系统测时间"
          prop="sysTime"
          width="150"
        ></el-table-column>
        <el-table-column
          label="X差值（mm）"
          prop="cpXv"
          width="120"
        ></el-table-column>
        <el-table-column
          label="Y差值（mm）"
          prop="cpYv"
          width="120"
        ></el-table-column>
        <el-table-column
          label="Z差值（mm）"
          prop="cpZv"
          width="120"
        ></el-table-column>
        <el-table-column label="操作人" prop="userName"></el-table-column>
        <el-table-column
          label="操作时间"
          prop="createTime"
          width="150"
        ></el-table-column>
      </el-table>

      <!-- 分页组件 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <!-- 添加比对 -->
    <el-dialog
      title="添加比对"
      :visible.sync="addDialogVisible"
      width="60%"
      @close="addDialogClosed"
    >
      <div style="height: 260px; overflow: auto">
        <el-form :model="dataInfoForm" :rules="devRules" ref="addInfoRef">
          <el-form-item label="所属项目" label-width="120px" prop="projectCode">
            <el-select
              v-model="dataInfoForm.projectCode"
              placeholder="请选择项目"
              clearable
            >
              <el-option
                v-for="item in projectInfoList"
                :key="item.projectCode"
                :label="item.projectName"
                :value="item.projectCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-row>
            <el-col :span="12">
              <el-form-item
                label="请选择类型"
                label-width="120px"
                prop="dotType"
              >
                <el-select
                  v-model="dataInfoForm.dotType"
                  placeholder="请选择类型"
                  clearable
                  @change="changeDotType"
                >
                  <el-option label="GNSS监测点" :value="0"></el-option>
                  <el-option label="全站仪监测点" :value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="监测点"
                label-width="120px"
                prop="deviceCode"
              >
                <el-select
                  v-model="dataInfoForm.deviceCode"
                  placeholder="请选择设备"
                  clearable
                  @change="changeDeivces"
                >
                  <el-option
                    v-for="item in deviceInfoList"
                    :key="item.deviceCode"
                    :label="item.name"
                    :value="item.deviceCode"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="7">
              <el-form-item label="人工数据" label-width="120px" prop="rgXv">
                <el-input
                  autocomplete="off"
                  v-model="dataInfoForm.rgXv"
                  clearable
                  placeholder="X变形值"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="" label-width="10px" prop="rgYv">
                <el-input
                  autocomplete="off"
                  v-model="dataInfoForm.rgYv"
                  clearable
                  placeholder="Y变形值"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="" label-width="10px" prop="rgZv">
                <el-input
                  autocomplete="off"
                  v-model="dataInfoForm.rgZv"
                  clearable
                  placeholder="Z变形值"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="" label-width="10px" prop="rgTime">
                <el-date-picker
                  v-model="dataInfoForm.rgTime"
                  type="datetime"
                  placeholder="监测时间"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="7">
              <el-form-item label="系统数据" label-width="120px" prop="sysXv">
                <el-input
                  autocomplete="off"
                  v-model="dataInfoForm.sysXv"
                  disabled
                  placeholder="X变形值"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="" label-width="10px" prop="sysYv">
                <el-input
                  autocomplete="off"
                  v-model="dataInfoForm.sysYv"
                  disabled
                  placeholder="Y变形值"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="" label-width="10px" prop="sysZv">
                <el-input
                  autocomplete="off"
                  v-model="dataInfoForm.sysZv"
                  disabled
                  placeholder="Z变形值"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="" label-width="10px" prop="sysTime">
                <el-input
                  autocomplete="off"
                  v-model="dataInfoForm.sysTime"
                  disabled
                  placeholder="监测时间"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-button
                style="margin-left: 10px"
                type="primary"
                @click="getSystemtData"
                >获取系统数据</el-button
              >
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="7">
              <el-form-item label="对比差值" label-width="120px" prop="cpXv">
                <el-input
                  autocomplete="off"
                  v-model="dataInfoForm.cpXv"
                  disabled
                  placeholder="X差值"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="" label-width="10px" prop="cpYv">
                <el-input
                  autocomplete="off"
                  v-model="dataInfoForm.cpYv"
                  disabled
                  placeholder="Y差值"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="" label-width="10px" prop="cpZv">
                <el-input
                  autocomplete="off"
                  v-model="dataInfoForm.cpZv"
                  disabled
                  placeholder="Z差值"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8"></el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addInfoSubmit()">保存数据</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      devRules: {
        projectCode: [{ required: true, message: "请选择项目", trigger: "blur" }],
        deviceCode: [{ required: true, message: "请选择监测点", trigger: "blur" }],
        dotType: [{ required: true, message: "请选择类型", trigger: "blur" }],
        rgXv: [{ required: true, message: "请输入人工X变形值", trigger: "blur" }],
        rgYv: [{ required: true, message: "请输入人工Y变形值", trigger: "blur" }],
        rgZv: [{ required: true, message: "请输入人工Z变形值", trigger: "blur" }],
        rgTime: [{ required: true, message: "请输入人工监测时间", trigger: "blur" }],
        sysXv: [{ required: true, message: "无系统X变形值", trigger: "blur" }],
        sysYv: [{ required: true, message: "无系统Y变形值", trigger: "blur" }],
        sysZv: [{ required: true, message: "无系统Z变形值", trigger: "blur" }],
        sysTime: [{ required: true, message: "无系统监测时间", trigger: "blur" }],
        cpXv: [{ required: true, message: "无X差值", trigger: "blur" }],
        cpYv: [{ required: true, message: "无Y差值", trigger: "blur" }],
        cpZv: [{ required: true, message: "无Z差值", trigger: "blur" }],
      },
      addDialogVisible: false,
      showDownloadVisible: false,
      queryParams: {
        projectCode: "",
        deviceCode: "",
        pageNo: 1,
        pageSize: 10,
        startTime: null,
        endTime: null,
        dotType: null
      },
      total: 0,
      projectInfoList: [],
      deviceInfoList: [],
      dataInfoList: [],
      timeRange: [],
      dataInfoForm: {
        projectCode: "",
        deviceCode: "",
        deviceName: "",
        rgXv: null,
        rgYv: null,
        rgZv: null,
        rgTime: null,
        sysXv: null,
        sysYv: null,
        sysZv: null,
        sysTime: null,
        cpXv: null,
        cpYv: null,
        cpZv: null
      }
    }
  },
  created () {
    this.getAllProject();
  },
  methods: {
    triggerDate (date) {
      if (!this.common.triggerDate(date)) {
        this.$message.error('查询时间不能超过30天！');
      } else {
        this.getDataList();
      }
    },
    handleSizeChange (newSize) {
      this.queryParams.pageSize = newSize;
      this.getDataList();
    },
    handleCurrentChange (newPage) {
      this.queryParams.pageNo = newPage;
      this.getDataList();
    },
    async getAllProject () {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        this.projectInfoList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getDataList () {
      if (this.timeRange != null && this.timeRange.length > 0) {
        this.queryParams.startTime = this.common.dateFormat('YYYY-MM-dd HH:mm:ss', this.timeRange[0]);
        this.queryParams.endTime = this.common.dateFormat('YYYY-MM-dd HH:mm:ss', this.timeRange[1]);
      } else {
        this.queryParams.startTime = null;
        this.queryParams.endTime = null;
      }

      const { data: res } = await this.$http.post("device/bidui/weiyi/data", this.queryParams);
      if (res.code == 0) {
        if (res.data != null) {
          this.dataInfoList = res.data.list;
          this.total = res.data.total;
        } else {
          this.dataInfoList = [];
          this.total = 0;
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    async getDeviceList () {
      if (this.queryParams.dotType === 0) {
        const { data: res } = await this.$http.get("device/gnss/find/page", {
          params: {
            pageNo: 1,
            pageSize: 900,
            projectCode: this.queryParams.projectCode
          }
        });
        if (res.code == 0) {
          if (res.data != null) {
            this.deviceInfoList = res.data.list;
            this.getDataList();
          } else {
            this.deviceInfoList = [];
          }
        } else {
          this.$message.error(res.msg);
        }
      } else {
      }
    },
    async changeDotType (dotType) {
      if (dotType === 0) {
        const { data: res } = await this.$http.get("device/gnss/find/page", {
          params: {
            pageNo: 1,
            pageSize: 900,
            projectCode: this.dataInfoForm.projectCode
          }
        });
        if (res.code == 0) {
          if (res.data != null) {
            this.deviceInfoList = res.data.list;
          } else {
            this.deviceInfoList = [];
          }
        } else {
          this.$message.error(res.msg);
        }
      } else {
      }
    },
    showAddDialog () {
      this.addDialogVisible = true;
    },
    async getSystemtData () {
      if (this.dataInfoForm.deviceCode == null || this.dataInfoForm.deviceCode == '') {
        this.$message.error("请选择监测点");
        return;
      }
      if (this.dataInfoForm.rgXv == null || this.dataInfoForm.rgYv == null || this.dataInfoForm.rgZv == null) {
        this.$message.error("请输入人工数据");
        return;
      }

      const { data: res } = await this.$http.get("device/bidui/gnss/latest/data", {
        params: {
          deviceCode: this.dataInfoForm.deviceCode
        }
      });
      if (res.code == 0) {
        if (res.data != null) {
          this.dataInfoForm.sysXv = res.data.dVariationPlaneX;
          this.dataInfoForm.sysYv = res.data.dVariationPlaneY;
          this.dataInfoForm.sysZv = res.data.dVariationPlaneH;
          this.dataInfoForm.sysTime = res.data.time;
          this.dataInfoForm.cpXv = this.common.floatFormat(Math.abs(this.dataInfoForm.sysXv - this.dataInfoForm.rgXv), 6);
          this.dataInfoForm.cpYv = this.common.floatFormat(Math.abs(this.dataInfoForm.sysYv - this.dataInfoForm.rgYv), 6);
          this.dataInfoForm.cpZv = this.common.floatFormat(Math.abs(this.dataInfoForm.sysZv - this.dataInfoForm.rgZv), 6);
        } else {
          this.dataInfoForm.sysXv = null;
          this.dataInfoForm.sysYv = null;
          this.dataInfoForm.sysZv = null;
          this.dataInfoForm.sysTime = null;
          this.dataInfoForm.cpXv = null;
          this.dataInfoForm.cpYv = null;
          this.dataInfoForm.cpZv = null;
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    async changeDeivces (deviceCode) {
      this.deviceInfoList.forEach(o => {
        if (o.deviceCode == deviceCode) {
          this.dataInfoForm.deviceName = o.name;
          return;
        }
      });
    },
    async addInfoSubmit () {
      this.$refs.addInfoRef.validate(async (valid) => {
        if (valid) {
          // console.log(this.dataInfoForm.rgTime);
          this.dataInfoForm.rgTime = this.common.dateFormat('YYYY-MM-dd HH:mm:ss', this.dataInfoForm.rgTime);
          const { data: res } = await this.$http.post(
            "device/bidui/weiyi/add",
            this.dataInfoForm
          );
          if (res.code == 0) {
            this.$message.success("保存成功");
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.addDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    addDialogClosed () {
      this.$refs.addInfoRef.resetFields();
    }
  }
}
</script>