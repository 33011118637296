<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>设备管理</el-breadcrumb-item>
      <el-breadcrumb-item>GNSS</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row>
        <el-col :span="4">
          <el-select
            v-model="queryParams.projectCode"
            placeholder="请选择项目"
            clearable
            @clear="changeProjectCode"
            @change="changeProjectCode"
          >
            <el-option
              v-for="item in projectInfoList"
              :key="item.projectCode"
              :label="item.projectName"
              :value="item.projectCode"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row>
        <el-tabs
          type="border-card"
          v-model="tabActiveName"
          @tab-click="handleClick"
        >
          <el-tab-pane label="GNSS组管理" name="gps1">
            <v-sub-gps-group ref="gps1ref"></v-sub-gps-group>
          </el-tab-pane>
          <el-tab-pane label="GNSS设备管理" name="gps2">
            <v-sub-gps-point ref="gps2ref"></v-sub-gps-point>
          </el-tab-pane>
          <el-tab-pane label="GNSS配置" name="gps3">
            <v-sub-gps-config ref="gps3ref"></v-sub-gps-config>
          </el-tab-pane>
          <el-tab-pane label="GNSS限差" name="gps4">
            <v-sub-gps-alarm ref="gps4ref"></v-sub-gps-alarm>
          </el-tab-pane>
        </el-tabs>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import vSubGpsGroup from "./SubGpsGroup.vue";
import vSubGpsPoint from "./SubGpsPoint.vue";
import vSubGpsConfig from "./SubGpsConfig.vue";
import vSubGpsAlarm from "./SubGpsAlarm.vue";
export default {
  components: {
    vSubGpsGroup,
    vSubGpsPoint,
    vSubGpsConfig,
    vSubGpsAlarm,
  },
  data() {
    return {
      tabActiveName: "gps1",
      queryParams: {
        projectCode: "",
        pageNo: 1,
        pageSize: 10,
      },
      projectInfoList: [],
    }
  },
  created() {
    this.getAllProject();
  },
  methods: {
    handleClick(tab, event) {
      this.tabActiveName = tab.name;
      this.changeProjectCode();
    },
    async getAllProject() {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        this.projectInfoList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    changeProjectCode() {
      if (this.tabActiveName === 'gps1') {
        this.$refs.gps1ref.projectCodeChange(this.queryParams.projectCode);
      } else if (this.tabActiveName === 'gps2') {
        this.$refs.gps2ref.projectCodeChange(this.queryParams.projectCode);
      } else if (this.tabActiveName === 'gps3') {
        this.$refs.gps3ref.projectCodeChange(this.queryParams.projectCode);
      } else if (this.tabActiveName === 'gps4') {
        this.$refs.gps4ref.projectCodeChange(this.queryParams.projectCode);
      }
    },
  }
}
</script>

<style lang="less" scoped>
.el-tabs {
  margin-top: 10px;
}
</style>