<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>下载中心</el-breadcrumb-item>
      <el-breadcrumb-item>下载列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图 -->
    <el-card>
      <el-row>
        <el-col :span="24">
          <el-date-picker
            format="yyyy-MM-dd HH:mm:ss"
            v-model="timeRange"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="triggerDate">
          </el-date-picker>
          <span style="color: red; font-size: 14px; float: right;">温馨提示：已完成的下载文件仅保留30天，请及时下载.</span>
        </el-col>
      </el-row>

      <!-- 表格数据 -->
      <el-table :data="downloadInfoList"
        border stripe>
        <el-table-column label="导出内容" prop="remark"></el-table-column>
        <el-table-column label="文件名" prop="filePath"></el-table-column>
        <el-table-column label="导出状态" prop="dlStatus" width="80px">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.dlStatus === 1" type="success">已完成</el-tag>
            <el-tag v-else-if="scope.row.dlStatus === 0" type="primary">导出中</el-tag>
            <el-tag v-else type="warning">失败</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="开始时间" prop="createTime" width="180px"></el-table-column>
        <el-table-column label="完成时间" prop="finishTime" width="180px"></el-table-column>
        <el-table-column label="是否已下载" prop="isDownload" width="100px">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.isDownload === 1" type="info">已下载</el-tag>
            <el-tag v-else type="success">未下载</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80px" fixed="right">
          <template slot-scope="scope">
            <el-tooltip
              v-if="scope.row.dlStatus === 1"
              class="item"
              effect="dark"
              content="下载文件"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="success"
                icon="el-icon-download"
                @click="downlaodFile(scope.row.sn, scope.row.filePath)"
                v-has="'sys:download:file'"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>


      <!-- 分页组件 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      queryParams: {
        pageNo: 1,
        pageSize: 10,
        startTime: null,
        endTime: null,
      },
      total: 0,
      downloadInfoList: [],
      timeRange: [],
    }
  },
  created() {
    this.getDownloadList();
  },
  methods: {
    triggerDate(date) {
      if (!this.common.triggerDate(date)) {
        this.$message.error('查询时间不能超过30天！');
      } else {
        this.getDownloadList();
      }
    },
    handleSizeChange(newSize) {
      this.queryParams.pageSize = newSize;
      this.getDownloadList();
    },
    handleCurrentChange(newPage) {
      this.queryParams.pageNo = newPage;
      this.getDownloadList();
    },
    async getDownloadList(){
      if (this.timeRange != null && this.timeRange.length > 0) {
        this.queryParams.startTime = this.common.dateFormat('YYYY-MM-dd HH:mm:ss', this.timeRange[0]);
        this.queryParams.endTime = this.common.dateFormat('YYYY-MM-dd HH:mm:ss', this.timeRange[1]);
      } else {
        this.queryParams.startTime = null;
        this.queryParams.endTime = null;
      }
      const { data: res } = await this.$http.post("download/find/byPage", this.queryParams);
      if (res.code == 0) {
        this.downloadInfoList = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.error(res.msg);
      }
    },
    downlaodFile(sn, fileName) {
      this.$http(
        {
            method: 'get',
            url: 'file/download?sn=' + sn,
            headers: {
              'Content-Type': 'application/json'
            },
            responseType: 'blob'
        }).then(response => {
            // console.log(response);
            this.download(response, fileName);
        }).catch((error) => {
            this.$message.error(error);
        }
      );
    },
    download (data, fileName) {
        if (data.status == 204) {
          this.$message.error("文件不存在.");
          return;
        }

        let url = window.URL.createObjectURL(new Blob([data.data]));
        let link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    }
  }
}
</script>

<style lang="less" scoped>

</style>