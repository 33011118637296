<template>
  <div>
    <el-row :gutter="5">
      <el-col :span="12">
        <el-card>
          <div slot="header">
            <span>GNSS组列表</span>
            <!-- <el-button style="float: right; padding: 3px 0" type="text" @click="showAddDialog" v-has="'sys:device:gnss:gpadd'">添加组</el-button> -->
          </div>

          <!-- 表格数据 -->
          <el-table :data="tableInfoList" border stripe @row-click="selectDzRow">
            <el-table-column type="index"></el-table-column>
            <el-table-column label="名称" prop="name"></el-table-column>
            <el-table-column label="数据类型" prop="dataType">
              <template slot-scope="scope">
                  <span v-if="scope.row.dataType == 1">Fix数据</span>
                  <span v-else>平滑数据</span>
                </template>
            </el-table-column>
            <el-table-column label="定位类型" prop="locationType">
              <template slot-scope="scope">
                  <span v-if="scope.row.locationType == 1">静态解算</span>
                  <span v-else>动态解算</span>
                </template>
            </el-table-column>
            <el-table-column label="坐标系" prop="coordinateType">
              <template slot-scope="scope">
                  <span v-if="scope.row.coordinateType == 1">WGS84</span>
                  <span v-else>七参数</span>
                </template>
            </el-table-column>
            <!-- <el-table-column label="操作" width="120px">
              <template slot-scope="scope">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="修改"
                  placement="top"
                  :enterable="false"
                >
                  <el-button
                    type="primary"
                    icon="el-icon-edit"
                    @click="showEditDialog(scope.row.id)"
                    v-has="'sys:device:gnss:gpedit'"
                  ></el-button>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="删除"
                  placement="top"
                  :enterable="false"
                >
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    v-has="'sys:device:gnss:gpdelete'"
                    @click="removeById(scope.row.id)"
                  ></el-button>
                </el-tooltip>
              </template>
            </el-table-column> -->
          </el-table>

          <!-- 分页组件 -->
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryParams.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="queryParams.pageSize"
            layout="total, sizes, prev, pager, next"
            :total="total"
          >
          </el-pagination>


          <!-- 添加组 -->
          <el-dialog
            title="添加组"
            :visible.sync="addDialogVisible"
            width="50%"
            v-if="addDialogVisible"
            @close="addDialogClosed"
          >
            <el-form :model="dataFormObj" :rules="formRules" ref="addInfoRef">
              <el-form-item label="所属项目" label-width="120px" prop="projectCode">
                <el-select
                  v-model="dataFormObj.projectCode"
                  placeholder="请选择项目"
                  clearable
                >
                  <el-option
                    v-for="item in projectInfoList"
                    :key="item.projectCode"
                    :label="item.projectName"
                    :value="item.projectCode"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="名称" label-width="120px" prop="name">
                <el-input
                  autocomplete="off"
                  v-model="dataFormObj.name"
                  clearable
                  placeholder="最多输入15个字"
                ></el-input>
              </el-form-item>
              <el-form-item label="数据类型" label-width="120px" prop="dataType">
                <el-select v-model="dataFormObj.dataType" placeholder="请选择数据类型" clearable>
                  <el-option label="Fix数据" :value="1"></el-option>
                  <el-option label="平滑数据" :value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="定位类型" label-width="120px" prop="locationType">
                <el-select v-model="dataFormObj.locationType" placeholder="请选择定位类型" clearable>
                  <el-option label="静态解算" :value="1"></el-option>
                  <el-option label="动态解算" :value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="坐标系类型" label-width="120px" prop="coordinateType">
                <el-select v-model="dataFormObj.coordinateType" placeholder="请选择坐标系类型" clearable>
                  <el-option label="WGS84" :value="1"></el-option>
                  <el-option label="七参数" :value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="addDialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="addInfoSubmit()">提 交</el-button>
            </span>
          </el-dialog>



          <!-- 编辑点组 -->
          <el-dialog
            title="编辑点组"
            :visible.sync="editDialogVisible"
            width="50%"
            v-if="editDialogVisible"
          >
            <el-form :model="dataFormObj" :rules="formRules" ref="editInfoRef">
              <el-form-item label="所属项目" label-width="120px" prop="projectCode">
                <el-select
                  v-model="dataFormObj.projectCode"
                  placeholder="请选择项目"
                  clearable
                >
                  <el-option
                    v-for="item in projectInfoList"
                    :key="item.projectCode"
                    :label="item.projectName"
                    :value="item.projectCode"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="名称" label-width="120px" prop="name">
                <el-input
                  autocomplete="off"
                  v-model="dataFormObj.name"
                  clearable
                  placeholder="最多输入15个字"
                ></el-input>
              </el-form-item>
              <el-form-item label="数据类型" label-width="120px" prop="dataType">
                <el-select v-model="dataFormObj.dataType" placeholder="请选择数据类型" clearable>
                  <el-option label="Fix数据" :value="1"></el-option>
                  <el-option label="平滑数据" :value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="定位类型" label-width="120px" prop="locationType">
                <el-select v-model="dataFormObj.locationType" placeholder="请选择定位类型" clearable>
                  <el-option label="静态解算" :value="1"></el-option>
                  <el-option label="动态解算" :value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="坐标系类型" label-width="120px" prop="coordinateType">
                <el-select v-model="dataFormObj.coordinateType" placeholder="请选择坐标系类型" clearable>
                  <el-option label="WGS84" :value="1"></el-option>
                  <el-option label="七参数" :value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="editDialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="editInfoSubmit()">提 交</el-button>
            </span>
          </el-dialog>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card>
          <div slot="header">
            <span>选择GNSS设备</span>
          </div>
          <el-transfer 
            v-model="gnsssRef" 
            :titles="['可选设备', '已选设备']"
            :props="{
              key: 'deviceCode',
              label: 'name'
            }"
            :data="gnssList"
          ></el-transfer>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  data() {
    return {
      gnsssRef: [],
      addDialogVisible: false,
      editDialogVisible: false,
      tableInfoList: [],
      queryParams: {
        projectCode: "",
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      formRules: {
        projectCode: [{ required: true, message: "请选择项目", trigger: "blur" }],
        name: [{ required: true, message: "请输入名字", trigger: "blur" }],
        dataType: [{ required: true, message: "请选择数据类型", trigger: "blur" }],
        locationType: [{ required: true, message: "请选择定位类型", trigger: "blur" }],
        coordinateType: [{ required: true, message: "请选择坐标系类型", trigger: "blur" }],
      },
      dataFormObj: {},
      projectInfoList: [],
      gnssList: [],
      currentSelectRow: {}
    }
  },
  created() {
    this.getAllProject();
    this.getTableDataList();
  },
  methods: {
    async getAllProject() {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        this.projectInfoList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    handleSizeChange(newSize) {
      this.queryParams.pageSize = newSize;
    },
    handleCurrentChange(newPage) {
      this.queryParams.pageNo = newPage;
    },
    projectCodeChange(projectCode) {
      this.queryParams.projectCode = projectCode;
      this.getTableDataList();
    },
    async getTableDataList() {
      const { data: res } = await this.$http.get("device/gnss/gp/find/page", {
        params: this.queryParams
      });
      if (res.code == 0) {
        if (res.data == null) {
          this.tableInfoList = [];
          return;
        }
        this.tableInfoList = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.error(res.msg);
      }
    },
    showAddDialog() {
      this.dataFormObj = {};
      this.addDialogVisible = true;
    },
    addDialogClosed() {
      this.$refs.addInfoRef.resetFields();
    },
    async addInfoSubmit() {
      this.$refs.addInfoRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "device/gnss/gp/add",
            this.dataFormObj
          );
          if (res.code == 0) {
            this.$message.success("添加成功");
            this.getTableDataList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.addDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    async showEditDialog(id) {
      this.dataFormObj = {};
      const { data: res } = await this.$http.get("device/gnss/gp/find/byid/" + id);
      if (res.code == 0) {
        this.dataFormObj = res.data;
        this.editDialogVisible = true;
      } else {
        this.$message.error(res.msg);
      }
    },
    async editInfoSubmit() {
      this.$refs.editInfoRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "device/gnss/gp/update",
            this.dataFormObj
          );
          if (res.code == 0) {
            this.$message.success("修改成功");
            this.getTableDataList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.editDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    async removeById(id) {
      const confirmResult = await this.$confirm("是否确认删除该设备?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.delete("device/gnss/gp/remove/" + id);
      if (res.code == 0) {
        this.$message.success("删除成功！");
        this.getTableDataList();
      } else {
        return this.$message.error(res.msg);
      }
    },
    selectDzRow(row, column, event) {
      this.currentSelectRow = row;
      this.getUnbindGnssList(row.projectCode, row.gpCode);
      this.getMyBindGnssList(row.gpCode);
    },
    async getUnbindGnssList(projectCode, groupCode) {
      const { data: res } = await this.$http.get("device/gnss/find/unbind/list", {
        params: {
          projectCode: projectCode,
          groupCode: groupCode,
        }
      });
      if (res.code == 0) {
        if (res.data == null) {
          return;
        }
        this.gnssList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getMyBindGnssList(groupCode) {
      const { data: res } = await this.$http.get("device/gnss/find/bind/list", {
        params: {
          groupCode: groupCode,
        }
      });
      if (res.code == 0) {
        if (res.data == null) {
          return;
        }
        this.gnsssRef = [];
        res.data.forEach(o => {
          this.gnsssRef.push(o.deviceCode);
        });
      } else {
        this.$message.error(res.msg);
      }
    },
    async gnssChange(dt) {
      let body = {
          groupCode: this.currentSelectRow.gpCode,
          gnssCodes: dt,
      };
      const { data: res } = await this.$http.post("device/gnss/gp/bind/gnss", body);
      if (res.code == 0) {
        
      } else {
        this.$message.error(res.msg);
      }
    }
  }
}
</script>