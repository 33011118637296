<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>增值服务</el-breadcrumb-item>
      <el-breadcrumb-item>服务管理</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图 -->
    <el-card>
      <el-row :gutter="10">
        <el-button 
            type="primary" 
            @click="addServiceDialogShow()"
            icon="el-icon-plus"
            >添加服务</el-button
          >
      </el-row>

      <!-- 表格数据 -->
      <el-table :data="serviceList"
        ref="multipleTable"
        border stripe>
        <el-table-column label="服务名称" prop="serviceName"></el-table-column>
        <el-table-column label="资源名称" prop="rsName"></el-table-column>
        <el-table-column label="资源标识" prop="rsFlag"></el-table-column>
        <el-table-column label="备注" prop="remark"></el-table-column>
        <el-table-column label="创建时间" prop="updateTime" width="160px"></el-table-column>
        <el-table-column label="操作" width="120px">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="修改"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                @click="showEditDialog(scope.row.id)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                @click="removeServiceById(scope.row.id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>


      <!-- 分页组件 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <!-- 添加服务 -->
    <el-dialog
        title="添加服务"
        :visible.sync="addServiceDialogShowFlag"
        width="40%"
        @close="addDialogClosed"
      >
        <el-form :model="addServiceForm" :rules="addRules" ref="addServiceRef">
          <el-form-item label="服务名称" label-width="120px" prop="serviceName">
            <el-input
              autocomplete="off"
              v-model="addServiceForm.serviceName"
              clearable
              placeholder="最多输入50个字"
            ></el-input>
          </el-form-item>
          <el-form-item label="资源名称" label-width="120px" prop="rsName">
            <el-input
              autocomplete="off"
              v-model="addServiceForm.rsName"
              clearable
              placeholder="最多输入50个字"
            ></el-input>
          </el-form-item>
          <el-form-item label="资源标识" label-width="120px" prop="rsFlag">
            <el-input
              autocomplete="off"
              v-model="addServiceForm.rsFlag"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="备注" label-width="120px" prop="remark">
            <el-input
              autocomplete="off"
              v-model="addServiceForm.remark"
              clearable
              placeholder="最多输入200个字"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addServiceDialogShowFlag = false">取 消</el-button>
          <el-button type="primary" @click="addServiceInfoSubmit()">提 交</el-button>
        </span>
      </el-dialog>

      <!-- 修改服务 -->
    <el-dialog
        title="修改服务"
        :visible.sync="editServiceDialogShowFlag"
        width="40%"
        @close="editDialogClosed"
      >
        <el-form :model="editServiceForm" :rules="addRules" ref="editServiceRef">
          <el-form-item label="服务名称" label-width="120px" prop="serviceName">
            <el-input
              autocomplete="off"
              v-model="editServiceForm.serviceName"
              clearable
              placeholder="最多输入50个字"
            ></el-input>
          </el-form-item>
          <el-form-item label="资源名称" label-width="120px" prop="rsName">
            <el-input
              autocomplete="off"
              v-model="editServiceForm.rsName"
              clearable
              placeholder="最多输入50个字"
            ></el-input>
          </el-form-item>
          <el-form-item label="资源标识" label-width="120px" prop="rsFlag">
            <el-input
              autocomplete="off"
              v-model="editServiceForm.rsFlag"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="备注" label-width="120px" prop="remark">
            <el-input
              autocomplete="off"
              v-model="editServiceForm.remark"
              clearable
              placeholder="最多输入200个字"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editServiceDialogShowFlag = false">取 消</el-button>
          <el-button type="primary" @click="editServiceInfoSubmit()">提 交</el-button>
        </span>
      </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      addRules: {
        rsFlag: [
          { required: true, message: "请输入资源标识", trigger: "blur" }
        ],
        serviceName: [
          { required: true, message: "请输入服务名称", trigger: "blur" },
          {
            min: 2,
            max: 50,
            message: "最多只能输入50个字",
            trigger: "blur",
          },
        ],
        rsName: [
          { required: true, message: "请输入资源名称", trigger: "blur" },
          {
            min: 2,
            max: 50,
            message: "最多只能输入50个字",
            trigger: "blur",
          },
        ],
        remark: [
          {
            min: 2,
            max: 200,
            message: "最多只能输入200个字",
            trigger: "blur",
          },
        ]
      },
      addServiceDialogShowFlag: false,
      editServiceDialogShowFlag: false,
      queryParams: {
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      serviceList: [],
      addServiceForm: {},
      editServiceForm: {},
    }
  },
  created() {
    this.getInfoList();
  },
  methods: {
    async getInfoList() {
      const { data: res } = await this.$http.get("added/services/find/service/byPage", {
        params: this.queryParams,
      });
      if (res.code == 0) {
        this.serviceList = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.error(res.msg);
      }
    },
    handleSizeChange(newSize) {
      this.queryParams.pageSize = newSize;
      this.getInfoList();
    },
    handleCurrentChange(newPage) {
      this.queryParams.pageNo = newPage;
      this.getInfoList();
    },
    addServiceDialogShow() {
      this.addServiceDialogShowFlag = true;
    },
    addDialogClosed() {
      this.$refs.addServiceRef.resetFields();
    },
    editDialogClosed() {
      this.$refs.editServiceRef.resetFields();
    },
    addServiceInfoSubmit() {
      this.$refs.addServiceRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "added/services/newservice",
            this.addServiceForm
          );
          if (res.code == 0) {
            this.$message.success("添加成功");
            this.getInfoList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.addServiceDialogShowFlag = false;
        } else {
          return false;
        }
      });
    },
    editServiceInfoSubmit() {
      this.$refs.editServiceRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "added/services/editservice",
            this.editServiceForm
          );
          if (res.code == 0) {
            this.$message.success("修改成功");
            this.getInfoList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.editServiceDialogShowFlag = false;
        } else {
          return false;
        }
      });
    },
    async removeServiceById(id) {
      const confirmResult = await this.$confirm("是否确认删除该服务?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.delete("added/services/remove/" + id);
      if (res.code == 0) {
        this.$message.success("删除服务成功！");
        this.getInfoList();
      } else {
        return this.$message.error("删除服务失败！");
      }
    },
    async showEditDialog(id) {
      const { data: res } = await this.$http.get("added/services/find/service/" + id);
      if (res.code == 0) {
        this.editServiceForm = res.data;
        this.editServiceDialogShowFlag = true;
      } else {
        this.$message.error(res.msg);
      }
    },
  }
}
</script>

<style>

</style>