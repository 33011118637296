<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>消息管理</el-breadcrumb-item>
      <el-breadcrumb-item>短信消息</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图 -->
    <el-card>
      <el-row :gutter="10">
        <el-col :span="3">
          <el-select
            v-model="queryParams.projectCode"
            placeholder="请选择项目"
            clearable
          >
            <el-option
              v-for="item in projectInfoList"
              :key="item.projectCode"
              :label="item.projectName"
              :value="item.projectCode"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <el-date-picker
            format="yyyy-MM-dd HH:mm:ss"
            v-model="timeRange"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="triggerDate"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="4">
          <el-input
            placeholder="请输入短信内容"
            v-model="queryParams.content"
            clearable
            @clear="getSmsList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getSmsList"
            ></el-button>
          </el-input>
        </el-col>
      </el-row>

      <!-- 表格数据 -->
      <el-table :data="smsInfoList" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column
          label="接收号码"
          prop="rcPhone"
          width="110px"
        ></el-table-column>
        <el-table-column label="类型" width="80px">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.msgType === 1" type="danger">预警</el-tag>
            <el-tag v-else-if="scope.row.msgType === 2" type="success"
              >通知</el-tag
            >
            <el-tag v-else type="warning">其他</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="发送状态" width="90px">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.sendStatus === 0" type="info"
              >未发送</el-tag
            >
            <el-tag v-else-if="scope.row.sendStatus === 1" type="success"
              >已发送</el-tag
            >
            <el-tag v-else-if="scope.row.sendStatus === 2" type="danger"
              >发送失败</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="发送内容" prop="msgContent">
          <template slot-scope="scope">
            <div v-html="scope.row.msgContent"></div>
          </template>
        </el-table-column>
        <el-table-column
          label="发送时间"
          prop="sendTime"
          width="150px"
        ></el-table-column>
      </el-table>

      <!-- 分页组件 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      queryParams: {
        pageNo: 1,
        pageSize: 10,
        projectCode: "",
        content: "",
        startTime: null,
        endTime: null,
      },
      total: 0,
      projectInfoList: [],
      smsInfoList: [],
      timeRange: [],
    }
  },
  created () {
    this.getProjectInfoList();
  },
  methods: {
    triggerDate (date) {
      if (!this.common.triggerDate(date)) {
        this.$message.error('查询时间不能超过30天！');
      } else {
        this.getSmsList();
      }
    },
    async getProjectInfoList () {
      const { data: res } = await this.$http.get("project/find/by/code");
      if (res.code == 0) {
        this.projectInfoList = res.data;
      }
    },
    async getSmsList () {
      if (this.queryParams.projectCode === '') {
        this.$message.error('请选择项目');
        return false;
      }

      if (this.timeRange != null && this.timeRange.length > 0) {
        this.queryParams.startTime = this.common.dateFormat('YYYY-MM-dd HH:mm:ss', this.timeRange[0]);
        this.queryParams.endTime = this.common.dateFormat('YYYY-MM-dd HH:mm:ss', this.timeRange[1]);
      } else {
        this.queryParams.startTime = null;
        this.queryParams.endTime = null;
      }

      const { data: res } = await this.$http.post("message/find/sms/msg/page", this.queryParams);
      if (res.code == 0) {
        this.smsInfoList = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.error(res.msg);
      }
    },
    handleSizeChange (newSize) {
      this.queryParams.pageSize = newSize;
      this.getSmsList();
    },
    handleCurrentChange (newPage) {
      this.queryParams.pageNo = newPage;
      this.getSmsList();
    }
  }
}
</script>

<style lang="less" scoped>
</style>