<template>
  <div class="unreadTab">
    <!-- 卡片视图 -->
    <el-card>
      <el-row>
        <el-col :span="9">
          <el-date-picker
            format="yyyy-MM-dd HH:mm:ss"
            v-model="timeRange"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="triggerDate"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="5">
          <el-button
            type="primary"
            @click="getUnReadList()"
            icon="el-icon-refresh"
            >刷新</el-button
          >
        </el-col>
      </el-row>

      <!-- 表格数据 -->
      <el-table :data="unReadInfoList" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column label="类型" width="80px">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.msgType === 1" type="danger">预警</el-tag>
            <el-tag v-else-if="scope.row.msgType === 2" type="success"
              >通知</el-tag
            >
            <el-tag v-else type="warning">其他</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="标题"
          prop="title"
          width="180px"
        ></el-table-column>
        <el-table-column label="内容" prop="msgContent">
          <template slot-scope="scope">
            <div v-html="scope.row.msgContent"></div>
          </template>
        </el-table-column>
        <el-table-column
          label="消息时间"
          prop="createTime"
          width="160px"
        ></el-table-column>
        <el-table-column label="操作" width="120px">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="详情"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-view"
                size="mini"
                @click="showDetailDialog(scope.row.id)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="top"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="removeById(scope.row.id)"
                v-has="'sys:msg:internal:delete'"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页组件 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>

      <!-- 消息详情 -->
      <el-dialog
        title="消息详情"
        :visible.sync="msgDetailDialogVisible"
        width="40%"
        @close="getUnReadList"
      >
        <el-form :model="msgInfoForm">
          <el-form-item label="消息类型" label-width="120px">
            <el-tag v-if="msgInfoForm.msgType === 1" type="danger">预警</el-tag>
            <el-tag v-else-if="msgInfoForm.msgType === 2" type="success"
              >通知</el-tag
            >
          </el-form-item>
          <el-form-item label="消息编号" label-width="120px">
            <div v-html="msgInfoForm.msgSn"></div>
          </el-form-item>
          <el-form-item label="发送时间" label-width="120px">
            <div v-html="msgInfoForm.createTime"></div>
          </el-form-item>
          <el-form-item label="内容" label-width="120px">
            <div v-html="msgInfoForm.msgContent"></div>
          </el-form-item>
        </el-form>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "unreadTab",
  data () {
    return {
      queryParams: {
        pageNo: 1,
        pageSize: 10,
        isRead: 0,
        startTime: null,
        endTime: null,
      },
      total: 0,
      unReadInfoList: [],
      msgDetailDialogVisible: false,
      msgInfoForm: {},
      timeRange: [],
    }
  },
  mounted () {
    this.getUnReadList();
  },
  methods: {
    triggerDate (date) {
      if (!this.common.triggerDate(date)) {
        this.$message.error('查询时间不能超过30天！');
      } else {
        this.getUnReadList();
      }
    },
    async getUnReadList () {
      if (this.timeRange != null && this.timeRange.length > 0) {
        this.queryParams.startTime = this.common.dateFormat('YYYY-MM-dd HH:mm:ss', this.timeRange[0]);
        this.queryParams.endTime = this.common.dateFormat('YYYY-MM-dd HH:mm:ss', this.timeRange[1]);
      } else {
        this.queryParams.startTime = null;
        this.queryParams.endTime = null;
      }

      const { data: res } = await this.$http.post("message/find/internal/msg/page", this.queryParams);
      if (res.code == 0) {
        this.unReadInfoList = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.error(res.msg);
      }
    },
    handleSizeChange (newSize) {
      this.queryParams.pageSize = newSize;
      this.getUnReadList();
    },
    handleCurrentChange (newPage) {
      this.queryParams.pageNo = newPage;
      this.getUnReadList();
    },
    async showDetailDialog (id) {
      const { data: res } = await this.$http.get("message/read/msg", {
        params: {
          msgId: id
        },
      });
      if (res.code == 0) {
        this.msgInfoForm = res.data;
        this.msgDetailDialogVisible = true;
      } else {
        this.$message.error(res.msg);
      }
    },
    async removeById (id) {
      const confirmResult = await this.$confirm("是否确认删除该消息?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.delete("message/remove/" + id);
      if (res.code == 0) {
        this.$message.success("删除成功！");
        this.getUnReadList();
      } else {
        return this.$message.error("删除失败！");
      }
    }
  }
};
</script>