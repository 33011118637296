<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>消息管理</el-breadcrumb-item>
      <el-breadcrumb-item>消息统计</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图 -->
    <el-card>
      <el-row>
        <el-card style="height: 140px">
          <div slot="header">
            <span class="span-title">消息额度配置</span>
          </div>
          <el-col :span="6">
            <el-select
              v-model="queryParams.projectCode"
              placeholder="请选择项目"
              clearable
              @change="loadLimitByProject"
            >
              <el-option
                v-for="item in projectInfoList"
                :key="item.projectCode"
                :label="item.projectName"
                :value="item.projectCode"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="3"
            ><span style="line-height: 30px">每日可发送条数：</span></el-col
          >
          <el-col :span="3">
            <el-input
              autocomplete="off"
              v-model="smslimit"
              clearable
            ></el-input>
          </el-col>
          <el-col :span="12">
            <el-button
              @click="saveConfig"
              type="primary"
              style="margin-left: 20px"
              >保存</el-button
            >
          </el-col>
        </el-card>
      </el-row>
      <el-row :gutter="5" style="margin-top: 5px">
        <el-col :span="16">
          <el-card>
            <div slot="header">
              <span class="span-title">消息发送查询</span>
            </div>
            <el-row :gutter="10">
              <el-col :span="24">
                <el-date-picker
                  format="yyyy-MM-dd HH:mm:ss"
                  v-model="timeRange"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="triggerDate"
                >
                </el-date-picker>
              </el-col>
            </el-row>
            <el-row>
              <!-- 表格数据 -->
              <el-table :data="smsDayTotal" border stripe>
                <el-table-column type="index"></el-table-column>
                <el-table-column
                  label="项目名称"
                  prop="projectName"
                ></el-table-column>
                <el-table-column
                  label="时间范围已发送（成功/总量）"
                  width="200px"
                >
                  <template slot-scope="scope">
                    {{ scope.row.totalOk }}/{{ scope.row.total }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="每日发送上限（条）"
                  prop="smsLm"
                  width="140px"
                >
                </el-table-column>
              </el-table>
            </el-row>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card>
            <div slot="header">
              <span class="span-title">消息额度</span>
            </div>
            <el-table
              :data="smsInfoList"
              border
              stripe
              style="margin-top: 0px; padding-top: 0px"
            >
              <el-table-column
                label="项目名称"
                prop="projectName"
              ></el-table-column>
              <el-table-column
                label="每日发送上限(条)"
                prop="smsLm"
                width="120px"
              ></el-table-column>
            </el-table>
          </el-card>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      queryParams: {
        projectCode: "",
        startTime: null,
        endTime: null,
      },
      projectInfoList: [],
      smsInfoList: [],
      smsDayTotal: [],
      timeRange: [],
      smslimit: null
    }
  },
  created () {
    this.getAllProject();
    this.loadAllMsgConfig();
  },
  methods: {
    async loadAllMsgConfig () {
      const { data: res } = await this.$http.get("msg/config/list");
      if (res.code == 0) {
        this.smsInfoList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getAllProject () {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        this.projectInfoList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async loadLimitByProject () {
      const { data: res } = await this.$http.get("msg/config/load/bypro", {
        params: {
          projectCode: this.queryParams.projectCode
        }
      });
      if (res.code == 0) {
        if (res.data != null) {
          this.smslimit = res.data.smsDayLimit;
        } else {
          this.smslimit = 0;
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    async saveConfig () {
      let param = {
        projectCode: this.queryParams.projectCode,
        smsDayLimit: this.smslimit
      };
      const { data: res } = await this.$http.post("msg/config/save/record", param);
      if (res.code == 0) {
        this.$message.success("操作成功");
        this.loadAllMsgConfig();
        this.getDaySmsTotal();
      } else {
        this.$message.error(res.msg);
      }
    },
    triggerDate (date) {
      if (!this.common.triggerDate(date)) {
        this.$message.error('查询时间不能超过30天！');
      } else {
        this.getDaySmsTotal();
      }
    },
    async getDaySmsTotal () {
      if (this.timeRange != null && this.timeRange.length > 0) {
        this.queryParams.startTime = this.common.dateFormat('YYYY-MM-dd HH:mm:ss', this.timeRange[0]);
        this.queryParams.endTime = this.common.dateFormat('YYYY-MM-dd HH:mm:ss', this.timeRange[1]);
      } else {
        this.queryParams.startTime = null;
        this.queryParams.endTime = null;
      }

      const { data: res } = await this.$http.post("msg/config/day/summary", this.queryParams);
      if (res.code == 0) {
        this.smsDayTotal = res.data;
      } else {
        this.$message.error(res.msg);
      }
    }
  }
}
</script>

<style lang="less" scoped>
.span-title {
  margin-left: 5px;
}
</style>