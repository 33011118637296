<template>
  <div>
    <el-row>
      <el-col :span="24">
        <el-select
          v-model="queryParams.projectCode"
          placeholder="请选择项目"
          clearable
          @clear="getDeviceStatisc"
          @change="getDeviceStatisc"
        >
          <el-option label="全部项目" value=""></el-option>
          <el-option
            v-for="item in projectInfoList"
            :key="item.projectCode"
            :label="item.projectName"
            :value="item.projectCode"
          >
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row>
      <div style="height: 380px; overflow: auto">
        <div
          id="diagramChart"
          class="chart"
          style="width: 99%; height: 99%"
        ></div>
      </div>
    </el-row>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  data () {
    return {
      queryParams: {
        projectCode: ""
      },
      projectInfoList: [],
      deviceDetail: {
        quanzhanTotal: 0,
        gnssTotal: 0,
        cexieTotal: 0,
        shenyaTotal: 0,
        wuweiTotal: 0,
        yuliangTotal: 0,
        zuoduTotal: 0,
        gantanTotal: 0,
        chaogaoTotal: 0,
        mcuTotal: 0,
        mingquTotal: 0,
      },
      diagramChart: null,
    }
  },
  created () {
    this.getAllProject();
    this.getDeviceStatisc();
  },
  methods: {
    async getAllProject () {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        this.projectInfoList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getDeviceStatisc () {
      const { data: res } = await this.$http.get("device/find/device/type/smy", {
        params: this.queryParams
      });
      if (res.code == 0) {
        this.deviceDetail = res.data;
        this.drawData(true);
      } else {
        this.$message.error(res.msg);
        this.drawData(false);
      }
    },
    drawData (data) {
      // 基于准备好的dom，初始化echarts实例
      if (this.diagramChart == null) {
        this.diagramChart = this.$echarts.init(
          document.getElementById("diagramChart")
        );
      }

      if (!data) {
        this.diagramChart.clear();
        return;
      }

      // 无限接近0的值
      let valueData = [this.deviceDetail.quanzhanTotal, this.deviceDetail.gnssTotal, this.deviceDetail.cexieTotal, this.deviceDetail.shenyaTotal,
      this.deviceDetail.yuliangTotal, this.deviceDetail.wuweiTotal, this.deviceDetail.gantanTotal, this.deviceDetail.chaogaoTotal,
      this.deviceDetail.mcuTotal, this.deviceDetail.zuoduTotal, this.deviceDetail.mingquTotal];
      let nameData = ['全站仪', 'GNSS', '测斜仪', '渗压计', '雨量计', '水位计', '干滩', '超高', 'MCU', '浊度仪', '明渠流量计'];
      let yltotal = this.deviceDetail.quanzhanTotal + this.deviceDetail.gnssTotal +
        this.deviceDetail.cexieTotal + this.deviceDetail.shenyaTotal +
        this.deviceDetail.wuweiTotal + this.deviceDetail.yuliangTotal +
        this.deviceDetail.zuoduTotal + this.deviceDetail.gantanTotal +
        this.deviceDetail.chaogaoTotal + this.deviceDetail.mcuTotal + this.deviceDetail.mingquTotal;

      var app = {};

      app.config = {
        rotate: 90,
        align: "left",
        verticalAlign: "middle",
        position: "insideBottom",
        distance: 15,
      };

      let option = {
        title: {
          text: "项目安装设备明细（单位：个）",
          textStyle: {
            fontSize: 14,
          },
          subtext: "总量：" + yltotal + "（个）",
          subtextStyle: {
            fontSize: 12,
          }
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: [],
        },
        grid: {
          top: "90",
          bottom: "50",
          left: "60",
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          data: nameData,
          axisLabel: {
            interval: 0,
            rotate: 45,
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value} 个'
          },
        },
        series: [
          {
            name: "设备量",
            type: "bar",
            data: valueData,
            label: {
              show: true,
              position: app.config.position,
              distance: app.config.distance,
              align: app.config.align,
              verticalAlign: app.config.verticalAlign,
              rotate: app.config.rotate,
              formatter: "{c}",
              fontSize: 14,
              rich: {
                name: {},
              },
            }
          },
        ],
      };

      // 绘制图表
      this.diagramChart.clear();
      this.diagramChart.setOption(option);
    },
  }
}
</script>