<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>设备管理</el-breadcrumb-item>
      <el-breadcrumb-item>明渠流量计管理</el-breadcrumb-item>
      <el-breadcrumb-item>明渠流量计列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图 -->
    <el-card>
      <el-row>
        <el-col :span="4">
          <el-select
            v-model="queryParams.projectCode"
            placeholder="请选择项目"
            clearable
            @clear="getDeviceList"
            @change="getDeviceList"
          >
            <el-option
              v-for="item in projectInfoList"
              :key="item.projectCode"
              :label="item.projectName"
              :value="item.projectCode"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <!-- <el-button
            type="primary"
            @click="showAddDialog"
            v-has="'sys:device:wuwei:add'"
            icon="el-icon-plus"
            >添加明渠流量计</el-button
          >
          <el-button
            @click="showUploadView"
            type="warning"
            v-has="'sys:device:wuwei:import'"
            icon="el-icon-upload"
            >导入</el-button
          > -->
          <el-button
            @click="exportDevices"
            type="success"
            v-has="'sys:device:mingqu:export'"
            icon="el-icon-download"
            >导出</el-button
          >
        </el-col>
      </el-row>

      <!-- 表格数据 -->
      <el-table :data="deviceInfoList" border stripe>
        <el-table-column type="expand">
          <template slot-scope="props">
            <span v-if="props.row.remark == null || props.row.remark == ''"
              >无备注</span
            >
            <span v-else>{{ props.row.remark }}</span>
          </template>
        </el-table-column>
        <el-table-column label="图片">
          <template slot-scope="scope">
            <el-popover placement="right" width="300" trigger="hover">
              <img
                :src="COMMON_CONFIG.LOAD_DEVICE_IMG_URL + scope.row.deviceImg"
                height="300"
                width="300"
              />
              <img
                slot="reference"
                :src="COMMON_CONFIG.LOAD_DEVICE_IMG_URL + scope.row.deviceImg"
                height="50"
                width="50"
              />
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          label="设备名称"
          prop="name"
          width="100px"
        ></el-table-column>
        <el-table-column
          label="设备行政编号"
          prop="deviceGovCode"
          width="120px"
        ></el-table-column>
        <el-table-column
          label="分机编号"
          prop="addr"
          width="80px"
        ></el-table-column>
        <el-table-column
          label="仪器高程（M）"
          prop="yqgaocheng"
          width="110px"
        ></el-table-column>
        <el-table-column
          label="采集间隔（min）"
          prop="collectInterval"
          width="120px"
        ></el-table-column>
        <el-table-column label="关联MCU" prop="mcuDeviceCode" width="90px">
          <template slot-scope="scope">
            <el-tag
              v-if="
                scope.row.mcuDeviceCode === null ||
                scope.row.mcuDeviceCode === ''
              "
              type="info"
              >未关联</el-tag
            >
            <el-popover
              v-else
              placement="top-start"
              width="200"
              trigger="hover"
              @show="showMcuInfo(scope.row.mcuDeviceCode)"
              :content="mcuDeviceInfo"
            >
              <el-tag slot="reference" type="success">已关联</el-tag>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="关联串口" prop="comCode" width="90px">
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.comCode === null || scope.row.comCode === ''"
              type="info"
              >未关联</el-tag
            >
            <el-popover
              v-else
              placement="top-start"
              width="200"
              trigger="hover"
              @show="showComInfo(scope.row.comCode)"
              :content="comDeviceInfo"
            >
              <el-tag slot="reference" type="success">已关联</el-tag>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="是否有效" width="80px">
          <template slot-scope="scope">
            <el-switch
              disabled
              v-model="scope.row.availableBl"
              @change="deviceStateChanged(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          label="最新采集时间"
          prop="collectTime"
          width="150px"
        ></el-table-column>
        <el-table-column label="创建日期" prop="createTime" width="100px">
          <template slot-scope="scope">
            {{
              common.dateFormat(
                "YYYY-MM-dd",
                new Date(Date.parse(scope.row.createTime))
              )
            }}
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页组件 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <el-dialog title="导出提示" :visible.sync="showDownloadVisible" width="30%">
      <span>数据导出已执行，完成后文件将在【下载中心】展示。</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDownloadVisible = false">取 消</el-button>
        <el-button type="primary" @click="showDownloadVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    var checkDeviceName = async (rule, value, callback) => {
      if (value == null) {
        return callback()
      }

      if (value.length == 0) {
        return callback()
      }
      // 验证平台是否已存在
      const { data: res } = await this.$http.get('device/devicename/exist', {
        params: {
          deviceName: value,
          deviceCode: this.deviceInfoForm.deviceCode
        }
      });
      if (res.code == 0) {
        // 未被使用时返回0
        if (res.data == 0) {
          return callback()
        }
      }

      callback(new Error('名称已被使用'))
    }
    return {
      showUploadVisible: false,
      showDownloadVisible: false,
      editDialogVisible: false,
      addDialogVisible: false,
      showBindMcuDialogVisible: false,
      showSelectComDialogVisible: false,
      queryParams: {
        projectCode: "",
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      deviceRules: {
        projectCode: [{ required: true, message: "请选择项目", trigger: "blur" }],
        deviceModel: [{ required: true, message: "请选择型号", trigger: "blur" }],
        name: [{ required: true, message: "请输入设备名称", trigger: "blur" },
        { validator: checkDeviceName, trigger: 'blur' }],
        addr: [{ required: true, message: "请输入分机编号", trigger: "blur" }],
        yqgaocheng: [{ required: true, message: "请输入仪器高程", trigger: "blur" }],
        collectInterval: [{ required: true, message: "请输入采集间隔", trigger: "blur" }],
      },
      projectInfoList: [],
      deviceInfoList: [],
      comDeviceInfo: "",
      mcuDeviceInfo: "",
      currentDeviceCode: "",
      currentProjectCode: "",
      deviceInfoForm: {},
      deviceModelList: [],
    }
  },
  created () {
    this.getAllProject();
    this.getDeviceModel();
  },
  methods: {
    handleCommand (cmd) {
      let op = cmd.op;
      switch (op) {
        case 1:
          this.mcuSettingDialog(cmd.row);
          break;
        case 2:
          this.comSettingDialog(cmd.row.deviceCode);
          break;
        case 3:
          this.showEditDialog(cmd.row.deviceCode);
          break;
        case 4:
          this.removeByDeviceCode(cmd.row.deviceCode);
          break;
        case 5:
          this.sendCmdByDeviceCode(cmd.row.deviceCode);
          break;
        default:
          break;
      }
    },
    handleSizeChange (newSize) {
      this.queryParams.pageSize = newSize;
      this.getDeviceList();
    },
    handleCurrentChange (newPage) {
      this.queryParams.pageNo = newPage;
      this.getDeviceList();
    },
    async getDeviceModel () {
      const { data: res } = await this.$http.get("device/find/models", {
        params: {
          deviceType: 22
        }
      });
      if (res.code == 0) {
        this.deviceModelList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getAllProject () {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        this.projectInfoList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async getDeviceList () {
      const { data: res } = await this.$http.get("device/mingqu/find/page", {
        params: this.queryParams
      });
      if (res.code == 0) {
        if (res.data == null) {
          this.deviceInfoList = [];
          this.total = 0;
          return;
        }

        this.deviceInfoList = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.error(res.msg);
      }
    },
    async showMcuInfo (mcuDeviceCode) {
      const { data: res } = await this.$http.get("device/find/bycode", {
        params: {
          deviceCode: mcuDeviceCode
        },
        showLoading: false
      });
      if (res.code == 0) {
        this.mcuDeviceInfo = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async showComInfo (comCode) {
      const { data: res } = await this.$http.get("virtual/com/find/bycode", {
        params: {
          comCode: comCode
        },
        showLoading: false
      });
      if (res.code == 0) {
        this.comDeviceInfo = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async deviceStateChanged (devInfo) {
      const confirmResult = await this.$confirm(
        "此操作将影响平台收集设备数据, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        this.$message.info("已取消操作");
        devInfo.availableBl = !devInfo.availableBl;
        return false;
      }

      const { data: res } = await this.$http.get("device/change/status", {
        params: {
          deviceCode: devInfo.deviceCode,
          available: devInfo.availableBl
        }
      });

      if (res.code == 0) {
        this.$message.success("修改成功");
      } else {
        this.$message.error(res.msg);
      }
    },
    mcuSettingDialog (deviceInfo) {
      this.currentProjectCode = deviceInfo.projectCode;
      this.currentDeviceCode = deviceInfo.deviceCode;
      this.showBindMcuDialogVisible = true;
    },
    comSettingDialog (deviceCode) {
      this.currentDeviceCode = deviceCode;
      this.showSelectComDialogVisible = true;
    },
    showAddDialog () {
      this.deviceInfoForm = {};
      this.addDialogVisible = true;
    },
    addDialogClosed () {
      this.$refs.addDeviceInfoRef.resetFields();
    },
    async addInfoSubmit () {
      this.$refs.addDeviceInfoRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "device/mingqu/add",
            this.deviceInfoForm
          );
          if (res.code == 0) {
            this.$message.success("添加成功");
            this.getDeviceList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.addDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    addDialogClosed () {
      this.$refs.addDeviceInfoRef.resetFields();
    },
    addDeviceImgChange (url) {
      this.deviceInfoForm.deviceImg = url;
    },
    addDeviceImgRemove () {
      this.deviceInfoForm.deviceImg = "";
    },
    async removeByDeviceCode (deviceCode) {
      const confirmResult = await this.$confirm("是否确认删除该设备?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.delete("device/mingqu/remove/" + deviceCode);
      if (res.code == 0) {
        this.$message.success("删除成功！");
        this.getDeviceList();
      } else {
        return this.$message.error(res.msg);
      }
    },
    async showEditDialog (deviceCode) {
      const { data: res } = await this.$http.get("device/mingqu/find/bycode/" + deviceCode);
      if (res.code == 0) {
        this.deviceInfoForm = res.data;
        this.editDialogVisible = true;
      } else {
        this.$message.error(res.msg);
      }
    },
    async editInfoSubmit () {
      this.$refs.editDeviceInfoRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "device/mingqu/update",
            this.deviceInfoForm
          );
          if (res.code == 0) {
            this.$message.success("修改成功");
            this.getDeviceList();
          } else {
            this.$message.error(res.msg);
            return false;
          }
          this.editDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    afterBindCom () {
      this.getDeviceList();
    },
    cancelBindMcu () {
      this.showBindMcuDialogVisible = false;
    },
    bindMcuSubmit () {
      this.showBindMcuDialogVisible = false;
      this.getDeviceList();
    },
    async exportDevices () {
      if (this.queryParams.projectCode == null || this.queryParams.projectCode == "") {
        this.$message.error("请选择项目.");
        return;
      }
      const { data: res } = await this.$http.get("file/mingqu/export", {
        params: {
          projectCode: this.queryParams.projectCode
        }
      });
      if (res.code == 0) {
        this.showDownloadVisible = true;
      } else {
        this.$message.error(res.msg);
      }
    },
    showUploadView () {
      this.showUploadVisible = true;
    },
    batchAddDevices () {
      this.$refs.deviceInfoUploadRef.submitUpload();
    },
    batchAddDevicesSuccess () {
      this.getDeviceList();
    },
    async sendCmdByDeviceCode (deviceCode) {
      const { data: res } = await this.$http.get("device/send/cmd", {
        params: {
          deviceCode: deviceCode
        }
      });
      if (res.code == 0) {
        this.$message.success("指令已下发，请在'作业日志'列表中查看采集结果！");
      } else {
        this.$message.error(res.msg);
      }
    }
  }
}
</script>

<style lang="less" scoped>
</style>